import { languageswitchConstants } from "../constants";

export function languageswitch(state = 0, action) {
    switch (action.type) {
        case languageswitchConstants.ENGLISH:
            return 0;
        case languageswitchConstants.SPANISH:
            return 1;
        case languageswitchConstants.DEUTSCH:
            return 2;
        case languageswitchConstants.FRANCAIS:
            return 3;
        case languageswitchConstants.PORTUGUESE:
            return 4;
        case languageswitchConstants.HINDI:
            return 5;
        case languageswitchConstants.INDONESIAN:
            return 6;
        case languageswitchConstants.RUSSIAN:
            return 7;
        case languageswitchConstants.CHINESE:
            return 8;
        case languageswitchConstants.JAPANESE:
            return 9;
        case languageswitchConstants.ARABIC:
            return 10;
        case languageswitchConstants.ITALIAN:
            return 11;
        case languageswitchConstants.TURKISH:
            return 12;
        case languageswitchConstants.DUTCH:
            return 13;
        case languageswitchConstants.GREEK:
            return 14;
        default:
            return localStorage.getItem("lang") === null ? 0 : Number(localStorage.getItem("lang"));
    }
}