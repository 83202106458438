export const discussionConstants = {
    GET_REQUEST: 'DISCUSSION_GET_REQUEST',
    GET_SUCCESS: 'DISCUSSION_GET_SUCCESS',
    GET_FAILURE: 'DISCUSSION_GET_FAILURE',
    GET_FAILURE_DONE: 'DISCUSSION_GET_FAILURE_DONE',

    SAVE_REQUEST: 'DISCUSSION_SAVE_REQUEST',
    SAVE_SUCCESS: 'DISCUSSION_SAVE_SUCCESS',
    SAVE_CLEAR: 'DISCUSSION_SAVE_CLEAR',
    SAVE_FAILURE: 'DISCUSSION_SAVE_FAILURE',
    SAVE_FAILURE_DONE: 'DISCUSSION_SAVE_FAILURE_DONE',

    UPDATE_REQUEST: 'DISCUSSION_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'DISCUSSION_UPDATE_SUCCESS',
    UPDATE_CLEAR: 'DISCUSSION_UPDATE_CLEAR',
    UPDATE_FAILURE: 'DISCUSSION_UPDATE_FAILURE',
    UPDATE_FAILURE_DONE: 'DISCUSSION_UPDATE_FAILURE_DONE',

    DELETE_REQUEST: 'DISCUSSION_DELETE_REQUEST',
    DELETE_SUCCESS: 'DISCUSSION_DELETE_SUCCESS',
    DELETE_CLEAR: 'DISCUSSION_DELETE_CLEAR',
    DELETE_FAILURE: 'DISCUSSION_DELETE_FAILURE',
    DELETE_FAILURE_DONE: 'DISCUSSION_DELETE_FAILURE_DONE',
}