import { userConstants } from '../constants';
import { ProfileClass } from '../models/profile';

export function user(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_USER_REQUEST:
            return {
                user: new ProfileClass(),
                loading: true
            };
        case userConstants.GET_USER_SUCCESS:
            return {
                user: action.payload,
                loading: false
            };
        case userConstants.GET_USER_FAILURE:
            return {
                error: action.payload,
                loading: false
            };
        case userConstants.SAVE_TEMPUSER:
            return {
                user: action.payload,
                loading: false
            };
        case userConstants.SAVE_PASSWORD_REQUEST:
            return {
                loading: true
            };
        case userConstants.SAVE_PASSWORD_SUCCESS:
            return {
                loading: false
            };
        case userConstants.SAVE_PASSWORD_FAILURE:
            return {
                error: action.payload,
                loading: false
            };
        default:
            return state
    }
}

export function atname(state = {}, action) {
    switch (action.type) {
        case userConstants.CHECK_ATNAME_REQUEST:
            return {
                exist: Object.keys({ ...state.exist }).length === 0 ? false : { ...state.exist },
                done: false,
                loading: true
            };
        case userConstants.CHECK_ATNAME_SUCCESS:
            return {
                exist: action.payload[0],
                atname: action.payload[1],
                done: true,
                loading: false
            };
        case userConstants.CHECK_ATNAME_FAILURE:
            return {
                error: action.payload[0],
                atname: action.payload[1],
                exist: Object.keys({ ...state.exist }).length === 0 ? false : { ...state.exist },              
                done: true,
                loading: false,
            };
        case userConstants.CHECK_ATNAME_CLEAR:
            return {};
        default:
            return state
    }
}

export function moderation(state = {}, action) {
    switch (action.type) {
        case userConstants.MODERATION_USER_REQUEST:
            return {
                option: "",
                done: false,
                loading: true
            };
        case userConstants.MODERATION_USER_SUCCESS:
            return {
                moderation: action.payload[0],
                option: action.payload[1],
                done: true,
                loading: false
            };
        case userConstants.MODERATION_USER_FAILURE:
            return {
                error: action.payload[0],
                moderation: action.payload[1],
                done: true,
                loading: false,
            };
        case userConstants.MODERATION_USER_CLEAR:
            return {};
        default:
            return state
    }
}