import { makeStyles } from "@material-ui/core/styles";
import { Badge, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, Paper, Stack } from "@mui/material";
import Zoom from '@mui/material/Zoom';
import { styled } from '@mui/system';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Responsive, WidthProvider } from "react-grid-layout";
import { useWindowDimensions } from 'react-native';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { pageActions } from '../actions/page.actions';
import { listonespaceswitchConstants, pageConstants, spaceswitchConstants } from '../constants';
import { PostClass } from '../models/post';
import { CreatePost, DraggableLoadingPopup } from './SpaceItem';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';

import AppleIcon from '@mui/icons-material/Apple';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import FeedIcon from '@mui/icons-material/Feed';
import ForumIcon from '@mui/icons-material/Forum';
import GroupsIcon from '@mui/icons-material/Groups';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import PersonIcon from '@mui/icons-material/Person';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import StarIcon from '@mui/icons-material/Star';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { appreciationActions, discussionActions, infoActions, listonespaceswitchActions, messageActions, profilActions, spaceActions, spaceswitchActions } from '../actions';

import BalanceIcon from '@mui/icons-material/Balance';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import FacebookIcon from '@mui/icons-material/Facebook';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FeedbackIcon from '@mui/icons-material/Feedback';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import ForestIcon from '@mui/icons-material/Forest';
import GitHubIcon from '@mui/icons-material/GitHub';
import GroupIcon from '@mui/icons-material/Group';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HomeIcon from '@mui/icons-material/Home';
import InsightsIcon from '@mui/icons-material/Insights';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MoodIcon from '@mui/icons-material/Mood';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import NumbersSharpIcon from '@mui/icons-material/NumbersSharp';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import PinterestIcon from '@mui/icons-material/Pinterest';
import PsychologyIcon from '@mui/icons-material/Psychology';
import RedditIcon from '@mui/icons-material/Reddit';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import SchoolIcon from '@mui/icons-material/School';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SpaIcon from '@mui/icons-material/Spa';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import TelegramIcon from '@mui/icons-material/Telegram';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TvIcon from '@mui/icons-material/Tv';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import WarningIcon from '@mui/icons-material/Warning';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import WorkIcon from '@mui/icons-material/Work';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Draggable from "react-draggable";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import Swal from "sweetalert2";
import { FeedFilterClass } from "../models/FeedFilter";
import { Spinner } from "../spinner/spinner";
import { AdsComponent } from "./AdsComponent";
import { ButtonSpinner } from "./CustomPagination";
import { DiscordIcon, OnlyfansIcon, PatreonIcon, SignalIcon, SnapchatIcon, TikTokIcon, TinderIcon, TwitchIcon, VkontakteIcon, WeChatIcon } from './CustomSVG';

const ResponsiveGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'inherit',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    boxShadow: 'none'
}));

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});

export const CustomDiscussionPagination = ({ data, name, RenderComponent, title, pageLimit, dataLimit, spacename, urlparam, spaceid, search, shareLink }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [pages] = useState(data.length % dataLimit === 0 ? data.length / dataLimit : Math.floor(data.length / dataLimit) + 1);
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);
    const p = useSelector(state => state.page);
    const [page, setPage] = useState(p);
    const [currentPage, setCurrentPage] = useState(page);
    const s = useSelector(state => state.space.spaceList);
    const [spaceList, setSpaceList] = useState(s);

    const [post, setPost] = useState(new PostClass())
    const [numField, setNumField] = useState(1)
    const [inputText, setInputText] = useState("");
    const [characterLimit] = useState(2000);
    const [open, setOpen] = React.useState(false);

    const lin = useSelector((state) => state.authentication.loggedIn);
    const [loggedIn, setLoggedIn] = useState(lin);
    const u = useSelector((state) => state.authentication.user);
    const [user, setUser] = useState(u);
    const username = user ? user.firstname : "";
    const fullname = user ? (user.displayname ? user.displayname : (user.name ? user.firstname + " " + user.name : user.firstname)) : "";
    const profileUrl = user ? user.profileUrl : "";
    const authenticated = loggedIn && user;

    const rd = useSelector((state) => state.checkreadmessage.read?.read);
    const [read, setRead] = useState(rd);

    const [layoutOnComment, setLayoutOnComment] = useState(null);

    const [images, setImages] = useState([])
    const [uploadLimit] = useState(10)
    const [uploadPreviewLimit] = useState(100)
    const inputUpload = "inputUpload";

    const titleRef = useRef('')
    const bodyRef = useRef('')
    const hashtagsRef = useRef('')
    const link1Ref = useRef('')
    const link2Ref = useRef('')
    const link3Ref = useRef('')
    const link4Ref = useRef('')

    const ss = useSelector(state => state.spaceswitch);
    const [spaceswitch, setSpaceSwitch] = useState(ss);
    const lop = useSelector(state => state.listonespaceswitch);

    const [urlp, setUlrp] = useState(localStorage.getItem("urlparam"))
    const [spname, setSpname] = useState(localStorage.getItem("spacename"))
    const [spaceNameForFeed, setSpaceNameForFeed] = useState("")

    const ff = useSelector(state => state.feedfilter.feedfilterList);
    const [feedfilterList, setFeedfilterList] = useState(ff);
    const [filtering, setFiltering] = useState(false)

    const dff = useSelector(state => state.feedfilter.done);
    const eff = useSelector(state => state.feedfilter.error);

    const ds = useSelector(state => state.discussion.discussionList);


    const ls = useSelector(state => state.socialmedia.loading);
    const [socialLoading, setSocialLoading] = useState(ls);
    const lses = useSelector(state => state.socialmediaES.loading);
    const [socialLoadingES, setSocialLoadingES] = useState(lses);
    const lsde = useSelector(state => state.socialmediaDE.loading);
    const [socialLoadingDE, setSocialLoadingDE] = useState(lsde);
    const lsfr = useSelector(state => state.socialmediaFR.loading);
    const [socialLoadingFR, setSocialLoadingFR] = useState(lsfr);
    const lspt = useSelector(state => state.socialmediaPT.loading);
    const [socialLoadingPT, setSocialLoadingPT] = useState(lspt);
    const lshi = useSelector(state => state.socialmediaHI.loading);
    const [socialLoadingHI, setSocialLoadingHI] = useState(lshi);
    const lsindo = useSelector(state => state.socialmediaINDO.loading);
    const [socialLoadingINDO, setSocialLoadingINDO] = useState(lsindo);
    const lsru = useSelector(state => state.socialmediaRU.loading);
    const [socialLoadingRU, setSocialLoadingRU] = useState(lsru);
    const lsch = useSelector(state => state.socialmediaCH.loading);
    const [socialLoadingCH, setSocialLoadingCH] = useState(lsch);
    const lsjp = useSelector(state => state.socialmediaJP.loading);
    const [socialLoadingJP, setSocialLoadingJP] = useState(lsjp);
    const lsar = useSelector(state => state.socialmediaAR.loading);
    const [socialLoadingAR, setSocialLoadingAR] = useState(lsar);
    const lsit = useSelector(state => state.socialmediaIT.loading);
    const [socialLoadingIT, setSocialLoadingIT] = useState(lsit);
    const lstr = useSelector(state => state.socialmediaTR.loading);
    const [socialLoadingTR, setSocialLoadingTR] = useState(lstr);
    const lsnl = useSelector(state => state.socialmediaNL.loading);
    const [socialLoadingNL, setSocialLoadingNL] = useState(lsnl);
    const lsgr = useSelector(state => state.socialmediaGR.loading);
    const [socialLoadingGR, setSocialLoadingGR] = useState(lsgr);
    const lmya = useSelector(state => state.myappreciation.loading);
    const [loadingMyAppreciation, setLoadingMyAppreciation] = useState(lmya);
    const lmess = useSelector(state => state.message.loading);
    const [loadingMessage, setLoadingMessage] = useState(lmess);
    const linf = useSelector(state => state.info.loading);
    const [loadingInfo, setLoadingInfo] = useState(linf);
    const lq = useSelector(state => state.quote.loading);
    const [loadingQuote, setLoadingQuote] = useState(lq);
    const lp = useSelector(state => state.publisher.loading);
    const [loadingPublisher, setLoadingPublisher] = useState(lp);

    const [openLoadingPopup, setOpenLoadingPopup] = useState(false);
    const handleOpenLoadingPopup = (e) => {
        setOpenLoadingPopup(true);
    }
    const handleCloseLoadingPopup = () => {
        setOpenLoadingPopup(false);
    }

    const [loading, setLoading] = useState(false);
    const WAIT_CHANGE = 2000;

    const interval = () => setTimeout(() => {
        setLoading(false);
    }, WAIT_CHANGE);

    const [openShowFeedConfigPopup, setOpenShowFeedConfigPopup] = useState(false);
    const handleOpenFeedConfigPopup = (e) => {
        setOpenShowFeedConfigPopup(true);
    }
    const handleCloseShowFeedConfigPopup = () => {
        setOpenShowFeedConfigPopup(false);
    }

    const [openSignInPopup, setOpenSignInPopup] = useState(false);
    const handleOpenSignInPopup = (e) => {
        setOpenSignInPopup(true);
    }
    const handleCloseSignInPopup = () => {
        setOpenSignInPopup(false);
    }

    const handleFeedFilter = () => {

        if (authenticated) {
            var ffilter = new FeedFilterClass()
            ffilter.guideandannouncement = feedfilterList.guideandannouncement
            ffilter.socialtrendnews = feedfilterList.socialtrendnews
            ffilter.socialhealth = feedfilterList.socialhealth
            ffilter.contentcreators = feedfilterList.contentcreators
            ffilter.twitterx = feedfilterList.twitterx
            ffilter.facebook = feedfilterList.facebook
            ffilter.instagram = feedfilterList.instagram
            ffilter.telegram = feedfilterList.telegram
            ffilter.discord = feedfilterList.discord
            ffilter.tiktok = feedfilterList.tiktok
            ffilter.reddit = feedfilterList.reddit
            ffilter.tinder = feedfilterList.tinder
            ffilter.twitch = feedfilterList.twitch
            ffilter.snapchat = feedfilterList.snapchat
            ffilter.whatsapp = feedfilterList.whatsapp
            ffilter.linkedin = feedfilterList.linkedin
            ffilter.vkontakte = feedfilterList.vkontakte
            ffilter.wechat = feedfilterList.wechat
            ffilter.pinterest = feedfilterList.pinterest
            ffilter.github = feedfilterList.github
            ffilter.patreon = feedfilterList.patreon
            ffilter.signa = feedfilterList.signa
            ffilter.youtube = feedfilterList.youtube
            ffilter.onlyfans = feedfilterList.onlyfans
            ffilter.newsocialmedias = feedfilterList.newsocialmedias
            ffilter.artificialintelligence = feedfilterList.artificialintelligence
            ffilter.school = feedfilterList.school
            ffilter.finance = feedfilterList.finance
            ffilter.general = feedfilterList.general

            ffilter.crypto = feedfilterList.crypto
            ffilter.health = feedfilterList.health
            ffilter.crime = feedfilterList.crime
            ffilter.gastronomy = feedfilterList.gastronomy
            ffilter.architecture = feedfilterList.architecture
            ffilter.mentalhealth = feedfilterList.mentalhealth
            ffilter.books = feedfilterList.books
            ffilter.lifebalance = feedfilterList.lifebalance
            ffilter.relationships = feedfilterList.relationships
            ffilter.nature = feedfilterList.nature
            ffilter.universe = feedfilterList.universe
            ffilter.sport = feedfilterList.sport

            ffilter.gaming = feedfilterList.gaming
            ffilter.anime = feedfilterList.anime
            ffilter.filmandserie = feedfilterList.filmandserie
            ffilter.music = feedfilterList.music
            ffilter.meetme = feedfilterList.meetme
            ffilter.feedback = feedfilterList.feedback
            ffilter.help = feedfilterList.help
            ffilter.morepersonal = feedfilterList.morepersonal
            ffilter.tech = feedfilterList.tech
            ffilter.memes = feedfilterList.memes
            ffilter.gossip = feedfilterList.gossip

            const replacer = (key, value) => {
                if (typeof value === 'undefined')
                    return ""
                else return value;
            }
            var feedfilterInfos = JSON.parse(JSON.stringify(ffilter, replacer));

            setFiltering(true)
            handleCloseShowFeedConfigPopup()
            dispatch(discussionActions.saveFeedFilter(feedfilterInfos))
        } else {
            handleOpenSignInPopup()
        }
    }

    const useWindowSize = () => {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            const updateSize = () => {
                // actions
                setSize([window.innerWidth, window.innerHeight]);
            };
            window.addEventListener("resize", updateSize);
            updateSize();
            return () => window.removeEventListener("resize", updateSize);
        }, [window.innerWidth]);
        return size;
    };



    useWindowSize()

    const { height, width } = useWindowDimensions();


    const handleChangeNew = () => {
        setOpen((prev) => !prev);
    };

    const handleChangeFeedFilter = (e) => {
        let v = false;

        if (e.target.type === "checkbox") {
            if (e.target.value === "false" || e.target.value === false) {
                v = true;
            }
        } else {
            v = e.target.value;
        }

        setFeedfilterList({ ...feedfilterList, [e.target.name]: v });
    };

    useEffect(() => {
        if (filtering) {
            if (dff) {
                if (eff !== undefined) {
                    setFiltering(false)
                    Toast.fire({
                        icon: "error",
                        title: "An error has occurred. Please try again ..."
                    });
                } else {
                    setFiltering(false)
                    dispatch(discussionActions.getFeedFilter())
                    dispatch(discussionActions.getFeed());

                    Toast.fire({
                        icon: "success",
                        title: "Great ! You have customized your feed"
                    });
                }
            }
        }
    }, [dff]);

    useEffect(() => {
        setRead(rd)
    }, [rd])

    useEffect(() => {
        setFeedfilterList(ff)
    }, [ff])

    useEffect(() => {
        setLoggedIn(lin)
    }, [lin])

    useEffect(() => {
        setUser(u)
    }, [u])

    useEffect(() => {
        setPost(post)
    }, [post])

    useEffect(() => {
        setInputText(inputText)
    }, [inputText])

    useEffect(() => {
        setNumField(numField)
    }, [numField])

    useEffect(() => {
        setOpen(open)
    }, [open])

    useEffect(() => {
        setSpaceList(s)
    }, [s])

    // console.log(localStorage.getItem("rgl-8"))
    // console.log(getLayout(dataLimit))

    const [layouts, setLayouts] = useState({ layouts: JSON.parse(JSON.stringify(originalLayouts)) }
    );

    // console.log(layout)

    // useEffect(() => {
    //     localStorage.setItem("layout", JSON.stringify(layout));
    // }, [layout]);

    const onLayoutChange = (layout, layouts) => {
        saveToLS("layouts", layouts);
        setLayouts({ layouts });
    }


    useEffect(() => {
        localStorage.setItem("page", p)
        setPage(p);
    }, [p])

    function goToNextPage() {
        if (page < pages) {
            dispatch(pageActions.changePage(pageConstants.NEXT))
            // setCurrentPage(page);

            scrollToTop();
        }
    }

    function goToPreviousPage() {
        if (page > 1) {
            dispatch(pageActions.changePage(pageConstants.PREVIOUS))
            // setCurrentPage(page);

            scrollToTop();
        }
    }

    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        // setCurrentPage(pageNumber);
        dispatch(pageActions.changePage(pageConstants.CURRENT, pageNumber))
        scrollToTop();
    }

    const getPaginatedData = () => {
        const startIndex = page * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return data.slice(startIndex, endIndex);
    };

    const getPaginationGroup = () => {
        let start = Math.floor((page - 1) / pageLimit) * pageLimit;
        return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
    };

    const scrollToTop = () => {
        setLoading(true)

        goToTop()

        interval() // For making good the transition between pages
    }

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    const getAllInfosForSpace = () => {
        return (dispatch, getState) => {
            batch(() => {
                dispatch(appreciationActions.myAppreciation())
                dispatch(messageActions.checkReadMessage())
                dispatch(infoActions.getInfos())
            })
        }
    }

    const dispatchAll = () => {
        dispatch(getAllInfosForSpace());

        resetPage()
        setLoading(true)

        if (lop === listonespaceswitchConstants.SPACE_LIST_NR) {
            if (ss == spaceswitchConstants.FEED_NR) {
                dispatch(discussionActions.getFeedFilter())
                dispatch(discussionActions.getFeed());
            }
            if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                dispatch(spaceActions.getSpaceList());
            }
            if (ss == spaceswitchConstants.MY_SPACE_NR) {
                dispatch(discussionActions.getMyDiscussionList());
            }
            if (ss == spaceswitchConstants.LIGHT_NR) {
                dispatch(discussionActions.getLightDiscussionList());
            }
            if (ss == spaceswitchConstants.STAR_NR) {
                dispatch(profilActions.getStarProfilList());
            }
            if (ss == spaceswitchConstants.MESSAGE_NR) {
                dispatch(profilActions.getMyTalkers());
            }
            if (ss == spaceswitchConstants.FIND_NR) {
                dispatch(profilActions.getProfilList());
            }
        }

        if (lop === listonespaceswitchConstants.ONE_SPACE_NR) {
            if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                if (ds && ds.urlparam) {
                    localStorage.setItem("urlparam", ds.urlparam)
                    var urlparam = localStorage.getItem("urlparam")
                    dispatch(discussionActions.getDiscussionList(urlparam, "", false));
                } else {
                    dispatch(discussionActions.getDiscussionList("guideandannouncement", "", false));
                }
            }
            if (ss == spaceswitchConstants.MY_SPACE_NR) {
                dispatch(discussionActions.getMyDiscussionList());
            }
            if (ss == spaceswitchConstants.LIGHT_NR) {
                dispatch(discussionActions.getLightDiscussionList());
            }
            if (ss == spaceswitchConstants.STAR_NR) {
                dispatch(profilActions.getStarProfilList());
            }
            if (ss == spaceswitchConstants.MESSAGE_NR) {
                dispatch(profilActions.getMyTalkers());
            }
            if (ss == spaceswitchConstants.FIND_NR) {
                dispatch(profilActions.getProfilList());
            }
        }

        interval()
    }

    useEffect(() => {
        setTheme(t);
    }, [t])

    useEffect(() => {
        setSpaceSwitch(ss)
    }, [ss])


    useEffect(() => {
        setSocialLoading(ls);
    }, [ls]);

    useEffect(() => {
        setSocialLoadingES(lses);
    }, [lses]);

    useEffect(() => {
        setSocialLoadingDE(lsde);
    }, [lsde]);

    useEffect(() => {
        setSocialLoadingFR(lsfr);
    }, [lsfr]);

    useEffect(() => {
        setSocialLoadingPT(lspt);
    }, [lspt]);

    useEffect(() => {
        setSocialLoadingHI(lshi);
    }, [lshi]);

    useEffect(() => {
        setSocialLoadingINDO(lsindo);
    }, [lsindo]);

    useEffect(() => {
        setSocialLoadingRU(lsru);
    }, [lsru]);

    useEffect(() => {
        setSocialLoadingCH(lsch);
    }, [lsch]);

    useEffect(() => {
        setSocialLoadingJP(lsjp);
    }, [lsjp]);

    useEffect(() => {
        setSocialLoadingAR(lsar);
    }, [lsar]);

    useEffect(() => {
        setSocialLoadingIT(lsit);
    }, [lsit]);

    useEffect(() => {
        setSocialLoadingTR(lstr);
    }, [lstr]);

    useEffect(() => {
        setSocialLoadingNL(lsnl);
    }, [lsnl]);

    useEffect(() => {
        setSocialLoadingGR(lsgr);
    }, [lsgr]);

    useEffect(() => {
        setLoadingMyAppreciation(lmya);
    }, [lmya]);

    useEffect(() => {
        setLoadingMessage(lmess);
    }, [lmess]);

    useEffect(() => {
        setLoadingInfo(linf);
    }, [linf]);

    useEffect(() => {
        setLoadingQuote(lq);
    }, [lq]);

    useEffect(() => {
        setLoadingPublisher(lp);
    }, [lp]);


    var rows = 0;

    const handleClick = (e) => {

        dispatch(getAllInfosForSpace());

        resetPage()

        switch (e.target.id) {
            case "btnradio0":
                if (lop == listonespaceswitchConstants.ONE_SPACE_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.ONE_SPACE))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.ALL_SPACES))
                    // dispatch(discussionActions.getDiscussionList(urlp));
                    navigate("/space/" + urlp);
                } else if (lop == listonespaceswitchConstants.SPACE_LIST_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.FEED))
                    dispatch(discussionActions.getFeedFilter())
                    dispatch(discussionActions.getFeed());
                    dispatch(spaceActions.getSpaceList());
                    navigate("/space");
                }


                // setLoading(true);
                // interval();
                break;
            case "btnradio5":
                if (lop == listonespaceswitchConstants.ONE_SPACE_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.ONE_SPACE))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.ALL_SPACES))
                    dispatch(discussionActions.getDiscussionList(urlp, "", false));
                    navigate("/space/" + urlp);
                } else if (lop == listonespaceswitchConstants.SPACE_LIST_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.ALL_SPACES))
                    dispatch(spaceActions.getSpaceList());
                    navigate("/space");
                }

                // setLoading(true);
                // interval();
                break;
            case "btnradio6":
                if (lop == listonespaceswitchConstants.ONE_SPACE_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.ONE_SPACE))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.MY_SPACE))
                    navigate("/space/" + urlp);
                } else if (lop == listonespaceswitchConstants.SPACE_LIST_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.MY_SPACE))
                    navigate("/space");
                }
                // setLoading(true);
                dispatch(discussionActions.getMyDiscussionList());
                // interval();
                break;
            case "btnradio7":
                // console.log("light")
                if (lop == listonespaceswitchConstants.ONE_SPACE_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.ONE_SPACE))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.LIGHT))
                    navigate("/space/" + urlp);
                } else if (lop == listonespaceswitchConstants.SPACE_LIST_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.LIGHT))
                    navigate("/space");
                }

                // setLoading(true);
                dispatch(discussionActions.getLightDiscussionList());
                // interval();
                break;
            case "btnradio8":
                if (lop == listonespaceswitchConstants.ONE_SPACE_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.ONE_SPACE))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.STAR))
                    navigate("/space/" + urlp);
                } else if (lop == listonespaceswitchConstants.SPACE_LIST_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.STAR))
                    navigate("/space");
                }
                // setLoading(true);
                dispatch(profilActions.getStarProfilList());
                // interval();
                break;
            case "btnradio9":
                if (lop == listonespaceswitchConstants.ONE_SPACE_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.ONE_SPACE))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.MESSAGE))
                    navigate("/space/" + urlp);
                } else if (lop == listonespaceswitchConstants.SPACE_LIST_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.MESSAGE))
                    navigate("/space");
                }
                // setLoading(true);
                dispatch(profilActions.getMyTalkers());
                // interval();
                break;
            case "btnradio10":
                if (lop == listonespaceswitchConstants.ONE_SPACE_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.ONE_SPACE))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.FIND))
                    navigate("/space/" + urlp);
                } else if (lop == listonespaceswitchConstants.SPACE_LIST_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.FIND))
                    navigate("/space");
                }
                // setLoading(true);
                dispatch(profilActions.getProfilList());
                // interval();
                break;
        }
    }

    var styleButtonGroup;
    if (width <= 360) {
        styleButtonGroup = "btn-group btn-group-sm"
    } else {
        styleButtonGroup = "btn-group"
    }

    const goBack = () => {
        dispatch(getAllInfosForSpace());

        if (shareLink || search) {
            dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
            dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.FEED))
            dispatch(discussionActions.getFeedFilter())
            dispatch(discussionActions.getFeed());
            dispatch(spaceActions.getSpaceList());
            navigate("/space")
            resetPage();
        } else {
            navigate("/space/spaces")
            resetPage();
        }
    }

    const goToSpace = () => {
        dispatch(getAllInfosForSpace());

        //if (shareLink) {
        dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
        dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.FEED))
        dispatch(discussionActions.getFeedFilter())
        dispatch(discussionActions.getFeed());
        dispatch(spaceActions.getSpaceList());
        navigate("/space")
        resetPage();
        //}
    }

    function resetPage() {
        // dispatch(pageActions.changePage(pageConstants.RESET))
        localStorage.setItem("page", 1)
    }

    return (
        <>

            <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                <div class="marginMobil" style={{ marginRight: "30px", marginLeft: "200px" }}>
                    <div>
                        {/* <FormControlLabel
                            control={<Switch checked={checked} onChange={handleChange} />}
                            label="Show"
                        /> */}

                        <Spinner show={loading} />

                        <DraggableFeedConfigPopup openShowFeedConfigPopup={openShowFeedConfigPopup} handleCloseShowFeedConfigPopup={handleCloseShowFeedConfigPopup} handleFeedFilter={handleFeedFilter} handleChangeFeedFilter={handleChangeFeedFilter} feedfilterList={feedfilterList} />
                        <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                            <h1 style={{ color: theme === 'dark' ? '#fff' : '#000' }} >
                                {title} {<ButtonSpinner handleOpenLoadingPopup={handleOpenLoadingPopup} show={
                                    socialLoading ||
                                    socialLoadingES ||
                                    socialLoadingDE ||
                                    socialLoadingFR ||
                                    socialLoadingPT ||
                                    socialLoadingHI ||
                                    socialLoadingINDO ||
                                    socialLoadingRU ||
                                    socialLoadingCH ||
                                    socialLoadingJP ||
                                    socialLoadingAR ||
                                    socialLoadingIT ||
                                    socialLoadingTR ||
                                    socialLoadingNL ||
                                    socialLoadingGR ||
                                    loadingMyAppreciation ||
                                    loadingMessage ||
                                    loadingInfo ||
                                    loadingQuote ||
                                    loadingPublisher
                                } />}

                                <DraggableLoadingPopup openLoadingPopup={openLoadingPopup} handleCloseLoadingPopup={handleCloseLoadingPopup} />
                            </h1>
                            <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                                <div class={styleButtonGroup} role="group" aria-label="Basic radio toggle button group">
                                    {lop == listonespaceswitchConstants.SPACE_LIST_NR ?
                                        <>
                                            <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio0" autocomplete="off" checked={ss === spaceswitchConstants.FEED_NR} onClick={handleClick} />
                                            <label class="btn btn-outline-primary" for="btnradio0"><FeedIcon /></label>
                                        </> : null}

                                    <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio5" autocomplete="off" checked={ss === spaceswitchConstants.ALL_SPACES_NR} onClick={handleClick} />
                                    {lop == listonespaceswitchConstants.SPACE_LIST_NR ? <label class="btn btn-outline-primary" for="btnradio5"><GroupsIcon /></label> : null}
                                    {lop == listonespaceswitchConstants.ONE_SPACE_NR ? <label class="btn btn-outline-primary" for="btnradio5"><LogoSpace name={spname} color={theme == "dark" ? "#fff" : "#000"} /></label> : null}


                                    <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio6" autocomplete="off" checked={ss === spaceswitchConstants.MY_SPACE_NR} onClick={handleClick} disabled={!authenticated} />
                                    <label class="btn btn-outline-primary" for="btnradio6"><PersonIcon /></label>

                                    <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio7" autocomplete="off" checked={ss === spaceswitchConstants.LIGHT_NR} onClick={handleClick} disabled={!authenticated} />
                                    <label class="btn btn-outline-primary" for="btnradio7"><TipsAndUpdatesIcon /></label>

                                    <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio8" autocomplete="off" checked={ss === spaceswitchConstants.STAR_NR} onClick={handleClick} disabled={!authenticated} />
                                    <label class="btn btn-outline-primary" for="btnradio8"><StarIcon /></label>

                                    <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio9" autocomplete="off" checked={ss === spaceswitchConstants.MESSAGE_NR} onClick={handleClick} disabled={!authenticated} />
                                    <label class="btn btn-outline-primary" for="btnradio9">
                                        <Badge color="success" variant="dot" invisible={read}>
                                            <ForumIcon />
                                        </Badge>
                                    </label>

                                    <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio10" autocomplete="off" checked={ss === spaceswitchConstants.FIND_NR} onClick={handleClick} />
                                    <label class="btn btn-outline-primary" for="btnradio10"><PersonSearchIcon /></label>
                                </div>
                            </div>
                            {lop == listonespaceswitchConstants.ONE_SPACE_NR && (ss === spaceswitchConstants.ALL_SPACES_NR || ss === spaceswitchConstants.MY_SPACE_NR) ||
                                (lop == listonespaceswitchConstants.SPACE_LIST_NR && ss === spaceswitchConstants.FEED_NR)
                                ?
                                <>
                                    {!search ?
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={0}
                                            p={0}
                                        >
                                            {ss !== spaceswitchConstants.FEED_NR &&
                                                <>
                                                    <Item>
                                                        <IconButton style={{ /*marginTop: '-5px',*/ background: "#1976d2", color: "#fff" }} onClick={() => goBack()} title="Back">
                                                            <ArrowCircleLeftIcon fontSize="large" />
                                                        </IconButton>
                                                    </Item>
                                                </>
                                            }

                                            {!shareLink &&
                                                <Item>
                                                    <div style={{ background: "#1976d2", borderRadius: 30, width: 120, display: 'inline-block' }}>
                                                        <IconButton onClick={handleChangeNew} style={{ /*marginTop: '-5px',*/ marginRight: '8px', background: "#1976d2", color: "#fff", transform: open ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }}>
                                                            <ArrowCircleUpIcon fontSize="large" />
                                                        </IconButton>
                                                        <span onClick={handleChangeNew} style={{ cursor: "pointer", fontSize: '1.2em', paddingRight: 15, textTransform: 'uppercase', fontWeight: 'bold' }}>New</span>
                                                    </div>
                                                </Item>}

                                            {getPaginatedData().length !== 0 &&
                                                <>
                                                    {authenticated && ss == spaceswitchConstants.FEED_NR &&
                                                        <Item>
                                                            <IconButton style={{ background: "#1976d2", color: "#fff" }} onClick={handleOpenFeedConfigPopup} title="Feed Filter">
                                                                <MiscellaneousServicesIcon fontSize="large" />
                                                            </IconButton>
                                                        </Item>}
                                                    <Item>
                                                        <IconButton style={{ background: "#1976d2", color: "#fff" }} onClick={dispatchAll} title="Refresh">
                                                            <RefreshIcon fontSize="large" />
                                                        </IconButton>
                                                    </Item>
                                                </>
                                            }
                                        </Stack> :
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={0}
                                            p={0}
                                        >
                                            <Item>
                                                <IconButton style={{ /*marginTop: '-5px',*/ background: "#1976d2", color: "#fff" }} onClick={() => goBack()} title="Back">
                                                    <ArrowCircleLeftIcon fontSize="large" />
                                                </IconButton>
                                            </Item>
                                        </Stack>
                                    }
                                </>
                                : null}
                        </div>
                        {/* show the posts, 10 posts at a time */}
                        {getPaginatedData() && getPaginatedData().length === 0 ?
                            <>
                                {!open && lop == listonespaceswitchConstants.ONE_SPACE_NR && ss === spaceswitchConstants.ALL_SPACES_NR &&
                                    <>
                                        {search || shareLink ?
                                            <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                                                <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 870, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white">
                                                    <WarningIcon style={{ marginBottom: 5 }} />{" "}Oops! What you're looking for cannot be found
                                                </h2>
                                            </div>
                                            :
                                            <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                                                <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 900, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white">
                                                    Be the first to share News, Opinions or make Discussions in this Space
                                                </h2>
                                            </div>}
                                    </>
                                }

                                {!open && ss === spaceswitchConstants.MY_SPACE_NR &&
                                    <>
                                        {search ?
                                            <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                                                <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 870, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white">
                                                    <WarningIcon style={{ marginBottom: 5 }} />{" "}Oops! What you're looking for cannot be found
                                                </h2>
                                            </div>
                                            :
                                            <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                                                <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 900, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white">
                                                    Here you'll find your published News, Opinions or Discussions
                                                </h2>
                                            </div>}
                                    </>
                                }

                                {ss === spaceswitchConstants.LIGHT_NR &&
                                    <>
                                        {search ?
                                            <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                                                <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 870, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white">
                                                    <WarningIcon style={{ marginBottom: 5 }} />{" "}Oops! What you're looking for cannot be found
                                                </h2>
                                            </div>
                                            :
                                            <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                                                <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 870, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white">
                                                    Here you'll find News, Opinions or Discussions you gave a lumen
                                                </h2>
                                            </div>}
                                    </>
                                }

                                {ss !== spaceswitchConstants.LIGHT_NR && ss !== spaceswitchConstants.MY_SPACE_NR && (lop != listonespaceswitchConstants.ONE_SPACE_NR || ss != spaceswitchConstants.ALL_SPACES_NR) &&
                                    <>
                                        {search ?
                                            <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                                                <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 870, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white">
                                                    <WarningIcon style={{ marginBottom: 5 }} />{" "}Oops! What you're looking for cannot be found
                                                </h2>
                                            </div>
                                            :
                                            <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                                                <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 870, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white">
                                                    <WarningIcon style={{ marginBottom: 5 }} />{" "}This could be a network issue or the App is in maintenance mode. Please refresh the page{" "}<IconButton style={{ color: "#fff" }} onClick={dispatchAll} title="Load News"><RefreshIcon /></IconButton>{" "}or try again later...
                                                </h2>
                                            </div>}
                                    </>
                                }

                                {open ? <Grid
                                    container
                                    spacing={5}
                                    // className="marginLaptop"
                                    justifyItems="center"
                                // style={{ PaddingRight: "1000px" }}
                                >
                                    <Zoom in={open} style={{ transitionDelay: open ? '500ms' : '0ms' }}><Grid key={10000} item xs={12} sm={12} md={12} lg={6} xl={4}>
                                        <CreatePost
                                            key={10000}
                                            index={10000}
                                            name={lop === listonespaceswitchConstants.SPACE_LIST_NR && ss === spaceswitchConstants.FEED_NR ? spaceNameForFeed : spname}
                                            setSpaceNameForFeed={setSpaceNameForFeed}
                                            spaceid={spaceid}
                                            post={post}
                                            setPost={setPost}
                                            inputText={inputText}
                                            setInputText={setInputText}
                                            numField={numField}
                                            setNumField={setNumField}
                                            characterLimit={characterLimit}
                                            open={open}
                                            setOpen={setOpen}
                                            spacename={spacename}
                                            user={user}
                                            username={username}
                                            fullname={fullname}
                                            profileUrl={profileUrl}
                                            authenticated={authenticated}
                                            loggedIn={loggedIn}
                                            images={images}
                                            setImages={setImages}
                                            uploadLimit={uploadLimit}
                                            uploadPreviewLimit={uploadPreviewLimit}
                                            inputUpload={inputUpload}
                                            spaceList={spaceList}
                                            onFeed={lop === listonespaceswitchConstants.SPACE_LIST_NR && ss === spaceswitchConstants.FEED_NR}
                                            titleRef={titleRef}
                                            bodyRef={bodyRef}
                                            hashtagsRef={hashtagsRef}
                                            link1Ref={link1Ref}
                                            link2Ref={link2Ref}
                                            link3Ref={link3Ref}
                                            link4Ref={link4Ref}
                                        />
                                    </Grid></Zoom>
                                </Grid> : null}
                            </>
                            :
                            <Grid
                                container
                                spacing={5}
                                // className="marginLaptop"
                                justifyItems="center"
                            // style={{ PaddingRight: "1000px" }}
                            >
                                {open ? <Zoom in={open} style={{ transitionDelay: open ? '500ms' : '0ms' }}><Grid key={10000} item xs={12} sm={12} md={12} lg={6} xl={4}>
                                    <CreatePost
                                        key={10000}
                                        index={10000}
                                        name={lop === listonespaceswitchConstants.SPACE_LIST_NR && ss === spaceswitchConstants.FEED_NR ? spaceNameForFeed : spname}
                                        setSpaceNameForFeed={setSpaceNameForFeed}
                                        spaceid={spaceid}
                                        post={post}
                                        setPost={setPost}
                                        inputText={inputText}
                                        setInputText={setInputText}
                                        numField={numField}
                                        setNumField={setNumField}
                                        characterLimit={characterLimit}
                                        open={open}
                                        setOpen={setOpen}
                                        spacename={spacename}
                                        user={user}
                                        username={username}
                                        fullname={fullname}
                                        profileUrl={profileUrl}
                                        authenticated={authenticated}
                                        loggedIn={loggedIn}
                                        images={images}
                                        setImages={setImages}
                                        uploadLimit={uploadLimit}
                                        uploadPreviewLimit={uploadPreviewLimit}
                                        inputUpload={inputUpload}
                                        spaceList={spaceList}
                                        onFeed={lop === listonespaceswitchConstants.SPACE_LIST_NR && ss === spaceswitchConstants.FEED_NR}
                                        titleRef={titleRef}
                                        bodyRef={bodyRef}
                                        hashtagsRef={hashtagsRef}
                                        link1Ref={link1Ref}
                                        link2Ref={link2Ref}
                                        link3Ref={link4Ref}
                                        link4Ref={link4Ref}
                                    />
                                </Grid></Zoom> : null}

                                {getPaginatedData().map((d, idx) => (
                                    <Grid key={idx} item xs={12} sm={12} md={12} lg={6} xl={4}>
                                        <RenderComponent
                                            key={idx}
                                            index={idx}
                                            data={d}
                                            spacename={spacename}
                                            user={user}
                                            username={username}
                                            fullname={fullname}
                                            profileUrl={profileUrl}
                                            authenticated={authenticated}
                                            loggedIn={loggedIn}
                                            layoutOnComment={layoutOnComment}
                                            setLayoutOnComment={setLayoutOnComment}
                                            resetButtonid={"preview" + idx}
                                            commentViewid={"comment" + idx}
                                            onFeed={lop === listonespaceswitchConstants.SPACE_LIST_NR && ss === spaceswitchConstants.FEED_NR}
                                        />
                                    </Grid>
                                ))}
                            </Grid>}
                        {/* <div>
                            {getPaginatedData().map((d, idx) => (
                                <RenderComponent key={idx} data={d} />
                            ))}
                        </div> */}

                        {/* show the pagiantion
        it consists of next and previous buttons
        along with page numbers, in our case, 5 page
        numbers at a time
    */}

                        {!shareLink && pages > 1 && getPaginatedData() && getPaginatedData().length >= 1 ?
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={0}
                                p={5}
                            // style={{ paddingBottom: 100, marginBottom: 70 }}
                            >
                                {page > 1 ? <Item>
                                    <IconButton style={{ marginTop: '-5px', background: "#1976d2", color: "#fff" }} onClick={goToPreviousPage} title="Previous">
                                        <ArrowCircleLeftIcon fontSize="large" />
                                    </IconButton>
                                </Item> : null}
                                {page < pages ? <Item>
                                    <IconButton style={{ marginTop: '-5px', background: "#1976d2", color: "#fff" }} onClick={goToNextPage} title="Next">
                                        <ArrowCircleRightIcon fontSize="large" />
                                    </IconButton>
                                </Item> : null}
                            </Stack> : null}

                        {shareLink || search ?
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={0}
                                p={5}
                            // style={{ paddingBottom: 100, marginBottom: 70 }}
                            >
                                <Item>
                                    <div style={{ background: "#1976d2", borderRadius: 30, width: 200, display: 'inline-block', cursor: 'pointer' }} onClick={goToSpace}>
                                        <IconButton style={{ /*marginTop: '-5px',*/ marginRight: '8px', background: "#1976d2", color: "#fff", transform: open ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }}>
                                            <FeedIcon fontSize="large" />
                                        </IconButton>
                                        <span style={{ cursor: "pointer", fontSize: '1.2em', paddingRight: 15, textTransform: 'uppercase', fontWeight: 'bold' }}>More News</span>
                                    </div>
                                </Item>
                            </Stack>
                            : null}

                        {/* <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={0}
                            p={1}
                        >
                            <Item>
                                <button swg-standard-button="contribution"></button>
                            </Item>
                        </Stack> */}

                        {getPaginatedData() && getPaginatedData().length >= 1 ?
                            <Stack
                                direction={width >= 1482 ? "row" : "column"}
                                justifyContent="center"
                                alignItems="center"
                                spacing={0}
                                p={1}
                                style={{ paddingBottom: 10, marginBottom: 15, paddingTop: pages === 1 ? 50 : 0 }}
                            >
                                {<Item><AdsComponent dataAdSlot='5472839718' currentPath={location.pathname} width={width >= 360 ? 360 : 260} /></Item>}

                                {<Item><AdsComponent dataAdSlot='5476709145' currentPath={location.pathname} width={width >= 360 ? 360 : 260} /></Item>}

                                {<Item><AdsComponent dataAdSlot='8931108113' currentPath={location.pathname} width={width >= 360 ? 360 : 260} /></Item>}
                            </Stack> : null}


                        {getPaginatedData() && getPaginatedData().length >= 1 ? <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={0}
                            p={1}
                            style={{ paddingBottom: 10, marginBottom: 15, paddingTop: pages === 1 ? 50 : 0 }}
                        >
                            <Item>
                                <IconButton style={{ marginTop: '-5px', background: "#1976d2", color: "#fff" }} onClick={() => goToTop()} title="Up">
                                    <ArrowCircleUpIcon fontSize="large" />
                                </IconButton>
                            </Item>
                        </Stack> : null}

                        {<div style={{ paddingBottom: width < 500 ? 25 : 100, paddingTop: width < 500 ? 25 : 50, /*paddingRight: "25px",*/ marginTop: 5, marginBottom: 70 }} />}

                    </div>
                </div>
            </div>
        </>
    );
}

const GridContainer = ({ children }) => {
    return (
        <Grid
            container
            spacing={5}
            // className="marginLaptop"
            justifyItems="center"
        // style={{ PaddingRight: "1000px" }}
        >
            {children}
        </Grid>
    );
};
const Row = ({
    key,
    index,
    name,
    setSpaceNameForFeed,
    spaceid,
    post,
    setPost,
    numField,
    setNumField,
    inputText,
    setInputText,
    characterLimit,
    open,
    setOpen,
    spacename,
    layoutPreview,
    setLayoutPreview,
    editMode,
    user,
    username,
    fullname,
    profileUrl,
    authenticated,
    loggedIn,
    images,
    setImages,
    uploadLimit,
    uploadPreviewLimit,
    inputUpload,
    spaceList,
    onFeed,
    data,
    layoutOnComment,
    setLayoutOnComment,
    resetButtonid,
    commentViewid,
    RenderComponent,
    getPaginatedData
}) => {


    return (
        <>
            {open ? <Zoom in={open} style={{ transitionDelay: open ? '500ms' : '0ms' }}><Grid key={10000} item xs={12} sm={12} md={12} lg={6} xl={4}>
                <CreatePost
                    key={10000}
                    index={10000}
                    name={name}
                    setSpaceNameForFeed={setSpaceNameForFeed}
                    spaceid={spaceid}
                    post={post}
                    setPost={setPost}
                    inputText={inputText}
                    setInputText={setInputText}
                    numField={numField}
                    setNumField={setNumField}
                    characterLimit={characterLimit}
                    open={open}
                    setOpen={setOpen}
                    spacename={spacename}
                    user={user}
                    username={username}
                    fullname={fullname}
                    profileUrl={profileUrl}
                    authenticated={authenticated}
                    loggedIn={loggedIn}
                    images={images}
                    setImages={setImages}
                    uploadLimit={uploadLimit}
                    uploadPreviewLimit={uploadPreviewLimit}
                    inputUpload={inputUpload}
                    spaceList={spaceList}
                    onFeed={onFeed}
                />
            </Grid></Zoom> : null}

            {getPaginatedData().map((d, idx) => (
                <Grid key={idx} item xs={12} sm={12} md={12} lg={6} xl={4}>
                    <RenderComponent
                        key={idx}
                        index={idx}
                        data={d}
                        spacename={spacename}
                        user={user}
                        username={username}
                        fullname={fullname}
                        profileUrl={profileUrl}
                        authenticated={authenticated}
                        loggedIn={loggedIn}
                        layoutOnComment={layoutOnComment}
                        setLayoutOnComment={setLayoutOnComment}
                        resetButtonid={"preview" + idx}
                        commentViewid={"comment" + idx}
                        onFeed={onFeed}
                    />
                </Grid>
            ))}
        </>
    );
};
export const Virtual = ({
    key,
    index,
    name,
    setSpaceNameForFeed,
    spaceid,
    post,
    setPost,
    numField,
    setNumField,
    inputText,
    setInputText,
    characterLimit,
    open,
    setOpen,
    spacename,
    layoutPreview,
    setLayoutPreview,
    editMode,
    user,
    username,
    fullname,
    profileUrl,
    authenticated,
    loggedIn,
    images,
    setImages,
    uploadLimit,
    uploadPreviewLimit,
    inputUpload,
    spaceList,
    onFeed,
    data,
    layoutOnComment,
    setLayoutOnComment,
    resetButtonid,
    commentViewid,
    RenderComponent,
    getPaginatedData
}) => {

    return (
        <AutoSizer>
            {({ width, height }) => (
                <FixedSizeList
                    className="List"
                    height={height}
                    itemCount={100}
                    itemSize={2000}
                    itemData={getPaginatedData}
                    width={width}
                    innerElementType={GridContainer}
                >
                    {RenderComponent}
                </FixedSizeList>
            )}
        </AutoSizer>
    );
};

const getLayout = (numData) => {
    // console.log(numData)
    // console.log(window.innerWidth)

    if (numData === 15 && window.innerWidth >= 1360) {
        console.log(3)
        return [
            { i: "0", x: 0, y: 0, w: 1, h: 1 },
            { i: "1", x: 1, y: 0, w: 1, h: 1 },
            { i: "2", x: 2, y: 0, w: 1, h: 1 },
            { i: "3", x: 0, y: 1, w: 1, h: 1 },
            { i: "4", x: 1, y: 1, w: 1, h: 1 },
            { i: "5", x: 2, y: 1, w: 1, h: 1 },
            { i: "6", x: 0, y: 2, w: 1, h: 1 },
            { i: "7", x: 1, y: 2, w: 1, h: 1 },
            { i: "8", x: 2, y: 2, w: 1, h: 1 },
            { i: "9", x: 0, y: 3, w: 1, h: 1 },
            { i: "10", x: 1, y: 3, w: 1, h: 1 },
            { i: "11", x: 2, y: 3, w: 1, h: 1 },
            { i: "12", x: 0, y: 4, w: 1, h: 1 },
            { i: "13", x: 1, y: 4, w: 1, h: 1 },
            { i: "14", x: 2, y: 4, w: 1, h: 1 }
        ]
    } else if (numData === 15 && window.innerWidth < 1200) {
        console.log(1)
        return [
            { i: "0", x: 0, y: 0, w: 1, h: 1 },
            { i: "1", x: 1, y: 0, w: 1, h: 1 },
            { i: "2", x: 2, y: 0, w: 1, h: 1 },
            { i: "3", x: 0, y: 1, w: 1, h: 1 },
            { i: "4", x: 1, y: 1, w: 1, h: 1 },
            { i: "5", x: 2, y: 1, w: 1, h: 1 },
            { i: "6", x: 0, y: 2, w: 1, h: 1 },
            { i: "7", x: 1, y: 2, w: 1, h: 1 },
            { i: "8", x: 2, y: 2, w: 1, h: 1 },
            { i: "9", x: 0, y: 3, w: 1, h: 1 },
            { i: "10", x: 1, y: 3, w: 1, h: 1 },
            { i: "11", x: 2, y: 3, w: 1, h: 1 },
            { i: "12", x: 0, y: 4, w: 1, h: 1 },
            { i: "13", x: 1, y: 4, w: 1, h: 1 },
            { i: "14", x: 2, y: 4, w: 1, h: 1 }
        ]
    } else if (numData === 14 && window.innerWidth >= 1200 && window.innerWidth < 1360) {
        console.log(2)
        return [
            { i: "0", x: 0, y: 0, w: 1, h: 1 },
            { i: "1", x: 1, y: 0, w: 1, h: 1 },
            { i: "2", x: 0, y: 1, w: 1, h: 1 },
            { i: "3", x: 1, y: 1, w: 1, h: 1 },
            { i: "4", x: 0, y: 2, w: 1, h: 1 },
            { i: "5", x: 1, y: 2, w: 1, h: 1 },
            { i: "6", x: 0, y: 3, w: 1, h: 1 },
            { i: "7", x: 1, y: 3, w: 1, h: 1 },
            { i: "8", x: 0, y: 4, w: 1, h: 1 },
            { i: "9", x: 1, y: 4, w: 1, h: 1 },
            { i: "10", x: 0, y: 5, w: 1, h: 1 },
            { i: "11", x: 1, y: 5, w: 1, h: 1 },
            { i: "12", x: 0, y: 6, w: 1, h: 1 },
            { i: "13", x: 1, y: 6, w: 1, h: 1 }
        ]
    }
}

const getLayoutForTwoColumns = () => {

    return [
        { i: "0", x: 0, y: 0, w: 1, h: 1 },
        { i: "1", x: 1, y: 0, w: 1, h: 1 },
        { i: "2", x: 0, y: 1, w: 1, h: 1 },
        { i: "3", x: 1, y: 1, w: 1, h: 1 },
        { i: "4", x: 0, y: 2, w: 1, h: 1 },
        { i: "5", x: 1, y: 2, w: 1, h: 1 },
        { i: "6", x: 0, y: 3, w: 1, h: 1 },
        { i: "7", x: 1, y: 3, w: 1, h: 1 },
        { i: "8", x: 0, y: 4, w: 1, h: 1 },
        { i: "9", x: 1, y: 4, w: 1, h: 1 },
        { i: "10", x: 0, y: 5, w: 1, h: 1 },
        { i: "11", x: 1, y: 5, w: 1, h: 1 },
        { i: "12", x: 0, y: 6, w: 1, h: 1 },
        { i: "13", x: 1, y: 6, w: 1, h: 1 }
    ]
}

const getLayoutForOneColumn = () => {

    return [
        { i: "0", x: 0, y: 0, w: 1, h: 1 },
        { i: "1", x: 1, y: 0, w: 1, h: 1 },
        { i: "2", x: 2, y: 0, w: 1, h: 1 },
        { i: "3", x: 3, y: 0, w: 1, h: 1 },
        { i: "4", x: 4, y: 0, w: 1, h: 1 },
        { i: "5", x: 5, y: 0, w: 1, h: 1 },
        { i: "6", x: 6, y: 0, w: 1, h: 1 },
        { i: "7", x: 7, y: 0, w: 1, h: 1 },
        { i: "8", x: 8, y: 0, w: 1, h: 1 },
        { i: "9", x: 9, y: 0, w: 1, h: 1 },
        { i: "10", x: 10, y: 0, w: 1, h: 1 },
        { i: "11", x: 11, y: 0, w: 1, h: 1 },
        { i: "12", x: 12, y: 0, w: 1, h: 1 },
        { i: "13", x: 13, y: 0, w: 1, h: 1 },
        { i: "14", x: 14, y: 0, w: 1, h: 1 }
    ]
}

function getFromLS(key) {
    let ls = {};
    if (localStorage) {
        try {
            ls = JSON.parse(localStorage.getItem("rgl-8")) || {};
        } catch (e) {
            /*Ignore*/
        }
    }
    return ls[key];
}

function saveToLS(key, value) {
    if (localStorage) {
        localStorage.setItem(
            "rgl-8",
            JSON.stringify({
                [key]: value
            })
        );
    }
}

const layout = [
    { i: "0", x: 0, y: 0, w: 1, h: 1 },
    { i: "1", x: 1, y: 0, w: 1, h: 1 },
    { i: "2", x: 2, y: 0, w: 1, h: 1 },
    { i: "3", x: 0, y: 1, w: 1, h: 1 },
    { i: "4", x: 1, y: 1, w: 1, h: 1 },
    { i: "5", x: 2, y: 1, w: 1, h: 1 },
    { i: "6", x: 0, y: 2, w: 1, h: 1 },
    { i: "7", x: 1, y: 2, w: 1, h: 1 },
    { i: "8", x: 2, y: 2, w: 1, h: 1 },
    { i: "9", x: 0, y: 3, w: 1, h: 1 },
    { i: "10", x: 1, y: 3, w: 1, h: 1 },
    { i: "11", x: 2, y: 3, w: 1, h: 1 },
    { i: "12", x: 0, y: 4, w: 1, h: 1 },
    { i: "13", x: 1, y: 4, w: 1, h: 1 },
    { i: "14", x: 2, y: 4, w: 1, h: 1 }
];

const getLayouts = () => {
    const savedLayouts = localStorage.getItem("grid-layout");

    return savedLayouts ? JSON.parse(savedLayouts) : { lg: layout };
};
// styled-components definition removed for brevity...

const handleLayoutChange = (layout, layouts) => {
    localStorage.setItem("grid-layout", JSON.stringify(layouts));
};

export const LogoSpace = ({ name, color }) => {
    switch (name) {
        case "Twitter X":
            return <XIcon style={{ color: color }} />;
        case "Facebook":
            return <FacebookIcon style={{ color: color }} />;
        case "Instagram":
            return <InstagramIcon style={{ color: color }} />;
        case "YouTube":
            return <YouTubeIcon style={{ color: color }} />;
        case "WhatsApp":
            return <WhatsAppIcon style={{ color: color }} />;
        case "GitHub":
            return <GitHubIcon style={{ color: color }} />;
        case "Reddit":
            return <RedditIcon style={{ color: color }} />;
        case "LinkedIn":
            return <LinkedInIcon style={{ color: color }} />;
        case "Telegram":
            return <TelegramIcon style={{ color: color }} />;
        case "Xing":
            return <img alt="xing-logo" src="/xing-logo.png" />;
        case "WeChat":
            return <WeChatIcon color={color} />;
        case "VKontakte":
            return <VkontakteIcon color={color} />;
        case "Pinterest":
            return <PinterestIcon style={{ color: color }} />;
        case "Discord":
            return <DiscordIcon color={color} />;
        case "Tinder":
            return <TinderIcon color={color} />;
        case "Signal":
            return <SignalIcon color={color} />;
        case "Snapchat":
            return <SnapchatIcon color={color} />;
        case "TikTok":
            return <TikTokIcon color={color} />;
        case "Twitch":
            return <TwitchIcon color={color} />;
        case "Patreon":
            return <PatreonIcon color={color} />;
        case "OnlyFans":
            return <OnlyfansIcon color={color} />;
        case "Social Health":
            return <HealthAndSafetyIcon style={{ color: color }} />;
        case "Social Trend News":
            return <TrendingUpIcon style={{ color: color }} />;
        case "Guide and Announcement":
            return <FollowTheSignsIcon style={{ color: color }} />;
        case "Content Creators":
            return <PhotoCameraFrontIcon style={{ color: color }} />;
        case "Random News":
            return <MoodIcon style={{ color: color }} />;
        case "Cryptocurrency":
            return <CurrencyBitcoinIcon style={{ color: color }} />;
        case "Health and Beauty":
            return <SpaIcon style={{ color: color }} />;
        case "Crime":
            return <RemoveModeratorIcon style={{ color: color }} />;
        case "Gastronomy":
            return <RestaurantIcon style={{ color: color }} />;
        case "Architecture":
            return <HomeIcon style={{ color: color }} />;
        case "Mental Health":
            return <PsychologyIcon style={{ color: color }} />;
        case "Books":
            return <MenuBookIcon style={{ color: color }} />;
        case "Life Balance":
            return <BalanceIcon style={{ color: color }} />;
        case "Relationships":
            return <GroupIcon style={{ color: color }} />;
        case "Nature":
            return <ForestIcon style={{ color: color }} />;
        case "Universe":
            return <SatelliteAltIcon style={{ color: color }} />;
        case "Sport":
            return <SportsSoccerIcon style={{ color: color }} />;
        case "New Social Medias":
            return <FiberNewIcon style={{ color: color }} />;
        case "Tech":
            return <AppleIcon style={{ color: color }} />;
        case "Memes":
            return <SentimentSatisfiedAltIcon style={{ color: color }} />;
        case "Job and Work Balance":
            return <WorkIcon style={{ color: color }} />;
        case "Gossip":
            return <WhatshotIcon style={{ color: color }} />;
        case "Artificial Intelligence":
            return <InsightsIcon style={{ color: color }} />;
        case "School":
            return <SchoolIcon style={{ color: color }} />;
        case "Financial Health":
            return <MonetizationOnIcon style={{ color: color }} />;
        case "Gaming":
            return <SportsEsportsIcon style={{ color: color }} />;
        case "Anime and Cartoons":
            return <LiveTvIcon style={{ color: color }} />;
        case "Film and Serie":
            return <TvIcon style={{ color: color }} />;
        case "Music":
            return <MusicNoteIcon style={{ color: color }} />;
        case "Meet me":
            return <FavoriteIcon style={{ color: color }} />;
        case "Feedback and Suggestions":
            return <FeedbackIcon style={{ color: color }} />;
        case "Help the Community":
            return <VolunteerActivismIcon style={{ color: color }} />;
        case "More Personal":
            return <EmojiPeopleIcon style={{ color: color }} />;
        case "Out of Scope":
            return <NumbersSharpIcon style={{ color: color }} />;
        default:
            return <HelpOutlineIcon style={{ color: color }} />;
    }
}

const useFeedConfigStyles = makeStyles(theme => ({
    paper: {
        width: 300,
        height: 500,

        '@media (min-width: 600px) and (max-width: 800px)': {
            width: 300,
            height: 500
        },

        '@media (min-width: 800px)': {
            width: 300,
            height: 500
        }
    },
    customizedButton: {
        backgroundColor: '#007bff',
        color: '#fff',
        float: 'right',
    },
}));

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export const DraggableFeedConfigPopup = ({ openShowFeedConfigPopup, handleCloseShowFeedConfigPopup, handleFeedFilter, handleChangeFeedFilter, feedfilterList }) => {
    const classes = useFeedConfigStyles();
    const dispatch = useDispatch();

    const changeUrl = (url) => {
        window.open(url)
    }

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    return (
        <div>
            <Dialog
                open={openShowFeedConfigPopup}
                onClose={handleCloseShowFeedConfigPopup}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.paper }}
            >
                <DialogTitle style={{ cursor: 'move', color: theme === 'dark' ? '#fff' : '#000', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} id="draggable-dialog-title">
                    <span>What Spaces do you prefer in your Feed ?</span>
                </DialogTitle>
                <DialogContent style={{ padding: 0, overflowY: 'auto', }}>
                    <DialogContentText style={{ backgroundColor: theme === 'dark' ? '#0D1F2D' : '#fff', color: theme === 'dark' ? '#fff' : '#000', marginBottom: 0 }}>
                        <FeedFilter handleChangeFeedFilter={handleChangeFeedFilter} feedfilterList={feedfilterList} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ color: '#fff', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} > {/* light */}
                    <Button onClick={handleCloseShowFeedConfigPopup} variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={handleFeedFilter} variant="contained">
                        Filter Feed
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default function FeedFilter({ handleChangeFeedFilter, feedfilterList }) {
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    return (
        <FormGroup style={{ color: theme == 'dark' ? "#fff" : "#000", padding: 20 }}>
            <FormControlLabel control={<Checkbox name="guideandannouncement" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.guideandannouncement} value={feedfilterList && feedfilterList.guideandannouncement} onChange={handleChangeFeedFilter} />} label="Guide and Announcement" />
            <FormControlLabel control={<Checkbox name="socialtrendnews" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.socialtrendnews} value={feedfilterList && feedfilterList.socialtrendnews} onChange={handleChangeFeedFilter} />} label="Social Trend News" />
            <FormControlLabel control={<Checkbox name="socialhealth" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.socialhealth} value={feedfilterList && feedfilterList.socialhealth} onChange={handleChangeFeedFilter} />} label="Social Health" />
            <FormControlLabel control={<Checkbox name="contentcreators" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.contentcreators} value={feedfilterList && feedfilterList.contentcreators} onChange={handleChangeFeedFilter} />} label="Content Creators" />
            <FormControlLabel control={<Checkbox name="twitterx" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.twitterx} value={feedfilterList && feedfilterList.twitterx} onChange={handleChangeFeedFilter} />} label="Twitter X" />
            <FormControlLabel control={<Checkbox name="facebook" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.facebook} value={feedfilterList && feedfilterList.facebook} onChange={handleChangeFeedFilter} />} label="Facebook" />
            <FormControlLabel control={<Checkbox name="instagram" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.instagram} value={feedfilterList && feedfilterList.instagram} onChange={handleChangeFeedFilter} />} label="Instagram" />
            <FormControlLabel control={<Checkbox name="telegram" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.telegram} value={feedfilterList && feedfilterList.telegram} onChange={handleChangeFeedFilter} />} label="Telegram" />
            <FormControlLabel control={<Checkbox name="discord" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.discord} value={feedfilterList && feedfilterList.discord} onChange={handleChangeFeedFilter} />} label="Discord" />
            <FormControlLabel control={<Checkbox name="tiktok" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.tiktok} value={feedfilterList && feedfilterList.tiktok} onChange={handleChangeFeedFilter} />} label="TikTok" />
            <FormControlLabel control={<Checkbox name="reddit" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.reddit} value={feedfilterList && feedfilterList.reddit} onChange={handleChangeFeedFilter} />} label="Reddit" />
            <FormControlLabel control={<Checkbox name="tinder" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.tinder} value={feedfilterList && feedfilterList.tinder} onChange={handleChangeFeedFilter} />} label="Tinder" />

            <FormControlLabel control={<Checkbox name="twitch" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.twitch} value={feedfilterList && feedfilterList.twitch} onChange={handleChangeFeedFilter} />} label="Twitch" />

            <FormControlLabel control={<Checkbox name="snapchat" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.snapchat} value={feedfilterList && feedfilterList.snapchat} onChange={handleChangeFeedFilter} />} label="Snapchat" />
            <FormControlLabel control={<Checkbox name="whatsapp" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.whatsapp} value={feedfilterList && feedfilterList.whatsapp} onChange={handleChangeFeedFilter} />} label="WhatsApp" />
            <FormControlLabel control={<Checkbox name="linkedin" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.linkedin} value={feedfilterList && feedfilterList.linkedin} onChange={handleChangeFeedFilter} />} label="LinkedIn" />
            <FormControlLabel control={<Checkbox name="vkontakte" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.vkontakte} value={feedfilterList && feedfilterList.vkontakte} onChange={handleChangeFeedFilter} />} label="VKontakte" />
            <FormControlLabel control={<Checkbox name="wechat" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.wechat} value={feedfilterList && feedfilterList.wechat} onChange={handleChangeFeedFilter} />} label="WeChat" />
            <FormControlLabel control={<Checkbox name="pinterest" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.pinterest} value={feedfilterList && feedfilterList.pinterest} onChange={handleChangeFeedFilter} />} label="Pinterest" />
            <FormControlLabel control={<Checkbox name="github" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.github} value={feedfilterList && feedfilterList.github} onChange={handleChangeFeedFilter} />} label="GitHub" />
            <FormControlLabel control={<Checkbox name="patreon" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.patreon} value={feedfilterList && feedfilterList.patreon} onChange={handleChangeFeedFilter} />} label="Patreon" />
            <FormControlLabel control={<Checkbox name="signa" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.signa} value={feedfilterList && feedfilterList.signa} onChange={handleChangeFeedFilter} />} label="Signal" />
            <FormControlLabel control={<Checkbox name="youtube" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.youtube} value={feedfilterList && feedfilterList.youtube} onChange={handleChangeFeedFilter} />} label="YouTube" />
            <FormControlLabel control={<Checkbox name="onlyfans" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.onlyfans} value={feedfilterList && feedfilterList.onlyfans} onChange={handleChangeFeedFilter} />} label="OnlyFans" />
            <FormControlLabel control={<Checkbox name="newsocialmedias" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.newsocialmedias} value={feedfilterList && feedfilterList.newsocialmedias} onChange={handleChangeFeedFilter} />} label="New Social Medias" />
            <FormControlLabel control={<Checkbox name="tech" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.tech} value={feedfilterList && feedfilterList.tech} onChange={handleChangeFeedFilter} />} label="Tech" />
            <FormControlLabel control={<Checkbox name="job" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.job} value={feedfilterList && feedfilterList.job} onChange={handleChangeFeedFilter} />} label="Job and Work Balance" />
            <FormControlLabel control={<Checkbox name="memes" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.memes} value={feedfilterList && feedfilterList.memes} onChange={handleChangeFeedFilter} />} label="Memes" />
            <FormControlLabel control={<Checkbox name="gossip" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.gossip} value={feedfilterList && feedfilterList.gossip} onChange={handleChangeFeedFilter} />} label="Gossip" />
            <FormControlLabel control={<Checkbox name="artificialintelligence" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.artificialintelligence} value={feedfilterList && feedfilterList.artificialintelligence} onChange={handleChangeFeedFilter} />} label="Artificial Intelligence" />

            <FormControlLabel control={<Checkbox name="school" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.school} value={feedfilterList && feedfilterList.school} onChange={handleChangeFeedFilter} />} label="School" />

            <FormControlLabel control={<Checkbox name="finance" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.finance} value={feedfilterList && feedfilterList.finance} onChange={handleChangeFeedFilter} />} label="Financial Health" />
            <FormControlLabel control={<Checkbox name="general" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.general} value={feedfilterList && feedfilterList.general} onChange={handleChangeFeedFilter} />} label="Random News" />

            <FormControlLabel control={<Checkbox name="crypto" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.crypto} value={feedfilterList && feedfilterList.crypto} onChange={handleChangeFeedFilter} />} label="Cryptocurrency" />
            <FormControlLabel control={<Checkbox name="health" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.health} value={feedfilterList && feedfilterList.health} onChange={handleChangeFeedFilter} />} label="Health & Beauty" />

            <FormControlLabel control={<Checkbox name="crime" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.crime} value={feedfilterList && feedfilterList.crime} onChange={handleChangeFeedFilter} />} label="Crime" />

            <FormControlLabel control={<Checkbox name="gastronomy" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.gastronomy} value={feedfilterList && feedfilterList.gastronomy} onChange={handleChangeFeedFilter} />} label="Gastronomy" />
            <FormControlLabel control={<Checkbox name="architecture" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.architecture} value={feedfilterList && feedfilterList.architecture} onChange={handleChangeFeedFilter} />} label="Architecture" />
            <FormControlLabel control={<Checkbox name="mentalhealth" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.mentalhealth} value={feedfilterList && feedfilterList.mentalhealth} onChange={handleChangeFeedFilter} />} label="Mental Health" />
            <FormControlLabel control={<Checkbox name="books" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.books} value={feedfilterList && feedfilterList.books} onChange={handleChangeFeedFilter} />} label="Books" />
            <FormControlLabel control={<Checkbox name="lifebalance" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.lifebalance} value={feedfilterList && feedfilterList.lifebalance} onChange={handleChangeFeedFilter} />} label="Life Balance" />
            <FormControlLabel control={<Checkbox name="relationships" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.relationships} value={feedfilterList && feedfilterList.relationships} onChange={handleChangeFeedFilter} />} label="Relationships" />
            <FormControlLabel control={<Checkbox name="nature" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.nature} value={feedfilterList && feedfilterList.nature} onChange={handleChangeFeedFilter} />} label="Nature" />
            <FormControlLabel control={<Checkbox name="universe" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.universe} value={feedfilterList && feedfilterList.universe} onChange={handleChangeFeedFilter} />} label="Universe" />
            <FormControlLabel control={<Checkbox name="sport" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.sport} value={feedfilterList && feedfilterList.sport} onChange={handleChangeFeedFilter} />} label="Sport" />

            <FormControlLabel control={<Checkbox name="gaming" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.gaming} value={feedfilterList && feedfilterList.gaming} onChange={handleChangeFeedFilter} />} label="Gaming" />
            <FormControlLabel control={<Checkbox name="anime" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.anime} value={feedfilterList && feedfilterList.anime} onChange={handleChangeFeedFilter} />} label="Anime and Cartoons" />
            <FormControlLabel control={<Checkbox name="filmandserie" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.filmandserie} value={feedfilterList && feedfilterList.filmandserie} onChange={handleChangeFeedFilter} />} label="Film And Serie" />
            <FormControlLabel control={<Checkbox name="music" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.music} value={feedfilterList && feedfilterList.music} onChange={handleChangeFeedFilter} />} label="Music" />
            <FormControlLabel control={<Checkbox name="meetme" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.meetme} value={feedfilterList && feedfilterList.meetme} onChange={handleChangeFeedFilter} />} label="Meet me" />
            {/* <FormControlLabel control={<Checkbox name="feedback" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.feedback} value={feedfilterList && feedfilterList.feedback} onChange={handleChangeFeedFilter} />} label="Feedback and Suggestions" /> */}
            <FormControlLabel control={<Checkbox name="help" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.help} value={feedfilterList && feedfilterList.help} onChange={handleChangeFeedFilter} />} label="Help the Community" />
            <FormControlLabel control={<Checkbox name="morepersonal" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={feedfilterList && feedfilterList.morepersonal} value={feedfilterList && feedfilterList.morepersonal} onChange={handleChangeFeedFilter} />} label="More Personal" />
        </FormGroup>
    );
}


// const aaaa = () => {
//     return (
//         <>
//             <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
//                 <div id="top" class="marginMobil" style={{ marginRight: "30px", marginLeft: window.innerWidth >= 600 && window.innerWidth < 1200 ? "100px" : "0px" }}>
//                     <div>
//                         <div style={{ paddingTop: 30, paddingBottom: 30 }}>
//                             <a href="#top" className="white" style={{ fontSize: "2.5rem", color: theme === 'dark' ? '#fff' : '#000', textDecoration: "none" }} >{title}</a> {/* light */}
//                         </div>

//                         {/* show the posts, 10 posts at a time */}
//                         <ResponsiveGridLayout
//                             className='layout'
//                             rowHeight={650}
//                             breakpoints={{ xl: 1482, lg: 1200, md: 900, sm: 600, xs: 480, xxs: 0 }}
//                             layouts={layouts}
//                             onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts)}
//                             cols={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1, xxs: 1 }}
//                             margin={[10, 10]}
//                             draggableHandle='.draggable'
//                             isResizable={false}
//                         >
//                             {getPaginatedData().map((d, idx) => {
//                                 {/* <Grid key={idx} item xs={12} sm={12} md={12} lg={6} xl={4}> */ }
//                                 var columns;
//                                 //console.log(window.innerWidth)
//                                 if (window.innerWidth < 1200) {
//                                     // console.log(1)
//                                     columns = 1;
//                                 } else if (window.innerWidth >= 1200 && window.innerWidth < 1482) {
//                                     // console.log(2)
//                                     columns = 2;
//                                 } else if (window.innerWidth >= 1482) {
//                                     // console.log(3)
//                                     columns = 3;
//                                 }

//                                 var cx = idx % columns;

//                                 return <div key={idx} data-grid={{ w: 1, h: 1, x: cx, y: cx === 0 && idx != 0 ? rows++ : rows }}><RenderComponent key={idx} index={idx} data={d} /></div>
//                                 {/* </Grid> */ }
//                             })}
//                         </ResponsiveGridLayout>
//                         {/* <div>
//                             {getPaginatedData().map((d, idx) => (
//                                 <RenderComponent key={idx} data={d} />
//                             ))}
//                         </div> */}

//                         {/* show the pagiantion
//         it consists of next and previous buttons
//         along with page numbers, in our case, 5 page
//         numbers at a time
//     */}
//                         <div className="pagination" style={{ paddingBottom: window.innerWidth < 500 ? 25 : 100, paddingTop: window.innerWidth < 500 ? 25 : 50, paddingRight: "25px", marginTop: 5, marginBottom: 70 }}>
//                             {/* previous button */}
//                             <button
//                                 onClick={goToPreviousPage}
//                                 className={`prev ${page === 1 ? 'disabled' : ''}`}
//                                 style={{ borderRadius: 20 }}
//                             >
//                                 prev
//                             </button>

//                             {/* show page numbers */}
//                             {getPaginationGroup().map((item, index) => (
//                                 <span
//                                     key={index}
//                                     onClick={changePage}
//                                     className={`paginationItem ${page === item ? 'active' : null}`}
//                                 >
//                                     <span>{item}</span>
//                                 </span>
//                             ))}

//                             {/* next button */}
//                             <button
//                                 onClick={goToNextPage}
//                                 className={`next ${page === pages ? 'disabled' : ''}`}
//                                 style={{ borderRadius: 20 }}
//                             >
//                                 next
//                             </button>
//                         </div>
//                         <div class="container" style={{ marginBottom: 1 }}>
//                             {/* <div class="row"> */}
//                             {/* <div class="col" style={{ justifyContent: "center" }}><iframe src="//www.highperformancedisplayformat.com/watchnew?key=cfec91924de057282b3b66314a365372" width="300" height="250" frameborder="0" scrolling="no"></iframe></div>
//                                     <div class="col" style={{ justifyContent: "center" }}><iframe src="//www.highperformancedisplayformat.com/watchnew?key=e7a0d089c39b74e6b81ca7d7673cae15" width="300" height="250" frameborder="0" scrolling="no"></iframe></div>
//                                     <div class="col" style={{ justifyContent: "center" }}><iframe src="//www.highperformancedisplayformat.com/watchnew?key=9e69e85b6c22a2f7893a2bd472d36553" width="300" height="250" frameborder="0" scrolling="no"></iframe></div>
//                                     <div class="col" style={{ justifyContent: "center" }}><iframe src="//www.highperformancedisplayformat.com/watchnew?key=0aa582b51414f429a2aaf11f822c4a8f" width="300" height="250" frameborder="0" scrolling="no"></iframe></div> */}
//                             {/* </div> */}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// } 