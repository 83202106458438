export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_SESSION: 'USERS_LOGIN_SESSION',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GET_USER_REQUEST: 'USERS_GET_REQUEST',
    GET_USER_SUCCESS: 'USERS_GET_SUCCESS',
    GET_USER_FAILURE: 'USERS_GET_FAILURE',

    SAVE_USER_REQUEST: 'USERS_SAVE_REQUEST',
    SAVE_USER_SUCCESS: 'USERS_SAVE_SUCCESS',
    SAVE_USER_FAILURE: 'USERS_SAVE_FAILURE',

    CHECK_ATNAME_REQUEST: 'CHECK_ATNAME_REQUEST',
    CHECK_ATNAME_SUCCESS: 'CHECK_ATNAME_SUCCESS',
    CHECK_ATNAME_FAILURE: 'CHECK_ATNAME_FAILURE',
    CHECK_ATNAME_CLEAR: 'CHECK_ATNAME_CLEAR',

    MODERATION_USER_REQUEST: 'MODERATION_USER_REQUEST',
    MODERATION_USER_SUCCESS: 'MODERATION_USER_SUCCESS',
    MODERATION_USER_FAILURE: 'MODERATION_USER_FAILURE',
    MODERATION_USER_CLEAR: 'MODERATION_USER_CLEAR',

    SAVE_TEMPUSER: 'USERS_TEMPSAVE',

    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

};