import { userConstants } from "../constants";

let user = JSON.parse(sessionStorage.getItem("user"));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.payload,
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                loggingIn: false,
                user: action.payload,
            };
        case userConstants.LOGIN_SESSION:
            return {
                loggedIn: true,
                loggingIn: false,
                user: action.payload,
            };
        case userConstants.LOGIN_FAILURE:
            return {
                loggedIn: false,
                loggingIn: false,
                message: action.payload,
            };
        case userConstants.SAVE_USER_REQUEST:
            return {
                done: false,
                loading: true,               
                error: undefined,
                loggedIn: true,
                loggingIn: false,
                user: Object.keys({ ...state.user }).length === 0 ? "" : { ...state.user },
            };
        case userConstants.SAVE_USER_SUCCESS:
            return {
                done: true,
                loading: false,                
                error: undefined,
                loggedIn: true,
                loggingIn: false,
                user: action.payload[0],
                profil: action.payload[1],            
            };
        case userConstants.SAVE_USER_FAILURE:
            return {
                done: true,              
                loading: false,
                loggedIn: true,
                loggingIn: false,
                user: Object.keys({ ...state.user }).length === 0 ? "" : { ...state.user },                 
                error: action.payload[0],
                profil: action.payload[1],
            };
        case userConstants.LOGOUT:
            return {};
        default:
            return state;
    }
}
