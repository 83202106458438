export const quoteService = {
  getAllQuotes,
};

function getAllQuotes() {
    // console.log("in Service")
    return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API}/quotes`,
        {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*" }
        });
}