import Swal from "sweetalert2";
import { userConstants } from "../constants";
import { userService } from "../services";
import { userActions } from "./user.actions";

export const profileActions = {
    saveProfile,
    saveImageProfile
};

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});

function saveProfile(socials) {
    // console.log(searchText)
    // console.log("in search news")
    return dispatch => {
        dispatch({ type: userConstants.SAVE_USER_REQUEST });

        userService.saveProfile(socials)
            .then(response => {
                // Directly use response.status to check the HTTP status code
                if (!response.ok) {
                    dispatch({ type: userConstants.SAVE_USER_FAILURE, payload: [response, socials] })
                    dispatch(userActions.session());

                    Toast.fire({
                        icon: "warning",
                        title: "Please verify if you complete correctly the profil"
                    });

                    throw new Error('Network response was not ok. Status Code: ' + response.status);
                }
                return response.json(); // Assuming the response is JSON. Use response.text() if it's plain text.
            })
            .then(data => {
                sessionStorage.setItem('user', JSON.stringify(data));
                dispatch({ type: userConstants.SAVE_USER_SUCCESS, payload: [data, socials] })                                                  
            })
            .catch(error => {
                dispatch({ type: userConstants.SAVE_USER_FAILURE, payload: [error, socials] })
                dispatch(userActions.session());
            })
    }
}

function saveImageProfile(profil, profilsharing, defaultavatar, firstprofilcard, uploadInfos) {
    // console.log(searchText)
    // console.log("in search news")
    return dispatch => {
        dispatch({ type: userConstants.SAVE_USER_REQUEST });

        userService.saveImageProfile(profil, profilsharing, defaultavatar, firstprofilcard, uploadInfos)
            .then(response => {
                // Directly use response.status to check the HTTP status code
                if (!response.ok) {
                    dispatch({ type: userConstants.SAVE_USER_FAILURE, payload: [response, ""] })
                    dispatch(userActions.session());

                    Toast.fire({
                        icon: "warning",
                        title: "Error while changing photo profile"
                    });

                    throw new Error('Network response was not ok. Status Code: ' + response.status);
                }
                return response.json(); // Assuming the response is JSON. Use response.text() if it's plain text.
            })
            .then(data => {
                sessionStorage.setItem('user', JSON.stringify(data));
                dispatch({ type: userConstants.SAVE_USER_SUCCESS, payload: [data, ""] })

                Toast.fire({
                    icon: "success",
                    title: "Photo profile updated"
                });
            })
            .catch(error => {
                dispatch({ type: userConstants.SAVE_USER_FAILURE, payload: [error, ""] })
                dispatch(userActions.session());
            })
    }
}