
import { appreciationConstants } from "../constants";
import { appreciationService } from "../services";

export const appreciationActions = {
    giveAppreciation,
    myAppreciation,
    clearAppreciation
};

function giveAppreciation(giving) {
    console.log("in service actions")
    return dispatch => {
        dispatch({ type: appreciationConstants.APPRECIATION_USER_REQUEST });

        appreciationService.giveAppreciation(giving)
            .then(res => res.json())
            .then(data => dispatch({ type: appreciationConstants.APPRECIATION_USER_SUCCESS, payload: [data, giving] }))
            .catch(error => dispatch({ type: appreciationConstants.APPRECIATION_USER_FAILURE, payload: [error, giving] }));
    };
}

function myAppreciation() {
    console.log("in service actions")
    return dispatch => {
        dispatch({ type: appreciationConstants.MYAPPRECIATION_USER_REQUEST });

        appreciationService.myAppreciation()
            .then(res => res.json())
            .then(data => dispatch({ type: appreciationConstants.MYAPPRECIATION_USER_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: appreciationConstants.MYAPPRECIATION_USER_FAILURE, payload: error }));
    };
}

function clearAppreciation() {
    // history.push('/examensportal/login');
    return { type: appreciationConstants.APPRECIATION_USER_CLEAR };
}