import { useLayoutEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { AdsComponent } from "./AdsComponent";

export const WrapperWeather = () => {
    const location = useLocation();

    const useWindowSize = () => {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            const updateSize = () => {
                // actions
                setSize([window.innerWidth, window.innerHeight]);
            };
            window.addEventListener("resize", updateSize);
            updateSize();
            return () => window.removeEventListener("resize", updateSize);
        }, []);
        return size;
    };

    useWindowSize()

    return (
        <div class="col-6 col-md-2 marginTopMobil" style={{ marginTop: "120px" }}>
            {window.innerWidth >= 960 ? <AdsComponent dataAdSlot='7781516273' currentPath={location.pathname} width={220} padding={8} marginTop={-180} /> : null}
            {/* {window.innerWidth >= 960 ? <Weather /> : null} */}
            {/* <div class="notOnMobil"><iframe src="//www.highperformancedisplayformat.com/watchnew?key=bab83dc46b63b75cebd658a1056f6d1b" width="160" height="600" frameborder="0" scrolling="no"></iframe></div>
            <div id="sidebanner2" class="notOnMobil"><iframe src="//www.highperformancedisplayformat.com/watchnew?key=72d489c8dc12e19a34fcf01d2052e1a2" width="160" height="600" frameborder="0" scrolling="no"></iframe></div>
            <div id="sidebanner3" class="notOnMobil"><iframe src="//www.highperformancedisplayformat.com/watchnew?key=b170a67306efe98732f54c3db2c7a251" width="160" height="600" frameborder="0" scrolling="no"></iframe></div>
            {window.innerWidth < 1536 ? <div id="sidebanner4" class="notOnMobil"><iframe src="//www.highperformancedisplayformat.com/watchnew?key=78502c4272fa9565f2ce61917d3c06c5" width="160" height="600" frameborder="0" scrolling="no"></iframe></div> : <div style={{ display: "none" }}></div>} */}
        </div>
    );
}