import { workerConstants } from "../constants";
import { authHeaderWithJSON } from "../helpers";

export const commentService = {
    writeComment,
    deleteComment,
    getComments,
};

const COMMUNO = workerConstants.WORKER

function writeComment(data) {
    // console.log("in service comment")
    const body = JSON.stringify(data);

    return fetch(COMMUNO + "commenting",
        {
            method: 'POST',
            headers: authHeaderWithJSON(),
            body: body
        });
}

function deleteComment(data) {
    // console.log("in service comment")
    const body = JSON.stringify(data);

    return fetch(COMMUNO + "deletecomment",
        {
            method: 'POST',
            headers: authHeaderWithJSON(),
            body: body
        });
}

function getComments(data) {
    // console.log("in service comment")
    const body = JSON.stringify(data);

    return fetch(COMMUNO + "comments",
        {
            method: 'POST',
            headers: authHeaderWithJSON(),
            body: body
        });
}