import { workerConstants } from "../constants";
import { authHeaderWithJSON } from "../helpers";

export const reportService = {
    submitReport
};

const COMMUNO = workerConstants.WORKER

function submitReport(data) {
    console.log("in service Report")
    const body = JSON.stringify(data);

    return fetch(COMMUNO + "report",
        {
            method: 'POST',
            headers: authHeaderWithJSON(),
            body: body
        });
}