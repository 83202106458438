import { SocialMediaClass } from "../models/socialmedia";
import { breachConstants } from "../constants/breach.constants";


export function breach(state = { loading: false }, action) {
    switch (action.type) {
        case breachConstants.SEARCH_REQUEST:
            // console.log("request")
            return {
                account: "",
                breachList: "",
                loading: true,
                done: false
            };
        case breachConstants.SEARCH_SUCCESS:
            // console.log(action.payload)
            return {
                account: action.payload[0],
                breachList: action.payload[1],
                loading: false,
                done: true
            };
        case breachConstants.SEARCH_FAILURE:
            // console.log(action.payload)
            return {
                error: action.payload[0],
                account: action.payload[1],
                breachList: "",
                loading: false,
                done: true
            };
        case breachConstants.SEARCH_FAILURE_DONE:
            return {
                account: "",
                breachList: "",
                loading: false,
                done: false
            };
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}