import { appreciationConstants } from "../constants";
import { GivingClass, MyAppreciationClass } from "../models/giving";


export function appreciation(state = { loading: false }, action) {
    switch (action.type) {
        case appreciationConstants.APPRECIATION_USER_REQUEST:
            // console.log("request")
            return {
                appreciation: new GivingClass(),
                loading: true,
                done: false
            };
        case appreciationConstants.APPRECIATION_USER_SUCCESS:
            // console.log(action.payload)
            return {
                given: action.payload[0],
                appreciation: action.payload[1],         
                loading: false,
                done: true,
                error: undefined
            };
        case appreciationConstants.APPRECIATION_USER_FAILURE:
            return {
                error: action.payload[0],
                appreciation: action.payload[1],
                loading: false,
                done: false         
            };
        case appreciationConstants.APPRECIATION_USER_CLEAR:
            return {};
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}

export function myappreciation(state = { loading: false }, action) {
    switch (action.type) {
        case appreciationConstants.MYAPPRECIATION_USER_REQUEST:
            // console.log("request")
            return {
                myappreciation: new MyAppreciationClass(),
                loading: true,
                done: false
            };
        case appreciationConstants.MYAPPRECIATION_USER_SUCCESS:
            // console.log(action.payload)
            return {
                myappreciation: action.payload,
                loading: false,
                done: true,
                error: undefined
            };
        case appreciationConstants.MYAPPRECIATION_USER_FAILURE:
            return {
                error: action.payload,
                loading: false,
                done: false
            };
        case appreciationConstants.MYAPPRECIATION_USER_CLEAR:
            return {};
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}