import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';


export const PrivacyPolicyGaming = () => {
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    return (
        <div class="row" style={{ marginBottom: 50 }}>
            <div class="col-6 col-md-2 marginTopMobil" style={{ marginTop: "120px" }}>
            </div>
            <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                <div id="top" class="marginMobil" style={{ marginRight: "30px", marginLeft: window.innerWidth >= 600 && window.innerWidth < 1200 ? "100px" : "0px" }}>
                    <div>
                        <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                            <a href="#top" className="white" style={{ fontSize: "2.5rem", color: theme === 'dark' ? '#fff' : '#000', textDecoration: "none" }} >Privacy Policy (For Gaming)</a> {/* light */}
                        </div>

                        <div>
                            <p>OneLike has developed the Roos Breaker game as an free-to-play game. This service is provided by OneLike at no cost, and it is intended to be used as is.</p>

                            <p>This page is meant to inform visitors about our policies regarding the collection, use, and disclosure of Personal Information if anyone chooses to use our Service.</p>

                            <p>By choosing to use our Service, you agree to the collection and use of information in accordance with this policy. The Personal Information we collect is used to provide and improve the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>

                            <p>The terms used in this Privacy Policy have the same meanings as those in our Terms and Conditions, which can be accessed on Roos Breaker, unless otherwise defined in this Privacy Policy.</p>

                            <p><b>Information Collection and Use</b></p>

                            <p>To enhance your experience while using our Service, we may require you to provide certain personally identifiable information, including but not limited to Name, Email, and Location. The information we request will be retained by us and used as described in this privacy policy.</p>

                            <p>The game uses third-party services that may collect information to identify you.</p>

                            <p>Links to third-party service providers' privacy policies used by the game are as follows:</p>
                            <ul>
                                <li>Google Play Services - Privacy Policy: <a href='https://policies.google.com/privacy' style={{ textDecoration: "none" }}>https://policies.google.com/privacy</a></li>
                                <li>AdMob - Privacy Policy: <a href='https://policies.google.com/privacy' style={{ textDecoration: "none" }}>https://policies.google.com/privacy</a></li>
                                <li>Firebase - Privacy Policy: <a href='https://firebase.google.com/support/privacy' style={{ textDecoration: "none" }}>https://firebase.google.com/support/privacy</a></li>
                                <li>Unity Ads - Privacy Policy: <a href='https://unity.com/legal/privacy-policy' style={{ textDecoration: "none" }}>https://unity.com/legal/privacy-policy</a></li>
                                <li>IronSource -Privacy Policy: <a href='https://developers.is.com/ironsource-mobile/air/ironsource-mobile-privacy-policy' style={{ textDecoration: "none" }}>https://developers.is.com/ironsource-mobile/air/ironsource-mobile-privacy-policy</a></li>
                            </ul>
                            <p><b>Log Data</b></p>

                            <p>We would like to inform you that whenever you use our Service, in case of an error in the game, we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device's Internet Protocol ("IP") address, device name, operating system version, the configuration of the game when utilizing our Service, the time and date of your use of the Service, and other statistics.</p>

                            <p><b>Cookies</b></p>

                            <p>Cookies are files containing a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites you visit and are stored on your device's internal memory.</p>

                            <p>This Service does not explicitly use these "cookies." However, the game may use third-party code and libraries that use "cookies" to collect information and improve their services. You have the option to accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>

                            <p><b>Service Providers</b></p>

                            <p>We may employ third-party companies and individuals for the following reasons:</p>
                            <ul>
                                <li>To facilitate our Service;</li>
                                <li>To provide the Service on our behalf;</li>
                                <li>To perform Service-related services; or</li>
                                <li>To assist us in analyzing how our Service is used.</li>
                            </ul>
                            <p>We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
                            <p><b>Security</b></p>

                            <p>We value your trust in providing us with your Personal Information; therefore, we strive to use commercially acceptable means of protecting it. However, please be aware that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

                            <p><b>Links to Other Sites</b></p>

                            <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Please note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

                            <p><b>Children's Privacy</b></p>

                            <p>Our services are not intended for anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If we become aware that we have collected personal information from a child under the age of 13, we will take steps to promptly delete that information from our records. If you are a parent or guardian and you become aware that your child has provided us with personal information, please contact us so that we can take appropriate action to delete the information.</p>

                            <p><b>Changes to This Privacy Policy</b></p>

                            <p>We reserve the right to update or change our privacy policy at any time. Any changes will be effective immediately upon posting the updated policy on our website. We encourage you to review this privacy policy periodically to stay informed about our information practices and the ways you can help protect your privacy.</p>

                            <p><b>Contact Us</b></p>

                            <p>If you have any questions or concerns about our privacy policy or our practices with regards to your personal information, please contact us at info@onelike.app.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}