import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import PushPinIcon from '@mui/icons-material/PushPin';
import RefreshIcon from '@mui/icons-material/Refresh';
import TaskIcon from '@mui/icons-material/Task';
import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Modal, Paper, Popper, Stack } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { appreciationActions, discussionActions, infoActions, languageswitchActions, listonespaceswitchActions, messageActions, profilActions, publisherswitchActions, quoteActions, socialMediaActions, spaceActions, spaceswitchActions, switchActions } from '../actions';
import { publisherActions } from '../actions/publisher.actions';
import { themeswitchActions } from '../actions/themeswitch.actions';
import { languageswitchConstants, listonespaceswitchConstants, publisherswitchConstants, spaceswitchConstants, switchConstants, themeswitchConstants, workerConstants } from '../constants';
import { isTodayAfter } from '../helpers';
import { util } from '../services/util.service';
import { Spinner } from '../spinner/spinner';

export const Switch = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ms = useSelector(state => state.menuswitch);
    const [loading, setLoading] = useState(false);
    const WAIT_CHANGE = 2000;
    const publisherSwitch = useSelector(state => state.publisherswitch);

    const interval = () => setTimeout(() => {
        setLoading(false);
    }, WAIT_CHANGE);

    const handleClick = (e) => {
        switch (e.target.id) {
            case "btnradio1":
                setLoading(true);

                navigate("/");
                resetPage();

                dispatch(switchActions.changeTab(switchConstants.SOCIAL_MEDIA));
                // dispatch(getAllNews());

                interval();
                break;
            case "btnradio2i":
                setLoading(true);

                navigate("/publishers");
                resetPage();

                dispatch(switchActions.changeTab(switchConstants.PUBLISHERS))

                if (publisherSwitch == publisherswitchConstants.PINNED_NR) {
                    dispatch(publisherActions.getPublisherList())
                    dispatch(publisherswitchActions.showPublishers(publisherswitchConstants.ALL_PUBLISHERS))
                }

                interval();
                break;
            case "btnradio2":
                setLoading(true);

                navigate("/socialsearcher");
                resetPage();

                dispatch(switchActions.changeTab(switchConstants.ACCOUNT))

                interval();
                break;
            case "btnradio3":
                setLoading(true);

                navigate("/breach");
                resetPage();

                dispatch(switchActions.changeTab(switchConstants.HACKED))

                interval();
                break;
            case "btnradio4":
                setLoading(true);

                navigate("/space");
                resetPage();

                dispatch(switchActions.changeTab(switchConstants.SPACE))
                dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
                dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.FEED))
                // dispatch(getAllInfosForSpace())

                interval();
                break;
        }
    }

    function resetPage() {
        // dispatch(pageActions.changePage(pageConstants.RESET))
        localStorage.setItem("page", 1)
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    const getAllInfosForSpace = () => {
        return (dispatch, getState) => {
            batch(() => {
                dispatch(discussionActions.getFeedFilter())
                dispatch(discussionActions.getFeed());
                dispatch(spaceActions.getSpaceList());
                dispatch(infoActions.getInfos())
                dispatch(messageActions.checkReadMessage())
                dispatch(appreciationActions.myAppreciation())
            })
        }
    }

    const getAllNews = () => {
        // console.log(ms)
        // console.log("Dispatch LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL")

        return (dispatch, getState) => {
            batch(() => {
                dispatch(socialMediaActions.getSocialMediaList());
                dispatch(socialMediaActions.getSocialMediaListES());
                dispatch(socialMediaActions.getSocialMediaListDE());
                dispatch(socialMediaActions.getSocialMediaListFR());
                dispatch(socialMediaActions.getSocialMediaListPT());
                dispatch(socialMediaActions.getSocialMediaListHI());
                dispatch(socialMediaActions.getSocialMediaListINDO());
                dispatch(socialMediaActions.getSocialMediaListRU());
                dispatch(socialMediaActions.getSocialMediaListCH());
                dispatch(socialMediaActions.getSocialMediaListJP());
                dispatch(socialMediaActions.getSocialMediaListAR());
                dispatch(socialMediaActions.getSocialMediaListIT());
                dispatch(socialMediaActions.getSocialMediaListTR());
                dispatch(socialMediaActions.getSocialMediaListNL());
                dispatch(socialMediaActions.getSocialMediaListGR());
                dispatch(quoteActions.getQuotes());
            })
        }
    }

    return (
        <div class="container" style={{ marginLeft: -11 }}>
            <Spinner show={loading} />
            <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked={ms === switchConstants.SOCIAL_MEDIA_NR} onClick={handleClick} />
                <label class="btn btn-outline-primary" for="btnradio1">News</label>

                <input type="radio" class="btn-check" name="btnradio" id="btnradio2i" autocomplete="off" checked={ms === switchConstants.PUBLISHERS_NR} onClick={handleClick} />
                <label class="btn btn-outline-primary" for="btnradio2i">Publishers {!isTodayAfter(workerConstants.SHOW_NEW) ? <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary" style={{ zIndex: 1000 }}>New</span> : null}
                </label>

                <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" checked={ms === switchConstants.ACCOUNT_NR} onClick={handleClick} />
                <label class="btn btn-outline-primary" for="btnradio2">Searcher</label>

                {/* <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" checked={ms === switchConstants.HACKED_NR} onClick={handleClick} />
                <label class="btn btn-outline-primary" for="btnradio3">Breaches</label> */}

                <input type="radio" class="btn-check" name="btnradio" id="btnradio4" autocomplete="off" checked={ms === switchConstants.SPACE_NR} onClick={handleClick} />
                <label class="btn btn-outline-primary" for="btnradio4">Space</label>
            </div>
        </div>
    );
}

export const LanguageSwitch = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ls = useSelector(state => state.languageswitch);

    return (
        <div class="container">
            <div class="btn-group" role="group" aria-label="Basic radio toggle button group" style={{ marginTop: 20 }}>
                <input type="radio" class="btn-check" name="btnradioForLang" id="btnradio4" autocomplete="off" checked={ls === 0} onClick={() => { navigate("/"); dispatch(languageswitchActions.changeLanguage(languageswitchConstants.ENGLISH)) }} />
                <label class="btn btn-outline-primary" for="btnradio4"><img alt="uk-flag" src="/united-kingdom16x16.png" /></label>

                <input type="radio" class="btn-check" name="btnradioForLang" id="btnradio5" autocomplete="off" checked={ls === 1} onClick={() => { navigate("/es"); dispatch(languageswitchActions.changeLanguage(languageswitchConstants.SPANISH)) }} />
                <label class="btn btn-outline-primary" for="btnradio5"><img alt="spain-flag" src="/spain16x16.png" /></label>

                <input type="radio" class="btn-check" name="btnradioForLang" id="btnradio6" autocomplete="off" checked={ls === 2} onClick={() => { navigate("/de"); dispatch(languageswitchActions.changeLanguage(languageswitchConstants.DEUTSCH)) }} />
                <label class="btn btn-outline-primary" for="btnradio6"><img alt="germany-flag" src="/germany16x16.png" /></label>

                <input type="radio" class="btn-check" name="btnradioForLang" id="btnradio7" autocomplete="off" checked={ls === 3} onClick={() => { navigate("/fr"); dispatch(languageswitchActions.changeLanguage(languageswitchConstants.FRANCAIS)) }} />
                <label class="btn btn-outline-primary" for="btnradio7"><img alt="france-flag" src="/france16x16.png" /></label>

                <input type="radio" class="btn-check" name="btnradioForLang" id="btnradio8" autocomplete="off" checked={ls === 4} onClick={() => { navigate("/hi"); dispatch(languageswitchActions.changeLanguage(languageswitchConstants.HINDI)) }} />
                <label class="btn btn-outline-primary" for="btnradio8"><img alt="india-flag" src="/india16x16.png" /></label>

                <input type="radio" class="btn-check" name="btnradioForLang" id="btnradio9" autocomplete="off" checked={ls === 5} onClick={() => { navigate("/indo"); dispatch(languageswitchActions.changeLanguage(languageswitchConstants.INDONESIAN)) }} />
                <label class="btn btn-outline-primary" for="btnradio9"><img alt="indonesia-flag" src="/indonesia16x16.png" /></label>
            </div>
        </div>
    );
}



export const SplitButton = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const lang = useSelector(state => state.languageswitch);
    // const [selectedIndex, setSelectedIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const options = ['English', 'Spanish', 'German', 'French', 'Portuguese', 'Hindi', 'Indonesian', 'Russian', 'Chinese', 'Japanese', 'Arabic', 'Italian', 'Turkish', 'Dutch', 'Greek', '', '', ''];
    const WAIT_CHANGE = 2000;
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);
    const menuSwitch = useSelector(state => state.menuswitch);

    const ss = useSelector(state => state.spaceswitch);
    const lop = useSelector(state => state.listonespaceswitch);

    const lin = useSelector((state) => state.authentication.loggedIn);
    const [loggedIn, setLoggedIn] = useState(lin);
    const u = useSelector((state) => state.authentication.user);
    const [user, setUser] = useState(u);
    const authenticated = loggedIn && user;

    const [urlp, setUlrp] = useState(localStorage.getItem("urlparam"))

    const publisherSwitch = useSelector(state => state.publisherswitch);
    var pinStyle = publisherSwitch == publisherswitchConstants.ALL_PUBLISHERS_NR ? { color: "#fff" } : { color: "#ff0000" }

    const style1 = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: window.innerWidth <= 320 ? 295 : 350,
        bgcolor: theme === 'dark' ? '#0D1F2D' : '#fff', // light
        color: theme === 'dark' ? '#fff' : '#000', // light
        border: theme === 'dark' ? "2px solid #fff" : "2px solid #000", // light
        borderRadius: "5px",
        boxShadow: 24,
        overflowY: "auto",
        overflowX: "hidden",
        zIndex: 1100000,
        p: 4,
        '@media (min-width: 600px)': {
            width: 500
        }
    };

    function resetPage() {
        // dispatch(pageActions.changePage(pageConstants.RESET))
        localStorage.setItem("page", 1)
    }

    const [open1, setOpen1] = useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);

    const show1 = (e) => {
        e.preventDefault();
        handleOpen1();
    }


    const handleClick = () => {
        // console.info(`You clicked ${options[selectedIndex]}`);
    };

    const interval = () => setTimeout(() => {
        setLoading(false);
    }, WAIT_CHANGE);

    const handleMenuItemClick = (
        event,
        index
    ) => {
        setLoading(true);
        switch (index) {
            case languageswitchConstants.ENGLISH_NR:
                navigate("/");
                dispatch(languageswitchActions.changeLanguage(languageswitchConstants.ENGLISH))
                break;
            case languageswitchConstants.SPANISH_NR:
                navigate("/es");
                dispatch(languageswitchActions.changeLanguage(languageswitchConstants.SPANISH))
                break;
            case languageswitchConstants.DEUTSCH_NR:
                navigate("/de");
                dispatch(languageswitchActions.changeLanguage(languageswitchConstants.DEUTSCH))
                break;
            case languageswitchConstants.FRANCAIS_NR:
                navigate("/fr");
                dispatch(languageswitchActions.changeLanguage(languageswitchConstants.FRANCAIS))
                break;
            case languageswitchConstants.PORTUGUESE_NR:
                navigate("/pt");
                dispatch(languageswitchActions.changeLanguage(languageswitchConstants.PORTUGUESE))
                break;
            case languageswitchConstants.HINDI_NR:
                navigate("/hi");
                dispatch(languageswitchActions.changeLanguage(languageswitchConstants.HINDI))
                break;
            case languageswitchConstants.INDONESIAN_NR:
                navigate("/indo");
                dispatch(languageswitchActions.changeLanguage(languageswitchConstants.INDONESIAN))
                break;
            case languageswitchConstants.RUSSIAN_NR:
                navigate("/ru");
                dispatch(languageswitchActions.changeLanguage(languageswitchConstants.RUSSIAN))
                break;
            case languageswitchConstants.CHINESE_NR:
                navigate("/ch");
                dispatch(languageswitchActions.changeLanguage(languageswitchConstants.CHINESE))
                break;
            case languageswitchConstants.JAPANESE_NR:
                navigate("/jp");
                dispatch(languageswitchActions.changeLanguage(languageswitchConstants.JAPANESE))
                break;
            case languageswitchConstants.ARABIC_NR:
                navigate("/ar");
                dispatch(languageswitchActions.changeLanguage(languageswitchConstants.ARABIC))
                break;
            case languageswitchConstants.ITALIAN_NR:
                navigate("/it");
                dispatch(languageswitchActions.changeLanguage(languageswitchConstants.ITALIAN))
                break;
            case languageswitchConstants.TURKISH_NR:
                navigate("/tr");
                dispatch(languageswitchActions.changeLanguage(languageswitchConstants.TURKISH))
                break;
            case languageswitchConstants.DUTCH_NR:
                navigate("/nl");
                dispatch(languageswitchActions.changeLanguage(languageswitchConstants.DUTCH))
                break;
            case languageswitchConstants.GREEK_NR:
                navigate("/gr");
                dispatch(languageswitchActions.changeLanguage(languageswitchConstants.GREEK))
                break;
        }
        // setSelectedIndex(index);
        setOpen(false);
        interval();
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target)
        ) {
            return;
        }

        setOpen(false);
    };

    const showPinned = () => {
        resetPage()

        if (publisherSwitch == publisherswitchConstants.ALL_PUBLISHERS_NR) {
            dispatch(publisherswitchActions.showPublishers(publisherswitchConstants.PINNED))
            dispatch(publisherActions.getMyPublisherList())
            return;
        }

        if (publisherSwitch == publisherswitchConstants.PINNED_NR) {
            dispatch(publisherswitchActions.showPublishers(publisherswitchConstants.ALL_PUBLISHERS))
            dispatch(publisherActions.getPublisherList())
        }
    };

    useEffect(() => {
        setLoggedIn(lin)
    }, [lin])

    useEffect(() => {
        setUser(u)
    }, [u])

    useEffect(() => {
        localStorage.setItem("lang", lang)
    }, [lang])

    useEffect(() => {
        setTheme(t);
    }, [t])

    const dispatchAll = () => {
        if (menuSwitch == switchConstants.PUBLISHERS_NR) {
            resetPage()
        }

        if (publisherSwitch == publisherswitchConstants.PINNED_NR) {
            dispatch(publisherswitchActions.showPublishers(publisherswitchConstants.ALL_PUBLISHERS))
        }

        if (lop === listonespaceswitchConstants.SPACE_LIST_NR) {
            if (ss == spaceswitchConstants.FEED_NR) {
                dispatch(discussionActions.getFeedFilter())
                dispatch(discussionActions.getFeed());
            }
            if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                dispatch(spaceActions.getSpaceList());
            }
            if (ss == spaceswitchConstants.MY_SPACE_NR) {
                dispatch(discussionActions.getMyDiscussionList());
            }
            if (ss == spaceswitchConstants.LIGHT_NR) {
                dispatch(discussionActions.getLightDiscussionList());
            }
            if (ss == spaceswitchConstants.STAR_NR) {
                dispatch(profilActions.getStarProfilList());
            }
            if (ss == spaceswitchConstants.MESSAGE_NR) {
                dispatch(profilActions.getMyTalkers());
            }
            if (ss == spaceswitchConstants.FIND_NR) {
                dispatch(profilActions.getProfilList("",));
            }
        }

        if (lop === listonespaceswitchConstants.ONE_SPACE_NR) {
            if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                dispatch(discussionActions.getDiscussionList(urlp, "", false));
            }
            if (ss == spaceswitchConstants.MY_SPACE_NR) {
                dispatch(discussionActions.getMyDiscussionList());
            }
            if (ss == spaceswitchConstants.LIGHT_NR) {
                dispatch(discussionActions.getLightDiscussionList());
            }
            if (ss == spaceswitchConstants.STAR_NR) {
                dispatch(profilActions.getStarProfilList());
            }
            if (ss == spaceswitchConstants.MESSAGE_NR) {
                dispatch(profilActions.getMyTalkers());
            }
            if (ss == spaceswitchConstants.FIND_NR) {
                dispatch(profilActions.getProfilList());
            }
        }

        batch(() => {
            dispatch(socialMediaActions.getSocialMediaList());
            dispatch(socialMediaActions.getSocialMediaListES());
            dispatch(socialMediaActions.getSocialMediaListDE());
            dispatch(socialMediaActions.getSocialMediaListFR());
            dispatch(socialMediaActions.getSocialMediaListPT());
            dispatch(socialMediaActions.getSocialMediaListHI());
            dispatch(socialMediaActions.getSocialMediaListINDO());
            dispatch(socialMediaActions.getSocialMediaListRU());
            dispatch(socialMediaActions.getSocialMediaListCH());
            dispatch(socialMediaActions.getSocialMediaListJP());
            dispatch(socialMediaActions.getSocialMediaListAR());
            dispatch(socialMediaActions.getSocialMediaListIT());
            dispatch(socialMediaActions.getSocialMediaListTR());
            dispatch(socialMediaActions.getSocialMediaListNL());
            dispatch(socialMediaActions.getSocialMediaListGR());
            dispatch(publisherActions.getPublisherList())
            dispatch(quoteActions.getQuotes());

            dispatch(infoActions.getInfos())
            if (authenticated) {
                dispatch(messageActions.checkReadMessage())
                dispatch(appreciationActions.myAppreciation())
            }
        })
    }

    return (

        <div class="container" style={{ paddingBottom: 5 }}>
            <NewsStatus open={open1} customStyle={style1} handleClose={handleClose1} menuSwitch={menuSwitch} />
            <Stack spacing={2} direction="row">
                {menuSwitch == switchConstants.SOCIAL_MEDIA_NR ? <ButtonGroup style={{ marginTop: 20 }} variant="contained" ref={anchorRef} aria-label="split button">
                    <Spinner show={loading} />
                    <Button onClick={handleClick}>{options[lang]}<Flag language={options[lang]} /></Button>
                    <Button
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        <ArrowDropDownCircleIcon />
                    </Button>
                </ButtonGroup> : null}
                {authenticated && menuSwitch === switchConstants.PUBLISHERS_NR ?
                    <IconButton style={{ marginTop: 15, marginBottom: -3, background: "#1976d2", color: "#fff", borderRadius: 5 }} onClick={showPinned} title="Pinned News">
                        <PushPinIcon style={pinStyle} />
                    </IconButton>
                    : null}
                {menuSwitch === switchConstants.SOCIAL_MEDIA_NR || menuSwitch === switchConstants.PUBLISHERS_NR ? <IconButton style={{ marginTop: 15, marginBottom: -3, background: "#1976d2", color: "#fff", borderRadius: 5 }} onClick={e => show1(e)} title="Check News">
                    <TaskIcon />
                </IconButton> : null}
                {menuSwitch === switchConstants.SOCIAL_MEDIA_NR || menuSwitch === switchConstants.PUBLISHERS_NR ?
                    <IconButton style={{ marginTop: 15, marginBottom: -3, background: "#1976d2", color: "#fff" }} onClick={dispatchAll} title="Load News">
                        <RefreshIcon />
                    </IconButton> : null}
            </Stack>
            <Popper
                sx={{
                    zIndex: 2,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <div class="row">
                                <div class="col" style={{ paddingRight: 0, zIndex: 2 }}>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu" autoFocusItem style={{ paddingLeft: 12, paddingRight: 0 }}>
                                            {options.map((option, index) => {
                                                {
                                                    return index < 6 && <MenuItem
                                                        key={option}
                                                        // disabled={index === 2}
                                                        selected={index === lang}
                                                        onClick={(event) => handleMenuItemClick(event, index)}
                                                    >
                                                        {option}<Flag language={option} />
                                                    </MenuItem>
                                                }
                                            })}
                                        </MenuList>
                                    </ClickAwayListener>
                                </div>
                                <div class="col" style={{ paddingRight: 0, zIndex: 2 }}>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu2" autoFocusItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                                            {options.map((option, index) => {
                                                {
                                                    return index >= 6 && index < 12 && <MenuItem
                                                        key={option}
                                                        // disabled={index === 2}
                                                        selected={index === lang}
                                                        onClick={(event) => handleMenuItemClick(event, index)}
                                                    >
                                                        {option}<Flag language={option} />
                                                    </MenuItem>
                                                }
                                            })}
                                        </MenuList>
                                    </ClickAwayListener>
                                </div>
                                <div class="col" style={{ zIndex: 2 }}>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu3" autoFocusItem style={{ paddingLeft: window.innerWidth <= 320 ? 12 : 0, paddingRight: 0 }}>
                                            {options.map((option, index) => {
                                                {
                                                    return index >= 12 && index < 18 && <MenuItem
                                                        key={option}
                                                        disabled={index >= 15 && index <= 17}
                                                        selected={index === lang}
                                                        onClick={(event) => handleMenuItemClick(event, index)}
                                                    >
                                                        {option}<Flag language={option} />
                                                    </MenuItem>
                                                }
                                            })}
                                        </MenuList>
                                    </ClickAwayListener>
                                </div>
                            </div>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}

export const ThemeSwitch = () => {
    const dispatch = useDispatch();
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    const themeHandler = (e) => {
        if (theme === 'dark') {
            dispatch(themeswitchActions.changeTheme(themeswitchConstants.LIGHT_MODE))
        } else {
            dispatch(themeswitchActions.changeTheme(themeswitchConstants.DARK_MODE))
        }
    }

    useEffect(() => {
        localStorage.setItem("theme", t)
        setTheme(t);
    }, [t])

    return <Box
        sx={{
            display: 'block',
            width: '100%',
            alignItems: 'center',
            bgcolor: theme === 'dark' ? '#0D1F2D' : '#fff',
            color: theme === 'dark' ? '#fff' : '#000',
            borderRadius: 1,
            p: 0.1,
        }}
    >
        <div style={{ float: "right" }}>
            <span onClick={themeHandler} style={{ cursor: "pointer" }}>{theme} mode</span>
            <IconButton sx={{ ml: 1 }} onClick={themeHandler} color="inherit">
                {theme === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
        </div>
    </Box>;
}

const Flag = ({ language }) => {
    switch (language) {
        case "English":
            return <img alt="uk-flag" style={{ marginLeft: 3 }} src="/united-kingdom16x16.png" />;
        case "Spanish":
            return <img alt="spain-flag" style={{ marginLeft: 3 }} src="/spain16x16.png" />;
        case "German":
            return <img alt="germany-flag" style={{ marginLeft: 3 }} src="/germany16x16.png" />;
        case "French":
            return <img alt="france-flag" style={{ marginLeft: 3 }} src="/france16x16.png" />;
        case "Portuguese":
            return <img alt="france-flag" style={{ marginLeft: 3 }} src="/portugal16x16.png" />;
        case "Hindi":
            return <img alt="india-flag" style={{ marginLeft: 3 }} src="/india16x16.png" />;
        case "Indonesian":
            return <img alt="indonesia-flag" style={{ marginLeft: 3 }} src="/indonesia16x16.png" />;
        case "Russian":
            return <img alt="russia-flag" style={{ marginLeft: 3 }} src="/russia16x16.png" />;
        case "Chinese":
            return <img alt="china-flag" style={{ marginLeft: 3 }} src="/china16x16.png" />;
        case "Japanese":
            return <img alt="japan-flag" style={{ marginLeft: 3 }} src="/japan16x16.png" />;
        case "Arabic":
            return <img alt="emirates-flag" style={{ marginLeft: 3 }} src="/united-arab-emirates16x16.png" />;
        case "Italian":
            return <img alt="italy-flag" style={{ marginLeft: 3 }} src="/italy16x16.png" />;
        case "Turkish":
            return <img alt="turkey-flag" style={{ marginLeft: 3 }} src="/turkey16x16.png" />;
        case "Dutch":
            return <img alt="netherlands-flag" style={{ marginLeft: 3 }} src="/netherlands16x16.png" />;
        case "Greek":
            return <img alt="greece-flag" style={{ marginLeft: 3 }} src="/greece16x16.png" />;
        default:
            return <></>;
    }
}

export const NewsStatus = ({ customStyle, open, handleClose, menuSwitch }) => {

    const s = useSelector(state => state.socialmedia.socialmediaList);
    const [socialmediaList, setSocialmedialList] = useState(s);
    const ses = useSelector(state => state.socialmediaES.socialmediaListES);
    const [socialmediaListES, setSocialmedialListES] = useState(ses);
    const sde = useSelector(state => state.socialmediaDE.socialmediaListDE);
    const [socialmediaListDE, setSocialmedialListDE] = useState(sde);
    const sfr = useSelector(state => state.socialmediaFR.socialmediaListFR);
    const [socialmediaListFR, setSocialmedialListFR] = useState(sfr);
    const spt = useSelector(state => state.socialmediaPT.socialmediaListPT);
    const [socialmediaListPT, setSocialmedialListPT] = useState(spt);
    const shi = useSelector(state => state.socialmediaHI.socialmediaListHI);
    const [socialmediaListHI, setSocialmedialListHI] = useState(shi);
    const sindo = useSelector(state => state.socialmediaINDO.socialmediaListINDO);
    const [socialmediaListINDO, setSocialmedialListINDO] = useState(sindo);
    const sru = useSelector(state => state.socialmediaRU.socialmediaListRU);
    const [socialmediaListRU, setSocialmedialListRU] = useState(sru);
    const sch = useSelector(state => state.socialmediaCH.socialmediaListCH);
    const [socialmediaListCH, setSocialmedialListCH] = useState(sch);
    const sjp = useSelector(state => state.socialmediaJP.socialmediaListJP);
    const [socialmediaListJP, setSocialmedialListJP] = useState(sjp);
    const sar = useSelector(state => state.socialmediaAR.socialmediaListAR);
    const [socialmediaListAR, setSocialmedialListAR] = useState(sar);
    const sit = useSelector(state => state.socialmediaIT.socialmediaListIT);
    const [socialmediaListIT, setSocialmedialListIT] = useState(sit);
    const str = useSelector(state => state.socialmediaTR.socialmediaListTR);
    const [socialmediaListTR, setSocialmedialListTR] = useState(str);
    const snl = useSelector(state => state.socialmediaNL.socialmediaListNL);
    const [socialmediaListNL, setSocialmedialListNL] = useState(snl);
    const sgr = useSelector(state => state.socialmediaGR.socialmediaListGR);
    const [socialmediaListGR, setSocialmedialListGR] = useState(sgr);

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    const p = useSelector(state => state.publisher.publisherList);
    const [publisherList, setPublisherList] = useState(p);

    useEffect(() => {
        setSocialmedialList(s);
    }, [s]);

    useEffect(() => {
        setSocialmedialListES(ses);
    }, [ses]);

    useEffect(() => {
        setSocialmedialListDE(sde);
    }, [sde]);

    useEffect(() => {
        setSocialmedialListFR(sfr);
    }, [sfr]);

    useEffect(() => {
        setSocialmedialListPT(spt);
    }, [spt]);

    useEffect(() => {
        setSocialmedialListHI(shi);
    }, [shi]);

    useEffect(() => {
        setSocialmedialListINDO(sindo);
    }, [sindo]);

    useEffect(() => {
        setSocialmedialListRU(sru);
    }, [sru]);

    useEffect(() => {
        setSocialmedialListCH(sch);
    }, [sch]);

    useEffect(() => {
        setSocialmedialListJP(sjp);
    }, [sjp]);

    useEffect(() => {
        setSocialmedialListAR(sar);
    }, [sar]);

    useEffect(() => {
        setSocialmedialListIT(sit);
    }, [sit]);

    useEffect(() => {
        setSocialmedialListTR(str);
    }, [str]);

    useEffect(() => {
        setSocialmedialListNL(snl);
    }, [snl]);

    useEffect(() => {
        setSocialmedialListGR(sgr);
    }, [sgr]);

    useEffect(() => {
        setTheme(t);
    }, [t])

    useEffect(() => {
        setPublisherList(p)
    }, [p]);

    const Availability = ({ language }) => {
        // console.log(language)
        var socialnews;
        switch (language) {
            case "English":
                socialnews = socialmediaList;
                break;
            case "Spanish":
                socialnews = socialmediaListES;
                break;
            case "Deutsch":
                socialnews = socialmediaListDE;
                break;
            case "Francais":
                socialnews = socialmediaListFR;
                break;
            case "Portuguese":
                socialnews = socialmediaListPT;
                break;
            case "Hindi":
                socialnews = socialmediaListHI;
                break;
            case "Indonesian":
                socialnews = socialmediaListINDO;
                break;
            case "Russian":
                socialnews = socialmediaListRU;
                break;
            case "Chinese":
                socialnews = socialmediaListCH;
                break;
            case "Japanese":
                socialnews = socialmediaListJP;
                break;
            case "Arabic":
                socialnews = socialmediaListAR;
                break;
            case "Italian":
                socialnews = socialmediaListIT;
                break;
            case "Turkish":
                socialnews = socialmediaListTR;
                break;
            case "Dutch":
                socialnews = socialmediaListNL;
                break;
            case "Greek":
                socialnews = socialmediaListGR;
                break;
            default:
                socialnews = null;
        }

        if (!socialnews || !socialnews.socialmedias) {
            return <img alt="" src={theme === "dark" ? "/loading-dark.gif" : "/loading-light.gif"} />
        }

        var avail = 0;
        if (socialnews && socialnews.socialmedias && socialnews.socialmedias[0].news && socialnews.socialmedias[0].news.length > 0) {
            avail++;
        }
        if (socialnews && socialnews.socialmedias && socialnews.socialmedias[1].news && socialnews.socialmedias[1].news.length > 0) {
            avail++;
        }
        if (socialnews && socialnews.socialmedias && socialnews.socialmedias[2].news && socialnews.socialmedias[2].news.length > 0) {
            avail++;
        }
        if (socialnews && socialnews.socialmedias && socialnews.socialmedias[3].news && socialnews.socialmedias[3].news.length > 0) {
            avail++;
        }
        if (socialnews && socialnews.socialmedias && socialnews.socialmedias[4].news && socialnews.socialmedias[4].news.length > 0) {
            avail++;
        }
        if (socialnews && socialnews.socialmedias && socialnews.socialmedias[5].news && socialnews.socialmedias[5].news.length > 0) {
            avail++;
        }
        if (socialnews && socialnews.socialmedias && socialnews.socialmedias[6].news && socialnews.socialmedias[6].news.length > 0) {
            avail++;
        }
        if (socialnews && socialnews.socialmedias && socialnews.socialmedias[7].news && socialnews.socialmedias[7].news.length > 0) {
            avail++;
        }
        if (socialnews && socialnews.socialmedias && socialnews.socialmedias[8].news && socialnews.socialmedias[8].news.length > 0) {
            avail++;
        }
        if (socialnews && socialnews.socialmedias && socialnews.socialmedias[9].news && socialnews.socialmedias[9].news.length > 0) {
            avail++;
        }
        if (socialnews && socialnews.socialmedias && socialnews.socialmedias[10].news && socialnews.socialmedias[10].news.length > 0) {
            avail++;
        }
        if (socialnews && socialnews.socialmedias && socialnews.socialmedias[11].news && socialnews.socialmedias[11].news.length > 0) {
            avail++;
        }
        if (socialnews && socialnews.socialmedias && socialnews.socialmedias[12].news && socialnews.socialmedias[12].news.length > 0) {
            avail++;
        }
        if (socialnews && socialnews.socialmedias && socialnews.socialmedias[13].news && socialnews.socialmedias[13].news.length > 0) {
            avail++;
        }
        if (socialnews && socialnews.socialmedias && socialnews.socialmedias[14].news && socialnews.socialmedias[14].news.length > 0) {
            avail++;
        }
        if (socialnews && socialnews.socialmedias && socialnews.socialmedias[15].news && socialnews.socialmedias[15].news.length > 0) {
            avail++;
        }
        if (socialnews && socialnews.socialmedias && socialnews.socialmedias[16].news && socialnews.socialmedias[16].news.length > 0) {
            avail++;
        }
        if (socialnews && socialnews.socialmedias && socialnews.socialmedias[17].news && socialnews.socialmedias[17].news.length > 0) {
            avail++;
        }
        if (socialnews && socialnews.socialmedias && socialnews.socialmedias[18].news && socialnews.socialmedias[18].news.length > 0) {
            avail++;
        }
        if (socialnews && socialnews.socialmedias && socialnews.socialmedias[19].news && socialnews.socialmedias[19].news.length > 0) {
            avail++;
        }
        if (avail === 20) {
            return <img alt="" src="/ok.png" />

        } else if (avail > 0 && avail < 20) {
            return <img alt="" src="/warning.png" />
        }
        return <img alt="" src="/error.png" />
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={customStyle}>
                    {menuSwitch === switchConstants.SOCIAL_MEDIA_NR ? <>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            <h1>News Status</h1>
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <p>See the News availability</p>
                            <Grid
                                container
                                spacing={4}
                                justifyItems="center"
                            >
                                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                                    <span>{"English"}{" "}{<Availability language={"English"} />}</span>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                                    <span>{"Spanish"}{" "}{<Availability language={"Spanish"} />}</span>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                                    <span>{"German"}{" "}{<Availability language={"Deutsch"} />}</span>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                                    <span>{"French"}{" "}{<Availability language={"Francais"} />}</span>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                                    <span>{"Portuguese"}{" "}{<Availability language={"Portuguese"} />}</span>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                                    <span>{"Hindi"}{" "}{<Availability language={"Hindi"} />}</span>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                                    <span>{"Indonesian"}{" "}{<Availability language={"Indonesian"} />}</span>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                                    <span>{"Russian"}{" "}{<Availability language={"Russian"} />}</span>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                                    <span>{"Chinese"}{" "}{<Availability language={"Chinese"} />}</span>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                                    <span>{"Japanese"}{" "}{<Availability language={"Japanese"} />}</span>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                                    <span>{"Arabic"}{" "}{<Availability language={"Arabic"} />}</span>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                                    <span>{"Italian"}{" "}{<Availability language={"Italian"} />}</span>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                                    <span>{"Turkish"}{" "}{<Availability language={"Turkish"} />}</span>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                                    <span>{"Dutch"}{" "}{<Availability language={"Dutch"} />}</span>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                                    <span>{"Greek"}{" "}{<Availability language={"Greek"} />}</span>
                                </Grid>
                            </Grid>
                        </Typography>
                    </> : null}

                    {menuSwitch === switchConstants.SOCIAL_MEDIA_NR && socialmediaList && socialmediaList.socialmedias[0] ?
                        <div style={{ marginTop: 20 }}>
                            <b>
                                <span>Last Update: {util.convertToGermanyTime(socialmediaList.socialmedias[0].last_update)}</span>
                                {/* <span>{" "}{util.needManuellUpdate(socialmediaList.socialmedias[0].last_update) ? <img alt="" src="/ok.png" /> : <img alt="" src="/error.png" />}</span> */}
                            </b>
                        </div> : null}

                    {publisherList && menuSwitch === switchConstants.PUBLISHERS_NR ?
                        <>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                <h1>News Status</h1>
                            </Typography>

                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <p>See the News availability</p>
                            </Typography>

                            <div style={{ marginTop: 20 }}>
                                <b>
                                    <span><img alt="" src="/ok.png" /> Last Update: {util.convertToGermanyTime(publisherList.lastupdate)}</span>
                                    {/* <span>{" "}{util.needManuellUpdate(socialmediaList.socialmedias[0].last_update) ? <img alt="" src="/ok.png" /> : <img alt="" src="/error.png" />}</span> */}
                                </b>
                            </div>
                        </>
                        : null}
                </Box>
            </Modal>
        </div>
    );
}