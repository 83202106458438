import { Box } from '@material-ui/core';
import Avatar from "@material-ui/core/Avatar";
import { deepOrange } from "@material-ui/core/colors";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import FlagIcon from '@mui/icons-material/Flag';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { Button, Paper, Stack } from "@mui/material";
import { styled } from '@mui/system';
import React, { useEffect, useState } from "react";
import { roundTimeAgo, truncateNumber } from "../services/util.service";
import { DraggableDeletePopup, DraggableShowProfilePopup, DraggableSignInPopup } from "./SpaceItem";
// import { clearInterval, clearTimeout, setInterval, setTimeout } from 'worker-timers';
import { useDispatch, useSelector } from "react-redux";
import { SyncLoader } from "react-spinners";
import Swal from "sweetalert2";
import { appreciationActions, commentActions, messageActions, reportActions } from "../actions";
import { GivingClass } from "../models/giving";
import { ReportClass } from "../models/report";
import { DraggableReportPopup } from "./ProfilItem";

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import $ from 'jquery';
import { CommentClass } from "../models/comment";
import { MessageClass } from "../models/message";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'inherit',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    boxShadow: 'none'
}));

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});

export const MessageLeft = (props) => {
    const t = useSelector(state => state.themeswitch);
    const [customTheme, setCustomTheme] = useState(t);

    useEffect(() => {
        setCustomTheme(t);
    }, [t])

    const useStyles = makeStyles((theme) =>
        createStyles({
            messageRow: {
                display: "flex",
                width: '80%',
            },
            messageRowRight: {
                display: "flex",
                justifyContent: "flex-end",
            },
            messageBlue: {
                position: "relative",
                marginLeft: "20px",
                marginBottom: "10px",
                padding: "18px",
                backgroundColor: "#0d6efd",
                color: customTheme === 'dark' ? '#fff !important' : '#fff !important',
                width: "100%",
                //height: "50px",
                textAlign: "left",
                font: "400 .9em 'Open Sans', sans-serif",
                border: "1px solid #0d6efd",
                borderRadius: "10px",
                "&:after": {
                    content: "''",
                    position: "absolute",
                    width: "0",
                    height: "0",
                    borderTop: "15px solid #0d6efd",
                    borderLeft: "15px solid transparent",
                    borderRight: "15px solid transparent",
                    top: "0",
                    left: "-15px"
                },
                "&:before": {
                    content: "''",
                    position: "absolute",
                    width: "0",
                    height: "0",
                    borderTop: "17px solid #0d6efd",
                    borderLeft: "16px solid transparent",
                    borderRight: "16px solid transparent",
                    top: "-1px",
                    left: "-17px"
                },
                cursor: 'pointer'
            },
            messageOrange: {
                position: "relative",
                marginRight: "20px",
                marginBottom: "10px",
                padding: "18px",
                backgroundColor: "#0096FF",
                color: customTheme === 'dark' ? '#fff !important' : '#fff !important',
                maxWidth: "80%",
                minWidth: "20%",
                //height: "50px",
                textAlign: "left",
                font: "400 .9em 'Open Sans', sans-serif",
                border: "1px solid #0096FF",
                borderRadius: "10px",
                "&:after": {
                    content: "''",
                    position: "absolute",
                    width: "0",
                    height: "0",
                    borderTop: "15px solid #0096FF",
                    borderLeft: "15px solid transparent",
                    borderRight: "15px solid transparent",
                    top: "0",
                    right: "-15px"
                },
                "&:before": {
                    content: "''",
                    position: "absolute",
                    width: "0",
                    height: "0",
                    borderTop: "17px solid #0096FF",
                    borderLeft: "16px solid transparent",
                    borderRight: "16px solid transparent",
                    top: "-1px",
                    right: "-17px"
                },
                cursor: 'pointer'
            },

            messageContent: {
                padding: 0,
                margin: 0,
                whiteSpace: 'pre-line',
                color: customTheme === 'dark' ? '#fff !important' : '#fff !important',
            },
            messageTimeStampRight: {
                position: "absolute",
                fontSize: ".85em",
                fontWeight: "bold",
                marginTop: "10px",
                bottom: "-3px",
                right: "5px",
                color: customTheme === 'dark' ? '#fff !important' : '#fff !important',
                padding: 5
            },

            orange: {
                color: theme.palette.getContrastText(deepOrange[500]),
                backgroundColor: deepOrange[500],
                width: theme.spacing(4),
                height: theme.spacing(4),
                cursor: 'pointer'
            },
            avatarNothing: {
                color: "transparent",
                backgroundColor: "transparent",
                width: theme.spacing(4),
                height: theme.spacing(4)
            },
            displayName: {
                marginLeft: "20px",
                color: customTheme === 'dark' ? '#fff !important' : '#000 !important',
                cursor: 'pointer'
            }
        })
    );
    const classes = useStyles();

    const message = props.message ? props.message : "no message";
    // const timestamp = props.timestamp ? props.timestamp : "";
    const photoURL = props.photoURL ? props.photoURL : "dummy.js";
    const displayName = props.displayName ? props.displayName : ""; // Anonymous in Chat
    const createdby = props.createdby ? props.createdby : null;
    const commentid = props.commentid
    const reported = props.reported
    const authenticated = props.authenticated
    const cuid = props.cuid
    const duid = props.duid
    const uuid = props.uuid
    const user = props.user
    const balance = props.balance
    const chat = props.chat
    const lumens = props.lumens
    const edited = props.edited

    const dispatch = useDispatch();

    const [showBalance, setShowBalance] = useState(!chat && lumens > 0)

    const [onLumen, setOnLumen] = useState(false)
    const [onNun, setOnNun] = useState(false)
    const [lumenStyle, setLumenStyle] = useState({ color: '#fff' })
    const [nunStyle, setNunStyle] = useState({ color: "#fff", transform: 'rotate(180deg)' })

    const [reportStyle, setReportStyle] = useState({ color: '#fff' })
    const [reportedComment, setReportedComment] = useState(reported)
    const [reporting, setReporting] = useState(false)

    const [issue, setIssue] = useState("")

    const e = useSelector(state => state.report.error);
    const [error, setError] = useState(e);
    const d = useSelector(state => state.report.done);
    const [done, setDone] = useState(d);

    const [entry, setEntry] = useState(true)

    const [actualLumens, setActualLumens] = useState(lumens)

    const [sending, setSending] = useState(false)

    const [onStar, setOnStar] = useState(false)
    const [starStyle, setStarStyle] = useState({ color: '#fff' })

    const [openShowReportPopup, setOpenShowReportPopup] = useState(false);
    const handleOpenReportPopup = (e) => {
        setOpenShowReportPopup(true);
    }
    const handleCloseShowReportPopup = () => {
        setOpenShowReportPopup(false);
    }

    const [openSignInPopup, setOpenSignInPopup] = useState(false);
    const handleOpenSignInPopup = (e) => {
        setOpenSignInPopup(true);
    }
    const handleCloseSignInPopup = () => {
        setOpenSignInPopup(false);
    }

    const [openShowProfilePopup, setOpenShowProfilePopup] = useState(false);
    const handleOpenShowProfilePopup = (e) => {
        setOpenShowProfilePopup(true);
    }

    const handleCloseShowProfilePopup = () => {
        setOpenShowProfilePopup(false);
    }

    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const handleOpenDeletePopup = (e) => {
        setOpenDeletePopup(true);
    }
    const handleCloseDeletePopup = () => {
        setOpenDeletePopup(false);
    }

    const [iteration, setIteration] = useState(parseTimeAgoToTimestamp(props.timestamp));
    const [timestamp, setTimestamp] = useState("")

    useEffect(() => {
        if (iteration <= 3600) {
            const intervalId = setInterval(() => {
                setIteration(iteration + 1);
                const seconds = iteration;

                if (seconds < 60) setTimestamp(seconds + " s");
                else if (seconds < 3600) setTimestamp(`${Math.floor(seconds / 60)} min`);
                else if (seconds < 86400) setTimestamp(`${Math.floor(seconds / 3600)} h`);
                else if (seconds < 2592000) setTimestamp(`${Math.floor(seconds / 86400)} d`);
                else if (seconds < 31536000) setTimestamp(`${Math.floor(seconds / 2592000)} M`);
                else return setTimestamp(`${Math.floor(seconds / 31536000)} y`);
            }, 1000);
            return () => clearInterval(intervalId);
        } else {
            setTimestamp(roundTimeAgo(props.timestamp))
        }
    }, [iteration]);

    useEffect(() => {
        setIteration(parseTimeAgoToTimestamp(props.timestamp));
    }, [parseTimeAgoToTimestamp(props.timestamp)])

    const switchVue = () => {
        if (!chat && lumens <= 0) {
            setShowBalance(!showBalance)
        } else if (chat) {
            setShowBalance(!showBalance)
        }
    }

    const report = () => {
        handleOpenReportPopup()
    }

    const handleChange = (e) => {
        if (e.target.type === "radio") {
            setIssue(e.target.value);
        }
    }

    const handleDelete = (e) => {
        // For deleting
        var comment = new CommentClass()
        comment.cuid = cuid;
        comment.discussion = duid;

        const replacer = (key, value) => {
            if (typeof value === 'undefined')
                return ""
            else return value;
        }
        var commentInfos = JSON.parse(JSON.stringify(comment, replacer));

        setSending(true)
        setOpenDeletePopup(false);

        $('#' + commentid).remove();
        dispatch(commentActions.deleteComment(commentInfos));
    }

    const handleReportSubmit = () => {
        if (authenticated) {
            if (!reportedComment) {
                if (issue) {
                    var report = new ReportClass()
                    report.cuid = cuid
                    report.issue = issue
                    const replacer = (key, value) => {
                        if (typeof value === 'undefined')
                            return ""
                        else return value;
                    }
                    var reportInfos = JSON.parse(JSON.stringify(report, replacer));

                    setReporting(true)
                    setReportedComment(true)
                    setReportStyle({ color: '#ff0000' })

                    handleCloseShowReportPopup()
                    dispatch(reportActions.submitReport(reportInfos))
                } else {
                    Toast.fire({
                        icon: "warning",
                        title: "Select one issue about the report"
                    });
                }
            }

        } else {
            handleOpenSignInPopup()
        }
    }

    const setBalanceOnLumen = (e) => {
        if (authenticated) {
            if (!onLumen && !onNun) {
                setNunStyle({ color: "#fff", transform: 'rotate(180deg)' })
                setLumenStyle({ color: '#ffff00' })
                var giving = new GivingClass()
                giving.comment = cuid;
                giving.lumen = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));
                setOnLumen(true)
                setOnNun(false)
                setActualLumens((prev) => prev + 1)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            } else if (onLumen && !onNun) {
                setNunStyle({ color: "#fff", transform: 'rotate(180deg)' })
                setLumenStyle({ color: '#fff' })
                var giving = new GivingClass()
                giving.comment = cuid;
                giving.resetLumen = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));
                setOnLumen(false)
                setOnNun(false)
                setActualLumens((prev) => prev - 1 > 0 ? prev - 1 : 0)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            } else if (onNun && !onLumen) {
                // Switch
                setNunStyle({ color: "#fff", transform: 'rotate(180deg)' })
                setLumenStyle({ color: '#ffff00' })
                var giving = new GivingClass()
                giving.comment = cuid;
                giving.lumen = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));
                setOnLumen(true)
                setOnNun(false)
                setActualLumens((prev) => prev + 1)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            }
        } else {
            handleOpenSignInPopup()
        }
    }

    const setBalanceOnNun = (e) => {
        if (authenticated) {
            // Normal
            if (!onNun && !onLumen) {
                setLumenStyle({ color: '#fff' })
                setNunStyle({ color: "#0D1F2D", transform: 'rotate(180deg)' })
                var giving = new GivingClass()
                giving.comment = cuid;
                giving.nun = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));
                setOnNun(true)
                setOnLumen(false)
                setActualLumens((prev) => prev - 1 > 0 ? prev - 1 : 0)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            } else if (onNun && !onLumen) {
                // Reset
                setNunStyle({ color: "#fff", transform: 'rotate(180deg)' })
                setLumenStyle({ color: '#fff' })
                var giving = new GivingClass()
                giving.comment = cuid;
                giving.resetNun = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));
                setOnLumen(false)
                setOnNun(false)
                if (actualLumens > 0) {
                    setActualLumens((prev) => prev + 1)
                }
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            } else if (onLumen && !onNun) {
                // Switch
                setLumenStyle({ color: '#fff' })
                setNunStyle({ color: "#0D1F2D", transform: 'rotate(180deg)' })
                var giving = new GivingClass()
                giving.comment = cuid;
                giving.nun = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));
                setOnNun(true)
                setOnLumen(false)
                setActualLumens((prev) => prev - 1 > 0 ? prev - 1 : 0)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            }
        } else {
            handleOpenSignInPopup()
        }
    }

    const setBalanceOnStar = (e) => {
        if (authenticated) {
            if (!onStar) {
                setStarStyle({ color: '#fff' })
                setStarStyle({ color: '#ffff00' })

                var giving = new GivingClass()
                giving.user = uuid;
                giving.star = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));

                setOnStar(true)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            } else {
                setStarStyle({ color: '#ffff00' })
                setStarStyle({ color: '#fff' })

                var giving = new GivingClass()
                giving.user = uuid;
                giving.resetStar = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));

                setOnStar(false)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            }
        } else {
            handleOpenSignInPopup()
        }
    }

    useEffect(() => {
        // console.log("balance" + balance)
        if (createdby) {
            if (entry) {
                if (createdby.star) {
                    setOnStar(true)
                    setStarStyle({ color: '#ffff00' })
                    // console.log("sssssssssssttttttttttttttaaaaaaaaaaaaaaaarrrrrrrrrrrrrrrrrrrr")
                }
            }
            setEntry(false)
        }
    }, [])

    useEffect(() => {
        if (reportedComment) {
            setReportedComment(true)
            setReportStyle({ color: '#ff0000' })
        } else {
            setReportedComment(false)
            setReportStyle({ color: '#fff' })
        }
    }, [])

    useEffect(() => {
        if (balance) {
            if (entry) {
                if (balance.lumen) {
                    setOnLumen(true)
                    setOnNun(false)
                    setLumenStyle({ color: '#ffff00' })
                } else if (balance.nun) {
                    setOnNun(true)
                    setOnLumen(false)
                    setNunStyle({ color: "#0D1F2D", transform: 'rotate(180deg)' })
                }
            }
            setEntry(false)
        }
    }, [])

    useEffect(() => {
        if (reporting) {
            if (d) {
                if (e !== undefined) {
                    setReporting(false)
                    Toast.fire({
                        icon: "error",
                        title: "An error has occurred. Please try again ..."
                    });
                } else {
                    setReporting(false)
                    Toast.fire({
                        icon: "success",
                        title: "Thank you for Reporting !"
                    });
                }
            }
        }
    }, [d]);

    return (
        <>
            <DraggableReportPopup openShowReportPopup={openShowReportPopup} handleCloseShowReportPopup={handleCloseShowReportPopup} handleReportSubmit={handleReportSubmit} handleChange={handleChange} />
            <DraggableShowProfilePopup openShowProfilePopup={openShowProfilePopup} handleOpenShowProfilePopup={handleOpenShowProfilePopup} handleCloseShowProfilePopup={handleCloseShowProfilePopup} createdby={createdby} setBalanceOnStar={setBalanceOnStar} starStyle={starStyle} showStar={true} />
            <DraggableSignInPopup openSignInPopup={openSignInPopup} handleCloseSignInPopup={handleCloseSignInPopup} />
            <DraggableDeletePopup openDeletePopup={openDeletePopup} handleCloseDeletePopup={handleCloseDeletePopup} handleSubmit={handleDelete} />

            <div className={classes.messageRow} id={commentid}>
                <div onClick={handleOpenShowProfilePopup} data-onclickparam={JSON.stringify({ commentid: commentid })}>
                    <Avatar
                        alt={displayName}
                        className={classes.orange}
                        src={photoURL}
                        onClick={handleOpenShowProfilePopup}
                    ></Avatar></div>
                <div>
                    <div className={classes.displayName} onClick={handleOpenShowProfilePopup} data-onclickparam={JSON.stringify({ commentid: commentid })}>{chat ? "" : displayName}</div>
                    <div className={classes.messageBlue}>
                        <div onClick={switchVue}>
                            <p className={classes.messageContent}>{message}</p>
                        </div>
                        {!chat ?
                            <>
                                {showBalance ? <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={0}
                                >
                                    <Item style={{ paddingLeft: 0 }}>
                                        <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={setBalanceOnLumen}>
                                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><span style={{ marginRight: 2, fontWeight: 'bold' }}>{truncateNumber(actualLumens)}</span><TipsAndUpdatesIcon style={lumenStyle} /></Box>
                                            {/* <TipsAndUpdatesIcon style={{ color: "#FFFF00" }} /> */}
                                        </Button>
                                    </Item>
                                    <Item style={{ paddingLeft: 0 }}>
                                        <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={setBalanceOnNun}>
                                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><TipsAndUpdatesIcon style={nunStyle} /></Box>
                                            {/* <TipsAndUpdatesIcon style={{ color: "#0D1F2D", transform: 'rotate(180deg)' }} /> */}
                                        </Button>
                                    </Item>
                                    {((user && user.role && user.role.includes("MODERATOR")) || (user && user.role && user.role.includes("ADMIN"))) ?
                                        <Item style={{ paddingLeft: 0 }}>
                                            <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={handleOpenDeletePopup}>
                                                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><DeleteIcon /></Box>
                                            </Button>
                                        </Item>
                                        : null}
                                    <Item style={{ paddingLeft: 0 }}>
                                        <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={report}>
                                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><FlagIcon style={reportStyle} /></Box>
                                            {/* <TipsAndUpdatesIcon style={{ color: "#0D1F2D", transform: 'rotate(180deg)' }} /> */}
                                        </Button>
                                    </Item>
                                </Stack> : null}
                            </>
                            :
                            null
                        }
                        <div className={classes.messageTimeStampRight}>
                            {timestamp ?
                                <>
                                    {edited ?
                                        <>
                                            <EditIcon style={{ fontSize: "1em" }} /> {timestamp}
                                        </>
                                        :
                                        <>
                                            {timestamp}
                                        </>
                                    }
                                </> : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export const MessageRight = (props) => {
    const t = useSelector(state => state.themeswitch);
    const [customTheme, setCustomTheme] = useState(t);

    useEffect(() => {
        setCustomTheme(t);
    }, [t])

    const useStyles = makeStyles((theme) =>
        createStyles({
            messageRow: {
                display: "flex",
                width: '80%',
            },
            messageRowRight: {
                display: "flex",
                justifyContent: "flex-end",
            },
            messageBlue: {
                position: "relative",
                marginLeft: "20px",
                marginBottom: "10px",
                padding: "18px",
                backgroundColor: "#0d6efd",
                color: customTheme === 'dark' ? '#fff !important' : '#fff !important',
                width: "100%",
                //height: "50px",
                textAlign: "left",
                font: "400 .9em 'Open Sans', sans-serif",
                border: "1px solid #0d6efd",
                borderRadius: "10px",
                "&:after": {
                    content: "''",
                    position: "absolute",
                    width: "0",
                    height: "0",
                    borderTop: "15px solid #0d6efd",
                    borderLeft: "15px solid transparent",
                    borderRight: "15px solid transparent",
                    top: "0",
                    left: "-15px"
                },
                "&:before": {
                    content: "''",
                    position: "absolute",
                    width: "0",
                    height: "0",
                    borderTop: "17px solid #0d6efd",
                    borderLeft: "16px solid transparent",
                    borderRight: "16px solid transparent",
                    top: "-1px",
                    left: "-17px"
                },
                cursor: 'pointer'
            },
            messageOrange: {
                position: "relative",
                marginRight: "20px",
                marginBottom: "10px",
                padding: "18px",
                backgroundColor: "#0096FF",
                color: customTheme === 'dark' ? '#fff !important' : '#fff !important',
                maxWidth: "80%",
                minWidth: "20%",
                //height: "50px",
                textAlign: "left",
                font: "400 .9em 'Open Sans', sans-serif",
                border: "1px solid #0096FF",
                borderRadius: "10px",
                "&:after": {
                    content: "''",
                    position: "absolute",
                    width: "0",
                    height: "0",
                    borderTop: "15px solid #0096FF",
                    borderLeft: "15px solid transparent",
                    borderRight: "15px solid transparent",
                    top: "0",
                    right: "-15px"
                },
                "&:before": {
                    content: "''",
                    position: "absolute",
                    width: "0",
                    height: "0",
                    borderTop: "17px solid #0096FF",
                    borderLeft: "16px solid transparent",
                    borderRight: "16px solid transparent",
                    top: "-1px",
                    right: "-17px"
                },
                cursor: 'pointer'
            },

            messageContent: {
                padding: 0,
                margin: 0,
                whiteSpace: 'pre-line',
                color: customTheme === 'dark' ? '#fff !important' : '#fff !important',
            },
            messageTimeStampRight: {
                position: "absolute",
                fontSize: ".85em",
                fontWeight: "bold",
                marginTop: "10px",
                bottom: "-3px",
                right: "5px",
                color: '#fff',
                padding: 5
            },

            orange: {
                color: theme.palette.getContrastText(deepOrange[500]),
                backgroundColor: deepOrange[500],
                width: theme.spacing(4),
                height: theme.spacing(4),
                cursor: 'pointer'
            },
            avatarNothing: {
                color: "transparent",
                backgroundColor: "transparent",
                width: theme.spacing(4),
                height: theme.spacing(4)
            },
            displayName: {
                marginLeft: "20px",
                color: '#fff',
                cursor: 'pointer'
            }
        })
    );
    const classes = useStyles();

    const message = props.message ? props.message : "no message";
    // const timestamp = props.timestamp ? props.timestamp : "";
    const photoURL = props.photoURL ? props.photoURL : "dummy.js";
    const displayName = props.displayName ? props.displayName : "Anonymous";
    const createdby = props.createdby ? props.createdby : null;
    const commentid = props.commentid
    const reported = props.reported
    const authenticated = props.authenticated
    const muid = props.muid // message uid
    const cuid = props.cuid // comment uid or chat uid
    const duid = props.duid
    const uuid = props.uuid
    const user = props.user
    const chat = props.chat
    const balance = props.balance
    const lumens = props.lumens
    const setOpenEditmode = props.setOpenEditmode
    const setCommentForEdit = props.setCommentForEdit
    const commentEdit = props.comment
    const editbuttonid = props.editbuttonid

    const messageEdit = props.messageEdit
    const setMessageForEdit = props.setMessageForEdit
    const edited = props.edited

    const dispatch = useDispatch();

    const dc = useSelector(state => state.comment.done);
    const [donec, setDonec] = useState(dc);
    const ec = useSelector(state => state.comment.error);
    const [errorc, setErrorc] = useState(ec);

    const dm = useSelector(state => state.message.done);
    const [donem, setDonem] = useState(dm);
    const em = useSelector(state => state.message.error);
    const [errorm, setErrorm] = useState(em);

    // console.log("lumens : " + lumens)

    const [showBalance, setShowBalance] = useState(!chat && lumens > 0)

    const [onLumen, setOnLumen] = useState(false)
    const [onNun, setOnNun] = useState(false)
    const [lumenStyle, setLumenStyle] = useState({ color: '#fff' })
    const [nunStyle, setNunStyle] = useState({ color: "#fff", transform: 'rotate(180deg)' })

    const [entry, setEntry] = useState(true)

    const [actualLumens, setActualLumens] = useState(lumens)

    const [sending, setSending] = useState(false)

    const switchVue = () => {
        if (!chat && lumens <= 0) {
            setShowBalance(!showBalance)
        } else if (chat) {
            setShowBalance(!showBalance)
        }
    }

    const [openSignInPopup, setOpenSignInPopup] = useState(false);
    const handleOpenSignInPopup = (e) => {
        setOpenSignInPopup(true);
    }
    const handleCloseSignInPopup = () => {
        setOpenSignInPopup(false);
    }

    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const handleOpenDeletePopup = (e) => {
        setOpenDeletePopup(true);
    }
    const handleCloseDeletePopup = () => {
        setOpenDeletePopup(false);
    }




    const [iteration, setIteration] = useState(parseTimeAgoToTimestamp(props.timestamp));
    const [timestamp, setTimestamp] = useState("")

    useEffect(() => {
        if (iteration <= 3600) {
            const intervalId = setInterval(() => {
                setIteration(iteration + 1);
                const seconds = iteration;

                if (seconds < 60) setTimestamp(seconds + " s");
                else if (seconds < 3600) setTimestamp(`${Math.floor(seconds / 60)} min`);
                else if (seconds < 86400) setTimestamp(`${Math.floor(seconds / 3600)} h`);
                else if (seconds < 2592000) setTimestamp(`${Math.floor(seconds / 86400)} d`);
                else if (seconds < 31536000) setTimestamp(`${Math.floor(seconds / 2592000)} M`);
                else return setTimestamp(`${Math.floor(seconds / 31536000)} y`);
            }, 1000);
            return () => clearInterval(intervalId);
        } else {
            setTimestamp(roundTimeAgo(props.timestamp))
        }
    }, [iteration]);

    useEffect(() => {
        setIteration(parseTimeAgoToTimestamp(props.timestamp));
    }, [parseTimeAgoToTimestamp(props.timestamp)])

    useEffect(() => {
        if (balance) {
            if (entry) {
                if (balance.lumen) {
                    setOnLumen(true)
                    setOnNun(false)
                    setLumenStyle({ color: '#ffff00' })
                } else if (balance.nun) {
                    setOnNun(true)
                    setOnLumen(false)
                    setNunStyle({ color: "#0D1F2D", transform: 'rotate(180deg)' })
                }
            }
            setEntry(false)
        }
    }, [])

    const setBalanceOnLumen = (e) => {
        if (authenticated) {
            if (!onLumen && !onNun) {
                setNunStyle({ color: "#fff", transform: 'rotate(180deg)' })
                setLumenStyle({ color: '#ffff00' })
                var giving = new GivingClass()
                giving.comment = cuid;
                giving.lumen = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));
                setOnLumen(true)
                setOnNun(false)
                setActualLumens((prev) => prev + 1)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            } else if (onLumen && !onNun) {
                setNunStyle({ color: "#fff", transform: 'rotate(180deg)' })
                setLumenStyle({ color: '#fff' })
                var giving = new GivingClass()
                giving.comment = cuid;
                giving.resetLumen = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));
                setOnLumen(false)
                setOnNun(false)
                setActualLumens((prev) => prev - 1 > 0 ? prev - 1 : 0)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            } else if (onNun && !onLumen) {
                // Switch
                setNunStyle({ color: "#fff", transform: 'rotate(180deg)' })
                setLumenStyle({ color: '#ffff00' })
                var giving = new GivingClass()
                giving.comment = cuid;
                giving.lumen = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));
                setOnLumen(true)
                setOnNun(false)
                setActualLumens((prev) => prev + 1)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            }
        } else {
            handleOpenSignInPopup()
        }
    }

    const setBalanceOnNun = (e) => {
        if (authenticated) {
            // Normal
            if (!onNun && !onLumen) {
                setLumenStyle({ color: '#fff' })
                setNunStyle({ color: "#0D1F2D", transform: 'rotate(180deg)' })
                var giving = new GivingClass()
                giving.comment = cuid;
                giving.nun = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));
                setOnNun(true)
                setOnLumen(false)
                setActualLumens((prev) => prev - 1 > 0 ? prev - 1 : 0)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            } else if (onNun && !onLumen) {
                // Reset
                setNunStyle({ color: "#fff", transform: 'rotate(180deg)' })
                setLumenStyle({ color: '#fff' })
                var giving = new GivingClass()
                giving.comment = cuid;
                giving.resetNun = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));
                setOnLumen(false)
                setOnNun(false)
                if (actualLumens > 0) {
                    setActualLumens((prev) => prev + 1)
                }
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            } else if (onLumen && !onNun) {
                // Switch
                setLumenStyle({ color: '#fff' })
                setNunStyle({ color: "#0D1F2D", transform: 'rotate(180deg)' })
                var giving = new GivingClass()
                giving.comment = cuid;
                giving.nun = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));
                setOnNun(true)
                setOnLumen(false)
                setActualLumens((prev) => prev - 1 > 0 ? prev - 1 : 0)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            }
        } else {
            handleOpenSignInPopup()
        }
    }

    useEffect(() => {
        if (sending) {
            if (dc) {
                if (ec !== undefined) {
                    // console.log("done11 : " + done)
                    // console.log("error11 : " + error)
                    // setShowSpinner(false);
                    setSending(false)
                    // dispatch(discussionActions.getDiscussionList(spacename))
                    // Toast.fire({
                    //     icon: "error",
                    //     title: "An error has occurred. Please try again ..."
                    // });
                } else {
                    // console.log("done21 : " + done)
                    // console.log("error21 : " + error)
                    // setShowSpinner(false);
                    // setSending(false)
                    // dispatch(discussionActions.getDiscussionList(spacename))
                    // Toast.fire({
                    //     icon: "success",
                    //     title: "Successfully deleted"
                    // });
                }
            }
        }
    }, [dc]);

    useEffect(() => {
        if (sending) {
            if (dm) {
                if (em !== undefined) {
                    // console.log("done11 : " + done)
                    // console.log("error11 : " + error)
                    // setShowSpinner(false);
                    setSending(false)
                    // dispatch(discussionActions.getDiscussionList(spacename))
                    // Toast.fire({
                    //     icon: "error",
                    //     title: "An error has occurred. Please try again ..."
                    // });
                } else {
                    // console.log("done21 : " + done)
                    // console.log("error21 : " + error)
                    // setShowSpinner(false);
                    // setSending(false)
                    // dispatch(discussionActions.getDiscussionList(spacename))
                    // Toast.fire({
                    //     icon: "success",
                    //     title: "Successfully deleted"
                    // });
                }
            }
        }
    }, [dm]);

    const handleDelete = (e) => {
        // For deleting
        if (chat) {
            var message = new MessageClass()
            message.muid = muid;
            message.cuid = cuid;

            const replacer = (key, value) => {
                if (typeof value === 'undefined')
                    return ""
                else return value;
            }
            var messageInfos = JSON.parse(JSON.stringify(message, replacer));

            setSending(true)
            setOpenDeletePopup(false);

            $('#' + commentid).remove();
            dispatch(messageActions.deleteMessage(messageInfos));
        } else {
            var comment = new CommentClass()
            comment.cuid = cuid;
            comment.discussion = duid;

            const replacer = (key, value) => {
                if (typeof value === 'undefined')
                    return ""
                else return value;
            }
            var commentInfos = JSON.parse(JSON.stringify(comment, replacer));

            setSending(true)
            setOpenDeletePopup(false);

            $('#' + commentid).remove();
            dispatch(commentActions.deleteComment(commentInfos));
        }
    }

    const DeleteComment = (e) => {
        if (authenticated) {
            if (cuid) {
                var comment = new CommentClass();

                comment.cuid = cuid
                comment.discussion = duid
                comment.deleting = true

                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var commentInfos = JSON.parse(JSON.stringify(comment, replacer));

                setSending(true)
                setOpenDeletePopup(false);

                // $('#' + commentid).remove();
                dispatch(commentActions.writeComment(commentInfos));
            }
        } else {
            handleOpenSignInPopup()
        }
    }

    const DeleteMessage = (e) => {
        if (authenticated) {
            if (muid && cuid) {
                var message = new MessageClass();

                message.muid = muid;
                message.cuid = cuid
                message.uuid = uuid
                message.deleting = true

                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var messageInfos = JSON.parse(JSON.stringify(message, replacer));

                setSending(true)
                setOpenDeletePopup(false);

                // $('#' + commentid).remove();
                dispatch(messageActions.writeMessage(messageInfos));
            }
        } else {
            handleOpenSignInPopup()
        }
    }

    const handleEdit = (e) => {
        setOpenEditmode(true)

        if (chat) {
            setMessageForEdit(messageEdit)
        } else {
            setCommentForEdit(commentEdit)
        }
    }

    return (
        <div className={classes.messageRowRight} id={commentid}>

            <DraggableSignInPopup openSignInPopup={openSignInPopup} handleCloseSignInPopup={handleCloseSignInPopup} />
            <DraggableDeletePopup openDeletePopup={openDeletePopup} handleCloseDeletePopup={handleCloseDeletePopup} handleSubmit={chat ? DeleteMessage : DeleteComment} />

            <div className={classes.messageOrange}>
                <p className={classes.messageContent} onClick={switchVue}>{message}</p>
                {showBalance ? <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={0}
                >
                    {chat ?
                        <>
                            {(user && user.uuid && (user.uuid == uuid)) ?
                                <>
                                    <Item style={{ paddingLeft: 0 }}>
                                        <Button size="small" variant="contained" style={{ backgroundColor: "#0096FF", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={handleOpenDeletePopup}>
                                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><DeleteIcon /></Box>
                                        </Button>
                                    </Item>

                                    <Item style={{ paddingLeft: 0 }}>
                                        <Button size="small" variant="contained" style={{ backgroundColor: "#0096FF", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={handleEdit}>
                                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><EditIcon /></Box>
                                        </Button>
                                    </Item>
                                </>
                                : null}
                        </>
                        :
                        <>
                            <Item style={{ paddingLeft: 0 }}>
                                <Button size="small" variant="contained" style={{ backgroundColor: "#0096FF", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={setBalanceOnLumen}>
                                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><span style={{ marginRight: 2, fontWeight: 'bold' }}>{truncateNumber(actualLumens)}</span><TipsAndUpdatesIcon style={lumenStyle} /></Box>
                                    {/* <TipsAndUpdatesIcon style={{ color: "#FFFF00" }} /> */}
                                </Button>
                            </Item>


                            <Item style={{ paddingLeft: 0 }}>
                                <Button size="small" variant="contained" style={{ backgroundColor: "#0096FF", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={setBalanceOnNun}>
                                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><TipsAndUpdatesIcon style={nunStyle} /></Box>
                                    {/* <TipsAndUpdatesIcon style={{ color: "#0D1F2D", transform: 'rotate(180deg)' }} /> */}
                                </Button>
                            </Item>

                            {(user && user.uuid && (user.uuid == uuid)) ?
                                <Item style={{ paddingLeft: 0 }}>
                                    <Button id={editbuttonid} size="small" variant="contained" style={{ backgroundColor: "#0096FF", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={handleEdit}>
                                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><EditIcon /></Box>
                                    </Button>
                                </Item>
                                : null}

                            {((user && user.uuid && (user.uuid == uuid)) || (user && user.role && user.role.includes("MODERATOR")) || (user && user.role && user.role.includes("ADMIN"))) ?
                                <Item style={{ paddingLeft: 0 }}>
                                    <Button size="small" variant="contained" style={{ backgroundColor: "#0096FF", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={handleOpenDeletePopup}>
                                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><DeleteIcon /></Box>
                                    </Button>
                                </Item>
                                : null}
                        </>
                    }
                </Stack> : null}
                <div className={classes.messageTimeStampRight}>
                    {timestamp ?
                        <>
                            {edited ?
                                <>
                                    <EditIcon style={{ fontSize: "1em" }} /> {timestamp}
                                </>
                                :
                                <>
                                    {timestamp}
                                </>
                            }
                        </> : null}
                </div>
            </div>
        </div>
    );
};

export const MessageBlank = (props) => {
    const t = useSelector(state => state.themeswitch);
    const [customTheme, setCustomTheme] = useState(t);

    useEffect(() => {
        setCustomTheme(t);
    }, [t])

    const useStyles = makeStyles((theme) =>
        createStyles({
            messageRow: {
                display: "flex",
                width: '80%',
            },
            messageRowRight: {
                display: "flex",
                justifyContent: "flex-end",
            },
            messageBlue: {
                position: "relative",
                marginLeft: "20px",
                marginBottom: "10px",
                padding: "18px",
                backgroundColor: "#0d6efd",
                color: customTheme === 'dark' ? '#fff !important' : '#fff !important',
                width: "100%",
                //height: "50px",
                textAlign: "left",
                font: "400 .9em 'Open Sans', sans-serif",
                border: "1px solid #0d6efd",
                borderRadius: "10px",
                "&:after": {
                    content: "''",
                    position: "absolute",
                    width: "0",
                    height: "0",
                    borderTop: "15px solid #0d6efd",
                    borderLeft: "15px solid transparent",
                    borderRight: "15px solid transparent",
                    top: "0",
                    left: "-15px"
                },
                "&:before": {
                    content: "''",
                    position: "absolute",
                    width: "0",
                    height: "0",
                    borderTop: "17px solid #0d6efd",
                    borderLeft: "16px solid transparent",
                    borderRight: "16px solid transparent",
                    top: "-1px",
                    left: "-17px"
                },
                cursor: 'pointer'
            },
            messageOrange: {
                position: "relative",
                marginRight: "20px",
                marginBottom: "10px",
                padding: "18px",
                backgroundColor: "#0096FF",
                color: customTheme === 'dark' ? '#fff !important' : '#fff !important',
                maxWidth: "80%",
                minWidth: "20%",
                //height: "50px",
                textAlign: "left",
                font: "400 .9em 'Open Sans', sans-serif",
                border: "1px solid #0096FF",
                borderRadius: "10px",
                "&:after": {
                    content: "''",
                    position: "absolute",
                    width: "0",
                    height: "0",
                    borderTop: "15px solid #0096FF",
                    borderLeft: "15px solid transparent",
                    borderRight: "15px solid transparent",
                    top: "0",
                    right: "-15px"
                },
                "&:before": {
                    content: "''",
                    position: "absolute",
                    width: "0",
                    height: "0",
                    borderTop: "17px solid #0096FF",
                    borderLeft: "16px solid transparent",
                    borderRight: "16px solid transparent",
                    top: "-1px",
                    right: "-17px"
                },
                cursor: 'pointer'
            },

            messageContent: {
                padding: 0,
                margin: 0,
                whiteSpace: 'pre-line',
                color: customTheme === 'dark' ? '#fff !important' : '#fff !important',
            },
            messageTimeStampRight: {
                position: "absolute",
                fontSize: ".85em",
                fontWeight: "bold",
                marginTop: "10px",
                bottom: "-3px",
                right: "5px",
                color: '#fff',
                padding: 5
            },

            orange: {
                color: theme.palette.getContrastText(deepOrange[500]),
                backgroundColor: deepOrange[500],
                width: theme.spacing(4),
                height: theme.spacing(4),
                cursor: 'pointer'
            },
            avatarNothing: {
                color: "transparent",
                backgroundColor: "transparent",
                width: theme.spacing(4),
                height: theme.spacing(4)
            },
            displayName: {
                marginLeft: "20px",
                color: '#fff',
                cursor: 'pointer'
            }
        })
    );
    const classes = useStyles();

    return (
        <>
            <div className={classes.messageRow}>
                <Avatar
                    alt={"Someone is typing"}
                    className={classes.orange}
                    src={""}
                // onClick={handleOpenShowProfilePopup}
                ></Avatar>
                <div>
                    <div className={classes.displayName}>{""}</div>
                    <div className={classes.messageBlue}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <p className={classes.messageContent}><SyncLoader size={'8px'} color={'#FFF'} /></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export const parseTimeAgoToTimestamp = (timeAgo) => {
    const units = {
        s: 1,
        min: 60 * 1,
        h: 60 * 60 * 1,
        d: 24 * 60 * 60 * 1,
        M: 30 * 24 * 60 * 60 * 1, // Approximation
        y: 365 * 24 * 60 * 60 * 1, // Approximation
    };

    const parts = timeAgo.split(" ");
    const value = parseInt(parts[0], 10);
    const unit = parts[1];

    if (!units[unit] || isNaN(value)) {
        console.error('Invalid timeAgo format');
        return 1;
    }

    return value * units[unit];
};
