import { gdprdataConstants } from "../constants/gdprdata.constants";
import { GDPRDataClass } from "../models/gdprdata";


export function gdprdata(state = { loading: false, done: false, error: undefined  }, action) {
    switch (action.type) {
        case gdprdataConstants.GET_REQUEST:
            return {
                gdprdata: new GDPRDataClass(),
                done: false,
                loading: true,
                error: undefined,
            };
        case gdprdataConstants.GET_SUCCESS:
            return {
                gdprdata: action.payload.data,
                loading: false,
                done: true,
                error: undefined,
            };
        case gdprdataConstants.GET_FAILURE:
            return {
                error: action.payload,
                gdprdata: Object.keys({ ...state.gdprdata }).length === 0 ? "" : { ...state.gdprdata },
                done: true,
                loading: false
            };
        case gdprdataConstants.GET_FAILURE_DONE:
            return {
                gdprdata: Object.keys({ ...state.gdprdata }).length === 0 ? "" : { ...state.gdprdata },
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error },
                loading: false,
                done: false,
            };
        case gdprdataConstants.SAVE_REQUEST:
            return {
                gdprdata: action.payload,
                loading: true,
                done: false,
                error: undefined
            };
        case gdprdataConstants.SAVE_SUCCESS:
            return {
                gdprdata: action.payload.data,
                loading: false,
                done: true,
                errorcheck: false,
                error: undefined
            };
        case gdprdataConstants.SAVE_TEMP_SUCCESS:
            return {
                gdprdata: action.payload.data,
                loading: false,
                done: false,
                error: undefined
            };
        case gdprdataConstants.SAVE_FAILURE:
            return {
                errorcheck: true,
                error: action.payload[0],
                gdprdata: action.payload[1],
                loading: false,
                done: true
            };
        case gdprdataConstants.SAVE_TEMP_FAILURE:
            return {
                error: action.payload[0],
                gdprdata: action.payload[1],
                loading: false,
                done: false
            };
        case gdprdataConstants.GET_DONE:
            return {
                gdprdata: Object.keys({ ...state.gdprdata }).length === 0 ? "" : { ...state.gdprdata },
                error: undefined,
                loading: false,
                done: false,
            };
        case gdprdataConstants.SAVE_FAILURE_DONE:
            return {
                errorcheck: false,
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error },
                gdprdata: Object.keys({ ...state.gdprdata }).length === 0 ? "" : { ...state.gdprdata },
                loading: false,
                done: false
            };
        case gdprdataConstants.SAVE_TEMP:
            return {
                gdprdata: action.payload,
                loading: false
            };
        case gdprdataConstants.CLEAR:
            return {};
        default:
            return state
    }
}