import { breachConstants, accountConstants } from '../constants';
import { socialMediaService } from '../services';
import { getErrorMessage } from '../helpers/error';

export const accountActions = {
    searchProfiles,
    searchBreaches
};


function searchProfiles(searchText) {
    console.log(searchText)
    console.log("in search profile")
    return dispatch => {
        dispatch({ type: accountConstants.SEARCH_REQUEST });
        
        socialMediaService.searchProfiles(searchText)
            .then(res => res.json())
            .then(data => dispatch({ type: accountConstants.SEARCH_SUCCESS, payload: [searchText, data] }))
            .catch(error => dispatch({ type: accountConstants.SEARCH_FAILURE, payload: [error, searchText] }));
    };
}

function searchBreaches(searchText) {
    console.log(searchText)
    console.log("in search breach")
    return dispatch => {
        dispatch({ type: breachConstants.SEARCH_REQUEST });
        
        socialMediaService.searchBreaches(searchText)
            .then(res => res.json())
            .then(data => dispatch({ type: breachConstants.SEARCH_SUCCESS, payload: [searchText, data] }))
            .catch(error => dispatch({ type: breachConstants.SEARCH_FAILURE, payload: [error, searchText] }));
    };
}