import { spaceConstants } from '../constants';
import { spaceService } from '../services';

export const spaceActions = {
    getSpaceList,
};

function getSpaceList() {
    console.log("in Actions in TopicList")
    return dispatch => {
        dispatch({ type: spaceConstants.GET_REQUEST });

        spaceService.getSpaceList()
            .then(res => res.json())
            .then(data => dispatch({ type: spaceConstants.GET_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: spaceConstants.GET_FAILURE, payload: error }));
    };
}