export const gdprdataConstants = {
    SAVE_REQUEST: 'GDPRDATA_SAVE_REQUEST',
    SAVE_SUCCESS: 'GDPRDATA_SAVE_SUCCESS',
    SAVE_TEMP_SUCCESS: 'GDPRDATA_TEMP_SAVE_SUCCESS',
    SAVE_FAILURE: 'GDPRDATA_SAVE_FAILURE',
    SAVE_TEMP_FAILURE: 'GDPRDATA_TEMP_SAVE_FAILURE',

    GET_REQUEST: 'GDPRDATA_GET_REQUEST',
    GET_SUCCESS: 'GDPRDATA_GET_SUCCESS',
    GET_FAILURE: 'GDPRDATA_GET_FAILURE',

    SAVE_TEMP: 'GDPRDATA_TEMPSAVE',

    GET_DONE: 'GDPRDATA_GET_DONE',
    GET_FAILURE_DONE: 'GDPRDATA_GET_FAILURE_DONE',
    SAVE_FAILURE_DONE: 'GDPRDATA_SAVE_FAILURE_DONE',

    CLEAR: 'GDPRDATA_CLEAR'
};
