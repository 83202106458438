import { previewswitchConstants } from "../constants";

export function previewswitch(
    state = true, 
    action
    ) {
    switch (action.type) {
        case previewswitchConstants.NO:
            return false;
        case previewswitchConstants.YES:
            // console.log("request")
            return true;
        default:
            return localStorage.getItem("preview") === null ? true : localStorage.getItem("preview") === "true";
    }
}

// export function previewswitch(
//     state = false,
//     action
// ) {
//     switch (action.type) {
//         case previewswitchConstants.NO:
//             return false;
//         case previewswitchConstants.YES:
//             // console.log("request")
//             return true;
//         default:
//             return localStorage.getItem("preview") === null ? false : !(localStorage.getItem("preview") === "false");
//     }
// }