import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { clearInterval, setInterval } from 'worker-timers';


export const util = {
    convertToGermanyTime,
    convertToFomatJSONLD,
    needManuellUpdate,
    truncateNumber,
    roundTimeAgo,
    formatTimeAgo
};

function convertToGermanyTime(utcDateString) {
    // Define the source and target timezones
    const sourceTimezone = 'Etc/UTC'; // UTC timezone
    const targetTimezone = 'Europe/Berlin'; // Germany timezone

    // Parse the input date string in UTC timezone
    const dateInUtc = moment.tz(utcDateString, sourceTimezone);

    // Convert the date to the target timezone (Germany)
    const dateInGermany = moment.tz(dateInUtc, targetTimezone);

    // Format the date as a string in Germany timezone
    return dateInGermany.format('DD.MM.YYYY HH:mm:ss');
}

export function convertToFomatJSONLD(utcDateString) {

    return moment(utcDateString).toISOString();
}

function addHoursToDateString(inputDateString, hoursToAdd) {
    const inputFormat = 'DD.MM.YYYY HH:mm:ss';
    const outputFormat = 'DD.MM.YYYY HH:mm:ss';

    // Parse the input date using the specified format
    const parsedDate = moment(inputDateString, inputFormat);

    // Add the specified hours
    parsedDate.add(hoursToAdd, 'hours');

    // Format the new date in the desired output format
    const resultDate = parsedDate.format(outputFormat);

    return resultDate;
}

function compareDates(dateString1, dateString2) {
    const format = 'DD.MM.YYYY HH:mm:ss';

    // Parse the input date strings using the specified format
    const date1 = moment(dateString1, format);
    const date2 = moment(dateString2, format);

    // Compare the two dates
    if (date1.isBefore(date2)) {
        return -1; // dateString1 is earlier than dateString2
    } else if (date1.isAfter(date2)) {
        return 1;  // dateString1 is later than dateString2
    } else {
        return 0;  // The dates are equal
    }
}

function needManuellUpdate(dateString) {
    const format = 'DD.MM.YYYY HH:mm:ss';

    const date = moment(dateString, format);
    const currentDateTime = moment().format('DD.MM.YYYY HH:mm:ss');
    const datePlus = addHoursToDateString(currentDateTime, 3)
    if (compareDates(date, datePlus) === 1) {
        return true
    } else if (compareDates(date, datePlus) === -1) {
        return false
    }
}

export const actualYear = () => {
    // Use moment.js to create a moment object from the Date object
    const momentDate = moment(new Date());

    // Use the 'year' method to get the year
    const year = momentDate.year();

    return year;
}

export function truncateNumber(x) {
    if (x < 1000) return x.toString();
    else if (x >= 1000 && x < 10000) return (x / 1000).toFixed(1) + 'K';
    else if (x >= 10000 && x < 100000) return Math.round(x / 1000) + 'K';
    else if (x >= 100000 && x < 1000000) return Math.round(x / 1000) + 'K';
    else if (x >= 1000000 && x < 10000000) return (x / 1000000).toFixed(1) + 'M';
    else if (x >= 10000000 && x < 100000000) return Math.round(x / 1000000) + 'M';
    else if (x >= 100000000 && x < 1000000000) return Math.round(x / 1000000) + 'M';
    else if (x >= 1000000000 && x < 10000000000) return (x / 1000000000).toFixed(1) + 'B';
    else if (x >= 10000000000 && x < 100000000000) return Math.round(x / 1000000000) + 'B';
    else return 0;
}

const parseTimeAgoToTimestamp = (timeAgo) => {
    const units = {
        s: 1000,
        min: 60 * 1000,
        h: 60 * 60 * 1000,
        d: 24 * 60 * 60 * 1000,
        M: 30 * 24 * 60 * 60 * 1000, // Approximation
        y: 365 * 24 * 60 * 60 * 1000, // Approximation
    };

    const parts = timeAgo.split(" ");
    const value = parseInt(parts[0], 10);
    const unit = parts[1];

    if (!units[unit] || isNaN(value)) {
        console.error('Invalid timeAgo format');
        return new Date().getTime();
    }

    return new Date().getTime() - value * units[unit];
};

export const TimeAgo = ({ timeAgo }) => {
    const [timestamp, setTimestamp] = useState(() => parseTimeAgoToTimestamp(timeAgo));

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimestamp((prevTimestamp) => prevTimestamp + 1000);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const formatDateToTimeAgo = (timestamp) => {
        const now = new Date().getTime();
        const seconds = Math.floor((now - timestamp) / 1000);

        if (seconds < 60) return `${seconds} s`;
        else if (seconds < 3600) return `${Math.floor(seconds / 60)} min`;
        else if (seconds < 86400) return `${Math.floor(seconds / 3600)} h`;
        else if (seconds < 2592000) return `${Math.floor(seconds / 86400)} d`;
        else if (seconds < 31536000) return `${Math.floor(seconds / 2592000)} M`;
        else return `${Math.floor(seconds / 31536000)} y`;
    };

    return <div>{formatDateToTimeAgo(timestamp)}</div>;
};

export function formatTimeAgo(timeAgo) {
    const parts = timeAgo.split(" ");
    const value = parseInt(parts[0], 10);
    const unit = parts[1];

    return Math.floor(value) + " " + unit;
}

export function roundTimeAgo(timeAgo) {
    // Split the input string to extract the number and the unit
    const parts = timeAgo.split(" ");
    const number = parseFloat(parts[0]);
    const unit = parts[1];

    // Round the number to the nearest whole number
    const roundedNumber = Math.trunc(number);

    // Reconstruct the string with the rounded number and return it
    return `${roundedNumber} ${unit}`;
}