import { reportConstants } from "../constants";
import { reportService } from "../services";

export const reportActions = {
    submitReport,
};

function submitReport(report) {
    console.log("in report actions")
    return dispatch => {
        dispatch({ type: reportConstants.REPORT_SAVE_REQUEST });

        reportService.submitReport(report)
            .then(res => res.json())
            .then(data => dispatch({ type: reportConstants.REPORT_SAVE_SUCCESS, payload: [data, report] }))
            .catch(error => dispatch({ type: reportConstants.REPORT_SAVE_FAILURE, payload: [error, report] }));
    };
}