export class PinClass {
    constructor() {
        this.id = 0;
        this.ifid = "";
        this.pinned = "";
        this.uuid = null;
        this.firstname = "";
        this.name = "";
        this.email = "";
        this.displayname = "";
        this.onlypinned = false;
    }
}