import { socialmediapreviewConstants } from "../constants/socialmediapreview.constants";


export function socialmediapreview(state = { loading: false }, action) {
    switch (action.type) {
        case socialmediapreviewConstants.GET_REQUEST:
            return {
                socialmediaPreview: "",
                loading: true
            };
        case socialmediapreviewConstants.GET_SUCCESS:
            console.log(action.payload)
            return {
                socialmediaPreview: action.payload,
                loading: false
            };
        case socialmediapreviewConstants.GET_FAILURE:
            // console.log(action.payload)
            return {
                error: action.payload,
                socialmediaPreview: "",
                loading: false,
                done: true
            };
        case socialmediapreviewConstants.GET_FAILURE_DONE:
            return {
                error: Object.keys({ ...state.error }).length === 0 ?  "" : { ...state.error },
                socialmediaPreview: "",
                loading: false,
                done: false
            };
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}