export const appreciationConstants = {
    APPRECIATION_USER_REQUEST: 'APPRECIATION_SAVE_REQUEST',
    APPRECIATION_USER_SUCCESS: 'APPRECIATION_SAVE_SUCCESS',
    APPRECIATION_USER_FAILURE: 'APPRECIATION_SAVE_FAILURE',
    APPRECIATION_USER_CLEAR: 'APPRECIATION_USER_CLEAR',
    
    MYAPPRECIATION_USER_REQUEST: 'MYAPPRECIATION_SAVE_REQUEST',
    MYAPPRECIATION_USER_SUCCESS: 'MYAPPRECIATION_SAVE_SUCCESS',
    MYAPPRECIATION_USER_FAILURE: 'MYAPPRECIATION_SAVE_FAILURE',
    MYAPPRECIATION_USER_CLEAR: 'MYAPPRECIATION_USER_CLEAR',
};