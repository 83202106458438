import { switchConstants } from "../constants/switch.constants";

export function menuswitch(state = 0, action) {
    switch (action.type) {
        case switchConstants.SOCIAL_MEDIA:
            // console.log("request")
            return 0;
        case switchConstants.PUBLISHERS:
            return 1;
        case switchConstants.ACCOUNT:
            return 2;
        case switchConstants.HACKED:
            return 3;
        case switchConstants.SPACE:
            return 4;
        default:
            return state;
    }
}