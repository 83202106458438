import { socialmediaConstants } from "../constants/socialmedia.constants";


export function socialmedia(state = { loading: false }, action) {
    switch (action.type) {
        case socialmediaConstants.GET_REQUEST:
            return {
                socialmediaList: "",
                loading: true
            };
        case socialmediaConstants.GET_SUCCESS:
            console.log(action.payload)
            return {
                socialmediaList: action.payload,
                loading: false
            };
        case socialmediaConstants.GET_FAILURE:
            // console.log(action.payload)
            return {
                error: action.payload,
                socialmediaList: "",
                loading: false,
                done: true
            };
        case socialmediaConstants.GET_FAILURE_DONE:
            return {
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error },
                socialmediaList: "",
                loading: false,
                done: false
            };
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}

export function socialmediaDE(state = { loading: false }, action) {
    switch (action.type) {
        case socialmediaConstants.GET_REQUEST_DE:
            return {
                socialmediaListDE: "",
                loading: true
            };
        case socialmediaConstants.GET_SUCCESS_DE:
            console.log(action.payload)
            return {
                socialmediaListDE: action.payload,
                loading: false
            };
        case socialmediaConstants.GET_FAILURE_DE:
            // console.log(action.payload)
            return {
                error: action.payload,
                socialmediaListDE: "",
                loading: false,
                done: true
            };
        case socialmediaConstants.GET_FAILURE_DONE_DE:
            return {
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error },
                socialmediaListDE: "",
                loading: false,
                done: false
            };
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}

export function socialmediaES(state = { loading: false }, action) {
    switch (action.type) {
        case socialmediaConstants.GET_REQUEST_ES:
            return {
                socialmediaListES: "",
                loading: true
            };
        case socialmediaConstants.GET_SUCCESS_ES:
            console.log(action.payload)
            return {
                socialmediaListES: action.payload,
                loading: false
            };
        case socialmediaConstants.GET_FAILURE_ES:
            // console.log(action.payload)
            return {
                error: action.payload,
                socialmediaListES: "",
                loading: false,
                done: true
            };
        case socialmediaConstants.GET_FAILURE_DONE_ES:
            return {
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error },
                socialmediaListES: "",
                loading: false,
                done: false
            };
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}

export function socialmediaFR(state = { loading: false }, action) {
    switch (action.type) {
        case socialmediaConstants.GET_REQUEST_FR:
            return {
                socialmediaListFR: "",
                loading: true
            };
        case socialmediaConstants.GET_SUCCESS_FR:
            // console.log(action.payload)
            return {
                socialmediaListFR: action.payload,
                loading: false
            };
        case socialmediaConstants.GET_FAILURE_FR:
            // console.log(action.payload)
            return {
                error: action.payload,
                socialmediaListFR: "",
                loading: false,
                done: true
            };
        case socialmediaConstants.GET_FAILURE_DONE_FR:
            return {
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error },
                socialmediaListFR: "",
                loading: false,
                done: false
            };
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}

export function socialmediaPT(state = { loading: false }, action) {
    switch (action.type) {
        case socialmediaConstants.GET_REQUEST_PT:
            return {
                socialmediaListPT: "",
                loading: true
            };
        case socialmediaConstants.GET_SUCCESS_PT:
            // console.log(action.payload)
            return {
                socialmediaListPT: action.payload,
                loading: false
            };
        case socialmediaConstants.GET_FAILURE_PT:
            // console.log(action.payload)
            return {
                error: action.payload,
                socialmediaListPT: "",
                loading: false,
                done: true
            };
        case socialmediaConstants.GET_FAILURE_DONE_PT:
            return {
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error },
                socialmediaListPT: "",
                loading: false,
                done: false
            };
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}

export function socialmediaHI(state = { loading: false }, action) {
    switch (action.type) {
        case socialmediaConstants.GET_REQUEST_HI:
            return {
                socialmediaListHI: "",
                loading: true
            };
        case socialmediaConstants.GET_SUCCESS_HI:
            console.log(action.payload)
            return {
                socialmediaListHI: action.payload,
                loading: false
            };
        case socialmediaConstants.GET_FAILURE_HI:
            // console.log(action.payload)
            return {
                error: action.payload,
                socialmediaListHI: "",
                loading: false,
                done: true
            };
        case socialmediaConstants.GET_FAILURE_DONE_HI:
            return {
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error },
                socialmediaListHI: "",
                loading: false,
                done: false
            };
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}

export function socialmediaINDO(state = { loading: false }, action) {
    switch (action.type) {
        case socialmediaConstants.GET_REQUEST_INDO:
            return {
                socialmediaListINDO: "",
                loading: true
            };
        case socialmediaConstants.GET_SUCCESS_INDO:
            console.log(action.payload)
            return {
                socialmediaListINDO: action.payload,
                loading: false
            };
        case socialmediaConstants.GET_FAILURE_INDO:
            // console.log(action.payload)
            return {
                error: action.payload,
                socialmediaListINDO: "",
                loading: false,
                done: true
            };
        case socialmediaConstants.GET_FAILURE_DONE_INDO:
            return {
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error },
                socialmediaListINDO: "",
                loading: false,
                done: false
            };
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}

export function socialmediaRU(state = { loading: false }, action) {
    switch (action.type) {
        case socialmediaConstants.GET_REQUEST_RU:
            return {
                socialmediaListRU: "",
                loading: true
            };
        case socialmediaConstants.GET_SUCCESS_RU:
            // console.log(action.payload)
            return {
                socialmediaListRU: action.payload,
                loading: false
            };
        case socialmediaConstants.GET_FAILURE_RU:
            // console.log(action.payload)
            return {
                error: action.payload,
                socialmediaListRU: "",
                loading: false,
                done: true
            };
        case socialmediaConstants.GET_FAILURE_DONE_RU:
            return {
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error },
                socialmediaListRU: "",
                loading: false,
                done: false
            };
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}

export function socialmediaCH(state = { loading: false }, action) {
    switch (action.type) {
        case socialmediaConstants.GET_REQUEST_CH:
            return {
                socialmediaListCH: "",
                loading: true
            };
        case socialmediaConstants.GET_SUCCESS_CH:
            // console.log(action.payload)
            return {
                socialmediaListCH: action.payload,
                loading: false
            };
        case socialmediaConstants.GET_FAILURE_CH:
            // console.log(action.payload)
            return {
                error: action.payload,
                socialmediaListCH: "",
                loading: false,
                done: true
            };
        case socialmediaConstants.GET_FAILURE_DONE_CH:
            return {
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error },
                socialmediaListCH: "",
                loading: false,
                done: false
            };
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}

export function socialmediaJP(state = { loading: false }, action) {
    switch (action.type) {
        case socialmediaConstants.GET_REQUEST_JP:
            return {
                socialmediaListJP: "",
                loading: true
            };
        case socialmediaConstants.GET_SUCCESS_JP:
            // console.log(action.payload)
            return {
                socialmediaListJP: action.payload,
                loading: false
            };
        case socialmediaConstants.GET_FAILURE_JP:
            // console.log(action.payload)
            return {
                error: action.payload,
                socialmediaListJP: "",
                loading: false,
                done: true
            };
        case socialmediaConstants.GET_FAILURE_DONE_JP:
            return {
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error },
                socialmediaListJP: "",
                loading: false,
                done: false
            };
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}

export function socialmediaAR(state = { loading: false }, action) {
    switch (action.type) {
        case socialmediaConstants.GET_REQUEST_AR:
            return {
                socialmediaListAR: "",
                loading: true
            };
        case socialmediaConstants.GET_SUCCESS_AR:
            // console.log(action.payload)
            return {
                socialmediaListAR: action.payload,
                loading: false
            };
        case socialmediaConstants.GET_FAILURE_AR:
            // console.log(action.payload)
            return {
                error: action.payload,
                socialmediaListAR: "",
                loading: false,
                done: true
            };
        case socialmediaConstants.GET_FAILURE_DONE_AR:
            return {
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error },
                socialmediaListAR: "",
                loading: false,
                done: false
            };
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}

export function socialmediaIT(state = { loading: false }, action) {
    switch (action.type) {
        case socialmediaConstants.GET_REQUEST_IT:
            return {
                socialmediaListIT: "",
                loading: true
            };
        case socialmediaConstants.GET_SUCCESS_IT:
            // console.log(action.payload)
            return {
                socialmediaListIT: action.payload,
                loading: false
            };
        case socialmediaConstants.GET_FAILURE_IT:
            // console.log(action.payload)
            return {
                error: action.payload,
                socialmediaListIT: "",
                loading: false,
                done: true
            };
        case socialmediaConstants.GET_FAILURE_DONE_IT:
            return {
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error },
                socialmediaListIT: "",
                loading: false,
                done: false
            };
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}

export function socialmediaTR(state = { loading: false }, action) {
    switch (action.type) {
        case socialmediaConstants.GET_REQUEST_TR:
            return {
                socialmediaListTR: "",
                loading: true
            };
        case socialmediaConstants.GET_SUCCESS_TR:
            // console.log(action.payload)
            return {
                socialmediaListTR: action.payload,
                loading: false
            };
        case socialmediaConstants.GET_FAILURE_TR:
            // console.log(action.payload)
            return {
                error: action.payload,
                socialmediaListTR: "",
                loading: false,
                done: true
            };
        case socialmediaConstants.GET_FAILURE_DONE_TR:
            return {
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error },
                socialmediaListTR: "",
                loading: false,
                done: false
            };
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}

export function socialmediaNL(state = { loading: false }, action) {
    switch (action.type) {
        case socialmediaConstants.GET_REQUEST_NL:
            return {
                socialmediaListNL: "",
                loading: true
            };
        case socialmediaConstants.GET_SUCCESS_NL:
            // console.log(action.payload)
            return {
                socialmediaListNL: action.payload,
                loading: false
            };
        case socialmediaConstants.GET_FAILURE_NL:
            // console.log(action.payload)
            return {
                error: action.payload,
                socialmediaListNL: "",
                loading: false,
                done: true
            };
        case socialmediaConstants.GET_FAILURE_DONE_NL:
            return {
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error },
                socialmediaListNL: "",
                loading: false,
                done: false
            };
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}

export function socialmediaGR(state = { loading: false }, action) {
    switch (action.type) {
        case socialmediaConstants.GET_REQUEST_GR:
            return {
                socialmediaListGR: "",
                loading: true
            };
        case socialmediaConstants.GET_SUCCESS_GR:
            // console.log(action.payload)
            return {
                socialmediaListGR: action.payload,
                loading: false
            };
        case socialmediaConstants.GET_FAILURE_GR:
            // console.log(action.payload)
            return {
                error: action.payload,
                socialmediaListGR: "",
                loading: false,
                done: true
            };
        case socialmediaConstants.GET_FAILURE_DONE_GR:
            return {
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error },
                socialmediaListGR: "",
                loading: false,
                done: false
            };
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}