import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { breachConstants } from '../constants';
import { Spinner } from '../spinner/spinner';
import { CustomPagination } from './CustomPagination';
import { Breach } from './Item';


export const BreachList = () => {
    const dispatch = useDispatch();
    const b = useSelector(state => state.breach.breachList);
    const [breachList, setBreachList] = useState(b);
    const dataLimitPerPage = 12;
    const a = useSelector(state => state.breach.account);
    const [account, setAccount] = useState(a);
    const loading = useSelector(state => state.breach.loading);
    const e = useSelector(state => state.breach.error);
    const [error, setError] = useState(e);
    const d = useSelector(state => state.breach.done);
    const [done, setDone] = useState(d);
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setBreachList(b);
    }, [b]);

    useEffect(() => {
        setAccount(a);
    }, [a]);

    useEffect(() => {
        setError(e);
    }, [e]);

    useEffect(() => {
        setDone(d);
    }, [d]);

    useEffect(() => {
        setTheme(t);
    }, [t])

    // console.log(accountList)
    var title;
    if (breachList && breachList.breaches && breachList.breaches.length > 0) {
        if (breachList.breaches.length > 1) {
            title = breachList.breaches.length + " Breaches found on";
        } else if (breachList.breaches.length === 1) {
            title = breachList.breaches.length + " Breach found on";
        }
    }

    const getPageLimit = (allData, dataLimitPerPage) => {
        if (allData % dataLimitPerPage === 0) {
            return allData / dataLimitPerPage;
        } else {
            return Math.floor(allData / dataLimitPerPage) + 1;
        }
    }

    if (!breachList || !breachList.breaches) {
        if (loading) {
            return (
                <>
                    <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                        <div class="marginMobil" style={{ marginRight: "30px", marginLeft: "200px" }}>
                            <div>
                                <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                                    <Spinner show={loading} />
                                    <h1 style={{ color: theme === 'dark' ? '#fff' : '#000' }}>Search Breaches</h1> {/* light */}
                                    <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 975, color: '#fff', textAlign: "center" }} class="p-3 mb-2 bg-primary bg-gradient text-white"><InfoIcon style={{ marginBottom: 5 }} /> Check if you have an email address that has been compromised in a Data Breach.</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        if (done) {
            if (error) {
                dispatch({ type: breachConstants.SEARCH_FAILURE_DONE });
                Swal.fire({
                    icon: 'error',
                    title: 'An error has occurred. Please try again ...',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }

        return (
            <>
                <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                    <div class="marginMobil" style={{ marginRight: "30px", marginLeft: "200px" }}>
                        <div>
                            <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                                <h1 style={{ color: theme === 'dark' ? '#fff' : '#000' }} >Search Breaches</h1> {/* light */}
                                <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 975, color: '#fff', textAlign: "center" }} class="p-3 mb-2 bg-primary bg-gradient text-white"><InfoIcon style={{ marginBottom: 5 }} /> Check if you have an email address that has been compromised in a Data Breach.</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                {breachList.breaches.length > 0 ? (
                    <>
                        <CustomPagination
                            data={breachList.breaches}
                            RenderComponent={Breach}
                            title={title}
                            pageLimit={getPageLimit(breachList.breaches.length, dataLimitPerPage)}
                            dataLimit={dataLimitPerPage}
                        />
                    </>
                ) : (
                    <>
                        <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                            <div class="marginMobil" style={{ marginRight: "30px", marginLeft: "200px" }}>
                                <div>
                                    <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                                        <h1 style={{ color: theme === 'dark' ? '#fff' : '#000' }} >Search Breaches</h1>
                                        <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 975, color: '#fff', textAlign: "center" }} class="p-3 mb-2 bg-primary bg-gradient text-white"><CheckCircleIcon style={{ marginBottom: 5 }} /> Everything fine with the account "{account}".</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    }
}