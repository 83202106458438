export class PostClass{
    
    constructor() {
        this.id = 0;
        this.title = "";
        this.text = "";
        this.hashtags = "";
        this.link1 = "";
        this.link2 = "";
        this.link3 = "";
        this.link4 = "";
        this.createdAt = "";
        this.updatedAt = "";
        this.createdBy = "";
        this.space = "";
        this.spaceid = "";
    }
}
