import { publisherConstants } from "../constants";
import { publisherService } from "../services";



export const publisherActions = {
    getPublisherList,
    getMyPublisherList
};

function getPublisherList(query, search) {
    // console.log("in Actions")
    
    return dispatch => {
        dispatch({ type: publisherConstants.GET_REQUEST });

        publisherService.getPublisherList(query, search)
            .then(res => res.json())
            .then(data => dispatch({ type: publisherConstants.GET_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: publisherConstants.GET_FAILURE, payload: error }));
    };
}

function getMyPublisherList(query, search) {
    // console.log("in Actions")

    return dispatch => {
        dispatch({ type: publisherConstants.GET_REQUEST });

        publisherService.getMyPublisherList(query, search)
            .then(res => res.json())
            .then(data => dispatch({ type: publisherConstants.GET_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: publisherConstants.GET_FAILURE, payload: error }));
    };
}