import axios from "axios";
import { authHeader, authHeaderWithJSON, authHeaderWithMultipart, authHeaderWithoutContentType } from "../helpers";
import { workerConstants } from "../constants";

export const discussionService = {
    getDiscussionList,
    getMyDiscussionList,
    getLightDiscussionList,
    getFeed,
    saveDiscussion,
    updateDiscussion,
    deleteDiscussion,
    saveFeedFilter,
    getFeedFilter
};

const COMMUNO = workerConstants.WORKER


function getDiscussionList(space, query, search) {
    const body = space;

    const requestOptions = {
        headers: authHeader()
    };

    if (search) {
        return fetch(COMMUNO + "discussions?q=" + query,
            {
                method: 'POST',
                headers: authHeaderWithJSON(),
                //headers: { 'Accept': 'application/json', /*'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'text/html'*/ },
                body: body
            });
    } else {
        return fetch(COMMUNO + "discussions",
            {
                method: 'POST',
                headers: authHeaderWithJSON(),
                //headers: { 'Accept': 'application/json', /*'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'text/html'*/ },
                body: body
            });
    }
}

function getMyDiscussionList(query, search) {
    const requestOptions = {
        headers: authHeader()
    };

    if (search) {
        return fetch(COMMUNO + "mydiscussions?q=" + query,
            {
                method: 'GET',
                headers: authHeader(),
            });
    } else {
        return fetch(COMMUNO + "mydiscussions",
            {
                method: 'GET',
                headers: authHeader(),
            });
    }
}

function getLightDiscussionList(query, search) {
    const requestOptions = {
        headers: authHeader()
    };

    if (search) {
        return fetch(COMMUNO + "mylightdiscussions?q=" + query,
            {
                method: 'GET',
                headers: authHeader(),
            });
    } else {
        return fetch(COMMUNO + "mylightdiscussions",
            {
                method: 'GET',
                headers: authHeader(),
            });
    }
}

function getFeed(query, search) {
    const requestOptions = {
        headers: authHeader()
    };

    if (search) {
        return fetch(COMMUNO + "feed?q=" + query,
            {
                method: 'GET',
                headers: authHeader(),
            });
    } else {
        return fetch(COMMUNO + "feed",
            {
                method: 'GET',
                headers: authHeader(),
            });
    }

}

function getFeedFilter() {
    const requestOptions = {
        headers: authHeader()
    };

    return fetch(COMMUNO + "feedfilter",
        {
            method: 'GET',
            headers: authHeaderWithJSON(),
        });
}

function saveFeedFilter(data) {
    const body = JSON.stringify(data);

    return fetch(COMMUNO + "savefeedfilter",
        {
            method: 'POST',
            headers: authHeaderWithJSON(),
            // headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json' },
            body: body
        });
}

function saveDiscussion(data, images) {
    const requestOptions = {
        headers: authHeaderWithMultipart()
    };


    const formData = new FormData();

    if (images && images.length != 0) {
        for (let a = 0; a < images.length; a++) {
            formData.append("image", images[a]);
        }
    } else {
        formData.append("image", []);
    }

    formData.append("discussion", JSON.stringify(data));

    // return axios.post("https://service.onelike.app/servicespace/creatediscussion", formData, requestOptions);

    return fetch(COMMUNO + "creatediscussion",
        {
            method: 'POST',
            headers: authHeaderWithoutContentType(),
            body: formData
        });
}

function updateDiscussion(data, images, editImageInfos) {
    const requestOptions = {
        headers: authHeaderWithMultipart()
    };


    const formData = new FormData();

    if (images && images.length != 0) {
        for (let a = 0; a < images.length; a++) {
            formData.append("image", images[a]);
        }
    } else {
        formData.append("image", []);
    }

    // console.log(editImageInfos)
    if (editImageInfos) {
        console.log(editImageInfos)
        formData.append("editImageInfos", JSON.stringify(editImageInfos));
    }

    formData.append("discussion", JSON.stringify(data));

    // return axios.post("https://service.onelike.app/servicespace/updatediscussion", formData, requestOptions);

    return fetch(COMMUNO + "updatediscussion",
        {
            method: 'POST',
            headers: authHeaderWithoutContentType(),
            // headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json' },
            body: formData
        });
}

function deleteDiscussion(data) {
    const body = JSON.stringify(data);

    return fetch(COMMUNO + "deletediscussion",
        {
            method: 'POST',
            headers: authHeaderWithJSON(),
            // headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json' },
            body: body
        });
}