import { workerConstants } from "../constants";
import { authHeader, authHeaderWithJSON } from "../helpers";

export const appreciationService = {
    giveAppreciation,
    myAppreciation
};

const COMMUNO = workerConstants.WORKER


function giveAppreciation(data) {
    console.log("in service appreciation")
    const body = JSON.stringify(data);

    return fetch(COMMUNO + "giving",
        {
            method: 'POST',
            headers: authHeaderWithJSON(),
            // headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json' },
            body: body
        });
}

function myAppreciation(data) {
    console.log("in service appreciation")
    const body = JSON.stringify(data);

    return fetch(COMMUNO + "myappreciation",
        {
            method: 'GET',
            headers: authHeader(),            
        });
}