export class ImageClass {
    constructor() {
        this.uiid = ""; // filename or uuid
        this.url = "";
        this.new = false;
        this.file = "";
    }
}

export class ProfilImageClass {
    constructor() {
        this.uiid = ""; // filename or uuid
        this.url = "";
        this.new = true;
        this.file = "";
    }
}

export class OneEditInfoClass {
    constructor() {
        this.uiid = "";
        this.deleted = false;
    }
}

export class EditInfoClass {
    constructor() {
        this.editInfos = "";
    }
}

export class ProfilImageUploadClass {
    constructor() {
        this.pictureurl = false
        this.profilcard = false
    }
}