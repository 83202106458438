import { themeswitchConstants } from "../constants/themeswitch.constants";


export function themeswitch(state = 'dark', action) {
    switch (action.type) {
        case themeswitchConstants.DARK_MODE:
            return 'dark';
        case themeswitchConstants.LIGHT_MODE:
            return 'light';
        default:
            return localStorage.getItem("theme") === null ? 'light' : localStorage.getItem("theme");
    }
}