
export const socialMediaService = {
  getSocialMediaList,
  getSocialMediaListES,
  getSocialMediaListDE,
  getSocialMediaListFR,
  getSocialMediaListPT,
  getSocialMediaListHI,
  getSocialMediaListINDO,
  getSocialMediaListRU,
  getSocialMediaListCH,
  getSocialMediaListJP,
  getSocialMediaListAR,
  getSocialMediaListIT,
  getSocialMediaListTR,
  getSocialMediaListNL,
  getSocialMediaListGR,
  searchProfiles,
  searchBreaches,
  searchNews,
  searchNewsES,
  searchNewsDE,
  searchNewsFR,
  searchNewsPT,
  searchNewsHI,
  searchNewsINDO,
  searchNewsRU,
  searchNewsCH,
  searchNewsJP,
  searchNewsAR,
  searchNewsIT,
  searchNewsTR,
  searchNewsNL,
  searchNewsGR,
  getSocialMediaPreview
};

function searchNews(data) {
  // console.log("in Service SearchNews")

  // return axios.post(`${process.env.REACT_APP_ONELIKE_BACKEND_API}/searchnews`, data, {
  //   headers: { 'Content-Type': 'text/plain' }
  // })
  const body = data;

  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API}/searchnews`,
    {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'text/html' },
      body: body
    });
}

function searchNewsES(data) {
  // console.log("in Service SearchNews")

  const body = data;

  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_ES}/searchnews`,
    {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'text/html' },
      body: body
    });

}

function searchNewsDE(data) {
  // console.log("in Service SearchNews")

  const body = data;

  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_DE}/searchnews`,
    {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'text/html' },
      body: body
    });

}

function searchNewsFR(data) {
  // console.log("in Service SearchNews")

  const body = data;

  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_FR}/searchnews`,
    {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'text/html' },
      body: body
    });

}

function searchNewsPT(data) {
  // console.log("in Service SearchNews")

  const body = data;

  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_PT}/searchnews`,
    {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'text/html' },
      body: body
    });

}

function searchNewsHI(data) {
  // console.log("in Service SearchNews")

  const body = data;

  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_HI}/searchnews`,
    {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'text/html' },
      body: body
    });

}

function searchNewsINDO(data) {
  // console.log("in Service SearchNews")

  const body = data;

  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_INDO}/searchnews`,
    {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'text/html' },
      body: body
    });

}

function searchNewsRU(data) {
  // console.log("in Service SearchNews")

  const body = data;

  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_RU}/searchnews`,
    {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'text/html' },
      body: body
    });

}

function searchNewsCH(data) {
  // console.log("in Service SearchNews")

  const body = data;

  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_CH}/searchnews`,
    {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'text/html' },
      body: body
    });

}

function searchNewsJP(data) {
  // console.log("in Service SearchNews")

  const body = data;

  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_JP}/searchnews`,
    {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'text/html' },
      body: body
    });

}

function searchNewsAR(data) {
  // console.log("in Service SearchNews")

  const body = data;

  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_AR}/searchnews`,
    {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'text/html' },
      body: body
    });

}

function searchNewsIT(data) {
  // console.log("in Service SearchNews")

  const body = data;

  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_IT}/searchnews`,
    {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'text/html' },
      body: body
    });

}

function searchNewsTR(data) {
  // console.log("in Service SearchNews")

  const body = data;

  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_TR}/searchnews`,
    {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'text/html' },
      body: body
    });

}

function searchNewsNL(data) {
  // console.log("in Service SearchNews")

  const body = data;

  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_NL}/searchnews`,
    {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'text/html' },
      body: body
    });

}

function searchNewsGR(data) {
  // console.log("in Service SearchNews")

  const body = data;

  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_GR}/searchnews`,
    {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'text/html' },
      body: body
    });

}

function getSocialMediaList() {
  // console.log("in Service")
  // return axios.get(`${process.env.REACT_APP_ONELIKE_BACKEND_CLOUDFLARE}/news`, requestOptions);
  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API}/news`,
    {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*" }
    });
}

function getSocialMediaListES() {
  // console.log("in Service")
  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_ES}/news`,
    {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*" }
    });
}

function getSocialMediaListDE() {
  // console.log("in Service")
  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_DE}/news`,
    {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*" }
    });
}

function getSocialMediaListFR() {
  // console.log("in Service")
  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_FR}/news`,
    {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*" }
    });
}

function getSocialMediaListPT() {
  // console.log("in Service")
  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_PT}/news`,
    {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*" }
    });
}

function getSocialMediaListHI() {
  // console.log("in Service")
  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_HI}/news`,
    {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*" }
    });
}

function getSocialMediaListINDO() {
  // console.log("in Service")
  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_INDO}/news`,
    {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*" }
    });
}

function getSocialMediaListRU() {
  // console.log("in Service")
  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_RU}/news`,
    {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*" }
    });
}

function getSocialMediaListCH() {
  // console.log("in Service")
  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_CH}/news`,
    {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*" }
    });
}

function getSocialMediaListJP() {
  // console.log("in Service")
  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_JP}/news`,
    {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*" }
    });
}

function getSocialMediaListAR() {
  // console.log("in Service")
  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_AR}/news`,
    {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*" }
    });
}

function getSocialMediaListIT() {
  // console.log("in Service")
  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_IT}/news`,
    {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*" }
    });
}

function getSocialMediaListTR() {
  // console.log("in Service")
  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_TR}/news`,
    {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*" }
    });
}

function getSocialMediaListNL() {
  // console.log("in Service")
  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_NL}/news`,
    {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*" }
    });
}

function getSocialMediaListGR() {
  // console.log("in Service")
  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API_GR}/news`,
    {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*" }
    });
}

function searchBreaches(data) {
  // console.log("in Hacked")

  const body = data;

  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API}/breach`,
    {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'text/html' },
      body: body
    });

  // return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_CLOUDFLARE}/breach`);

}

function searchProfiles(data) {
  // console.log("in search Service")

  const body = data;

  return fetch(`${process.env.REACT_APP_ONELIKE_BACKEND_API}/searchprofil`,
    {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'text/html' },
      body: body
    });
}

function getSocialMediaPreview(data) {
  // console.log("in Service")
  return fetch(`preview1.json`,
    {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*" }
    });
}
