import { commentConstants } from "../constants";
import { CommentClass } from "../models/comment";

export function comment(state = { loading: false }, action) {
    switch (action.type) {
        case commentConstants.GET_REQUEST:
            return {
                comments: "",
                loading: true,
                done: false,
                error: undefined,
            };
        case commentConstants.GET_SUCCESS:
            // console.log(action.payload)
            return {
                comments: action.payload,
                loading: false,
                done: true,
                error: undefined,
            };
        case commentConstants.GET_FAILURE:
            // console.log(action.payload)
            return {
                error: action.payload,
                comments: Object.keys({ ...state.comments }).length === 0 ? "" : { ...state.comments },
                loading: false,
                done: true
            };
        case commentConstants.COMMENT_SAVE_REQUEST:
            // console.log("request")
            return {
                comment: new CommentClass(),
                comments: Object.keys({ ...state.comments }).length === 0 ? "" : { ...state.comments },
                loading: true,
                done: false
            };
        case commentConstants.COMMENT_SAVE_SUCCESS:
            // console.log(action.payload)
            return {
                comment: action.payload[0],
                comments: Object.keys({ ...state.comments }).length === 0 ? "" : { ...state.comments },
                loading: false,
                done: true,
                error: undefined
            };
        case commentConstants.COMMENT_SAVE_FAILURE:
            return {
                error: action.payload[0],
                comment: action.payload[1],
                comments: Object.keys({ ...state.comments }).length === 0 ? "" : { ...state.comments },
                loading: false,
                done: false
            };
        case commentConstants.DELETE_REQUEST:
            // console.log("request")
            return {
                comment: "",
                comments: Object.keys({ ...state.comments }).length === 0 ? "" : { ...state.comments },
                loading: true,
                done: false,
                error: undefined,
            };
        case commentConstants.DELETE_SUCCESS:
            // console.log(action.payload)
            return {
                comment: new CommentClass(),
                comments: Object.keys({ ...state.comments }).length === 0 ? "" : { ...state.comments },
                loading: false,
                done: true,
                error: undefined,
            };
        case commentConstants.DELETE_CLEAR:
            return {
                comment: "",
                comments: "",
                loading: false,
                done: false
            };
        case commentConstants.DELETE_FAILURE:
            // console.log(action.payload)
            return {
                error: action.payload[0],
                comment: action.payload[1],
                comments: Object.keys({ ...state.comments }).length === 0 ? "" : { ...state.comments },
                loading: false,
                done: true
            };
        case commentConstants.DELETE_FAILURE_DONE:
            return {
                comment: "",
                comments: "",
                loading: false,
                done: false
            };
        case commentConstants.COMMENT_SAVE_CLEAR:
            return {};
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}