import { quoteswitchConstants } from "../constants";

export function quoteswitch(
    state = true, 
    action
    ) {
    switch (action.type) {
        case quoteswitchConstants.NO:
            return false;
        case quoteswitchConstants.YES:
            // console.log("request")
            return true;
        default:
            return localStorage.getItem("quote") === null ? true : localStorage.getItem("quote") === "true";
    }
}