export const messageConstants = {
    GET_REQUEST: 'MESSAGE_GET_REQUEST',
    GET_SUCCESS: 'MESSAGE_GET_SUCCESS',
    GET_FAILURE: 'MESSAGE_GET_FAILURE',
    GET_FAILURE_DONE: 'MESSAGE_GET_FAILURE_DONE',

    MESSAGE_SAVE_REQUEST: 'MESSAGE_SAVE_REQUEST',
    MESSAGE_SAVE_SUCCESS: 'MESSAGE_SAVE_SUCCESS',
    MESSAGE_SAVE_FAILURE: 'MESSAGE_SAVE_FAILURE',
    MESSAGE_SAVE_CLEAR: 'MESSAGE_SAVE_CLEAR',

    MESSAGE_DELETE_REQUEST: 'MESSAGE_DELETE_REQUEST',
    MESSAGE_DELETE_SUCCESS: 'MESSAGE_DELETE_SUCCESS',
    MESSAGE_DELETE_CLEAR: 'MESSAGE_DELETE_CLEAR',
    MESSAGE_DELETE_FAILURE: 'MESSAGE_DELETE_FAILURE',
    MESSAGE_DELETE_FAILURE_DONE: 'MESSAGE_DELETE_FAILURE_DONE',

    MESSAGE_CHECK_READ_REQUEST: 'MESSAGE_CHECK_READ_REQUEST',
    MESSAGE_CHECK_READ_SUCCESS: 'MESSAGE_CHECK_READ_SUCCESS',
    MESSAGE_CHECK_READ_CLEAR: 'MESSAGE_CHECK_READ_CLEAR',
    MESSAGE_CHECK_READ_FAILURE: 'MESSAGE_CHECK_READ_FAILURE',
    MESSAGE_CHECK_READ_FAILURE_DONE: 'MESSAGE_CHECK_READ_FAILURE_DONE',
};