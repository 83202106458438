import { publisherswitchConstants } from "../constants";


export function publisherswitch(state = 0, action) {
    switch (action.type) {
        case publisherswitchConstants.ALL_PUBLISHERS:
            return 0;
        case publisherswitchConstants.PINNED:
            return 1;
        default:
            return state;
    }
}