import { commentConstants } from "../constants";
import { commentService } from "../services";

export const commentActions = {
    writeComment,
    deleteComment,
    clearComment,
    getComments,
};

function getComments(comment) {
    // console.log("in Actions in DiscussionList")
    return dispatch => {
        dispatch({ type: commentConstants.GET_REQUEST });

        commentService.getComments(comment)
            .then(res => res.json())
            .then(data => dispatch({ type: commentConstants.GET_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: commentConstants.GET_FAILURE, payload: error }));
    };
}

function writeComment(comment) {
    // console.log("in comment actions")
    return dispatch => {
        dispatch({ type: commentConstants.COMMENT_SAVE_REQUEST });

        commentService.writeComment(comment)
            .then(res => res.json())
            .then(data => dispatch({ type: commentConstants.COMMENT_SAVE_SUCCESS, payload: [data, comment] }))
            .catch(error => dispatch({ type: commentConstants.COMMENT_SAVE_FAILURE, payload: [error, comment] }));
    };
}


function deleteComment(comment) {
    // console.log("in comment actions")
    return dispatch => {
        dispatch({ type: commentConstants.COMMENT_SAVE_REQUEST });

        commentService.deleteComment(comment)
            .then(res => res.json())
            .then(data => dispatch({ type: commentConstants.DELETE_SUCCESS, payload: [data, comment] }))
            .catch(error => dispatch({ type: commentConstants.DELETE_FAILURE, payload: [error, comment] }));
    };
}

function clearComment() {
    // history.push('/examensportal/login');
    return { type: commentConstants.COMMENT_SAVE_CLEAR };
}