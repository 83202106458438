import { workerConstants } from "../constants";
import { authHeaderWithJSON } from "../helpers";

export const infoService = {
    getInfos,
    getInfoNewsGuide
};

const COMMUNO = workerConstants.WORKER

const COMMUNO_PUB = workerConstants.PUB

function getInfos() {

    return fetch(COMMUNO + "infos",
        {
            method: 'GET',
            headers: authHeaderWithJSON(),
        });

}

function getInfoNewsGuide() {

    return fetch(COMMUNO_PUB + "infonewsguide",
        {
            method: 'GET',
            headers: authHeaderWithJSON(),
        });

}