import { chatConstants } from "../constants";


export function chat(state = { loading: false }, action) {
    switch (action.type) {
        case chatConstants.GET_REQUEST:
            return {
                chat: Object.keys({ ...state.chat }).length === 0 ? "" : { ...state.chat},
                loading: true,
                done: false,
                error: undefined,
            };
        case chatConstants.GET_SUCCESS:
            // console.log(action.payload)
            return {
                chat: action.payload,
                loading: false,
                done: true,
                error: undefined,
            };
        case chatConstants.GET_FAILURE:
            // console.log(action.payload)
            return {
                error: action.payload,
                chat: Object.keys({ ...state.chat }).length === 0 ? "" : { ...state.chat},
                loading: false,
                done: true
            }
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}