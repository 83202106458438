import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { configureStore } from './helpers'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async';
import { register } from 'swiper/element/bundle';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import { render } from 'react-snapshot'
// import { hydrate, render } from "react-dom";

import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));

const preloadedState = window.__PRELOADED_STATE__;

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__

// Create Redux store with state injected by the server
const store = configureStore(preloadedState)

// Tell react-snap how to save Redux state
window.snapSaveState = () => ({
  __PRELOADED_STATE__: store.getState()
});

const helmetContext = {};

const rootElement = document.getElementById("root");

root.render(
  <>
    <Provider store={store}>
      <HelmetProvider context={helmetContext}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_ONELIKE_GOOGLE_CLIENT_ID}>
          <App />
        </GoogleOAuthProvider>
      </HelmetProvider>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
register();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
