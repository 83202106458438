import { useDispatch } from 'react-redux';
import { discussionConstants, feedfilterConstants } from '../constants';
import { discussionService } from '../services';

export const discussionActions = {
    getDiscussionList,
    getMyDiscussionList,
    getLightDiscussionList,
    getFeed,
    saveDiscussion,
    updateDiscussion,
    deleteDiscussion,
    saveFeedFilter,
    getFeedFilter
};

function getDiscussionList(space, query, search) {
    console.log("in Actions in DiscussionList")
    return dispatch => {
        dispatch({ type: discussionConstants.GET_REQUEST });

        discussionService.getDiscussionList(space, query, search)
            .then(res => res.json())
            .then(data => dispatch({ type: discussionConstants.GET_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: discussionConstants.GET_FAILURE, payload: error }));
    };
}

function getMyDiscussionList(query, search) {
    console.log("in Actions in DiscussionList")
    return dispatch => {
        dispatch({ type: discussionConstants.GET_REQUEST });

        discussionService.getMyDiscussionList(query, search)
            .then(res => res.json())
            .then(data => dispatch({ type: discussionConstants.GET_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: discussionConstants.GET_FAILURE, payload: error }));
    };
}

function getLightDiscussionList(query, search) {
    console.log("in Actions in LightDiscussionList")
    return dispatch => {
        dispatch({ type: discussionConstants.GET_REQUEST });

        discussionService.getLightDiscussionList(query, search)
            .then(res => res.json())
            .then(data => dispatch({ type: discussionConstants.GET_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: discussionConstants.GET_FAILURE, payload: error }));
    };
}

function getFeed(query, search) {
    // console.log("in Actions in Feed")
    
    return dispatch => {
        dispatch({ type: discussionConstants.GET_REQUEST });

        discussionService.getFeed(query, search)
            .then(res => res.json())
            .then(data => dispatch({ type: discussionConstants.GET_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: discussionConstants.GET_FAILURE, payload: error }));
    };
}

function getFeedFilter() {
    return dispatch => {
        dispatch({ type: feedfilterConstants.GET_REQUEST });

        discussionService.getFeedFilter()
            .then(res => res.json())
            .then(data => dispatch({ type: feedfilterConstants.GET_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: feedfilterConstants.GET_FAILURE, payload: error }));
    };
}

function saveFeedFilter(ffilter) {
    return dispatch => {
        dispatch({ type: feedfilterConstants.SAVE_REQUEST });

        discussionService.saveFeedFilter(ffilter)
            .then(res => res.json())
            .then(data => dispatch({ type: feedfilterConstants.SAVE_SUCCESS, payload: [data, ffilter] }))
            .catch(error => dispatch({ type: feedfilterConstants.SAVE_FAILURE, payload: [error, ffilter]}));
    };
}

function saveDiscussion(discussion, images) {
    // console.log(searchText)
    // console.log("in search news")
    return dispatch => {
        dispatch({ type: discussionConstants.SAVE_REQUEST });

        discussionService.saveDiscussion(discussion, images)
            .then(res => res.json())
            .then(data => dispatch({ type: discussionConstants.SAVE_SUCCESS, payload: [discussion, data] }))
            .catch(error => dispatch({ type: discussionConstants.SAVE_FAILURE, payload: [error, discussion] }));
    };
}

function updateDiscussion(discussion, newImages, editImageInfos) {
    console.log(editImageInfos)
    // console.log("in search news")
    return dispatch => {
        dispatch({ type: discussionConstants.UPDATE_REQUEST });

        discussionService.updateDiscussion(discussion, newImages, editImageInfos)
            .then(res => res.json())
            .then(data => dispatch({ type: discussionConstants.UPDATE_SUCCESS, payload: [discussion, data] }))
            .catch(error => dispatch({ type: discussionConstants.UPDATE_FAILURE, payload: [error, discussion] }));
    };
}

function deleteDiscussion(discussion) {
    // console.log(searchText)
    // console.log("in search news")
    return dispatch => {
        dispatch({ type: discussionConstants.DELETE_REQUEST });

        discussionService.deleteDiscussion(discussion)
            .then(res => res.json())
            .then(data => dispatch({ type: discussionConstants.DELETE_SUCCESS, payload: [discussion, data] }))
            .catch(error => dispatch({ type: discussionConstants.DELETE_FAILURE, payload: [error, discussion] }));
    };
}
