export const chatConstants = {
    GET_REQUEST: 'CHAT_GET_REQUEST',
    GET_SUCCESS: 'CHAT_GET_SUCCESS',
    GET_FAILURE: 'CHAT_GET_FAILURE',
    GET_FAILURE_DONE: 'CHAT_GET_FAILURE_DONE',

    BLOCK_REQUEST: 'CHAT_BLOCK_REQUEST',
    BLOCK_SUCCESS: 'CHAT_BLOCK_SUCCESS',
    BLOCK_FAILURE: 'CHAT_BLOCK_FAILURE',
    BLOCK_FAILURE_DONE: 'CHAT_BLOCK_FAILURE_DONE',

    UNBLOCK_REQUEST: 'CHAT_UNBLOCK_REQUEST',
    UNBLOCK_SUCCESS: 'CHAT_UNBLOCK_SUCCESS',
    UNBLOCK_FAILURE: 'CHAT_UNBLOCK_FAILURE',
    UNBLOCK_FAILURE_DONE: 'CHAT_UNBLOCK_FAILURE_DONE',
}