import { chatConstants } from "../constants";


export function blockchat(state = { loading: false }, action) {
    switch (action.type) {
        case chatConstants.BLOCK_REQUEST:
            return {
                blockchat: Object.keys({ ...state.blockchat }).length === 0 ? "" : { ...state.blockchat },
                loading: true,
                done: false,
                error: undefined,
            };
        case chatConstants.BLOCK_SUCCESS:
            // console.log(action.payload)
            return {
                blockchat: action.payload,
                loading: false,
                done: true,
                error: undefined,
            };
        case chatConstants.BLOCK_FAILURE:
            // console.log(action.payload)
            return {
                error: action.payload,
                blockchat: Object.keys({ ...state.blockchat }).length === 0 ? "" : { ...state.blockchat },
                loading: false,
                done: true
            }
        case chatConstants.UNBLOCK_REQUEST:
            return {
                blockchat: Object.keys({ ...state.blockchat }).length === 0 ? "" : { ...state.blockchat },
                loading: true,
                done: false,
                error: undefined,
            };
        case chatConstants.UNBLOCK_SUCCESS:
            // console.log(action.payload)
            return {
                blockchat: action.payload,
                loading: false,
                done: true,
                error: undefined,
            };
        case chatConstants.UNBLOCK_FAILURE:
            // console.log(action.payload)
            return {
                error: action.payload,
                blockchat: Object.keys({ ...state.blockchat }).length === 0 ? "" : { ...state.blockchat },
                loading: false,
                done: true
            }
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}