export const socialmediaConstants = {
    GET_REQUEST: 'SOCIALMEDIA_GET_REQUEST',
    GET_SUCCESS: 'SOCIALMEDIA_GET_SUCCESS',
    GET_FAILURE: 'SOCIALMEDIA_GET_FAILURE',
    GET_FAILURE_DONE: 'SOCIALMEDIA_GET_FAILURE_DONE',

    GET_REQUEST_ES: 'SOCIALMEDIA_ES_GET_REQUEST',
    GET_SUCCESS_ES: 'SOCIALMEDIA_ES_GET_SUCCESS',
    GET_FAILURE_ES: 'SOCIALMEDIA_ES_GET_FAILURE',
    GET_FAILURE_DONE_ES: 'SOCIALMEDIA_ES_GET_FAILURE_DONE',

    GET_REQUEST_DE: 'SOCIALMEDIA_DE_GET_REQUEST',
    GET_SUCCESS_DE: 'SOCIALMEDIA_DE_GET_SUCCESS',
    GET_FAILURE_DE: 'SOCIALMEDIA_DE_GET_FAILURE',
    GET_FAILURE_DONE_DE: 'SOCIALMEDIA_DE_GET_FAILURE_DONE',

    GET_REQUEST_FR: 'SOCIALMEDIA_FR_GET_REQUEST',
    GET_SUCCESS_FR: 'SOCIALMEDIA_FR_GET_SUCCESS',
    GET_FAILURE_FR: 'SOCIALMEDIA_FR_GET_FAILURE',
    GET_FAILURE_DONE_FR: 'SOCIALMEDIA_FR_GET_FAILURE_DONE',

    GET_REQUEST_PT: 'SOCIALMEDIA_PT_GET_REQUEST',
    GET_SUCCESS_PT: 'SOCIALMEDIA_PT_GET_SUCCESS',
    GET_FAILURE_PT: 'SOCIALMEDIA_PT_GET_FAILURE',
    GET_FAILURE_DONE_PT: 'SOCIALMEDIA_PT_GET_FAILURE_DONE',

    GET_REQUEST_HI: 'SOCIALMEDIA_HI_GET_REQUEST',
    GET_SUCCESS_HI: 'SOCIALMEDIA_HI_GET_SUCCESS',
    GET_FAILURE_HI: 'SOCIALMEDIA_HI_GET_FAILURE',
    GET_FAILURE_DONE_HI: 'SOCIALMEDIA_HI_GET_FAILURE_DONE',

    GET_REQUEST_INDO: 'SOCIALMEDIA_INDO_GET_REQUEST',
    GET_SUCCESS_INDO: 'SOCIALMEDIA_INDO_GET_SUCCESS',
    GET_FAILURE_INDO: 'SOCIALMEDIA_INDO_GET_FAILURE',
    GET_FAILURE_DONE_INDO: 'SOCIALMEDIA_INDO_GET_FAILURE_DONE',

    GET_REQUEST_RU: 'SOCIALMEDIA_RU_GET_REQUEST',
    GET_SUCCESS_RU: 'SOCIALMEDIA_RU_GET_SUCCESS',
    GET_FAILURE_RU: 'SOCIALMEDIA_RU_GET_FAILURE',
    GET_FAILURE_DONE_RU: 'SOCIALMEDIA_RU_GET_FAILURE_DONE',

    GET_REQUEST_CH: 'SOCIALMEDIA_CH_GET_REQUEST',
    GET_SUCCESS_CH: 'SOCIALMEDIA_CH_GET_SUCCESS',
    GET_FAILURE_CH: 'SOCIALMEDIA_CH_GET_FAILURE',
    GET_FAILURE_DONE_CH: 'SOCIALMEDIA_CH_GET_FAILURE_DONE',

    GET_REQUEST_JP: 'SOCIALMEDIA_JP_GET_REQUEST',
    GET_SUCCESS_JP: 'SOCIALMEDIA_JP_GET_SUCCESS',
    GET_FAILURE_JP: 'SOCIALMEDIA_JP_GET_FAILURE',
    GET_FAILURE_DONE_JP: 'SOCIALMEDIA_JP_GET_FAILURE_DONE',

    GET_REQUEST_AR: 'SOCIALMEDIA_AR_GET_REQUEST',
    GET_SUCCESS_AR: 'SOCIALMEDIA_AR_GET_SUCCESS',
    GET_FAILURE_AR: 'SOCIALMEDIA_AR_GET_FAILURE',
    GET_FAILURE_DONE_AR: 'SOCIALMEDIA_AR_GET_FAILURE_DONE',

    GET_REQUEST_IT: 'SOCIALMEDIA_IT_GET_REQUEST',
    GET_SUCCESS_IT: 'SOCIALMEDIA_IT_GET_SUCCESS',
    GET_FAILURE_IT: 'SOCIALMEDIA_IT_GET_FAILURE',
    GET_FAILURE_DONE_IT: 'SOCIALMEDIA_IT_GET_FAILURE_DONE',

    GET_REQUEST_TR: 'SOCIALMEDIA_TR_GET_REQUEST',
    GET_SUCCESS_TR: 'SOCIALMEDIA_TR_GET_SUCCESS',
    GET_FAILURE_TR: 'SOCIALMEDIA_TR_GET_FAILURE',
    GET_FAILURE_DONE_TR: 'SOCIALMEDIA_TR_GET_FAILURE_DONE',

    GET_REQUEST_NL: 'SOCIALMEDIA_NL_GET_REQUEST',
    GET_SUCCESS_NL: 'SOCIALMEDIA_NL_GET_SUCCESS',
    GET_FAILURE_NL: 'SOCIALMEDIA_NL_GET_FAILURE',
    GET_FAILURE_DONE_NL: 'SOCIALMEDIA_NL_GET_FAILURE_DONE',

    GET_REQUEST_GR: 'SOCIALMEDIA_GR_GET_REQUEST',
    GET_SUCCESS_GR: 'SOCIALMEDIA_GR_GET_SUCCESS',
    GET_FAILURE_GR: 'SOCIALMEDIA_GR_GET_FAILURE',
    GET_FAILURE_DONE_GR: 'SOCIALMEDIA_GR_GET_FAILURE_DONE',
};