import InfoIcon from '@mui/icons-material/Info';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { accountConstants } from '../constants';
import { Spinner } from '../spinner/spinner';
import { CustomPagination } from './CustomPagination';
import { Account } from './Item';


export const AccountList = () => {
    const dispatch = useDispatch();
    const a = useSelector(state => state.account.accountList);
    const [accountList, setAccountList] = useState(a);
    const dataLimitPerPage = 12;
    const n = useSelector(state => state.account.name);
    const [name, setName] = useState(n);
    const loading = useSelector(state => state.account.loading);
    const e = useSelector(state => state.account.error);
    const [error, setError] = useState(e);
    const d = useSelector(state => state.account.done);
    const [done, setDone] = useState(d);
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setAccountList(a);
    }, [a]);

    useEffect(() => {
        setName(n);
    }, [n]);

    useEffect(() => {
        setError(e);
    }, [e]);

    useEffect(() => {
        setDone(d);
    }, [d]);

    useEffect(() => {
        setTheme(t);
    }, [t])

    // console.log(accountList)
    var title;
    if (accountList && accountList.results && accountList.results > 0) {
        if (accountList.results > 1) {
            title = accountList.results + " " + name + "'s Accounts found on";
        } else if (accountList.results === 1) {
            title = accountList.results + " " + name + "'s Account found on";
        }
    }

    const getPageLimit = (allData, dataLimitPerPage) => {
        if (allData % dataLimitPerPage === 0) {
            return allData / dataLimitPerPage;
        } else {
            return Math.floor(allData / dataLimitPerPage) + 1;
        }
    }

    if (!accountList || !accountList.accounts) {
        if (loading) {
            return (
                <>
                    <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                        <div class="marginMobil" style={{ marginRight: "30px", marginLeft: "200px" }}>
                            <div>
                                <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                                    <Spinner show={loading} />
                                    <h1 style={{ color: theme === 'dark' ? '#fff' : '#000' }} >Search Social Accounts</h1> {/* light */}
                                    <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 975, color: '#fff', textAlign: "center" }} class="p-3 mb-2 bg-primary bg-gradient text-white"><InfoIcon style={{ marginBottom: 5 }} /> Find your friends' profiles across all social media platforms by entering their account username or full name</h2>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        if (done) {
            if (error) {
                dispatch({ type: accountConstants.SEARCH_FAILURE_DONE });
                Swal.fire({
                    icon: 'error',
                    title: 'An error has occurred. Please try again ...',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }

        return (
            <>
                <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                    <div class="marginMobil" style={{ marginRight: "30px", marginLeft: "200px" }}>
                        <div>
                            <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                                <h1 style={{ color: theme === 'dark' ? '#fff' : '#000' }} >Search Social Accounts</h1> {/* light */}
                                <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 975, color: '#fff', textAlign: "center" }} class="p-3 mb-2 bg-primary bg-gradient text-white"><InfoIcon style={{ marginBottom: 5 }} /> Find your friends' profiles across all social media platforms by entering their account username or full name</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                {accountList.accounts.length > 0 ? (
                    <>
                        <CustomPagination
                            data={accountList.accounts}
                            RenderComponent={Account}
                            title={title}
                            pageLimit={getPageLimit(accountList.accounts.length, dataLimitPerPage)}
                            dataLimit={dataLimitPerPage}
                        />
                    </>
                ) : (
                    <>
                        <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                            <div class="marginMobil" style={{ marginRight: "30px", marginLeft: "200px" }}>
                                <div>
                                    <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                                        <h1 style={{ color: theme === 'dark' ? '#fff' : '#000' }} >Search Social Accounts</h1>
                                        <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 975, color: '#fff', textAlign: "center" }} class="p-3 mb-2 bg-primary bg-gradient text-white"><InfoIcon style={{ marginBottom: 5 }} /> Sorry, no Results for "{name}".</h2> {/* light */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    }
}