import { discussionConstants, feedfilterConstants } from "../constants";


export function discussion(state = { loading: false }, action) {
    switch (action.type) {
        case discussionConstants.GET_REQUEST:
            return {
                discussionList: "",
                loading: true,
                done: false,
                error: undefined,
            };
        case discussionConstants.GET_SUCCESS:
            // console.log(action.payload)
            return {
                discussionList: action.payload,
                loading: false,
                done: true,
                error: undefined,
            };
        case discussionConstants.GET_FAILURE:
            // console.log(action.payload)
            return {
                error: action.payload,
                discussionList: Object.keys({ ...state.discussionList }).length === 0 ? "" : { ...state.discussionList },
                loading: false,
                done: true
            };
        case discussionConstants.GET_FAILURE_DONE:
            return {
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error },
                discussionList: "",
                loading: false,
                done: false
            };
        case discussionConstants.SAVE_REQUEST:
            console.log("save request")
            return {
                discussion: "",
                discussionList: Object.keys({ ...state.discussionList }).length === 0 ? "" : { ...state.discussionList },
                loading: true,
                done: false,
                error: undefined,
            };
        case discussionConstants.SAVE_SUCCESS:
            console.log("save success")
            // console.log(action.payload)
            return {
                discussion: action.payload[0],
                discussionList: action.payload[1],
                loading: false,
                done: true,
                error: undefined,
            };
        case discussionConstants.SAVE_CLEAR:
            return {
                discussion: "",
                discussionList: "",
                loading: false,
                done: false
            };
        case discussionConstants.SAVE_FAILURE:
            console.log("save failure")
            // console.log(action.payload)
            return {
                error: action.payload[0],
                discussion: action.payload[1],
                discussionList: Object.keys({ ...state.discussionList }).length === 0 ? "" : { ...state.discussionList },
                loading: false,
                done: true
            };
        case discussionConstants.SAVE_FAILURE_DONE:
            return {
                discussion: "",
                discussionList: "",
                loading: false,
                done: false
            };
        case discussionConstants.UPDATE_REQUEST:
            // console.log("request")
            return {
                discussion: "",
                discussionList: Object.keys({ ...state.discussionList }).length === 0 ? "" : { ...state.discussionList },
                loading: true,
                done: false,
                error: undefined,
            };
        case discussionConstants.UPDATE_SUCCESS:
            // console.log(action.payload)
            return {
                discussion: action.payload[0],
                discussionList: action.payload[1],
                loading: false,
                done: true,
                error: undefined,
            };
        case discussionConstants.UPDATE_CLEAR:
            return {
                discussion: "",
                discussionList: "",
                loading: false,
                done: false
            };
        case discussionConstants.UPDATE_FAILURE:
            // console.log(action.payload)
            return {
                error: action.payload[0],
                discussion: action.payload[1],
                discussionList: Object.keys({ ...state.discussionList }).length === 0 ? "" : { ...state.discussionList },
                loading: false,
                done: true
            };
        case discussionConstants.UPDATE_FAILURE_DONE:
            return {
                discussion: "",
                discussionList: "",
                loading: false,
                done: false
            };
        case discussionConstants.UPDATE_REQUEST:
            // console.log("request")
            return {
                discussion: "",
                discussionList: Object.keys({ ...state.discussionList }).length === 0 ? "" : { ...state.discussionList },
                loading: true,
                done: false
            };
        case discussionConstants.UPDATE_SUCCESS:
            // console.log(action.payload)
            return {
                discussion: action.payload[0],
                discussionList: action.payload[1],
                loading: false,
                done: true
            };
        case discussionConstants.UPDATE_CLEAR:
            return {
                discussion: "",
                discussionList: "",
                loading: false,
                done: false
            };
        case discussionConstants.UPDATE_FAILURE:
            // console.log(action.payload)
            return {
                error: action.payload[0],
                discussion: action.payload[1],
                discussionList: Object.keys({ ...state.discussionList }).length === 0 ? "" : { ...state.discussionList },
                loading: false,
                done: true
            };
        case discussionConstants.UPDATE_FAILURE_DONE:
            return {
                discussion: "",
                discussionList: "",
                loading: false,
                done: false
            };
        case discussionConstants.DELETE_REQUEST:
            // console.log("request")
            return {
                discussion: "",
                discussionList: Object.keys({ ...state.discussionList }).length === 0 ? "" : { ...state.discussionList },
                loading: true,
                done: false,
                error: undefined,
            };
        case discussionConstants.DELETE_SUCCESS:
            // console.log(action.payload)
            return {
                discussion: action.payload[0],
                discussionList: action.payload[1],
                loading: false,
                done: true,
                error: undefined,
            };
        case discussionConstants.DELETE_CLEAR:
            return {
                discussion: "",
                discussionList: "",
                loading: false,
                done: false
            };
        case discussionConstants.DELETE_FAILURE:
            // console.log(action.payload)
            return {
                error: action.payload[0],
                discussion: action.payload[1],
                discussionList: Object.keys({ ...state.discussionList }).length === 0 ? "" : { ...state.discussionList },
                loading: false,
                done: true
            };
        case discussionConstants.DELETE_FAILURE_DONE:
            return {
                discussion: "",
                discussionList: "",
                loading: false,
                done: false
            };
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}

export function feedfilter(state = { loading: false }, action) {
    switch (action.type) {
        case feedfilterConstants.GET_REQUEST:
            return {
                feedfilterList: "",
                loading: true,
                done: false,
                error: undefined,
            };
        case feedfilterConstants.GET_SUCCESS:
            // console.log(action.payload)
            return {
                feedfilterList: action.payload,
                loading: false,
                done: true,
                error: undefined,
            };
        case feedfilterConstants.GET_FAILURE:
            // console.log(action.payload)
            return {
                error: action.payload,
                feedfilterList: Object.keys({ ...state.feedfilterList }).length === 0 ? "" : { ...state.feedfilterList },
                loading: false,
                done: true
            };
        case feedfilterConstants.GET_FAILURE_DONE:
            return {
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error },
                feedfilterList: "",
                loading: false,
                done: false
            };
        case feedfilterConstants.SAVE_REQUEST:
            // console.log("save request")
            return {
                feedfilterList: Object.keys({ ...state.feedfilterList }).length === 0 ? "" : { ...state.feedfilterList },
                loading: true,
                done: false,
                error: undefined,
            };
        case feedfilterConstants.SAVE_SUCCESS:
            // console.log("save success")
            // console.log(action.payload)
            return {
                feedfilter: action.payload[0],
                feedfilterList: action.payload[1],
                loading: false,
                done: true,
                error: undefined,
            };
        case feedfilterConstants.SAVE_CLEAR:
            return {
                feedfilter: "",
                feedfilterList: "",
                loading: false,
                done: false
            };
        case feedfilterConstants.SAVE_FAILURE:
            // console.log("save failure")
            // console.log(action.payload)
            return {
                error: action.payload[0],
                feedfilter: action.payload[1],
                feedfilterList: Object.keys({ ...state.feedfilterList }).length === 0 ? "" : { ...state.feedfilterList },
                loading: false,
                done: true
            };
        case feedfilterConstants.SAVE_FAILURE_DONE:
            return {
                feedfilter: "",
                feedfilterList: "",
                loading: false,
                done: false
            };
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}