import { socialmediaConstants, newsConstants } from '../constants';
import { getErrorMessage } from '../helpers/error';
import { socialMediaService } from '../services';

export const socialMediaActions = {
    getSocialMediaList,
    getSocialMediaListES,
    getSocialMediaListDE,
    getSocialMediaListFR,
    getSocialMediaListPT,
    getSocialMediaListHI,
    getSocialMediaListINDO,
    getSocialMediaListRU,
    getSocialMediaListCH,
    getSocialMediaListJP,
    getSocialMediaListAR,
    getSocialMediaListIT,
    getSocialMediaListTR,
    getSocialMediaListNL,
    getSocialMediaListGR,
    searchNews,
    searchNewsES,
    searchNewsDE,
    searchNewsFR,
    searchNewsPT,
    searchNewsHI,
    searchNewsINDO,
    searchNewsRU,
    searchNewsCH,
    searchNewsJP,
    searchNewsAR,
    searchNewsIT,
    searchNewsTR,
    searchNewsNL,
    searchNewsGR,
};

function getSocialMediaList() {
    console.log("in Actions")
    return dispatch => {
        dispatch({ type: socialmediaConstants.GET_REQUEST });
        

        socialMediaService.getSocialMediaList()
            .then(res => res.json())
            .then(data => dispatch({ type: socialmediaConstants.GET_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: socialmediaConstants.GET_FAILURE, payload: error }));
    };
}

function getSocialMediaListES() {
    console.log("in Actions")
    return dispatch => {
        dispatch({ type: socialmediaConstants.GET_REQUEST_ES });
        

        socialMediaService.getSocialMediaListES()
            .then(res => res.json())
            .then(data => dispatch({ type: socialmediaConstants.GET_SUCCESS_ES, payload: data }))
            .catch(error => dispatch({ type: socialmediaConstants.GET_FAILURE_ES, payload: error }));
    };
}

function getSocialMediaListDE() {
    console.log("in Actions")
    return dispatch => {
        dispatch({ type: socialmediaConstants.GET_REQUEST_DE });
        

        socialMediaService.getSocialMediaListDE()
            .then(res => res.json())
            .then(data => dispatch({ type: socialmediaConstants.GET_SUCCESS_DE, payload: data }))
            .catch(error => dispatch({ type: socialmediaConstants.GET_FAILURE_DE, payload: error }));
    };
}

function getSocialMediaListFR() {
    console.log("in Actions")
    return dispatch => {
        dispatch({ type: socialmediaConstants.GET_REQUEST_FR });
        

        socialMediaService.getSocialMediaListFR()
            .then(res => res.json())
            .then(data => dispatch({ type: socialmediaConstants.GET_SUCCESS_FR, payload: data }))
            .catch(error => dispatch({ type: socialmediaConstants.GET_FAILURE_FR, payload: error }));
    };
}

function getSocialMediaListPT() {
    console.log("in Actions")
    return dispatch => {
        dispatch({ type: socialmediaConstants.GET_REQUEST_PT });
        

        socialMediaService.getSocialMediaListPT()
            .then(res => res.json())
            .then(data => dispatch({ type: socialmediaConstants.GET_SUCCESS_PT, payload: data }))
            .catch(error => dispatch({ type: socialmediaConstants.GET_FAILURE_PT, payload: error }));
    };
}

function getSocialMediaListHI() {
    console.log("in Actions")
    return dispatch => {
        dispatch({ type: socialmediaConstants.GET_REQUEST_HI });
        

        socialMediaService.getSocialMediaListHI()
            .then(res => res.json())
            .then(data => dispatch({ type: socialmediaConstants.GET_SUCCESS_HI, payload: data }))
            .catch(error => dispatch({ type: socialmediaConstants.GET_FAILURE_HI, payload: error }));
    };
}

function getSocialMediaListINDO() {
    console.log("in Actions")
    return dispatch => {
        dispatch({ type: socialmediaConstants.GET_REQUEST_INDO });
        

        socialMediaService.getSocialMediaListINDO()
            .then(res => res.json())
            .then(data => dispatch({ type: socialmediaConstants.GET_SUCCESS_INDO, payload: data }))
            .catch(error => dispatch({ type: socialmediaConstants.GET_FAILURE_INDO, payload: error }));
    };
}

function getSocialMediaListRU() {
    console.log("in Actions")
    return dispatch => {
        dispatch({ type: socialmediaConstants.GET_REQUEST_RU });
        

        socialMediaService.getSocialMediaListRU()
            .then(res => res.json())
            .then(data => dispatch({ type: socialmediaConstants.GET_SUCCESS_RU, payload: data }))
            .catch(error => dispatch({ type: socialmediaConstants.GET_FAILURE_RU, payload: error }));
    };
}

function getSocialMediaListCH() {
    console.log("in Actions")
    return dispatch => {
        dispatch({ type: socialmediaConstants.GET_REQUEST_CH });
        

        socialMediaService.getSocialMediaListCH()
            .then(res => res.json())
            .then(data => dispatch({ type: socialmediaConstants.GET_SUCCESS_CH, payload: data }))
            .catch(error => dispatch({ type: socialmediaConstants.GET_FAILURE_CH, payload: error }));
    };
}

function getSocialMediaListJP() {
    console.log("in Actions")
    return dispatch => {
        dispatch({ type: socialmediaConstants.GET_REQUEST_JP });
        

        socialMediaService.getSocialMediaListJP()
            .then(res => res.json())
            .then(data => dispatch({ type: socialmediaConstants.GET_SUCCESS_JP, payload: data }))
            .catch(error => dispatch({ type: socialmediaConstants.GET_FAILURE_JP, payload: error }));
    };
}

function getSocialMediaListAR() {
    console.log("in Actions")
    return dispatch => {
        dispatch({ type: socialmediaConstants.GET_REQUEST_AR });
        

        socialMediaService.getSocialMediaListAR()
            .then(res => res.json())
            .then(data => dispatch({ type: socialmediaConstants.GET_SUCCESS_AR, payload: data }))
            .catch(error => dispatch({ type: socialmediaConstants.GET_FAILURE_AR, payload: error }));
    };
}

function getSocialMediaListIT() {
    console.log("in Actions")
    return dispatch => {
        dispatch({ type: socialmediaConstants.GET_REQUEST_IT });
        

        socialMediaService.getSocialMediaListIT()
            .then(res => res.json())
            .then(data => dispatch({ type: socialmediaConstants.GET_SUCCESS_IT, payload: data }))
            .catch(error => dispatch({ type: socialmediaConstants.GET_FAILURE_IT, payload: error }));
    };
}

function getSocialMediaListTR() {
    console.log("in Actions")
    return dispatch => {
        dispatch({ type: socialmediaConstants.GET_REQUEST_TR });
        

        socialMediaService.getSocialMediaListTR()
            .then(res => res.json())
            .then(data => dispatch({ type: socialmediaConstants.GET_SUCCESS_TR, payload: data }))
            .catch(error => dispatch({ type: socialmediaConstants.GET_FAILURE_TR, payload: error }));
    };
}

function getSocialMediaListNL() {
    console.log("in Actions")
    return dispatch => {
        dispatch({ type: socialmediaConstants.GET_REQUEST_NL });
        

        socialMediaService.getSocialMediaListNL()
            .then(res => res.json())
            .then(data => dispatch({ type: socialmediaConstants.GET_SUCCESS_NL, payload: data }))
            .catch(error => dispatch({ type: socialmediaConstants.GET_FAILURE_NL, payload: error }));
    };
}

function getSocialMediaListGR() {
    console.log("in Actions")
    return dispatch => {
        dispatch({ type: socialmediaConstants.GET_REQUEST_GR });
        

        socialMediaService.getSocialMediaListGR()
            .then(res => res.json())
            .then(data => dispatch({ type: socialmediaConstants.GET_SUCCESS_GR, payload: data }))
            .catch(error => dispatch({ type: socialmediaConstants.GET_FAILURE_GR, payload: error }));
    };
}

function searchNews(searchText) {
    // console.log(searchText)
    // console.log("in search news")
    return dispatch => {
        dispatch({ type: newsConstants.SEARCH_REQUEST });
        
        socialMediaService.searchNews(searchText)
            .then(res => res.json())
            .then(data => dispatch({ type: newsConstants.SEARCH_SUCCESS, payload: [searchText, data] }))
            .catch(error => dispatch({ type: newsConstants.SEARCH_FAILURE, payload: [error, searchText] }));
    };
}

function searchNewsES(searchText) {
    // console.log(searchText)
    // console.log("in search news")
    return dispatch => {
        dispatch({ type: newsConstants.SEARCH_REQUEST });
        
        socialMediaService.searchNewsES(searchText)
            .then(res => res.json())
            .then(data => dispatch({ type: newsConstants.SEARCH_SUCCESS, payload: [searchText, data] }))
            .catch(error => dispatch({ type: newsConstants.SEARCH_FAILURE, payload: [error, searchText] }));
    };
}

function searchNewsDE(searchText) {
    // console.log(searchText)
    // console.log("in search news")
    return dispatch => {
        dispatch({ type: newsConstants.SEARCH_REQUEST });
        
        socialMediaService.searchNewsDE(searchText)
            .then(res => res.json())
            .then(data => dispatch({ type: newsConstants.SEARCH_SUCCESS, payload: [searchText, data] }))
            .catch(error => dispatch({ type: newsConstants.SEARCH_FAILURE, payload: [error, searchText] }));
    };
}

function searchNewsFR(searchText) {
    // console.log(searchText)
    // console.log("in search news")
    return dispatch => {
        dispatch({ type: newsConstants.SEARCH_REQUEST });
        
        socialMediaService.searchNewsFR(searchText)
            .then(res => res.json())
            .then(data => dispatch({ type: newsConstants.SEARCH_SUCCESS, payload: [searchText, data] }))
            .catch(error => dispatch({ type: newsConstants.SEARCH_FAILURE, payload: [error, searchText] }));
    };
}

function searchNewsPT(searchText) {
    // console.log(searchText)
    // console.log("in search news")
    return dispatch => {
        dispatch({ type: newsConstants.SEARCH_REQUEST });
        
        socialMediaService.searchNewsPT(searchText)
            .then(res => res.json())
            .then(data => dispatch({ type: newsConstants.SEARCH_SUCCESS, payload: [searchText, data] }))
            .catch(error => dispatch({ type: newsConstants.SEARCH_FAILURE, payload: [error, searchText] }));
    };
}

function searchNewsHI(searchText) {
    // console.log(searchText)
    // console.log("in search news")
    return dispatch => {
        dispatch({ type: newsConstants.SEARCH_REQUEST });
        
        socialMediaService.searchNewsHI(searchText)
            .then(res => res.json())
            .then(data => dispatch({ type: newsConstants.SEARCH_SUCCESS, payload: [searchText, data] }))
            .catch(error => dispatch({ type: newsConstants.SEARCH_FAILURE, payload: [error, searchText] }));
    };
}

function searchNewsINDO(searchText) {
    // console.log(searchText)
    // console.log("in search news")
    return dispatch => {
        dispatch({ type: newsConstants.SEARCH_REQUEST });
        
        socialMediaService.searchNewsINDO(searchText)
            .then(res => res.json())
            .then(data => dispatch({ type: newsConstants.SEARCH_SUCCESS, payload: [searchText, data] }))
            .catch(error => dispatch({ type: newsConstants.SEARCH_FAILURE, payload: [error, searchText] }));
    };
}

function searchNewsRU(searchText) {
    // console.log(searchText)
    // console.log("in search news")
    return dispatch => {
        dispatch({ type: newsConstants.SEARCH_REQUEST });
        
        socialMediaService.searchNewsRU(searchText)
            .then(res => res.json())
            .then(data => dispatch({ type: newsConstants.SEARCH_SUCCESS, payload: [searchText, data] }))
            .catch(error => dispatch({ type: newsConstants.SEARCH_FAILURE, payload: [error, searchText] }));
    };
}

function searchNewsCH(searchText) {
    // console.log(searchText)
    // console.log("in search news")
    return dispatch => {
        dispatch({ type: newsConstants.SEARCH_REQUEST });
        
        socialMediaService.searchNewsCH(searchText)
            .then(res => res.json())
            .then(data => dispatch({ type: newsConstants.SEARCH_SUCCESS, payload: [searchText, data] }))
            .catch(error => dispatch({ type: newsConstants.SEARCH_FAILURE, payload: [error, searchText] }));
    };
}

function searchNewsJP(searchText) {
    // console.log(searchText)
    // console.log("in search news")
    return dispatch => {
        dispatch({ type: newsConstants.SEARCH_REQUEST });
        
        socialMediaService.searchNewsJP(searchText)
            .then(res => res.json())
            .then(data => dispatch({ type: newsConstants.SEARCH_SUCCESS, payload: [searchText, data] }))
            .catch(error => dispatch({ type: newsConstants.SEARCH_FAILURE, payload: [error, searchText] }));
    };
}

function searchNewsAR(searchText) {
    // console.log(searchText)
    // console.log("in search news")
    return dispatch => {
        dispatch({ type: newsConstants.SEARCH_REQUEST });
        
        socialMediaService.searchNewsAR(searchText)
            .then(res => res.json())
            .then(data => dispatch({ type: newsConstants.SEARCH_SUCCESS, payload: [searchText, data] }))
            .catch(error => dispatch({ type: newsConstants.SEARCH_FAILURE, payload: [error, searchText] }));
    };
}

function searchNewsIT(searchText) {
    // console.log(searchText)
    // console.log("in search news")
    return dispatch => {
        dispatch({ type: newsConstants.SEARCH_REQUEST });
        
        socialMediaService.searchNewsIT(searchText)
            .then(res => res.json())
            .then(data => dispatch({ type: newsConstants.SEARCH_SUCCESS, payload: [searchText, data] }))
            .catch(error => dispatch({ type: newsConstants.SEARCH_FAILURE, payload: [error, searchText] }));
    };
}

function searchNewsTR(searchText) {
    // console.log(searchText)
    // console.log("in search news")
    return dispatch => {
        dispatch({ type: newsConstants.SEARCH_REQUEST });
        
        socialMediaService.searchNewsTR(searchText)
            .then(res => res.json())
            .then(data => dispatch({ type: newsConstants.SEARCH_SUCCESS, payload: [searchText, data] }))
            .catch(error => dispatch({ type: newsConstants.SEARCH_FAILURE, payload: [error, searchText] }));
    };
}

function searchNewsNL(searchText) {
    // console.log(searchText)
    // console.log("in search news")
    return dispatch => {
        dispatch({ type: newsConstants.SEARCH_REQUEST });
        
        socialMediaService.searchNewsNL(searchText)
            .then(res => res.json())
            .then(data => dispatch({ type: newsConstants.SEARCH_SUCCESS, payload: [searchText, data] }))
            .catch(error => dispatch({ type: newsConstants.SEARCH_FAILURE, payload: [error, searchText] }));
    };
}

function searchNewsGR(searchText) {
    // console.log(searchText)
    // console.log("in search news")
    return dispatch => {
        dispatch({ type: newsConstants.SEARCH_REQUEST });
        
        socialMediaService.searchNewsGR(searchText)
            .then(res => res.json())
            .then(data => dispatch({ type: newsConstants.SEARCH_SUCCESS, payload: [searchText, data] }))
            .catch(error => dispatch({ type: newsConstants.SEARCH_FAILURE, payload: [error, searchText] }));
    };
}