import { pinConstants } from "../constants";
import { pinService } from "../services";

export const pinActions = {
    pin,
};

function pin(pin) {

    return dispatch => {
        dispatch({ type: pinConstants.PIN_SAVE_REQUEST });

        pinService.pin(pin)
            .then(res => res.json())
            .then(data => dispatch({ type: pinConstants.PIN_SAVE_SUCCESS, payload: [data, pin] }))
            .catch(error => dispatch({ type: pinConstants.PIN_SAVE_FAILURE, payload: [error, pin] }));
    };
}