export class DiscussionClass{
    constructor() {
        this.id = 0;
        this.duid = 0;
        this.title = "";
        this.post = "";
        this.hashtags = "";
        this.link1 = "";
        this.link2 = "";
        this.link3 = "";
        this.link4 = "";
        this.createdat = "";
        this.updatedat = "";
        this.createdby = "";
        this.spacename = "";
        this.space = 0;
    }
}