import { messageConstants } from "../constants";
import { MessageClass } from "../models/message";

export function message(state = { loading: false }, action) {
    switch (action.type) {
        case messageConstants.GET_REQUEST:
            return {
                messages: "",
                loading: true,
                done: false,
                error: undefined,
            };
        case messageConstants.GET_SUCCESS:
            // console.log(action.payload)
            return {
                messages: action.payload,
                loading: false,
                done: true,
                error: undefined,
            };
        case messageConstants.GET_FAILURE:
            // console.log(action.payload)
            return {
                error: action.payload,
                messages: Object.keys({ ...state.messages }).length === 0 ? "" : { ...state.messages },
                loading: false,
                done: true
            };
        case messageConstants.MESSAGE_SAVE_REQUEST:
            // console.log("request")
            return {
                message: new MessageClass(),
                messages: Object.keys({ ...state.messages }).length === 0 ? "" : { ...state.messages },
                loading: true,
                done: false
            };
        case messageConstants.MESSAGE_SAVE_SUCCESS:
            // console.log(action.payload)
            return {
                message: action.payload[0],
                messages: Object.keys({ ...state.messages }).length === 0 ? "" : { ...state.messages },
                loading: false,
                done: true,
                error: undefined
            };
        case messageConstants.MESSAGE_SAVE_FAILURE:
            return {
                error: action.payload[0],
                message: action.payload[1],
                messages: Object.keys({ ...state.messages }).length === 0 ? "" : { ...state.messages },
                loading: false,
                done: false
            };
        case messageConstants.MESSAGE_DELETE_REQUEST:
            // console.log("request")
            return {
                message: "",
                messages: Object.keys({ ...state.messages }).length === 0 ? "" : { ...state.messages },
                loading: true,
                done: false,
                error: undefined,
            };
        case messageConstants.MESSAGE_DELETE_SUCCESS:
            // console.log(action.payload)
            return {
                message: action.payload[0],
                messages: Object.keys({ ...state.messages }).length === 0 ? "" : { ...state.messages },
                loading: false,
                done: true,
                error: undefined
            };
        case messageConstants.MESSAGE_DELETE_CLEAR:
            return {
                message: "",
                messages: "",
                loading: false,
                done: false
            };
        case messageConstants.MESSAGE_DELETE_FAILURE:
            // console.log(action.payload)
            return {
                error: action.payload[0],
                message: action.payload[1],
                messages: Object.keys({ ...state.messages }).length === 0 ? "" : { ...state.messages },
                loading: false,
                done: true
            };
        case messageConstants.MESSAGE_DELETE_FAILURE_DONE:
            return {
                message: "",
                messages: "",
                loading: false,
                done: false
            };
        case messageConstants.MESSAGE_SAVE_CLEAR:
            return {};
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}

export function checkreadmessage(state = { read : {"read" : true}, loading: false }, action) {
    switch (action.type) {
        case messageConstants.MESSAGE_CHECK_READ_REQUEST:
            // console.log("request")
            return {
                read: { "read": true },
                loading: true,
                done: false,
                error: undefined,
            };
        case messageConstants.MESSAGE_CHECK_READ_SUCCESS:
            // console.log(action.payload)
            return {
                read: action.payload[0],
                loading: false,
                done: true,
                error: undefined
            };
        case messageConstants.MESSAGE_CHECK_READ_CLEAR:
            return {
                read: { "read": true },
                error: "",
                loading: false,
                done: false
            };
        case messageConstants.MESSAGE_CHECK_READ_FAILURE:
            // console.log(action.payload)
            return {
                read: { "read": true },
                error: action.payload[0],
                loading: false,
                done: true
            };
        case messageConstants.MESSAGE_CHECK_READ_FAILURE_DONE:
            return {
                read: { "read": true },
                error: "",
                loading: false,
                done: false
            };
        case messageConstants.MESSAGE_CHECK_READ_CLEAR:
            return {};
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}