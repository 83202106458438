import { outofscopeConstants } from "../constants";
import { OutOfScopeClass } from "../models/outofscope";

export function outofscope(state = { loading: false }, action) {
    switch (action.type) {
        case outofscopeConstants.OUTOFSCOPE_SAVE_REQUEST:
            // console.log("request")
            return {
                outofscope: new OutOfScopeClass(),
                loading: true,
                done: false
            };
        case outofscopeConstants.OUTOFSCOPE_SAVE_SUCCESS:
            // console.log(action.payload)
            return {
                submitted: action.payload[0],
                outofscope: action.payload[1],
                loading: false,
                done: true,
                error: undefined
            };
        case outofscopeConstants.OUTOFSCOPE_SAVE_FAILURE:
            return {
                error: action.payload[0],
                outofscope: action.payload[1],
                loading: false,
                done: false
            };
        case outofscopeConstants.OUTOFSCOPE_SAVE_CLEAR:
            return {};
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}