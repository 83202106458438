import { workerConstants } from "../constants";
import { authHeaderWithJSON } from "../helpers";

export const chatService = {
    beginChat,
    blockChat,
    unblockChat,
    getMyTalkers
};

const COMMUNO = workerConstants.WORKER


function beginChat(data) {
    console.log("in service Chat")
    const body = JSON.stringify(data);

    return fetch(COMMUNO + "chat",
        {
            method: 'POST',
            headers: authHeaderWithJSON(),
            body: body
        });
}

function blockChat(data) {
    console.log("in service Chat")
    const body = JSON.stringify(data);

    return fetch(COMMUNO + "blockchat",
        {
            method: 'POST',
            headers: authHeaderWithJSON(),
            body: body
        });
}

function unblockChat(data) {
    console.log("in service Chat")
    const body = JSON.stringify(data);

    return fetch(COMMUNO + "unblockchat",
        {
            method: 'POST',
            headers: authHeaderWithJSON(),
            body: body
        });
}

function getMyTalkers(query, search) {
    if (search) {
        return fetch(COMMUNO + "mytalkers?q=" + query,
            {
                method: 'GET',
                headers: authHeaderWithJSON(),
            });
    } else {
        return fetch(COMMUNO + "mytalkers",
            {
                method: 'GET',
                headers: authHeaderWithJSON(),
            });
    }
}