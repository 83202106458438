export const commentConstants = {
    GET_REQUEST: 'COMMENT_GET_REQUEST',
    GET_SUCCESS: 'COMMENT_GET_SUCCESS',
    GET_FAILURE: 'COMMENT_GET_FAILURE',
    GET_FAILURE_DONE: 'COMMENT_GET_FAILURE_DONE',

    COMMENT_SAVE_REQUEST: 'COMMENT_SAVE_REQUEST',
    COMMENT_SAVE_SUCCESS: 'COMMENT_SAVE_SUCCESS',
    COMMENT_SAVE_FAILURE: 'COMMENT_SAVE_FAILURE',
    COMMENT_SAVE_CLEAR: 'COMMENT_SAVE_CLEAR',
    
    DELETE_REQUEST: 'COMMENT_DELETE_REQUEST',
    DELETE_SUCCESS: 'COMMENT_DELETE_SUCCESS',
    DELETE_CLEAR: 'COMMENT_DELETE_CLEAR',
    DELETE_FAILURE: 'COMMENT_DELETE_FAILURE',
    DELETE_FAILURE_DONE: 'COMMENT_DELETE_FAILURE_DONE',
};