import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { appreciationActions, discussionActions, infoActions, listonespaceswitchActions, messageActions, spaceActions, spaceswitchActions, switchActions } from '../actions';
import { listonespaceswitchConstants, spaceswitchConstants, switchConstants } from '../constants';
import { isOnlyWhitespace } from '../helpers';
import { DiscussionListRender } from './DiscussionListRender';
import { ProfilListRender } from './ProfilListRender';
import { WrapperRow } from './Row';
import { WrapperWeather } from './WrapperWeather';

export function DiscussionPage() {
    const dispatch = useDispatch();
    const { space } = useParams();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const query = searchParams.get("q")
    const ds = useSelector(state => state.discussion.discussionList);
    const d = useSelector(state => state.discussion.done);
    const [discussionList, setDiscussionList] = useState(ds);
    // spacename = space;
    // console.log(space)

    useEffect(() => {
        if (location.pathname === "/space/search" || location.pathname === "/space/search/") {
            if (!isOnlyWhitespace(query)) {
                dispatch(switchActions.changeTab(switchConstants.SPACE))
                dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
                dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.FEED))
            }
        } else {
            dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.ALL_SPACES))
            dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.ONE_SPACE))
            dispatch(switchActions.changeTab(switchConstants.SPACE))
        }

        dispatch(getAllInfosForSpace())
        // dispatch(discussionActions.getFeed());
    }, []);

    const getAllInfosForSpace = () => {
        return (dispatch, getState) => {
            batch(() => {
                if (location.pathname === "/space/search" || location.pathname === "/space/search/") {
                    if (!isOnlyWhitespace(query)) {
                        dispatch(discussionActions.getFeed(query, true))
                    } else {
                        dispatch(discussionActions.getFeed(query, false))
                    }
                } else {
                    dispatch(discussionActions.getDiscussionList(space, "", false));
                }
                dispatch(spaceActions.getSpaceList());
                dispatch(infoActions.getInfos())
                dispatch(messageActions.checkReadMessage())
                dispatch(appreciationActions.myAppreciation())
            })
        }
    }

    useEffect(() => {
        setDiscussionList(ds);
    }, [ds]);

    return (
        <>
            <WrapperRow LinksCol={WrapperWeather} RightCol={DiscussionList}>
            </WrapperRow>
        </>
    );
}

export function DiscussionList() {
    const { space } = useParams();
    var spacename = space;

    const ds = useSelector(state => state.discussion.discussionList);
    const [discussionList, setDiscussionList] = useState(ds);
    const loading = useSelector(state => state.discussion.loading);
    const e = useSelector(state => state.discussion.error);
    const [error, setError] = useState(e);
    const d = useSelector(state => state.discussion.done);
    const [done, setDone] = useState(d);
    var dataLimitPerPage = 100;

    const p = useSelector(state => state.profil.profilList);
    const [profilList, setProfilList] = useState(p);
    const loadingp = useSelector(state => state.profil.loading);
    const ep = useSelector(state => state.profil.error);
    const [errorp, setErrorp] = useState(ep);
    const dp = useSelector(state => state.profil.done);
    const [donep, setDonep] = useState(dp);
    var dataLimitPerPageDi = 34;

    // const sp = useSelector(state => state.space.spaceList);
    // const [spaceList, setSpaceList] = useState(sp);
    // const loadingsp = useSelector(state => state.space.loading);
    // const esp = useSelector(state => state.space.error);
    // const [errorsp, setErrorsp] = useState(esp);
    // const dsp = useSelector(state => state.space.done);
    // const [donesp, setDonesp] = useState(dsp);
    // var dataLimitPerPage = 35;

    const ss = useSelector(state => state.spaceswitch);

    return <>
        {ss == spaceswitchConstants.FEED_NR ||
            ss == spaceswitchConstants.ALL_SPACES_NR ||
            ss == spaceswitchConstants.MY_SPACE_NR ||
            ss == spaceswitchConstants.LIGHT_NR ?
            <DiscussionListRender
                stateList={ds}
                discussionList={discussionList}
                setDiscussionList={setDiscussionList}
                loading={loading}
                errorState={e}
                error={error}
                setError={setError}
                doneState={d}
                done={done}
                setDone={setDone}
                dataLimitPerPage={dataLimitPerPage}
                spacename={spacename}
            /> : null}
        {ss == spaceswitchConstants.STAR_NR ||
            ss == spaceswitchConstants.MESSAGE_NR ||
            ss == spaceswitchConstants.FIND_NR ?
            <ProfilListRender
                stateList={p}
                profilList={profilList}
                setProfilList={setProfilList}
                loading={loadingp}
                errorState={ep}
                error={errorp}
                setError={setErrorp}
                doneState={dp}
                done={donep}
                setDone={setDonep}
                dataLimitPerPage={dataLimitPerPage}
            /> : null}
    </>
}