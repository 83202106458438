export const feedfilterConstants = {
    GET_REQUEST: 'FEEDFILTER_GET_REQUEST',
    GET_SUCCESS: 'FEEDFILTER_GET_SUCCESS',
    GET_FAILURE: 'FEEDFILTER_GET_FAILURE',
    GET_FAILURE_DONE: 'FEEDFILTER_GET_FAILURE_DONE',

    SAVE_REQUEST: 'FEEDFILTER_SAVE_REQUEST',
    SAVE_SUCCESS: 'FEEDFILTER_SAVE_SUCCESS',
    SAVE_CLEAR: 'FEEDFILTER_SAVE_CLEAR',
    SAVE_FAILURE: 'FEEDFILTER_SAVE_FAILURE',
    SAVE_FAILURE_DONE: 'FEEDFILTER_SAVE_FAILURE_DONE',
}