import { pinConstants } from "../constants";
import { PinClass } from "../models/Pin";

export function pin(state = { loading: false }, action) {
    switch (action.type) {
        case pinConstants.PIN_SAVE_REQUEST:
            // console.log("request")
            return {
                pin: new PinClass(),
                loading: true,
                done: false
            };
        case pinConstants.PIN_SAVE_SUCCESS:
            // console.log(action.payload)
            return {
                pinned: action.payload[0],
                pin: action.payload[1],
                loading: false,
                done: true,
                error: undefined
            };
        case pinConstants.PIN_SAVE_FAILURE:
            return {
                error: action.payload[0],
                pin: action.payload[1],
                loading: false,
                done: false
            };
        case pinConstants.PIN_SAVE_CLEAR:
            return {};
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}