import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { languageswitchConstants } from '../constants';
import { SocialMediaListRender } from './SocialMediaListRender';


export const SocialMediaListIT = () => {
    const dispatch = useDispatch();
    const s = useSelector(state => state.socialmediaIT.socialmediaListIT);
    const [socialmediaListIT, setSocialmedialListIT] = useState(s);
    const loading = useSelector(state => state.socialmediaIT.loading);
    const e = useSelector(state => state.socialmediaIT.error);
    const [error, setError] = useState(e);
    const d = useSelector(state => state.socialmediaIT.done);
    const [done, setDone] = useState(d);
    var dataLimitPerPage = 15;

    const lang = useSelector(state => state.languageswitch);
    if (lang === languageswitchConstants.ITALIAN_NR) {
        return <SocialMediaListRender
            stateList={s}
            socialmediaList={socialmediaListIT}
            setSocialmedialList={setSocialmedialListIT}
            loading={loading}
            errorState={e}
            error={error}
            setError={setError}
            doneState={d}
            done={done}
            setDone={setDone}
            dataLimitPerPage={dataLimitPerPage}
        />
    }
}