import { infoConstants, infonewsguideConstants } from '../constants';
import { infoService } from '../services';

export const infoActions = {
    getInfos,
    getInfoNewsGuide
};

function getInfos() {
    // console.log("in Actions in DiscussionList")
    return dispatch => {
        dispatch({ type: infoConstants.GET_REQUEST });

        infoService.getInfos()
            .then(res => res.json())
            .then(data => dispatch({ type: infoConstants.GET_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: infoConstants.GET_FAILURE, payload: error }));
    };
}

function getInfoNewsGuide() {
    // console.log("in Actions in DiscussionList")
    return dispatch => {
        dispatch({ type: infonewsguideConstants.GET_REQUEST });

        infoService.getInfoNewsGuide()
            .then(res => res.json())
            .then(data => dispatch({ type: infonewsguideConstants.GET_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: infonewsguideConstants.GET_FAILURE, payload: error }));
    };
}