export class ProfileClass {

    constructor() {
        this.id = 0;
        this.displayname = "";
        this.gender = "";
        this.showgender = "";
        this.atname = "";
        this.description = "";
        this.birthday = "";
        this.showage = false;
        this.mytwitterx = "";
        this.myreddit = "";
        this.mytelegram = "";
        this.mylinkedin = "";
        this.mygithub = "";
        this.myyoutube = "";
        this.mytwitch = "";
        this.myinstagram = "";
        this.myfacebook = "";
        this.mytiktok = "";
        this.access = "";
        this.role = "";
        this.visibility = "";
        this.message = "";
    }
}