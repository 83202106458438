export class CommentClass {
    constructor() {
        this.id = 0;
        this.cuid = "";
        this.text = "";
        this.discussion = "";
        this.deleting = false;
        this.editing = false;
        this.adding = false;
        this.commentid = "";
    }
}