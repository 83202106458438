import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import FeedIcon from '@mui/icons-material/Feed';
import ForumIcon from '@mui/icons-material/Forum';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import RefreshIcon from '@mui/icons-material/Refresh';
import StarIcon from '@mui/icons-material/Star';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import WarningIcon from '@mui/icons-material/Warning';
import { Badge, Grid, IconButton, Paper, Stack } from "@mui/material";
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { appreciationActions, discussionActions, infoActions, listonespaceswitchActions, messageActions, profilActions, spaceActions, spaceswitchActions } from '../actions';
import { pageActions } from '../actions/page.actions';
import { listonespaceswitchConstants, pageConstants, spaceswitchConstants } from '../constants';
import { Spinner } from '../spinner/spinner';
import { AdsComponent } from "./AdsComponent";
import { LogoSpace } from './CustomDiscussionPagination';
import { ButtonSpinner } from './CustomPagination';
import { DraggableLoadingPopup } from './SpaceItem';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'inherit',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    boxShadow: 'none'
}));

export const CustomProfilPagination = ({ data, RenderComponent, title, pageLimit, dataLimit, search, shareLink }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [pages] = useState(data.length % dataLimit === 0 ? data.length / dataLimit : Math.floor(data.length / dataLimit) + 1);
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);
    const p = useSelector(state => state.page);
    const [page, setPage] = useState(p);
    const [currentPage, setCurrentPage] = useState(page);

    const lin = useSelector((state) => state.authentication.loggedIn);
    const [loggedIn, setLoggedIn] = useState(lin);
    const u = useSelector((state) => state.authentication.user);
    const [user, setUser] = useState(u);
    const authenticated = loggedIn && user;

    const ss = useSelector(state => state.spaceswitch);
    const [spaceswitch, setSpaceSwitch] = useState(ss);
    const lop = useSelector(state => state.listonespaceswitch);

    const [layoutOnChat, setLayoutOnChat] = useState(null);

    const [urlp, setUlrp] = useState(localStorage.getItem("urlparam"))
    const [spname, setSpname] = useState(localStorage.getItem("spacename"))

    const [onStar, setOnStar] = useState(false)
    const [starStyle, setStarStyle] = useState({ color: '#fff' })

    const { width, height } = useWindowDimensions();

    const rd = useSelector((state) => state.checkreadmessage.read?.read);
    const [read, setRead] = useState(rd);

    const ls = useSelector(state => state.socialmedia.loading);
    const [socialLoading, setSocialLoading] = useState(ls);
    const lses = useSelector(state => state.socialmediaES.loading);
    const [socialLoadingES, setSocialLoadingES] = useState(lses);
    const lsde = useSelector(state => state.socialmediaDE.loading);
    const [socialLoadingDE, setSocialLoadingDE] = useState(lsde);
    const lsfr = useSelector(state => state.socialmediaFR.loading);
    const [socialLoadingFR, setSocialLoadingFR] = useState(lsfr);
    const lspt = useSelector(state => state.socialmediaPT.loading);
    const [socialLoadingPT, setSocialLoadingPT] = useState(lspt);
    const lshi = useSelector(state => state.socialmediaHI.loading);
    const [socialLoadingHI, setSocialLoadingHI] = useState(lshi);
    const lsindo = useSelector(state => state.socialmediaINDO.loading);
    const [socialLoadingINDO, setSocialLoadingINDO] = useState(lsindo);
    const lsru = useSelector(state => state.socialmediaRU.loading);
    const [socialLoadingRU, setSocialLoadingRU] = useState(lsru);
    const lsch = useSelector(state => state.socialmediaCH.loading);
    const [socialLoadingCH, setSocialLoadingCH] = useState(lsch);
    const lsjp = useSelector(state => state.socialmediaJP.loading);
    const [socialLoadingJP, setSocialLoadingJP] = useState(lsjp);
    const lsar = useSelector(state => state.socialmediaAR.loading);
    const [socialLoadingAR, setSocialLoadingAR] = useState(lsar);
    const lsit = useSelector(state => state.socialmediaIT.loading);
    const [socialLoadingIT, setSocialLoadingIT] = useState(lsit);
    const lstr = useSelector(state => state.socialmediaTR.loading);
    const [socialLoadingTR, setSocialLoadingTR] = useState(lstr);
    const lsnl = useSelector(state => state.socialmediaNL.loading);
    const [socialLoadingNL, setSocialLoadingNL] = useState(lsnl);
    const lsgr = useSelector(state => state.socialmediaGR.loading);
    const [socialLoadingGR, setSocialLoadingGR] = useState(lsgr);
    const lmya = useSelector(state => state.myappreciation.loading);
    const [loadingMyAppreciation, setLoadingMyAppreciation] = useState(lmya);
    const lmess = useSelector(state => state.message.loading);
    const [loadingMessage, setLoadingMessage] = useState(lmess);
    const linf = useSelector(state => state.info.loading);
    const [loadingInfo, setLoadingInfo] = useState(linf);
    const lq = useSelector(state => state.quote.loading);
    const [loadingQuote, setLoadingQuote] = useState(lq);
    const lp = useSelector(state => state.publisher.loading);
    const [loadingPublisher, setLoadingPublisher] = useState(lp);

    const [openLoadingPopup, setOpenLoadingPopup] = useState(false);
    const handleOpenLoadingPopup = (e) => {
        setOpenLoadingPopup(true);
    }
    const handleCloseLoadingPopup = () => {
        setOpenLoadingPopup(false);
    }

    const [loading, setLoading] = useState(false);
    const WAIT_CHANGE = 2000;

    const interval = () => setTimeout(() => {
        setLoading(false);
    }, WAIT_CHANGE);

    // console.log(localStorage.getItem("rgl-8"))
    // console.log(getLayout(dataLimit))
    // console.log(layout)

    // useEffect(() => {
    //     localStorage.setItem("layout", JSON.stringify(layout));
    // }, [layout]);


    useEffect(() => {
        localStorage.setItem("page", p)
        setPage(p);
    }, [p])

    const getAllInfosForSpace = () => {
        return (dispatch, getState) => {
            batch(() => {
                dispatch(appreciationActions.myAppreciation())
                dispatch(messageActions.checkReadMessage())
                dispatch(infoActions.getInfos())
            })
        }
    }

    const handleClick = (e) => {

        dispatch(getAllInfosForSpace());

        resetPage()

        switch (e.target.id) {
            case "btnradio0":
                if (lop == listonespaceswitchConstants.ONE_SPACE_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.ONE_SPACE))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.ALL_SPACES))
                    // dispatch(discussionActions.getDiscussionList(urlp));
                    navigate("/space/" + urlp);
                } else if (lop == listonespaceswitchConstants.SPACE_LIST_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.FEED))
                    dispatch(discussionActions.getFeedFilter())
                    dispatch(discussionActions.getFeed());
                    dispatch(spaceActions.getSpaceList());
                    navigate("/space");
                }

                // setLoading(true);
                // interval();
                break;
            case "btnradio5":
                if (lop == listonespaceswitchConstants.ONE_SPACE_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.ONE_SPACE))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.ALL_SPACES))
                    dispatch(discussionActions.getDiscussionList(urlp, "", false));
                    navigate("/space/" + urlp);
                } else if (lop == listonespaceswitchConstants.SPACE_LIST_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.ALL_SPACES))
                    dispatch(spaceActions.getSpaceList());
                    navigate("/space");
                }

                // setLoading(true);
                // interval();
                break;
            case "btnradio6":
                if (lop == listonespaceswitchConstants.ONE_SPACE_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.ONE_SPACE))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.MY_SPACE))
                    navigate("/space/" + urlp);
                } else if (lop == listonespaceswitchConstants.SPACE_LIST_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.MY_SPACE))
                    navigate("/space");
                }
                // setLoading(true);
                dispatch(discussionActions.getMyDiscussionList());
                // interval();
                break;
            case "btnradio7":
                if (lop == listonespaceswitchConstants.ONE_SPACE_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.ONE_SPACE))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.LIGHT))
                    navigate("/space/" + urlp);
                } else if (lop == listonespaceswitchConstants.SPACE_LIST_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.LIGHT))
                    navigate("/space");
                }
                // setLoading(true);
                dispatch(discussionActions.getLightDiscussionList());
                // interval();
                break;
            case "btnradio8":
                if (lop == listonespaceswitchConstants.ONE_SPACE_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.ONE_SPACE))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.STAR))
                    navigate("/space/" + urlp);
                } else if (lop == listonespaceswitchConstants.SPACE_LIST_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.STAR))
                    navigate("/space");
                }
                // setLoading(true);
                dispatch(profilActions.getStarProfilList());
                // interval();
                break;
            case "btnradio9":
                if (lop == listonespaceswitchConstants.ONE_SPACE_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.ONE_SPACE))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.MESSAGE))
                    navigate("/space/" + urlp);
                } else if (lop == listonespaceswitchConstants.SPACE_LIST_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.MESSAGE))
                    navigate("/space");
                }
                // setLoading(true);
                dispatch(profilActions.getMyTalkers());
                // interval();
                break;
            case "btnradio10":
                if (lop == listonespaceswitchConstants.ONE_SPACE_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.ONE_SPACE))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.FIND))
                    navigate("/space/" + urlp);
                } else if (lop == listonespaceswitchConstants.SPACE_LIST_NR) {
                    dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
                    dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.FIND))
                    navigate("/space");
                }
                // setLoading(true);
                dispatch(profilActions.getProfilList());
                // interval();
                break;
        }
    }

    function goToNextPage() {
        if (page < pages) {
            dispatch(pageActions.changePage(pageConstants.NEXT))
            // setCurrentPage(page);

            scrollToTop();
        }
    }

    function goToPreviousPage() {
        if (page > 1) {
            dispatch(pageActions.changePage(pageConstants.PREVIOUS))
            // setCurrentPage(page);

            scrollToTop();
        }
    }

    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        // setCurrentPage(pageNumber);
        dispatch(pageActions.changePage(pageConstants.CURRENT, pageNumber))
        scrollToTop();
    }

    const getPaginatedData = () => {
        const startIndex = page * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return data.slice(startIndex, endIndex);
    };

    const getPaginationGroup = () => {
        let start = Math.floor((page - 1) / pageLimit) * pageLimit;
        return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
    };

    const scrollToTop = () => {
        setLoading(true)

        goToTop()

        interval() // For making good the transition between pages
    }

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    useEffect(() => {
        setRead(rd)
    }, [rd])

    useEffect(() => {
        setTheme(t);
    }, [t])

    useEffect(() => {
        setLoggedIn(lin)
    }, [lin])

    useEffect(() => {
        setUser(u)
    }, [u])

    var rows = 0;

    useEffect(() => {
        setSpaceSwitch(ss)
    }, [ss])

    useEffect(() => {
        if (lop == listonespaceswitchConstants.ONE_SPACE_NR && spaceswitch == spaceswitchConstants.ALL_SPACES_NR) {
            dispatch(discussionActions.getDiscussionList(urlp));
        }
    }, [spaceswitch])


    useEffect(() => {
        setSocialLoading(ls);
    }, [ls]);

    useEffect(() => {
        setSocialLoadingES(lses);
    }, [lses]);

    useEffect(() => {
        setSocialLoadingDE(lsde);
    }, [lsde]);

    useEffect(() => {
        setSocialLoadingFR(lsfr);
    }, [lsfr]);

    useEffect(() => {
        setSocialLoadingPT(lspt);
    }, [lspt]);

    useEffect(() => {
        setSocialLoadingHI(lshi);
    }, [lshi]);

    useEffect(() => {
        setSocialLoadingINDO(lsindo);
    }, [lsindo]);

    useEffect(() => {
        setSocialLoadingRU(lsru);
    }, [lsru]);

    useEffect(() => {
        setSocialLoadingCH(lsch);
    }, [lsch]);

    useEffect(() => {
        setSocialLoadingJP(lsjp);
    }, [lsjp]);

    useEffect(() => {
        setSocialLoadingAR(lsar);
    }, [lsar]);

    useEffect(() => {
        setSocialLoadingIT(lsit);
    }, [lsit]);

    useEffect(() => {
        setSocialLoadingTR(lstr);
    }, [lstr]);

    useEffect(() => {
        setSocialLoadingNL(lsnl);
    }, [lsnl]);

    useEffect(() => {
        setSocialLoadingGR(lsgr);
    }, [lsgr]);

    useEffect(() => {
        setLoadingMyAppreciation(lmya);
    }, [lmya]);

    useEffect(() => {
        setLoadingMessage(lmess);
    }, [lmess]);

    useEffect(() => {
        setLoadingInfo(linf);
    }, [linf]);

    useEffect(() => {
        setLoadingQuote(lq);
    }, [lq]);

    useEffect(() => {
        setLoadingPublisher(lp);
    }, [lp]);

    var styleButtonGroup;
    if (width <= 360) {
        styleButtonGroup = "btn-group btn-group-sm"
    } else {
        styleButtonGroup = "btn-group"
    }

    const dispatchAll = () => {
        // TODO
    }

    function resetPage() {
        // dispatch(pageActions.changePage(pageConstants.RESET))
        localStorage.setItem("page", 1)
    }

    return (
        <>
            <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                <div class="marginMobil" style={{ marginRight: "30px", marginLeft: "200px" }}>

                    <Spinner show={loading} />

                    <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                        <h1 style={{ color: theme === 'dark' ? '#fff' : '#000' }} >
                            {title} {<ButtonSpinner handleOpenLoadingPopup={handleOpenLoadingPopup} show={
                                socialLoading ||
                                socialLoadingES ||
                                socialLoadingDE ||
                                socialLoadingFR ||
                                socialLoadingPT ||
                                socialLoadingHI ||
                                socialLoadingINDO ||
                                socialLoadingRU ||
                                socialLoadingCH ||
                                socialLoadingJP ||
                                socialLoadingAR ||
                                socialLoadingIT ||
                                socialLoadingTR ||
                                socialLoadingNL ||
                                socialLoadingGR ||
                                loadingMyAppreciation ||
                                loadingMessage ||
                                loadingInfo ||
                                loadingQuote ||
                                loadingPublisher
                            } />}

                            <DraggableLoadingPopup openLoadingPopup={openLoadingPopup} handleCloseLoadingPopup={handleCloseLoadingPopup} />
                        </h1>
                        <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                            <div class={styleButtonGroup} role="group" aria-label="Basic radio toggle button group">
                                {lop == listonespaceswitchConstants.SPACE_LIST_NR ?
                                    <>
                                        <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio0" autocomplete="off" checked={ss === spaceswitchConstants.FEED_NR} onClick={handleClick} />
                                        <label class="btn btn-outline-primary" for="btnradio0"><FeedIcon /></label>
                                    </> : null}

                                <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio5" autocomplete="off" checked={ss === spaceswitchConstants.ALL_SPACES_NR} onClick={handleClick} />
                                {lop == listonespaceswitchConstants.SPACE_LIST_NR ? <label class="btn btn-outline-primary" for="btnradio5"><GroupsIcon /></label> : null}
                                {lop == listonespaceswitchConstants.ONE_SPACE_NR ? <label class="btn btn-outline-primary" for="btnradio5"><LogoSpace name={spname} color={theme == "dark" ? "#fff" : "#000"} /></label> : null}

                                <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio6" autocomplete="off" checked={ss === spaceswitchConstants.MY_SPACE_NR} onClick={handleClick} disabled={!authenticated} />
                                <label class="btn btn-outline-primary" for="btnradio6"><PersonIcon /></label>

                                <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio7" autocomplete="off" checked={ss === spaceswitchConstants.LIGHT_NR} onClick={handleClick} disabled={!authenticated} />
                                <label class="btn btn-outline-primary" for="btnradio7"><TipsAndUpdatesIcon /></label>

                                <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio8" autocomplete="off" checked={ss === spaceswitchConstants.STAR_NR} onClick={handleClick} disabled={!authenticated} />
                                <label class="btn btn-outline-primary" for="btnradio8"><StarIcon /></label>

                                <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio9" autocomplete="off" checked={ss === spaceswitchConstants.MESSAGE_NR} onClick={handleClick} disabled={!authenticated} />
                                <label class="btn btn-outline-primary" for="btnradio9">
                                    <Badge color="success" variant="dot" invisible={read}>
                                        <ForumIcon />
                                    </Badge>
                                </label>

                                <input type="radio" class="btn-check" name="btnradioForSpace" id="btnradio10" autocomplete="off" checked={ss === spaceswitchConstants.FIND_NR} onClick={handleClick} />
                                <label class="btn btn-outline-primary" for="btnradio10"><PersonSearchIcon /></label>
                            </div>
                        </div>

                        {getPaginatedData() && getPaginatedData().length === 0 ?
                            <>
                                {ss === spaceswitchConstants.STAR_NR &&
                                    <>
                                        {search ?
                                            <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                                                <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 870, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white">
                                                    <WarningIcon style={{ marginBottom: 5 }} />{" "}Oops! What you're looking for cannot be found
                                                </h2>
                                            </div>
                                            :
                                            <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                                                <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 870, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white">
                                                    Here you'll find people who gave you a star to your profil
                                                </h2>
                                            </div>}
                                    </>
                                }

                                {ss === spaceswitchConstants.MESSAGE_NR &&
                                    <>
                                        {search ?
                                            <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                                                <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 870, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white">
                                                    <WarningIcon style={{ marginBottom: 5 }} />{" "}Oops! What you're looking for cannot be found
                                                </h2>
                                            </div>
                                            :
                                            <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                                                <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 870, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white">
                                                    Here you'll find people who messaged you
                                                </h2>
                                            </div>
                                        }
                                    </>
                                }

                                {ss !== spaceswitchConstants.STAR_NR && ss !== spaceswitchConstants.MESSAGE_NR &&
                                    <>
                                        {search ?
                                            <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                                                <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 870, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white">
                                                    <WarningIcon style={{ marginBottom: 5 }} />{" "}Oops! What you're looking for cannot be found
                                                </h2>
                                            </div>
                                            :
                                            <div class="container" style={{ paddingBottom: 30, paddingLeft: 0, marginLeft: 0 }}>
                                                <h2 style={{ borderRadius: 20, marginTop: 32, maxWidth: 870, color: '#fff' }} class="p-3 mb-2 bg-primary bg-gradient text-white">
                                                    <WarningIcon style={{ marginBottom: 5 }} />{" "}This could be a network issue or the App is in maintenance mode. Please refresh the page{" "}<IconButton style={{ color: "#fff" }} onClick={dispatchAll} title="Load News"><RefreshIcon /></IconButton>{" "}or try again later...
                                                </h2>
                                            </div>
                                        }
                                    </>
                                }
                            </>
                            :
                            <Grid
                                container
                                spacing={4}
                                // className="marginLaptop"
                                justifyItems="center"
                            // style={{ PaddingRight: "1000px" }}
                            >
                                {getPaginatedData().map((d, idx) => (
                                    <Grid key={idx} item xs={12} sm={12} md={12} lg={6} xl={4}>
                                        <RenderComponent
                                            key={idx}
                                            index={idx}
                                            data={d}
                                            onStar={onStar}
                                            setOnStar={setOnStar}
                                            starStyle={starStyle}
                                            setStarStyle={setStarStyle}
                                            resetButtonid={"previewchat" + idx}
                                            profilViewid={"profil" + idx}
                                            layoutOnChat={layoutOnChat}
                                            setLayoutOnChat={setLayoutOnChat}
                                        />
                                    </Grid>
                                ))}
                            </Grid>}
                        {/* <div>
                            {getPaginatedData().map((d, idx) => (
                                <RenderComponent key={idx} data={d} />
                            ))}
                        </div> */}

                        {/* show the pagiantion
        it consists of next and previous buttons
        along with page numbers, in our case, 5 page
        numbers at a time
    */}
                        {!shareLink && pages > 1 && getPaginatedData() && getPaginatedData().length >= 1 ?
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={0}
                                p={5}
                            // style={{ paddingBottom: 100, marginBottom: 70 }}
                            >
                                {page > 1 ? <Item>
                                    <IconButton style={{ marginTop: '-5px', background: "#1976d2", color: "#fff" }} onClick={goToPreviousPage} title="Previous">
                                        <ArrowCircleLeftIcon fontSize="large" />
                                    </IconButton>
                                </Item> : null}
                                {page < pages ? <Item>
                                    <IconButton style={{ marginTop: '-5px', background: "#1976d2", color: "#fff" }} onClick={goToNextPage} title="Next">
                                        <ArrowCircleRightIcon fontSize="large" />
                                    </IconButton>
                                </Item> : null}
                            </Stack> : null}

                        {getPaginatedData() && getPaginatedData().length >= 1 ?
                            <Stack
                                direction={width >= 1482 ? "row" : "column"} //1200 1482
                                justifyContent="center"
                                alignItems="center"
                                spacing={0}
                                p={1}
                                style={{ paddingBottom: 10, marginBottom: 15, paddingTop: pages === 1 ? 50 : 0 }}
                            >
                                {<Item><AdsComponent dataAdSlot='5472839718' currentPath={location.pathname} width={width >= 360 ? 360 : 260} /></Item>}

                                {<Item><AdsComponent dataAdSlot='5476709145' currentPath={location.pathname} width={width >= 360 ? 360 : 260} /></Item>}

                                {<Item><AdsComponent dataAdSlot='8931108113' currentPath={location.pathname} width={width >= 360 ? 360 : 260} /></Item>}
                            </Stack> : null}


                        {getPaginatedData() && getPaginatedData().length >= 1 ? <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={0}
                            p={1}
                            style={{ paddingBottom: 10, marginBottom: 15, paddingTop: pages === 1 ? 50 : 0 }}
                        >
                            <Item>
                                <IconButton style={{ marginTop: '-5px', background: "#1976d2", color: "#fff" }} onClick={() => goToTop()} title="Up">
                                    <ArrowCircleUpIcon fontSize="large" />
                                </IconButton>
                            </Item>
                        </Stack> : null}

                        {<div style={{ paddingBottom: width < 500 ? 25 : 100, paddingTop: width < 500 ? 25 : 50, /*paddingRight: "25px",*/ marginTop: 5, marginBottom: 70 }} />}

                    </div>
                </div>
            </div>
        </>
    );
}