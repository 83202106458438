export class GDPRDataClass {

    constructor() {
        this.id = 0;
        this.firstname = "";
        this.lastname = "";
        this.email = "";
        this.phone = "";
        this.country = "";
        this.requestas = "";
        this.requesttype = "";
        this.extra = "";
        this.requesttime = "";
    }
}