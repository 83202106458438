import { reportConstants } from "../constants";
import { ReportClass } from "../models/report";

export function report(state = { loading: false }, action) {
    switch (action.type) {
        case reportConstants.REPORT_SAVE_REQUEST:
            // console.log("request")
            return {
                report: new ReportClass(),
                loading: true,
                done: false
            };
        case reportConstants.REPORT_SAVE_SUCCESS:
            // console.log(action.payload)
            return {
                submitted: action.payload[0],
                report: action.payload[1],
                loading: false,
                done: true,
                error: undefined
            };
        case reportConstants.REPORT_SAVE_FAILURE:
            return {
                error: action.payload[0],
                report: action.payload[1],
                loading: false,
                done: false
            };
        case reportConstants.REPORT_SAVE_CLEAR:
            return {};
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}