import { Box, IconButton, Paper as Paperx, Zoom } from '@material-ui/core';
import BlockIcon from '@mui/icons-material/Block';
import ConstructionIcon from '@mui/icons-material/Construction';
import FacebookIcon from '@mui/icons-material/Facebook';
import FlagIcon from '@mui/icons-material/Flag';
import GitHubIcon from '@mui/icons-material/GitHub';
import InfoIcon from '@mui/icons-material/Info';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RedditIcon from '@mui/icons-material/Reddit';
import TelegramIcon from '@mui/icons-material/Telegram';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Button, Card, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Stack, TextField } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import { useWindowDimensions } from 'react-native';

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createStyles, makeStyles } from "@material-ui/core/styles";
import $ from 'jquery';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { isOnlyWhitespace, shortGender, truncateFullName } from "../helpers/util";

// Import Swiper React components
import StarIcon from '@mui/icons-material/Star';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { styled } from '@mui/system';
import { appreciationActions, chatActions, messageActions, reportActions, userActions } from "../actions";
import { TikTokBlueIcon, TikTokIcon, TikTokWhiteIcon, TwitchBlueIcon, TwitchIcon, TwitchWhiteIcon } from "./CustomSVG";

import SendIcon from '@material-ui/icons/Send';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { MessageBlank, MessageLeft, MessageRight } from "./Message.js";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import SyncIcon from '@mui/icons-material/Sync';

import CancelIcon from '@mui/icons-material/Cancel';
import ForumIcon from '@mui/icons-material/Forum';
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { ChatClass } from "../models/chat.js";
import { MessageClass } from "../models/message.js";

import { Client } from '@stomp/stompjs';
// import { clearInterval, clearTimeout, setInterval, setTimeout } from 'worker-timers';

import Draggable from "react-draggable";
import { WebSocketServer } from "ws";
import { chatConstants } from "../constants/chat.constants.js";
import { messageConstants } from "../constants/message.constants.js";
import { BlockChatClass } from "../models/blockchat.js";
import { GivingClass } from "../models/giving.js";
import { ReportClass } from "../models/report.js";
import { BeginText, Spinner, SpinnerLoading } from "../spinner/spinner.js";
import { DraggableInfoPopup, DraggableShowProfilePopup, DraggableSignInPopup, ReadMoreReadLess, Share } from "./SpaceItem.js";

import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { spaceswitchConstants } from "../constants/spaceswitch.constants.js";
import { workerConstants } from "../constants/worker.constants.js";
import { ModerationUserClass } from "../models/moderation.js";
import { DraggableStatusPopup } from "./Menu.js";

Object.assign(global, { WebSocketServer });


const preUrlProfil = workerConstants.PRE_URL_PROFIL

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'inherit',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    boxShadow: 'none'
}));

const styleTextField = {
    // width: 320,
    // margin: '15px',
    marginBottom: '15px',
    // maxWidth: '100%',
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
}

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});

const client = new Client({
    brokerURL: workerConstants.WS_WORKER,
    // webSocketFactory: function () {
    //     // Note that the URL is different from the WebSocket URL
    //     return new SockJS('https://service.onelike.app/ws');
    // },
    // forceBinaryWSFrames : true,
    debug: function (str) {
        console.log('STOMP: ' + str);
    },
});

export const ProfilLayout = ({
    data,
    resetButtonid,
    profilViewid,
    layoutOnChat,
    setLayoutOnChat
}) => {
    const [layoutPreview, setLayoutPreview] = useState(1)
    const { uuid, name, firstname, pictureUrl, mytwitterx, myreddit, mytelegram, mylinkedin, mygithub, myyoutube, mytwitch, myinstagram, myfacebook, mytiktok, stars, lumens, blocked, reported, star, atname, displayname, description, age, showage, banned, suspended, access, showgender, gender, visibility, message, read, synced } = data;
    const userdata = data;

    const u = useSelector((state) => state.authentication.user);
    const [user, setUser] = useState(u);
    const lin = useSelector((state) => state.authentication.loggedIn);
    const [loggedIn, setLoggedIn] = useState(lin);
    const authenticated = loggedIn && user;

    const [readMessage, setReadMessage] = useState(read)

    const [openSignInPopup, setOpenSignInPopup] = useState(false);
    const handleOpenSignInPopup = (e) => {
        setOpenSignInPopup(true);
    }
    const handleCloseSignInPopup = () => {
        setOpenSignInPopup(false);
    }

    useEffect(() => {
        setUser(user)
    }, [u])

    useEffect(() => {
        setLoggedIn(lin)
    }, [lin])

    useEffect(() => {
        setReadMessage(read)
    }, [read])

    useEffect(() => {
        if (layoutPreview == 2) {
            setLayoutOnChat($('#' + resetButtonid))
        }
    }, [layoutPreview])

    const switchToMessage = (e) => {
        if (authenticated) {
            if (layoutOnChat && layoutOnChat.id !== e.target.id) {
                // if (client && client.connected) {
                //     client.deactivate();
                // }
                dispatch({ type: chatConstants.GET_REQUEST });
                dispatch({ type: messageConstants.GET_REQUEST });

                setTimeout(1000)
                layoutOnChat.click()
            } else {
                dispatch({ type: chatConstants.GET_REQUEST });
                dispatch({ type: messageConstants.GET_REQUEST });
            }

            dispatch(messageActions.checkReadMessage())
            setLayoutPreview(2)
        } else {
            handleOpenSignInPopup()
        }
    }

    const dispatch = useDispatch();

    return (
        <>
            {layoutPreview == 1 &&
                <ProfilItem
                    layoutPreview={layoutPreview}
                    setLayoutPreview={setLayoutPreview}
                    layoutOnChat={layoutOnChat}
                    setLayoutOnChat={setLayoutOnChat}
                    authenticated={authenticated}
                    uuid={uuid}
                    user={user}
                    userdata={userdata}
                    name={name}
                    firstname={firstname}
                    pictureUrl={pictureUrl}
                    mytwitterx={mytwitterx}
                    myreddit={myreddit}
                    mytelegram={mytelegram}
                    mylinkedin={mylinkedin}
                    mygithub={mygithub}
                    myyoutube={myyoutube}
                    mytwitch={mytwitch}
                    myinstagram={myinstagram}
                    myfacebook={myfacebook}
                    mytiktok={mytiktok}
                    star={star}
                    stars={stars}
                    lumens={lumens}
                    atname={atname}
                    displayname={displayname}
                    description={description}
                    blocked={blocked}
                    reported={reported}
                    suspended={suspended}
                    banned={banned}
                    resetButtonid={resetButtonid}
                    profilViewid={profilViewid}
                    switchToMessage={switchToMessage}
                    age={age}
                    showage={showage}
                    access={access}
                    gender={gender}
                    showgender={showgender}
                    visibility={visibility}
                    message={message}
                    read={read} // fixed
                    readMessage={readMessage}
                    synced={synced}
                    openSignInPopup={openSignInPopup}
                    handleOpenSignInPopup={handleOpenSignInPopup}
                    handleCloseSignInPopup={handleCloseSignInPopup}
                />}
            {layoutPreview == 2 ?
                <Chat
                    layoutPreview={layoutPreview}
                    setLayoutPreview={setLayoutPreview}
                    authenticated={authenticated}
                    user={user}
                    uuid={uuid}
                    name={name}
                    firstname={firstname}
                    displayname={displayname}
                    pictureUrl={pictureUrl}
                    mytwitterx={mytwitterx}
                    myreddit={myreddit}
                    mytelegram={mytelegram}
                    mylinkedin={mylinkedin}
                    mygithub={mygithub}
                    myyoutube={myyoutube}
                    mytwitch={mytwitch}
                    myinstagram={myinstagram}
                    myfacebook={myfacebook}
                    mytiktok={mytiktok}
                    stars={stars}
                    lumens={lumens}
                    resetButtonid={resetButtonid}
                    profilViewid={profilViewid}
                    setReadMessage={setReadMessage}
                /> : null}
        </>
    );

}

export const ProfilItem = ({
    data,
    layoutPreview,
    authenticated,
    setLayoutPreview,
    layoutOnChat,
    setLayoutOnChat,
    user,
    userdata,
    uuid, // receiver
    name,
    firstname,
    pictureUrl,
    mytwitterx,
    myreddit,
    mytelegram,
    mylinkedin,
    mygithub,
    myyoutube,
    mytwitch,
    myinstagram,
    myfacebook,
    mytiktok,
    star,
    stars,
    lumens,
    atname,
    displayname,
    description,
    blocked,
    reported,
    suspended,
    banned,
    access,
    resetButtonid,
    profilViewid,
    switchToMessage,
    age,
    showage,
    showgender,
    gender,
    visibility,
    message,
    read,
    readMessage,
    synced,
    openSignInPopup,
    handleOpenSignInPopup,
    handleCloseSignInPopup
}) => {
    const { height, width } = useWindowDimensions();
    const dispatch = useDispatch();
    // console.log(data)

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    var styleMobLarge1;
    var styleMobLarge2;
    var styleAvatar;
    if (width > 767) {
        styleMobLarge1 = { maxwidth: '80vw', height: 60, backgroundColor: readMessage ? (theme === 'dark' ? '#002147' : "#F7F9F9") : '#2E7D32', borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { maxWidth: '100vw', backgroundColor: readMessage ? (theme === 'dark' ? '#002147' : "#F7F9F9") : '#2E7D32', borderRadius: 20 }
        styleAvatar = { width: 50, height: 50 }
    } else {
        styleMobLarge1 = { width: '80vw', height: 60, backgroundColor: readMessage ? (theme === 'dark' ? '#002147' : "#F7F9F9") : '#2E7D32', borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { width: '80vw', backgroundColor: readMessage ? (theme === 'dark' ? '#002147' : "#F7F9F9") : '#2E7D32', borderRadius: 20 }
        styleAvatar = { width: 50, height: 50 }
    }

    const [blockStyle, setBlockStyle] = useState({ color: '#fff' })
    const [blockedUser, setBlockedUser] = useState(blocked)
    const [isReadMoreShown, setReadMoreShown] = useState(false)

    const [openShowReportPopup, setOpenShowReportPopup] = useState(false);
    const handleOpenReportPopup = (e) => {
        setOpenShowReportPopup(true);
    }
    const handleCloseShowReportPopup = () => {
        setOpenShowReportPopup(false);
    }

    const e = useSelector(state => state.report.error);
    const [error, setError] = useState(e);
    const d = useSelector(state => state.report.done);
    const [done, setDone] = useState(d);

    const [reportStyle, setReportStyle] = useState({ color: '#fff' })
    const [reportedUser, setReportedUser] = useState(reported)
    const [reporting, setReporting] = useState(false)

    const [issue, setIssue] = useState("")
    const [entry, setEntry] = useState(true)

    const [onStar, setOnStar] = useState(false)
    const [starStyle, setStarStyle] = useState({ color: '#fff' })
    const [shareStyle, setShareStyle] = useState({ color: "#fff", transform: 'scaleX(-1)' })

    const l = useSelector((state) => state.moderation.loading);

    const ss = useSelector(state => state.spaceswitch);
    const [spaceswitch, setSpaceSwitch] = useState(ss);
    const lop = useSelector(state => state.listonespaceswitch);
    const [starSynced, setStarSynced] = useState(synced)

    const [openShowProfilePopup, setOpenShowProfilePopup] = useState(false);
    const handleOpenShowProfilePopup = (e) => {
        setOpenShowProfilePopup(true);
    }

    const handleCloseShowProfilePopup = () => {
        setOpenShowProfilePopup(false);
    }

    const [openStatusPopup, setOpenStatusPopup] = useState(false);
    const handleOpenStatusPopup = (e) => {
        setOpenStatusPopup(true);
    }
    const handleCloseStatusPopup = () => {
        setOpenStatusPopup(false);
    }

    const setBalanceOnStar = (e) => {
        if (authenticated) {
            if (!onStar) {
                setStarStyle({ color: '#fff' })
                setStarStyle({ color: '#ffff00' })

                var giving = new GivingClass()
                giving.user = uuid;
                giving.star = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));

                setOnStar(true)
                if (ss == spaceswitchConstants.STAR_NR) {
                    setStarSynced(true)
                }
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            } else {
                setStarStyle({ color: '#ffff00' })
                setStarStyle({ color: '#fff' })

                var giving = new GivingClass()
                giving.user = uuid;
                giving.resetStar = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));

                setOnStar(false)
                if (ss == spaceswitchConstants.STAR_NR ||
                    ss == spaceswitchConstants.MESSAGE_NR ||
                    ss == spaceswitchConstants.FIND_NR
                ) {
                    setStarSynced(false)
                }
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            }
        } else {
            handleOpenSignInPopup()
        }
    }

    const blockUser = () => {
        // console.log("BlockUser")
        if (authenticated) {
            if (!blockedUser) {
                console.log("Block")
                setBlockedUser(true)
                setBlockStyle({ color: '#ff0000' })

                var blockchat = new BlockChatClass()
                blockchat.uuid = uuid
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var blockChatInfos = JSON.parse(JSON.stringify(blockchat, replacer));

                dispatch(chatActions.blockChat(blockChatInfos))
            } else {
                console.log("Unblock")
                setBlockedUser(false)
                setBlockStyle({ color: '#fff' })

                var blockchat = new BlockChatClass()
                blockchat.uuid = uuid
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var blockChatInfos = JSON.parse(JSON.stringify(blockchat, replacer));

                dispatch(chatActions.unblockChat(blockChatInfos))
            }
        } else {
            handleOpenSignInPopup()
        }
    }

    const report = () => {
        handleOpenReportPopup()
    }

    const handleChange = (e) => {
        if (e.target.type === "radio") {
            setIssue(e.target.value);
        }
    }

    const handleReportSubmit = () => {

        if (authenticated) {
            if (!reportedUser) {
                if (issue) {
                    var report = new ReportClass()
                    report.uuid = uuid
                    report.issue = issue
                    const replacer = (key, value) => {
                        if (typeof value === 'undefined')
                            return ""
                        else return value;
                    }
                    var reportInfos = JSON.parse(JSON.stringify(report, replacer));

                    setReporting(true)
                    setReportedUser(true)
                    setReportStyle({ color: '#ff0000' })
                    handleCloseShowReportPopup()
                    dispatch(reportActions.submitReport(reportInfos))
                } else {
                    Toast.fire({
                        icon: "warning",
                        title: "Select one issue about the report"
                    });
                }
            }

        } else {
            handleOpenSignInPopup()
        }
    }

    useEffect(() => {
        setStarSynced(synced)
    }, [synced])

    useEffect(() => {
        if (blockedUser) {
            setBlockedUser(true)
            setBlockStyle({ color: '#ff0000' })
        } else {
            setBlockedUser(false)
            setBlockStyle({ color: '#fff' })
        }
    }, [])

    useEffect(() => {
        if (reportedUser) {
            setReportedUser(true)
            setReportStyle({ color: '#ff0000' })
        } else {
            setReportedUser(false)
            setReportStyle({ color: '#fff' })
        }
    }, [])

    useEffect(() => {
        if (reporting) {
            if (d) {
                if (e !== undefined) {
                    setReporting(false)
                    Toast.fire({
                        icon: "error",
                        title: "An error has occurred. Please try again ..."
                    });
                } else {
                    setReporting(false)
                    Toast.fire({
                        icon: "success",
                        title: "Thank you for Reporting !"
                    });
                }
            }
        }
    }, [d]);

    useEffect(() => {
        if (entry) {
            if (star) {
                setOnStar(true)
                setStarStyle({ color: '#ffff00' })
            }
        }
        setEntry(false)
    }, [])

    var displaynameAgeGender = displayname ?
        truncateFullName(displayname) + (showage && age > 1 ? (", " + age) : "") + ((!showgender || !gender || gender == "NONE") ? "" : (", " + shortGender(gender)))
        :
        (name ?
            truncateFullName(firstname + " " + name) + (showage && age > 1 ? (", " + age) : "") + ((!showgender || !gender || gender == "NONE") ? "" : (", " + shortGender(gender)))
            :
            truncateFullName(firstname) + (showage && age > 1 ? (", " + age) : "") + ((!showgender || !gender || gender == "NONE") ? "" : (", " + shortGender(gender))))

    var title = displayname ?
        displayname + " - OneLike Social"
        :
        (name ?
            firstname + " " + name + " - OneLike Social"
            :
            firstname + " - OneLike Social")

    return (
        <Container id={profilViewid}>

            <Spinner show={l} />

            <DraggableReportPopup openShowReportPopup={openShowReportPopup} handleCloseShowReportPopup={handleCloseShowReportPopup} handleReportSubmit={handleReportSubmit} handleChange={handleChange} />
            <DraggableSignInPopup openSignInPopup={openSignInPopup} handleCloseSignInPopup={handleCloseSignInPopup} />
            <DraggableShowProfilePopup openShowProfilePopup={openShowProfilePopup} handleCloseShowProfilePopup={handleCloseShowProfilePopup} createdby={userdata} setBalanceOnStar={setBalanceOnStar} starStyle={starStyle} showStar={true} />
            <DraggableStatusPopup openShowStatusPopup={openStatusPopup} handleCloseStatusPopup={handleCloseStatusPopup} banned={banned} suspended={suspended} profil={true} />

            <div style={{ display: 'block' }}>
                <Card sx={styleMobLarge2}>
                    <Container style={{ paddingRight: 0, paddingLeft: 0, width: '90%' }}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                        >
                            <Item style={{ padding: 40 }}>
                                {atname && (visibility && visibility.includes("VISIBLE")) && (access && access.includes("PUBLIC")) ?
                                    <Stack
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={0}
                                    >
                                        <Share shareStyle={shareStyle} title={title} post={description ? description : ""} path={atname} preUrl={preUrlProfil} profilsharing={true} />

                                    </Stack>
                                    :
                                    <Button size="small" variant="contained" style={{ backgroundColor: readMessage ? (theme === 'dark' ? '#002147' : "#F7F9F9") : '#2E7D32', borderRadius: 20, minWidth: 0, padding: 5, boxShadow: "none", cursor: "auto" }}>
                                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20, color: readMessage ? (theme === 'dark' ? '#002147' : "#F7F9F9") : '#2E7D32' }}>RER</Box>
                                    </Button>
                                }
                            </Item>

                            {((user && user.role && user.role.includes("MODERATOR")) || (user && user.role && user.role.includes("ADMIN"))) ?
                                <Item>
                                    <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", display: "flex", justifyContent: "center", alignItems: "center", padding: 8, borderRadius: 25, minWidth: 0 }} onClick={() => handleOpenStatusPopup()}>
                                        <Box>
                                            {banned && <ErrorIcon style={{ color: '#FF0000' }} title={"Banned"} />}
                                            {suspended && <NewReleasesIcon style={{ color: "#FFFF00" }} title={"Suspended"} />}
                                            {!suspended && !banned && <CheckCircleIcon style={{ color: '#00ff00' }} title={"Good"} />}
                                        </Box>
                                    </Button>
                                </Item>
                                : null}

                            <Item
                                style={{ padding: 40 }}
                            >
                                <Stack
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={0}
                                    p={0}
                                >
                                    <Item>
                                        <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={setBalanceOnStar}>
                                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><StarIcon style={starStyle} /></Box>
                                        </Button>
                                    </Item>
                                    {starSynced ? <Item>
                                        <Button size="small" variant="contained" style={{ cursor: "auto", backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} title={"Synced"}>
                                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><SyncIcon style={starStyle} /></Box>
                                        </Button>
                                    </Item> : null}
                                </Stack>

                            </Item>
                        </Stack>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={0}
                            p={2}
                        >
                            <Item>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={0}
                                    style={{ display: 'flex' }}
                                >
                                    <Item style={{ padding: 0, cursor: 'pointer' }} onClick={() => handleOpenShowProfilePopup()}><Avatar alt={displaynameAgeGender} src={pictureUrl ? pictureUrl : "/avatar2.png"} sx={styleAvatar} /></Item>
                                    <Item style={{ cursor: 'pointer', color: theme === 'dark' ? "#fff" : "#000" }} onClick={() => handleOpenShowProfilePopup()}>{displaynameAgeGender}</Item>
                                </Stack>
                            </Item>
                            <Item><p style={{ marginBottom: 0 }}>{atname ? "@" + atname : ""}</p></Item>
                            <Item>
                                <p style={{ marginBottom: 0 }}>
                                    {description && description.length > 120 ?
                                        <ReadMoreReadLess limit={120} isReadMoreShown={isReadMoreShown} setReadMoreShown={setReadMoreShown}>
                                            {description ? description : ""}
                                        </ReadMoreReadLess> :
                                        description
                                    }
                                </p>
                            </Item>
                            <Item>
                                <p style={{ marginBottom: 16 }}>
                                    {!isOnlyWhitespace(mytwitterx) ? <><a class="hover" target="_blank" href={mytwitterx}><XIcon style={{ marginBottom: 3, color: theme == 'dark' ? "#fff" : "#166FE5" }} /></a>{" "}</> : null}
                                    {!isOnlyWhitespace(myreddit) ? <><a target="_blank" href={myreddit}><RedditIcon style={{ marginBottom: 3, color: theme == 'dark' ? "#fff" : "#166FE5" }} /></a>{" "}</> : null}
                                    {!isOnlyWhitespace(mytelegram) ? <><a target="_blank" href={mytelegram}><TelegramIcon style={{ marginBottom: 3, color: theme == 'dark' ? "#fff" : "#166FE5" }} /></a>{" "}</> : null}
                                    {!isOnlyWhitespace(mylinkedin) ? <><a target="_blank" href={mylinkedin}><LinkedInIcon style={{ marginBottom: 3, color: theme == 'dark' ? "#fff" : "#166FE5" }} /></a>{" "}</> : null}
                                    {!isOnlyWhitespace(mygithub) ? <><a target="_blank" href={mygithub}><GitHubIcon style={{ marginBottom: 3, color: theme == 'dark' ? "#fff" : "#166FE5" }} /></a>{" "}</> : null}
                                    {!isOnlyWhitespace(myyoutube) ? <><a target="_blank" href={myyoutube}><YouTubeIcon style={{ marginBottom: 3, color: theme == 'dark' ? "#fff" : "#166FE5" }} /></a>{" "}</> : null}
                                    {!isOnlyWhitespace(mytwitch) ? <><a target="_blank" href={mytwitch}>{theme == 'dark' ? <TwitchWhiteIcon style={{ marginBottom: 3 }} /> : <TwitchBlueIcon style={{ marginBottom: 3 }} />}</a>{" "}</> : null}
                                    {!isOnlyWhitespace(myinstagram) ? <><a target="_blank" href={myinstagram}><InstagramIcon style={{ marginBottom: 3, color: theme == 'dark' ? "#fff" : "#166FE5" }} /></a>{" "}</> : null}
                                    {!isOnlyWhitespace(myfacebook) ? <><a target="_blank" href={myfacebook}><FacebookIcon style={{ marginBottom: 3, color: theme == 'dark' ? "#fff" : "#166FE5" }} /></a>{" "}</> : null}
                                    {!isOnlyWhitespace(mytiktok) ? <><a target="_blank" href={mytiktok}>{theme == 'dark' ? <TikTokWhiteIcon style={{ marginBottom: 3 }} /> : <TikTokBlueIcon style={{ marginBottom: 3 }} />}</a></> : null}
                                </p>
                            </Item>
                            <Item>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={1}
                                    p={0}
                                    style={{ display: "flex", marginTop: 5 }}
                                >
                                    <Item style={{ paddingLeft: 0 }}><Box style={{ backgroundColor: "#166fe5", display: "flex", justifyContent: "center", alignItems: "center", padding: 5, borderRadius: 20 }}><span style={{ marginRight: 2, fontWeight: 'bold' }}>{stars}</span><StarIcon style={{ color: "#FFFF00" }} /></Box></Item>
                                    <Item><Box style={{ backgroundColor: "#166fe5", display: "flex", justifyContent: "center", alignItems: "center", padding: 5, borderRadius: 20 }}><span style={{ marginRight: 2, fontWeight: 'bold' }}>{lumens}</span><TipsAndUpdatesIcon style={{ color: "#FFFF00" }} /></Box></Item>
                                </Stack>
                            </Item>
                            {((user?.uuid !== uuid) && (!authenticated || (user && user.message && user.message.includes("YES"))) && (message.includes("YES")) && !blockedUser) ? <Item>
                                <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={switchToMessage}>
                                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><ForumIcon /></Box>
                                </Button>
                            </Item> : null}
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={1}
                            p={0}
                        >
                            <Item style={{ padding: 40 }}>
                                <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={report}>
                                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><FlagIcon style={reportStyle} /></Box>
                                </Button>
                            </Item>
                            {((user && user.role && user.role.includes("MODERATOR")) || (user && user.role && user.role.includes("ADMIN"))) ?
                                <Item style={{ padding: 40 }}>
                                    <Moderation uuid={uuid} />
                                </Item>
                                :
                                null}
                            <Item style={{ padding: 40 }}>
                                <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={blockUser}>
                                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><BlockIcon style={blockStyle} /></Box>
                                </Button>
                            </Item>
                        </Stack>
                    </Container>
                </Card>
            </div>
        </Container>
    );
}

export function Chat({
    authenticated,
    space,
    setLayoutPreview,
    openSignInPopup,
    handleOpenSignInPopup,
    user,
    urlparam,
    uuid,
    name,
    firstname,
    displayname,
    pictureUrl,
    mytwitterx,
    myreddit,
    mytelegram,
    mylinkedin,
    mygithub,
    myyoutube,
    mytwitch,
    myinstagram,
    myfacebook,
    mytiktok,
    stars,
    lumens,
    resetButtonid,
    profilViewid,
    setReadMessage
}) {
    const { height, width } = useWindowDimensions();

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    var styleMobLarge1;
    var styleMobLarge2;
    if (width > 767) {
        styleMobLarge1 = { maxwidth: '80vw', height: 60, backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { maxWidth: '100vw', backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px" }
    } else {
        styleMobLarge1 = { width: '80vw', height: 60, backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { width: '80vw', backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px" }
    }

    const useStylesForMessage = makeStyles((thm) =>
        createStyles({
            paper: {
                width: "80vw",
                height: "80vh",
                /*maxWidth: "500px",*/
                maxHeight: "700px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                position: "relative",
                backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9"
            },
            paper2: {
                width: "80vw",
                maxWidth: "500px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                position: "relative"
            },
            container: {
                /*width: "100vw",
                height: "100vh",*/
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            },
            messagesBody: {
                width: "calc( 100% - 20px )",
                margin: 10,
                overflowY: "scroll",
                height: "calc( 100% - 80px )",
                backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9",
                boxShadow: 'none',
            }
        })
    );

    const dispatch = useDispatch();
    const location = useLocation();
    const classes = useStylesForMessage();
    const m = useSelector(state => state.message.messages?.messages);
    const [messageList, setMessageList] = useState(m)
    const [addingComment, setAddingComment] = useState(false)

    const e = useSelector(state => state.message.error);
    const [error, setError] = useState(e);
    const d = useSelector(state => state.message.done);
    const [done, setDone] = useState(d);

    const c = useSelector(state => state.chat.chat);
    const [chat, setChat] = useState(c)
    const ec = useSelector(state => state.chat.error);
    const [errorc, setErrorc] = useState(ec);
    const dc = useSelector(state => state.chat.done);
    const [donec, setDonec] = useState(dc);

    const ms = useSelector(state => state.menuswitch);
    const [menuSwitch, setMenuSwitch] = useState(ms);

    // const [inputText, setInputText] = useState("");
    const [open, setOpen] = useState(false)

    const [isTyping, setIsTyping] = useState(false);
    // let typingTimeoutId = null;

    const chatRef = useRef(null);

    const [otherUsersTyping, setOtherUsersTyping] = useState(new Set());
    const typingTimeoutId = useRef(null);
    const [subscription, setSubscription] = useState(null)

    const [entry, setEntry] = useState(true)
    const [beginMessaging, setBeginMessaging] = useState(true)
    const [loadingMessage, setLoadingMessage] = useState(true)

    const [openEditmode, setOpenEditmode] = useState(false)
    const [messageForEdit, setMessageForEdit] = useState(null)

    const [messaging, setMessaging] = useState(false)

    const [openInfoPopup, setOpenInfoPopup] = useState(false);
    const handleOpenInfoPopup = (e) => {
        setOpenInfoPopup(true);
    }

    const handleCloseInfoPopup = () => {
        setOpenInfoPopup(false);
    }


    useEffect(() => {
        var c = new ChatClass();

        c.uuid = uuid
        const replacer = (key, value) => {
            if (typeof value === 'undefined')
                return ""
            else return value;
        }
        var chatInfos = JSON.parse(JSON.stringify(c, replacer));

        dispatch(chatActions.beginChat(chatInfos))
    }, [])

    useEffect(() => {
        if (c) {
            setChat(c)
        }
    }, [c])

    useEffect(() => {
        setDonec(dc)
    }, [dc])

    const replacer = (key, value) => {
        if (typeof value === 'undefined')
            return ""
        else return value;
    }

    useEffect(() => {
        // console.log("chat1 : " + chat);
        // console.log("chat1c : " + c);
        if (entry) {
            // console.log("chat2 : " + chat);
            // console.log("chat2c : " + c);
            if (dc) {
                // console.log("chat3 : " + chat);
                // console.log("chat3c : " + c);
                if (c) {
                    // console.log("chat4 : " + chat);
                    // console.log("chat4c : " + c);
                    if (c && c.cuid && c.uuid) {
                        // console.log("chat6 : " + chat);
                        // console.log("chat6c : " + c);
                        var message = new MessageClass();

                        message.text = "";
                        message.cuid = c.cuid
                        message.uuid = uuid

                        var messageInfos = JSON.parse(JSON.stringify(message, replacer));

                        dispatch(messageActions.getMessages(messageInfos));
                        setEntry(false)
                        setBeginMessaging(true)
                        $('#' + profilViewid).animate({ scrollTop: 99999999999 }, 3000);

                    }
                }
            }
        }
    }, [dc, c])


    useEffect(() => {
        // console.log("done : " + done)
        // console.log("error : " + error)
        if (messaging) {
            // if (wsActive) {
            if (d) {
                if (e !== undefined) {
                    // console.log("done11 : " + done)
                    // console.log("error11 : " + error)
                    setMessaging(false)
                    // Toast.fire({
                    //     icon: "error",
                    //     title: "An error has occurred. Please try again ..."
                    // });
                } else {
                    // console.log("done21 : " + done)
                    // console.log("error21 : " + error)
                    setMessaging(false)
                    // setInputText("")
                    // commentList.comments.push(comment)
                    // Toast.fire({
                    //     icon: "success",
                    //     title: "You have just commented"
                    // });                    
                }
            }
            // }
        }
    }, [d]);

    const readMessage = (m) => {
        if (authenticated) {
            if ((m && m.muid) && (chat && chat.cuid)) {
                var message = new MessageClass();

                message.muid = m.muid;
                message.cuid = chat?.cuid
                message.uuid = uuid
                message.reading = true

                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var messageInfos = JSON.parse(JSON.stringify(message, replacer));

                setMessaging(true)
                dispatch(messageActions.writeMessage(messageInfos));
            }
        }
    }

    client.onConnect = () => {
        // console.log("connect2 c " + c)
        var sub = client.subscribe('/topic/' + chat?.cuid, msg => {// OnReceived
            // console.log(msg.body)
            if (msg.body) {
                // alert('got message with body ' + message.body);
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                // console.log(`Received: ${msg.body}`)
                try {
                    var message = JSON.parse(msg.body, replacer);
                    if (message.writing) {
                        if (message.text) {
                            // console.log(message)
                            if (!message.suspended && !message.banned) {
                                setOtherUsersTyping((prev) => {
                                    const updated = new Set(prev);
                                    if ((user) && (user.uuid != message.createdby.uuid)) {
                                        updated.delete(message.createdby.uuid);
                                    } else if (!user) {
                                        updated.delete(message.createdby.uuid);
                                    }
                                    return updated;
                                });

                                // console.log("push comment to view")
                                // setInputText("")
                                setOpen(false)
                                // setAddingComment(true)                        
                                // console.log("Before : " + messageList)
                                // commentList.push(comment)
                                setMessageList((messages) => {
                                    const updated = messages;

                                    updated.push(message)

                                    return updated;
                                });

                                // confirming to read a message
                                if (user.uuid !== message.createdby.uuid) {
                                    readMessage(message)
                                }

                                if (isScrollAtBottom()) {
                                    scrollToBottom();
                                }
                            } else {
                                if (user.uuid == message.createdby.uuid) {
                                    Toast.fire({
                                        icon: "warning",
                                        title: "Sorry, your sharing, commenting, and messaging are temporarily restricted."
                                    });
                                }
                            }
                            // console.log("After : " + messageList)
                        }
                    } else if (message.editing) {
                        if (message.text) {
                            if (!message.suspended && !message.banned) {
                                var message = JSON.parse(msg.body, replacer);

                                // setInputText("")
                                setMessageForEdit(null)
                                setOpenEditmode(false)

                                setMessageList((messages) => {
                                    const updated = messages.map(item => {
                                        if (item.muid === message.muid) {
                                            return { ...item, text: message.text, edited: message.edited };
                                        }
                                        return item;
                                    });

                                    return updated;
                                });

                                if (isScrollAtBottom()) {
                                    scrollToBottom();
                                }
                            } else {
                                if (user.uuid == message.createdby.uuid) {
                                    Toast.fire({
                                        icon: "warning",
                                        title: "Sorry, your sharing, commenting, and messaging are temporarily restricted."
                                    });
                                }
                            }
                        }
                    } else if (message.deleting) {
                        var message = JSON.parse(msg.body, replacer);

                        setMessageList((messages) => {
                            var updated = messages;

                            updated = updated.filter((item) => {
                                return item.muid != message.muid;
                            })

                            return updated
                        });

                        if (isScrollAtBottom()) {
                            scrollToBottom();
                        }
                    } else if (message.typing) {
                        // console.log(message.user)
                        const { typing, uuid } = JSON.parse(msg.body);

                        setOtherUsersTyping((prev) => {
                            const updated = new Set(prev);
                            if (typing && user && (uuid != user.uuid)) {
                                updated.add(uuid);
                                if (isScrollAtBottom()) {
                                    scrollToBottom();
                                }
                            } else if (!user && typing) {
                                updated.add(uuid);
                                if (isScrollAtBottom()) {
                                    scrollToBottom();
                                }
                            }
                            return updated;
                        });
                    } else if (!message.typing) {
                        const { typing, uuid } = JSON.parse(msg.body);

                        setOtherUsersTyping((prev) => {
                            const updated = new Set(prev);
                            if (!typing && user && (uuid != user.uuid)) {
                                updated.delete(uuid);
                            } else if (!user && !typing) {
                                updated.delete(uuid);
                            }
                            return updated;
                        });
                    }
                } catch (e) {
                    console.log("Not a comment")
                }
            } else {
                alert('got empty message');
            }
        });
        // client.publish({ destination: '/app/' + chat?.cuid, body: 'First Message Hola' });
        // client.publish({ destination: '/topic/' + chat?.cuid, body: 'First Message' });
        setSubscription(sub)
    }

    client.activate();

    const sendMessage = (e) => {
        e.preventDefault();
        client.send("/app/servicespace/sendMessage", {}, JSON.stringify({ content: 'message' }));
    };

    // useEffect(() => {
    //     if (dc) {
    //         if (d) {
    //             setBeginMessaging(true)
    //         }
    //     }
    // }, [beginMessaging])

    useEffect(() => {
        // Retrieve all comments
        if (beginMessaging) {
            if (dc) {
                if (d) {
                    setMessageList(m)
                    setBeginMessaging(false)
                    setLoadingMessage(false)
                }
            }
        }
    }, [dc, d])

    const sendStopTyping = () => {
        if (authenticated) {
            client.publish({ destination: '/topic/' + chat?.cuid, body: JSON.stringify({ typing: false, uuid: user.uuid }) });
        }
    };

    const startTyping = () => {
        // console.log("Typing")
        // console.log(chat?.cuid)
        if (authenticated) {
            // console.log(chat?.cuid)
            // Reset the timeout every time the user types
            clearTimeout(typingTimeoutId.current);

            // Send "typing" status over WebSocket with the user's UUID
            client.publish({ destination: '/topic/' + chat?.cuid, body: JSON.stringify({ typing: true, uuid: user.uuid }) });

            // Set a timeout to send "stop typing" after a period of inactivity
            typingTimeoutId.current = setTimeout(sendStopTyping, 5000);
        }
    };

    useEffect(() => {
        // Clean up the timeout on component unmount
        return () => clearTimeout(typingTimeoutId.current);
    }, []);

    function isScrollAtBottom() {
        const messagesContainer = $('#' + profilViewid);
        const scrollPosition = messagesContainer.scrollTop() + messagesContainer.innerHeight();
        const totalHeight = messagesContainer[0].scrollHeight;

        // Check if the scroll is at the bottom (with a small threshold)
        return scrollPosition + 5 >= totalHeight;
    }

    function scrollToBottom() {
        // const messagesContainer = $('#' + commentViewid);
        $('#' + profilViewid).animate({ scrollTop: 9999 }, 500);
    }

    const isSomeoneTyping = otherUsersTyping.size > 0;

    // console.log(messageList)

    return (
        <Container>
            <div style={{ display: 'block' }}>
                <div style={styleMobLarge1} class="p-3 bg-primary bg-gradient text-white draggable">
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={0}
                        style={{ display: 'flex' }}
                    >
                        <Item style={{ padding: 0, cursor: 'pointer' }} /*onClick={() => handleOpenProfilPopup()}*/><Avatar alt={displayname ? displayname : (name ? firstname + " " + name : firstname)} src={pictureUrl} /></Item>
                        <Item style={{ cursor: 'pointer' }} /*onClick={() => handleOpenProfilPopup()}*/>{displayname ? truncateFullName(displayname) : (name ? truncateFullName(firstname + " " + name) : truncateFullName(firstname))}</Item>
                    </Stack>
                    {/* <a target="_blank" className="white" style={{ color: "#fff", textDecoration: "none" }} href={"website"}>{space}</a> */}
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '0.5rem' }}>
                        {/* <EditIcon className="notDraggable text-white" style={{ cursor: 'pointer' }} onClick={e => setLayoutPreview(2)} /> */}
                        <InfoIcon className="notDraggable text-white" onClick={handleOpenInfoPopup} style={{ cursor: "pointer" }} />
                        {/* <DeleteIcon className="notDraggable text-white" style={{ cursor: 'pointer' }} onClick={e => handleOpenDeletePopup()} /> */}
                    </div>
                </div>

                <DraggableInfoPopup openInfoPopup={openInfoPopup} handleCloseInfoPopup={handleCloseInfoPopup} info={"chat"} />

                <Card sx={styleMobLarge2}>
                    <div className={classes.container}>
                        <Paperx className={classes.paper} zDepth={2}>
                            {loadingMessage ?
                                <Paperx ref={chatRef} id={profilViewid} className={classes.messagesBody}>
                                    <SpinnerLoading show={loadingMessage} theme={theme} />
                                </Paperx>
                                :
                                <Paperx ref={chatRef} id={profilViewid} className={classes.messagesBody}>
                                    {messageList?.length == 0 ?
                                        <>
                                            <BeginText show={true} open={open} text={"Begin to Chat"} theme={theme} />
                                        </>
                                        :
                                        <>
                                            {messageList?.map((d, idx) => (
                                                <>
                                                    {(user?.uuid != d.createdby?.uuid) &&
                                                        <MessageLeft
                                                            authenticated={authenticated}
                                                            key={idx}
                                                            cuid={chat?.cuid} // chat uid
                                                            uuid={d?.createdby?.uuid}
                                                            message={d?.text}
                                                            timestamp={d?.timeago}
                                                            photoURL={d?.createdby?.pictureUrl}
                                                            // displayName={truncateFullName(d?.createdby?.firstname + " " + d?.createdby?.name)}
                                                            displayName={""}
                                                            avatarDisp={true}
                                                            createdby={d.createdby}
                                                            // setBalanceOnStar={setBalanceOnStar}
                                                            // starStyle={starStyle}
                                                            commentid={"messagechat" + idx}
                                                            chat={true}
                                                            edited={d?.edited}
                                                        />}
                                                    {(user?.uuid == d.createdby?.uuid) &&
                                                        <MessageRight
                                                            authenticated={authenticated}
                                                            key={idx}
                                                            messageEdit={d}
                                                            muid={d?.muid}
                                                            uuid={d?.createdby?.uuid}
                                                            cuid={chat?.cuid} // chat uid
                                                            user={user}
                                                            message={d?.text}
                                                            timestamp={d?.timeago}
                                                            photoURL={d?.createdby?.pictureUrl}
                                                            displayName={truncateFullName(d?.createdby?.firstname + " " + d?.createdby?.name)}
                                                            avatarDisp={true}
                                                            commentid={"messagechat" + idx}
                                                            setOpenEditmode={setOpenEditmode}
                                                            setMessageForEdit={setMessageForEdit}
                                                            editbuttonid={"editmessagebutton" + idx}
                                                            chat={true}
                                                            edited={d?.edited}
                                                        />}
                                                </>
                                            ))}
                                        </>
                                    }

                                    {isSomeoneTyping ? <MessageBlank
                                        message={""}
                                        timestamp={""}
                                        photoURL={""}
                                        displayName={""}
                                        avatarDisp={true}
                                    /> : null}
                                </Paperx>}

                            <TextInput
                                authenticated={authenticated}
                                setLayoutPreview={setLayoutPreview}
                                chat={c}
                                uuid={uuid}
                                urlparam={urlparam}
                                resetButtonid={resetButtonid}
                                messageList={messageList}
                                handleOpenSignInPopup={handleOpenSignInPopup}
                                profilViewid={profilViewid}
                                // inputText={inputText}
                                // setInputText={setInputText}
                                startTyping={startTyping}
                                isScrollAtBottom={isScrollAtBottom}
                                scrollToBottom={scrollToBottom}
                                sendStopTyping={sendStopTyping}
                                subscription={subscription}
                                open={open}
                                setOpen={setOpen}
                                openEditmode={openEditmode}
                                setOpenEditmode={setOpenEditmode}
                                messageForEdit={messageForEdit}
                                setReadMessage={setReadMessage}
                            // setBalanceOnLumen={setBalanceOnLumen}
                            // setBalanceOnNun={setBalanceOnNun}
                            // lumenStyle={lumenStyle}
                            // nunStyle={nunStyle}
                            />
                        </Paperx>
                    </div>
                </Card>
            </div>
        </Container>
    );
}


const TextInput = ({
    setLayoutPreview,
    urlparam,
    resetButtonid,
    messageList,
    chat,
    uuid,
    handleOpenSignInPopup,
    authenticated,
    startTyping,
    sendStopTyping,
    isScrollAtBottom,
    scrollToBottom,
    subscription,
    open,
    setOpen,
    openEditmode,
    setOpenEditmode,
    messageForEdit,
    setReadMessage
    // setBalanceOnLumen,
    // setBalanceOnNun,
    // lumenStyle,
    // nunStyle
}) => {


    const dispatch = useDispatch();
    const message = useSelector(state => state.message.message);
    const e = useSelector(state => state.message.error);
    const [error, setError] = useState(e);
    const d = useSelector(state => state.message.done);
    const [done, setDone] = useState(d);
    const [messaging, setMessaging] = useState(false)
    const [wsActive, setWsActive] = useState(client.active)

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    const [inputText, setInputText] = useState("");
    const valueRef = useRef('')

    useEffect(() => {
        setTheme(t);
    }, [t])

    useEffect(() => {
        // console.log("done : " + done)
        // console.log("error : " + error)
        if (messaging) {
            // if (wsActive) {
            if (d) {
                if (e !== undefined) {
                    // console.log("done11 : " + done)
                    // console.log("error11 : " + error)
                    setMessaging(false)
                    Toast.fire({
                        icon: "error",
                        title: "An error has occurred. Please try again ..."
                    });
                } else {
                    // console.log("done21 : " + done)
                    // console.log("error21 : " + error)
                    setMessaging(false)
                    // setInputText("")
                    valueRef.current.value = ""
                    // commentList.comments.push(comment)
                    // Toast.fire({
                    //     icon: "success",
                    //     title: "You have just commented"
                    // });                    
                }
            }
            // }
        }
    }, [d]);

    // useEffect(() => {
    //     setWsActive(client.active)
    // }, [wsActive]);

    useEffect(() => {
        if (openEditmode) {
            if (messageForEdit) {
                valueRef.current.value = messageForEdit?.text
            }
        } else {

        }
    }, [openEditmode, messageForEdit])


    useEffect(() => {
        if (open) {
            valueRef.current.value = ""
        } else {

        }
    }, [open])

    const writeMessage = (e) => {
        if (authenticated) {
            if (chat && chat.cuid) {
                var input = valueRef.current.value;

                if (!isOnlyWhitespace(input)) {
                    var message = new MessageClass();

                    message.text = input;
                    message.cuid = chat?.cuid
                    message.uuid = uuid
                    message.writing = true

                    const replacer = (key, value) => {
                        if (typeof value === 'undefined')
                            return ""
                        else return value;
                    }
                    var messageInfos = JSON.parse(JSON.stringify(message, replacer));

                    setMessaging(true)
                    dispatch(messageActions.writeMessage(messageInfos));
                }
            }
        } else {
            handleOpenSignInPopup()
        }
    }

    const editMessage = (e) => {
        if (authenticated) {
            if ((messageForEdit && messageForEdit.muid) && (chat && chat.cuid)) {
                var input = valueRef.current.value;

                if (!isOnlyWhitespace(input)) {
                    var message = new MessageClass();

                    message.text = input;
                    message.muid = messageForEdit?.muid;
                    message.cuid = chat?.cuid
                    message.uuid = uuid
                    message.editing = true

                    const replacer = (key, value) => {
                        if (typeof value === 'undefined')
                            return ""
                        else return value;
                    }
                    var messageInfos = JSON.parse(JSON.stringify(message, replacer));

                    setMessaging(true)
                    dispatch(messageActions.writeMessage(messageInfos));
                }
            }
        } else {
            handleOpenSignInPopup()
        }
    }

    const handleChange = e => {
        if (e.target.id === "chat") {
            setInputText(e.target.value);
        }
    };

    const handleNewMessage = () => {
        setOpen((prev) => !prev);
        if (isScrollAtBottom()) {
            scrollToBottom();
        }
    };

    const handleCloseEdit = () => {
        setOpenEditmode(false);
    }

    const handleTyping = () => {
        const typing = { text: 'typing' }
        const body = JSON.stringify(typing)
        client.publish({ destination: '/topic/' + chat?.cuid, body: body });
    };

    const closeChat = () => {
        if (authenticated) {
            sendStopTyping();
        }
        if (subscription) {
            subscription.unsubscribe();
        }
        if (client && client.connected) {
            client.deactivate();
        }

        dispatch(messageActions.checkReadMessage())
        setReadMessage(true)
        setLayoutPreview(1)
    }

    return (
        <Container style={{ paddingRight: 0, paddingLeft: 0, width: '100%', color: '#fff' }}>
            <Zoom in={open || openEditmode} style={{ display: open || openEditmode ? 'block' : 'none', transitionDelay: open || openEditmode ? '500ms' : '0ms' }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0}
                    p={2}
                >
                    {/* <TextField
                    id="standard-text"
                    label="Add New Comment"
                    className={classes.wrapText}
                    multiline
                    rows={1}
                    maxRows={Infinity}
                //margin="normal"
                /> */}
                    <Item style={{ width: '100%' }}>
                        <TextField
                            fullWidth
                            id="chat"
                            multiline
                            rows={5}
                            placeholder={openEditmode ? "Edit a message" : "Write a message..."}
                            sx={styleTextField}
                            InputProps={{
                                style: { color: theme === 'dark' ? '#fff' : '#000' }, // light
                            }}
                            // value={inputText}
                            // onChange={handleChange}
                            defaultValue={openEditmode ? messageForEdit?.text : ""}
                            inputRef={valueRef}
                            onKeyUp={startTyping}
                        // error={inputText.length > characterLimit}
                        // helperText={inputText.length > characterLimit ? "" : ""}
                        /></Item>
                    <Item>
                        <Button size="small" variant="contained" style={{ borderRadius: 20, minWidth: 0, padding: 5 }} onClick={openEditmode ? editMessage : writeMessage}>
                            <SendIcon />
                        </Button></Item>
                </Stack>
            </Zoom>
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                p={1}
            >
                <Item>
                    <div style={{ background: "#1976d2", borderRadius: 30, width: 120, display: 'inline-block', marginBottom: 15 }}>
                        <IconButton onClick={openEditmode && !open ? handleCloseEdit : handleNewMessage} style={{ /*marginTop: '-5px',*/ background: "#1976d2", color: "#fff", transform: open ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.3s' }}>
                            {openEditmode && !open ? <CancelIcon fontSize="large" /> : <ArrowCircleUpIcon fontSize="large" />}
                        </IconButton>
                        <span onClick={openEditmode && !open ? handleCloseEdit : handleNewMessage} style={{ cursor: "pointer", fontSize: '1.2em', paddingRight: 15, textTransform: 'uppercase', fontWeight: 'bold' }}>{openEditmode && !open ? "Edit" : "New"}</span>
                    </div>
                </Item>
            </Stack>
            {!open && !openEditmode ? <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
            >
                <Item style={{ margin: 20 }}>
                    <Button id={resetButtonid} size="large" variant="contained" style={{ borderRadius: 20, minWidth: 0, padding: 5 }} onClick={closeChat}><ArrowCircleLeftIcon /></Button>
                </Item>
            </Stack> : null}
        </Container>
    )
}

const useReportStyles = makeStyles(theme => ({
    paper: {
        width: 300,
        height: 500,

        '@media (min-width: 600px) and (max-width: 800px)': {
            width: 300,
            height: 500
        },

        '@media (min-width: 800px)': {
            width: 300,
            height: 500
        }
    },
    customizedButton: {
        backgroundColor: '#007bff',
        color: '#fff',
        float: 'right',
    },
}));

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export const DraggableReportPopup = ({ openShowReportPopup, handleCloseShowReportPopup, handleReportSubmit, handleChange }) => {
    const classes = useReportStyles();
    const dispatch = useDispatch();

    const changeUrl = (url) => {
        window.open(url)
    }

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    return (
        <div>
            <Dialog
                open={openShowReportPopup}
                onClose={handleCloseShowReportPopup}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.paper }}
                style={{ zIndex: 10000000000 }}
            >
                <DialogTitle style={{ cursor: 'move', color: theme === 'dark' ? '#fff' : '#000', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} id="draggable-dialog-title">
                    <span>What issue would you like to report ?</span>
                </DialogTitle>
                <DialogContent style={{ padding: 0, overflowY: 'auto', }}>
                    <DialogContentText style={{ backgroundColor: theme === 'dark' ? '#0D1F2D' : '#fff', color: theme === 'dark' ? '#fff' : '#000', marginBottom: 0 }}>
                        <ReportIssue handleChange={handleChange} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ color: '#fff', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} > {/* light */}
                    <Button onClick={handleCloseShowReportPopup} variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={handleReportSubmit} variant="contained">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default function ReportIssue({ handleChange }) {
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    return (
        <FormControl style={{ color: "#fff", padding: 20 }}>
            {/* <FormLabel id="demo-radio-buttons-group-label" style={{ color: theme == 'dark' ? "#fff" : "#000" }}>What issue would you like to report?</FormLabel> */}
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="issue"
                onChange={handleChange}
            >
                <FormControlLabel style={{ color: theme == 'dark' ? "#fff" : "#000", marginBottom: 15 }} value={1} control={<Radio sx={{ color: theme == 'dark' ? '#fff' : '#000' }} />} label="Hate: Slurs, stereotypes, dehumanization, discrimination incitement" />
                <FormControlLabel style={{ color: theme == 'dark' ? "#fff" : "#000", marginBottom: 15 }} value={2} control={<Radio sx={{ color: theme == 'dark' ? '#fff' : '#000' }} />} label="Abuse & Harassment: Insults, unwanted sexual or NSFW content, violence denial, targeted harassment" />
                <FormControlLabel style={{ color: theme == 'dark' ? "#fff" : "#000", marginBottom: 15 }} value={3} control={<Radio sx={{ color: theme == 'dark' ? '#fff' : '#000' }} />} label="Violent Speech: Threats, harm wishes, violence glorification or incitement" />
                <FormControlLabel style={{ color: theme == 'dark' ? "#fff" : "#000", marginBottom: 15 }} value={4} control={<Radio sx={{ color: theme == 'dark' ? '#fff' : '#000' }} />} label="Child Safety: Exploitation, grooming, abuse, underage users" />
                <FormControlLabel style={{ color: theme == 'dark' ? "#fff" : "#000", marginBottom: 15 }} value={5} control={<Radio sx={{ color: theme == 'dark' ? '#fff' : '#000' }} />} label="Privacy: Exposing private info, sharing non-consensual images" />
                <FormControlLabel style={{ color: theme == 'dark' ? "#fff" : "#000", marginBottom: 15 }} value={6} control={<Radio sx={{ color: theme == 'dark' ? '#fff' : '#000' }} />} label="Spam: Fake accounts, scams, malicious links, hashtag misuse, repetitive content" />
                <FormControlLabel style={{ color: theme == 'dark' ? "#fff" : "#000", marginBottom: 15 }} value={7} control={<Radio sx={{ color: theme == 'dark' ? '#fff' : '#000' }} />} label="Self-Harm: Encouragement, instructions, or strategies for suicide" />
                <FormControlLabel style={{ color: theme == 'dark' ? "#fff" : "#000", marginBottom: 15 }} value={8} control={<Radio sx={{ color: theme == 'dark' ? '#fff' : '#000' }} />} label="Disturbing Media: Graphic content, excessive gore, adult nudity, violent conduct" />
                <FormControlLabel style={{ color: theme == 'dark' ? "#fff" : "#000", marginBottom: 15 }} value={9} control={<Radio sx={{ color: theme == 'dark' ? '#fff' : '#000' }} />} label="Deceptive Identities: Impersonation, non-compliant parodies" />
                <FormControlLabel style={{ color: theme == 'dark' ? "#fff" : "#000" }} value={10} control={<Radio sx={{ color: theme == 'dark' ? '#fff' : '#000' }} />} label="Violent & Hateful Entities: Extremism, terrorism, hate groups" />
            </RadioGroup>
        </FormControl>
    );
}

export const ProfilCard = ({
    data,
    layoutPreview,
    authenticated,
    setLayoutPreview,
    layoutOnChat,
    setLayoutOnChat,
    user,
    uuid, // receiver
    name,
    firstname,
    pictureUrl,
    mytwitterx,
    myreddit,
    mytelegram,
    mylinkedin,
    mygithub,
    myyoutube,
    mytwitch,
    myinstagram,
    myfacebook,
    mytiktok,
    star,
    stars,
    lumens,
    atname,
    displayname,
    description,
    blocked,
    reported,
    access,
    resetButtonid,
    profilViewid,
    switchToMessage,
    age,
    showage
}) => {
    const { height, width } = useWindowDimensions();
    const dispatch = useDispatch();
    // console.log(data)

    var styleMobLarge1;
    var styleMobLarge2;
    if (width > 767) {
        styleMobLarge1 = { maxwidth: '80vw', height: 60, borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { maxWidth: '100vw', backgroundColor: '#002147', borderRadius: 20 }
    } else {
        styleMobLarge1 = { width: '80vw', height: 60, borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { width: '80vw', backgroundColor: '#002147', borderRadius: 20 }
    }

    var styleMobLarge3;
    var styleMobLarge4;
    var styleAvatar;
    if (width >= 600) {
        styleMobLarge3 = { maxwidth: '80vw', height: 60, borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge4 = { width: 640, height: 320, backgroundColor: '#166fe5' }
        styleAvatar = { width: 90, height: 90 }
    } else {
        styleMobLarge3 = { width: '80vw', height: 60, borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge4 = { width: 640, height: 320, backgroundColor: '#166fe5' }
        styleAvatar = { width: 90, height: 90 }
    }

    const [blockStyle, setBlockStyle] = useState({ color: '#fff' })
    const [blockedUser, setBlockedUser] = useState(blocked)
    const [isReadMoreShown, setReadMoreShown] = useState(false)

    const [openSignInPopup, setOpenSignInPopup] = useState(false);
    const handleOpenSignInPopup = (e) => {
        setOpenSignInPopup(true);
    }
    const handleCloseSignInPopup = () => {
        setOpenSignInPopup(false);
    }

    const [openShowReportPopup, setOpenShowReportPopup] = useState(false);
    const handleOpenReportPopup = (e) => {
        setOpenShowReportPopup(true);
    }
    const handleCloseShowReportPopup = () => {
        setOpenShowReportPopup(false);
    }

    const e = useSelector(state => state.report.error);
    const [error, setError] = useState(e);
    const d = useSelector(state => state.report.done);
    const [done, setDone] = useState(d);

    const [reportStyle, setReportStyle] = useState({ color: '#fff' })
    const [reportedUser, setReportedUser] = useState(reported)
    const [reporting, setReporting] = useState(false)

    const [issue, setIssue] = useState("")
    const [entry, setEntry] = useState(true)

    const [onStar, setOnStar] = useState(false)
    const [starStyle, setStarStyle] = useState({ color: '#fff' })

    const setBalanceOnStar = (e) => {
        if (authenticated) {
            if (!onStar) {
                setStarStyle({ color: '#fff' })
                setStarStyle({ color: '#ffff00' })

                var giving = new GivingClass()
                giving.user = uuid;
                giving.star = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));

                setOnStar(true)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            } else {
                setStarStyle({ color: '#ffff00' })
                setStarStyle({ color: '#fff' })

                var giving = new GivingClass()
                giving.user = uuid;
                giving.resetStar = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));

                setOnStar(false)
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            }
        } else {
            // handleOpenSignInPopup()
        }
    }

    const blockUser = () => {
        // console.log("BlockUser")
        if (authenticated) {
            if (!blockedUser) {
                console.log("Block")
                setBlockedUser(true)
                setBlockStyle({ color: '#ff0000' })

                var blockchat = new BlockChatClass()
                blockchat.uuid = uuid
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var blockChatInfos = JSON.parse(JSON.stringify(blockchat, replacer));

                dispatch(chatActions.blockChat(blockChatInfos))
            } else {
                // console.log("Unblock")
                setBlockedUser(false)
                setBlockStyle({ color: '#fff' })

                var blockchat = new BlockChatClass()
                blockchat.uuid = uuid
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var blockChatInfos = JSON.parse(JSON.stringify(blockchat, replacer));

                dispatch(chatActions.unblockChat(blockChatInfos))
            }
        } else {
            handleOpenSignInPopup()
        }
    }

    const report = () => {
        handleOpenReportPopup()
    }

    const handleChange = (e) => {
        if (e.target.type === "radio") {
            setIssue(e.target.value);
        }
    }

    const handleReportSubmit = () => {

        if (authenticated) {
            if (!reportedUser) {
                if (issue) {
                    var report = new ReportClass()
                    report.uuid = uuid
                    report.issue = issue
                    const replacer = (key, value) => {
                        if (typeof value === 'undefined')
                            return ""
                        else return value;
                    }
                    var reportInfos = JSON.parse(JSON.stringify(report, replacer));

                    setReporting(true)
                    setReportedUser(true)
                    setReportStyle({ color: '#ff0000' })
                    handleCloseShowReportPopup()
                    dispatch(reportActions.submitReport(reportInfos))
                } else {
                    Toast.fire({
                        icon: "warning",
                        title: "Select one issue about the report"
                    });
                }
            }

        } else {
            handleOpenSignInPopup()
        }
    }

    useEffect(() => {
        if (blockedUser) {
            setBlockedUser(true)
            setBlockStyle({ color: '#ff0000' })
        } else {
            setBlockedUser(false)
            setBlockStyle({ color: '#fff' })
        }
    }, [])

    useEffect(() => {
        if (reportedUser) {
            setReportedUser(true)
            setReportStyle({ color: '#ff0000' })
        } else {
            setReportedUser(false)
            setReportStyle({ color: '#fff' })
        }
    }, [])

    useEffect(() => {
        if (reporting) {
            if (d) {
                if (e !== undefined) {
                    setReporting(false)
                    Toast.fire({
                        icon: "error",
                        title: "An error has occurred. Please try again ..."
                    });
                } else {
                    setReporting(false)
                    Toast.fire({
                        icon: "success",
                        title: "Thank you for Reporting !"
                    });
                }
            }
        }
    }, [d]);

    useEffect(() => {
        if (entry) {
            if (star) {
                setOnStar(true)
                setStarStyle({ color: '#ffff00' })
            }
        }
        setEntry(false)
    }, [])

    var displaynameAge = displayname ?
        truncateFullName(displayname)
        : (name ?
            truncateFullName(firstname + " " + name)
            :
            truncateFullName(firstname))

    return (
        <Card sx={styleMobLarge4} id={profilViewid}>
            <Container style={{ padding: 0 }}>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                    p={2}
                >
                    <Item>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={0}
                            style={{ display: 'flex' }}
                        >
                            <Item style={{ padding: 0 }} /*onClick={() => handleOpenProfilPopup()}*/><Avatar alt={displaynameAge} src={pictureUrl} sx={styleAvatar} /></Item>
                            <Item style={{}} /*onClick={() => handleOpenProfilPopup()}*/>{displaynameAge}</Item>
                        </Stack>
                    </Item>
                    <Item><p style={{ marginBottom: 0 }}>{atname ? "@" + atname : ""}</p></Item>
                    <Item>
                        <p style={{ marginBottom: 0, fontSize: '1.5em' }}>
                            OneLike Space
                        </p>
                    </Item>
                    <Item style={{ marginTop: 10 }}>
                        <p style={{ marginBottom: 16 }}>
                            {!isOnlyWhitespace(mytwitterx) ? <><span><XIcon style={{ marginBottom: 3, color: "#fff" }} /></span>{" "}</> : null}
                            {!isOnlyWhitespace(myreddit) ? <><span><RedditIcon style={{ marginBottom: 3, color: "#fff" }} /></span>{" "}</> : null}
                            {!isOnlyWhitespace(mytelegram) ? <><span><TelegramIcon style={{ marginBottom: 3, color: "#fff" }} /></span>{" "}</> : null}
                            {!isOnlyWhitespace(mylinkedin) ? <><span><LinkedInIcon style={{ marginBottom: 3, color: "#fff" }} /></span>{" "}</> : null}
                            {!isOnlyWhitespace(mygithub) ? <><span><GitHubIcon style={{ marginBottom: 3, color: "#fff" }} /></span>{" "}</> : null}
                            {!isOnlyWhitespace(myyoutube) ? <><span><YouTubeIcon style={{ marginBottom: 3, color: "#fff" }} /></span>{" "}</> : null}
                            {!isOnlyWhitespace(mytwitch) ? <><span><TwitchIcon style={{ marginBottom: 3, color: "#fff" }} color={"#fff"} /></span>{" "}</> : null}
                            {!isOnlyWhitespace(myinstagram) ? <><span><InstagramIcon style={{ marginBottom: 3, color: "#fff" }} /></span>{" "}</> : null}
                            {!isOnlyWhitespace(myfacebook) ? <><span><FacebookIcon style={{ marginBottom: 3, color: "#fff" }} /></span>{" "}</> : null}
                            {!isOnlyWhitespace(mytiktok) ? <><span><TikTokIcon style={{ marginBottom: 3, color: "#fff" }} color={"#fff"} /></span></> : null}
                        </p>
                    </Item>
                </Stack>
            </Container>
        </Card>
    );
}

export function Moderation({ uuid }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();

    const dm = useSelector(state => state.moderation.done);
    const em = useSelector(state => state.moderation.error);

    const [sending, setSending] = useState(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const suspendUser = () => {
        var moderation = new ModerationUserClass()
        moderation.uuid = uuid
        moderation.action = "suspend"
        const replacer = (key, value) => {
            if (typeof value === 'undefined')
                return ""
            else return value;
        }
        var moderationInfos = JSON.parse(JSON.stringify(moderation, replacer));

        setSending(true)
        dispatch(userActions.moderation(moderationInfos))
        handleClose()
    }

    const banUser = () => {
        var moderation = new ModerationUserClass()
        moderation.uuid = uuid
        moderation.action = "ban"
        const replacer = (key, value) => {
            if (typeof value === 'undefined')
                return ""
            else return value;
        }
        var moderationInfos = JSON.parse(JSON.stringify(moderation, replacer));

        setSending(true)
        dispatch(userActions.moderation(moderationInfos))
        handleClose()
    }

    const unsuspendUser = () => {
        var moderation = new ModerationUserClass()
        moderation.uuid = uuid
        moderation.action = "unsuspend"
        const replacer = (key, value) => {
            if (typeof value === 'undefined')
                return ""
            else return value;
        }
        var moderationInfos = JSON.parse(JSON.stringify(moderation, replacer));

        setSending(true)
        dispatch(userActions.moderation(moderationInfos))
        handleClose()
    }

    const unbanUser = () => {
        var moderation = new ModerationUserClass()
        moderation.uuid = uuid
        moderation.action = "unban"
        const replacer = (key, value) => {
            if (typeof value === 'undefined')
                return ""
            else return value;
        }
        var moderationInfos = JSON.parse(JSON.stringify(moderation, replacer));

        setSending(true)
        dispatch(userActions.moderation(moderationInfos))
        handleClose()
    }

    useEffect(() => {
        if (sending) {
            if (dm) {
                if (em !== undefined) {
                    setSending(false)
                    Toast.fire({
                        icon: "error",
                        title: "An error has occurred. Please try again ..."
                    });
                } else {
                    setSending(false)
                    Toast.fire({
                        icon: "success",
                        title: "User Status updated"
                    });
                }
            }
        }
    }, [dm]);

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                size="small"
                variant="contained"
                style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }}
            >
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><ConstructionIcon /></Box>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={suspendUser}>Suspend User</MenuItem>
                <MenuItem onClick={banUser}>Ban User</MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={unsuspendUser}>Unsuspend User</MenuItem>
                <MenuItem onClick={unbanUser}>Unban User</MenuItem>
            </Menu>
        </div>
    );
}