import { pageConstants } from "../constants";


export function page(state = 1, action) {
    switch (action.type) {
        case pageConstants.NEXT:
            return state + 1;
        case pageConstants.PREVIOUS:
            return state - 1;
        case pageConstants.CURRENT:
            // console.log("here")
            // console.log(action.payload)
            return action.payload;
        case pageConstants.RESET:
            return 1;
        default:
            return localStorage.getItem("page") === null ? 1 : Number(localStorage.getItem("page"));
    }
}