import { gdprdataConstants } from '../constants';
import { getErrorMessage } from '../helpers';
import { gdprdataService } from '../services/gdprdata.service';

export const gdprdataActions = {
    getGDPRData,
    saveGDPRData,
};

function getGDPRData() {
    // console.log("in Action getGDPRData")
    return dispatch => {
        dispatch({ type: gdprdataConstants.GET_REQUEST });       
        gdprdataService.getGDPRData()
            .then(res => {
                dispatch({ type: gdprdataConstants.GET_SUCCESS, payload: res })
                dispatch({ type: gdprdataConstants.GET_DONE })
            })
            .catch(error => {
                dispatch({ type: gdprdataConstants.GET_FAILURE, payload: getErrorMessage(error) })
                dispatch({ type: gdprdataConstants.GET_FAILURE_DONE })
            });
    };
}

function saveGDPRData(gdprdata) {
    // console.log(gdprdata)
    return dispatch => {
        dispatch({ type: gdprdataConstants.SAVE_REQUEST });
        gdprdataService.saveGDPRData(gdprdata)
            .then(res => {
                dispatch({ type: gdprdataConstants.SAVE_SUCCESS, payload: res });
            })
            .catch(error => {
                dispatch({ type: gdprdataConstants.SAVE_FAILURE, payload: [getErrorMessage(error), gdprdata] })
            });
    };
}