export class MessageClass {
    constructor() {
        this.id = 0;
        this.muid = "";
        this.text = "";
        this.cuid = "";
        this.uuid = "";
        this.writing = false;
        this.editing = false;
        this.deleting = false;
        this.reading = false;
    }
}