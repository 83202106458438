import { outofscopeConstants } from "../constants";
import { outofscopeService } from "../services";

export const outofscopeActions = {
    moveToOutOfScope,
};

function moveToOutOfScope(outofscope) {

    return dispatch => {
        dispatch({ type: outofscopeConstants.OUTOFSCOPE_SAVE_REQUEST });

        outofscopeService.moveToOutOfScope(outofscope)
            .then(res => res.json())
            .then(data => dispatch({ type: outofscopeConstants.OUTOFSCOPE_SAVE_SUCCESS, payload: [data, outofscope] }))
            .catch(error => dispatch({ type: outofscopeConstants.OUTOFSCOPE_SAVE_FAILURE, payload: [error, outofscope] }));
    };
}