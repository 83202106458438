import { listonespaceswitchConstants, spaceswitchConstants } from "../constants";


export function spaceswitch(state = 0, action) {
    switch (action.type) {
        case spaceswitchConstants.FEED:
            // console.log("request")
            return 0;
        case spaceswitchConstants.ALL_SPACES:
            // console.log("request")
            return 1;
        case spaceswitchConstants.MY_SPACE:
            return 2;
        case spaceswitchConstants.LIGHT:
            return 3;
        case spaceswitchConstants.STAR:
            return 4;
        case spaceswitchConstants.MESSAGE:
            return 5;
        case spaceswitchConstants.FIND:
            return 6;
        default:
            return state;
    }
}

export function listonespaceswitch(state = 0, action) {
    switch (action.type) {
        case listonespaceswitchConstants.SPACE_LIST:
            // console.log("request")
            return 0;
        case listonespaceswitchConstants.ONE_SPACE:
            return 1;
        default:
            return state;
    }
}