import { workerConstants } from "../constants";
import { authHeader, authHeaderWithJSON } from "../helpers";

export const profilService = {
    getStarProfilList,
    getProfilList
};

const COMMUNO = workerConstants.WORKER

function getStarProfilList(query, search) {
    if (search) {
        return fetch(COMMUNO + "mystars?q=" + query,
            {
                method: 'GET',
                headers: authHeader(),
            });
    } else {
        return fetch(COMMUNO + "mystars",
            {
                method: 'GET',
                headers: authHeaderWithJSON(),
            });
    }
}

function getProfilList(profil, query, search) {
    var body = profil;

    if (!search) {
        return fetch(COMMUNO + "profils",
            {
                method: 'POST',
                headers: authHeaderWithJSON(),
                body: body
            });
    } else {
        return fetch(COMMUNO + "profils?q=" + query,
            {
                method: 'POST',
                headers: authHeaderWithJSON(),
                body: body
            });
    }
}