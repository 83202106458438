import { chatConstants } from "../constants";
import { chatService } from "../services";

export const chatActions = {
    beginChat,
    blockChat,
    unblockChat
};

function beginChat(chat) {
    console.log("in Actions in Chat")
    return dispatch => {
        dispatch({ type: chatConstants.GET_REQUEST });

        chatService.beginChat(chat)
            .then(res => res.json())
            .then(data => dispatch({ type: chatConstants.GET_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: chatConstants.GET_FAILURE, payload: error }));
    };
}

function blockChat(chat) {
    console.log("in Actions in Chat")
    return dispatch => {
        dispatch({ type: chatConstants.BLOCK_REQUEST });

        chatService.blockChat(chat)
            .then(res => res.json())
            .then(data => dispatch({ type: chatConstants.BLOCK_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: chatConstants.BLOCK_FAILURE, payload: error }));
    };
}

function unblockChat(chat) {
    console.log("in Actions in Chat")
    return dispatch => {
        dispatch({ type: chatConstants.UNBLOCK_REQUEST });

        chatService.unblockChat(chat)
            .then(res => res.json())
            .then(data => dispatch({ type: chatConstants.UNBLOCK_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: chatConstants.UNBLOCK_FAILURE, payload: error }));
    };
}