export const switchConstants = {
    SOCIAL_MEDIA: 'SOCIALMEDIA',
    PUBLISHERS: 'PUBLISHERS',
    ACCOUNT: 'ACCOUNT',
    HACKED: 'HACKED',
    SPACE: 'SPACE',

    SOCIAL_MEDIA_NR: 0,
    PUBLISHERS_NR: 1,
    ACCOUNT_NR: 2,
    HACKED_NR: 3,
    SPACE_NR: 4
  
};