import { workerConstants } from "../constants";
import { authHeaderWithJSON } from "../helpers";

export const messageService = {
    writeMessage,
    deleteMessage,
    getMessages,
    checkReadMessage
};

const COMMUNO = workerConstants.WORKER

function writeMessage(data) {
    // console.log("in service Message")
    const body = JSON.stringify(data);

    return fetch(COMMUNO + "messaging",
        {
            method: 'POST',
            headers: authHeaderWithJSON(),
            body: body
        });
}

function deleteMessage(data) {
    // console.log("in service comment")
    const body = JSON.stringify(data);

    return fetch(COMMUNO + "deletemessage",
        {
            method: 'POST',
            headers: authHeaderWithJSON(),
            body: body
        });
}

function getMessages(data) {
    // console.log("in service messages")
    const body = JSON.stringify(data);

    return fetch(COMMUNO + "messages",
        {
            method: 'POST',
            headers: authHeaderWithJSON(),
            body: body
        });
}

function checkReadMessage() {
    return fetch(COMMUNO + "checkreadmessage",
        {
            method: 'GET',
            headers: authHeaderWithJSON(),
        });
}