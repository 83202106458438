import { useTabsList } from '@mui/base';
import { quoteConstants } from '../constants';
import { getErrorMessage } from '../helpers/error';
import { socialMediaService, quoteService } from '../services';

export const quoteActions = {
    getQuotes
};

function getQuotes() {
    return dispatch => {
        dispatch({ type: quoteConstants.GET_REQUEST });

        quoteService.getAllQuotes()
            .then(res => res.json())
            .then(data => dispatch({ type: quoteConstants.GET_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: quoteConstants.GET_FAILURE, payload: error }));
    };
}