export const workerConstants = {
    WORKER: 'https://communo.onelike.app/servicespace/',
    // WORKER: 'https://service.onelike.app/servicespace/',
    PUB: 'https://communo.onelike.app/servicepub/',
    // PUB: 'https://service.onelike.app/servicepub/',
    WS_WORKER: 'wss://communo.onelike.app/ws',
    // WS_WORKER: 'wss://service.onelike.app/ws',
    PRE_URL_DISCUSSION: 'https://social.onelike.app/space/discussion/',
    PRE_URL_PROFIL: 'https://social.onelike.app/space/profil/',
    HOMEPAGE: 'https://social.onelike.app',
    SHOW_NEW: '2024-10-15'
};