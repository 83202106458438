import { newsConstants } from "../constants/news.constants";


export function news(state = { loading: false }, action) {
    switch (action.type) {
        case newsConstants.SEARCH_REQUEST:
            // console.log("request")
            return {
                name: "",
                newsList: "",
                loading: true,
                done: false
            };
        case newsConstants.SEARCH_SUCCESS:
            // console.log(action.payload)
            return {
                name: action.payload[0],
                newsList: action.payload[1],
                loading: false,
                done: true
            };
        case newsConstants.SEARCH_CLEAR:
            return {
                name: "",
                newsList: "",
                loading: false,
                done: false
            };
        case newsConstants.SEARCH_FAILURE:
            // console.log(action.payload)
            return {
                error: action.payload[0],
                name: action.payload[1],
                newsList: "",
                loading: false,
                done: true
            };
        case newsConstants.SEARCH_FAILURE_DONE:
            return {
                name: "",
                newsList: "",
                loading: false,
                done: false
            };
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}