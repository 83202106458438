import { workerConstants } from "../constants";
import { authHeaderWithJSON } from "../helpers";

export const outofscopeService = {
    moveToOutOfScope
};

const COMMUNO = workerConstants.WORKER

function moveToOutOfScope(data) {

    const body = JSON.stringify(data);

    return fetch(COMMUNO + "outofscope",
        {
            method: 'POST',
            headers: authHeaderWithJSON(),
            body: body
        });
}