import { messageConstants } from "../constants";
import { messageService } from "../services";

export const messageActions = {
    writeMessage,
    deleteMessage,
    clearMessage,
    getMessages,
    checkReadMessage
};

function getMessages(message) {
    console.log("in Actions in MessageList")
    return dispatch => {
        dispatch({ type: messageConstants.GET_REQUEST });

        messageService.getMessages(message)
            .then(res => res.json())
            .then(data => dispatch({ type: messageConstants.GET_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: messageConstants.GET_FAILURE, payload: error }));
    };
}

function writeMessage(message) {
    console.log("in message actions")
    return dispatch => {
        dispatch({ type: messageConstants.MESSAGE_SAVE_REQUEST });

        messageService.writeMessage(message)
            .then(res => res.json())
            .then(data => dispatch({ type: messageConstants.MESSAGE_SAVE_SUCCESS, payload: [data, message] }))
            .catch(error => dispatch({ type: messageConstants.MESSAGE_SAVE_FAILURE, payload: [error, message] }));
    };
}

function deleteMessage(message) {
    // console.log("in comment actions")
    return dispatch => {
        dispatch({ type: messageConstants.MESSAGE_DELETE_REQUEST });

        messageService.deleteMessage(message)
            .then(res => res.json())
            .then(data => dispatch({ type: messageConstants.MESSAGE_DELETE_SUCCESS, payload: [data, message] }))
            .catch(error => dispatch({ type: messageConstants.MESSAGE_DELETE_FAILURE, payload: [error, message] }));
    };
}

function checkReadMessage(message) {
    // console.log("in comment actions")
    return dispatch => {
        dispatch({ type: messageConstants.MESSAGE_CHECK_READ_REQUEST });

        messageService.checkReadMessage()
            .then(res => res.json())
            .then(data => dispatch({ type: messageConstants.MESSAGE_CHECK_READ_SUCCESS, payload: [data, ""] }))
            .catch(error => dispatch({ type: messageConstants.MESSAGE_CHECK_READ_FAILURE, payload: [error, ""] }));
    };
}

function clearMessage() {
    // history.push('/examensportal/login');
    return { type: messageConstants.MESSAGE_SAVE_CLEAR };
}