import { workerConstants } from "../constants";
import { authHeaderWithJSON } from "../helpers";

export const pinService = {
    pin
};

const COMMUNO = workerConstants.PUB

function pin(data) {

    const body = JSON.stringify(data);

    return fetch(COMMUNO + "pin",
        {
            method: 'POST',
            headers: authHeaderWithJSON(),
            body: body
        });
}