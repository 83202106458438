export const infoConstants = {
    GET_REQUEST: 'INFO_GET_REQUEST',
    GET_SUCCESS: 'INFO_GET_SUCCESS',
    GET_FAILURE: 'INFO_GET_FAILURE',
    GET_FAILURE_DONE: 'INFO_GET_FAILURE_DONE',
};

export const infonewsguideConstants = {
    GET_REQUEST: 'INFO_NEWS_GUIDE_GET_REQUEST',
    GET_SUCCESS: 'INFO_NEWS_GUIDE_GET_SUCCESS',
    GET_FAILURE: 'INFO_NEWS_GUIDE_GET_FAILURE',
    GET_FAILURE_DONE: 'INFO_NEWS_GUIDE_GET_FAILURE_DONE',
};