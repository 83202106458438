export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(sessionStorage.getItem('user'));

    if (user && user.jwt) {
        return { Authorization: `Bearer ${user.jwt}` };
    } else {
        return {};
    }
}

export function authHeaderWithJSON() {
    // return authorization header with jwt token
    let user = JSON.parse(sessionStorage.getItem('user'));

    if (user && user.jwt) {
        return { Authorization: `Bearer ${user.jwt}`, 'Content-Type': 'application/json' };
    } else {
        return { 'Content-Type': 'application/json' };
    }
}

export function authHeaderWithMultipart() {
    // return authorization header with jwt token
    let user = JSON.parse(sessionStorage.getItem('user'));

    if (user && user.jwt) {
        return { Authorization: `Bearer ${user.jwt}`, 'Content-Type' : 'multipart/form-data' };
    } else {
        return {};
    }
}

export function authHeaderWithoutContentType() {
    // return authorization header with jwt token
    let user = JSON.parse(sessionStorage.getItem('user'));

    if (user && user.jwt) {
        return { Authorization: `Bearer ${user.jwt}` };
    } else {
        return {};
    }
}