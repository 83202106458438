import { workerConstants } from "../constants";
import { authHeaderWithJSON, authHeaderWithoutContentType } from "../helpers";

export const userService = {
    logout,
    saveProfile,
    checkAtName,
    saveImageProfile,
    moderation
};

const COMMUNO = workerConstants.WORKER

function logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('user');
}

function saveProfile(data) {
    const body = JSON.stringify(data);

    return fetch(COMMUNO + "editprofile",
        {
            method: 'POST',
            headers: authHeaderWithJSON(),
            // headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json' },
            body: body
        });
}

function saveImageProfile(dataForProfil, dataForProfilSharing, defaultavatar, firstprofilcard, uploadInfos) {
    const formData = new FormData();

    if (!firstprofilcard) {
        if (!defaultavatar) {
            if (uploadInfos.pictureurl && uploadInfos.profilcard) {
                formData.append("image", dataForProfil.file);
                formData.append("image", dataForProfilSharing, dataForProfil.uiid + "-profilesharing.png");
            } else if (uploadInfos.pictureurl) {
                formData.append("image", dataForProfil.file);
            } else if (uploadInfos.profilcard) {
                formData.append("image", dataForProfilSharing, dataForProfil.uiid + "-profilesharing.png");
            }
        } else {
            formData.append("image", []);
        }
    } else {
        if (uploadInfos.profilcard) {
            formData.append("image", dataForProfilSharing, "myfirstsocialcard-profilesharing.png");
        }
    }

    formData.append("uploadinfo", JSON.stringify(uploadInfos));

    return fetch(COMMUNO + "changeprofile",
        {
            method: 'POST',
            headers: authHeaderWithoutContentType(),
            // headers: { 'Accept': 'application/json', 'mode': "no-cors", "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json' },
            body: formData
        });
}

function checkAtName(data) {
    const body = JSON.stringify(data);

    return fetch(COMMUNO + "checkatname",
        {
            method: 'POST',
            headers: authHeaderWithJSON(),
            body: body
        });
}

function moderation(data) {
    const body = JSON.stringify(data);

    return fetch(COMMUNO + "moderation",
        {
            method: 'POST',
            headers: authHeaderWithJSON(),
            body: body
        });
}