import { profilConstants } from "../constants";


export function profil(state = { loading: false }, action) {
    switch (action.type) {
        case profilConstants.GET_REQUEST:
            return {
                profilList: "",
                loading: true,
                done: false,
                error: undefined,
            };
        case profilConstants.GET_SUCCESS:
            // console.log(action.payload)
            return {
                profilList: action.payload,
                loading: false,
                done: true,
                error: undefined,
            };
        case profilConstants.GET_FAILURE:
            // console.log(action.payload)
            return {
                error: action.payload,
                profilList: Object.keys({ ...state.profilList }).length === 0 ? "" : { ...state.profilList },
                loading: false,
                done: true
            }
        default:
            // console.log("in default")
            // console.log(state)
            return state;
    }
}