export const languageswitchConstants = {
    ENGLISH: 'ENGLISH',
    SPANISH: 'SPANISH',
    DEUTSCH: 'DEUTSCH',
    FRANCAIS: 'FRANCAIS',
    PORTUGUESE: 'PORTUGUESE',
    HINDI: 'HINDI',
    INDONESIAN: 'INDONESIAN',
    RUSSIAN: 'RUSSIAN',
    CHINESE: 'CHINESE',
    JAPANESE: 'JAPANESE',
    ARABIC: 'ARABIC',
    ITALIAN: 'ITALIAN',
    TURKISH: 'TURKISH',
    DUTCH: 'DUTCH',
    GREEK: 'GREEK',

    ENGLISH_NR: 0,
    SPANISH_NR: 1,
    DEUTSCH_NR: 2,
    FRANCAIS_NR: 3,
    PORTUGUESE_NR: 4,
    HINDI_NR: 5,
    INDONESIAN_NR: 6,
    RUSSIAN_NR: 7,
    CHINESE_NR: 8,
    JAPANESE_NR: 9,
    ARABIC_NR: 10,
    ITALIAN_NR: 11,
    TURKISH_NR: 12,
    DUTCH_NR: 13,
    GREEK_NR: 14,
    
};