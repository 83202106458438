export class FeedFilterClass { //FeedACL
    constructor() {
        this.id = 0;
        this.guideandannouncement = false;
        this.socialtrendnews = false;
        this.socialhealth = false;
        this.contentcreators = false;
        this.twitterx = false;
        this.facebook = false;
        this.instagram = false;
        this.telegram = false;
        this.discord = false;
        this.tiktok = false;
        this.reddit = false;
        this.tinder = false;
        this.twitch = false;
        this.snapchat = false;
        this.whatsapp = false;
        this.linkedin = false;
        this.vkontakte = false;
        this.wechat = false;
        this.pinterest = false;
        this.github = false;
        this.patreon = false;
        this.signa = false;
        this.youtube = false;
        this.onlyfans = false;
        this.newsocialmedias = false;
        this.artificialintelligence = false;
        this.school = false;
        this.finance = false;
        this.general = false;
        
        this.crypto = false;
        this.health = false;
        this.crime = false;
        this.gastronomy = false;
        this.architecture = false;
        this.mentalhealth = false;
        this.books = false;
        this.lifebalance = false;
        this.relationships = false;
        this.nature = false;
        this.universe = false;
        this.sport = false;

        this.gaming = false;
        this.anime = false;
        this.filmandserie = false;
        this.music = false;
        this.meetme = false;
        this.feedback = false;
        this.help = false;
        this.morepersonal = false;
        this.tech = false;
        this.memes = false;
        this.job = false;
        this.gossip = false;
    }
}