import { profilConstants } from '../constants';
import { chatService, profilService } from '../services';

export const profilActions = {
    getStarProfilList,
    getProfilList,
    getMyTalkers
};

function getStarProfilList(query, search) {
    // console.log("in Actions in ProfilList")
    return dispatch => {
        dispatch({ type: profilConstants.GET_REQUEST });

        profilService.getStarProfilList(query, search)
            .then(res => res.json())
            .then(data => dispatch({ type: profilConstants.GET_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: profilConstants.GET_FAILURE, payload: error }));
    };
}

function getProfilList(profil, query, search) {
    return dispatch => {
        dispatch({ type: profilConstants.GET_REQUEST });

        profilService.getProfilList(profil, query, search)
            .then(res => res.json())
            .then(data => dispatch({ type: profilConstants.GET_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: profilConstants.GET_FAILURE, payload: error }));
    };
}

function getMyTalkers(query, search) {
    // console.log("in Actions in ProfilList")
    return dispatch => {
        dispatch({ type: profilConstants.GET_REQUEST });

        chatService.getMyTalkers(query, search)
            .then(res => res.json())
            .then(data => dispatch({ type: profilConstants.GET_SUCCESS, payload: data }))
            .catch(error => dispatch({ type: profilConstants.GET_FAILURE, payload: error }));
    };
}