import React, { useEffect } from 'react';

export const AdsComponent = (props) => {
    const { dataAdSlot, currentPath, width, padding, marginTop, marginBottom } = props;

    // useEffect(() => {
    //     const pushAd = () => {
    //         try {
    //             const adsbygoogle = window.adsbygoogle
    //             // console.log({ adsbygoogle })
    //             adsbygoogle.push({})
    //         } catch (e) {
    //             console.error(e)
    //         }
    //     }

    //     let interval = setInterval(() => {
    //         // Check if Adsense script is loaded every 300ms
    //         if (window.adsbygoogle /*&& !window.adsbygoogle.loaded*/) {
    //             pushAd()
    //             // clear the interval once the ad is pushed so that function isn't called indefinitely
    //             clearInterval(interval)
    //         }
    //     }, 2000)

    //     return () => {
    //         clearInterval(interval)
    //     }
    // }, [currentPath])

    useEffect(() => {

        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({})
        }

        catch (e) {

        }

    }, []);



    return (
        <div style={{ width: width, padding: padding, marginTop: marginTop, marginBottom: marginBottom }}>
            <ins className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-8616525379955349"
                data-ad-slot={dataAdSlot}
                data-ad-format="auto"
                data-full-width-responsive="true"
                // data-adtest="on"
            >
            </ins>
        </div>
    );
};

export const AdsComponentFixed = (props) => {
    const { dataAdSlot, currentPath, width, padding, marginTop, marginBottom, adWidth, adHeight } = props;

    useEffect(() => {

        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({})
        }

        catch (e) {

        }

    }, []);



    return (
        <div style={{ width: width, padding: padding, marginTop: marginTop, marginBottom: marginBottom }}>
            <ins className="adsbygoogle"
                style={{ display: "block", width : adWidth, height : adHeight }}
                data-ad-client="ca-pub-8616525379955349"
                data-ad-slot={dataAdSlot}
                // data-ad-format="auto"
                // data-full-width-responsive="true"
                // data-adtest="on"
            >
            </ins>
        </div>
    );
};