export class GivingClass {
    constructor() {
        this.id = 0;
        this.lumen = false;
        this.nun = false;
        this.star = false;
        this.resetLumen = false;
        this.resetNun = false;
        this.resetStar = false;
        this.discussion = "";
        this.comment = "";
        this.user = ""; // Only star
    }
}

export class MyAppreciationClass {
    constructor() {
        this.id = 0;
        this.lumens = 0;
        this.stars = 0;
    }
}