import { useEffect, useState } from "react";
import { useSelector } from "react-redux";


export const RssFeedMessage = () => {
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    return (
        <div class="row" style={{ marginBottom: 50 }}>
            <div class="col-6 col-md-2 marginTopMobil" style={{ marginTop: "120px" }}>
            </div>
            <div class="col-12 col-md-10" style={{ marginTop: "10px" }}>
                <div id="top" class="marginMobil" style={{ marginRight: "30px", marginLeft: window.innerWidth >= 600 && window.innerWidth < 1200 ? "100px" : "0px" }}>
                    <div>
                        <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                            <a href="#top" className="white" style={{ fontSize: "2.5rem", color: theme === 'dark' ? '#fff' : '#000', textDecoration: "none" }} >How to Subscribe to OneLike RSS Feed News</a> {/* light */}
                        </div>

                        <div>
                            <p>Stay updated with OneLike news effortlessly! Follow these simple steps to subscribe to the OneLike RSS Feed using your preferred Chrome extension:</p>

                            <ul>
                                <li><b>1. Copy the RSS Feed URL:</b> Copy the following URL: <span style={{ color: '#166fe5', textDecoration: "underline dotted" }}>https://social.onelike.app/rss/space</span></li>
                                <li><b>2. Install an RSS Feed Reader Extension:</b> If you haven't already, install an RSS Feed Reader extension from the Chrome Web Store. Popular options include "RSS Feed Reader" or "Feeder"</li>
                                <li><b>3. Add the OneLike RSS Feed:</b></li>
                                <ul>
                                    <li>Open your RSS Feed Reader extension</li>
                                    <li>Find the option to add a new feed (this is usually marked with a "+" or "Add" button)</li>
                                    <li>Paste the copied URL <span style={{ color: '#166fe5', textDecoration: "underline dotted" }}>https://social.onelike.app/rss/space</span> into the feed URL field</li>
                                    <li>Click "Add" or "Subscribe"</li>
                                </ul>
                                <li><b>4. Stay Updated:</b> The latest news and updates from OneLike will now appear in your RSS Feed Reader. Enjoy seamless, real-time updates directly in your browser</li>
                            </ul>

                            <p>By following these steps, you'll always be in the loop with the latest news and updates from OneLike, ensuring you never miss out on important information</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}