import { Box } from '@material-ui/core';
import { Button, Card, Container, MenuItem, Paper, Stack, Typography } from "@mui/material";
import { useWindowDimensions } from 'react-native';

import InfoIcon from '@mui/icons-material/Info';
import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import $ from 'jquery';
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Import Swiper React components
import { styled } from '@mui/system';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
import { SuspenseImg } from "../helpers/SuspenseImage";
import { formatTimeAgo } from "../services";
import { DraggableInfoPopup, DraggableSignInPopup, ReadMoreReadLess } from "./SpaceItem";


import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PushPinIcon from '@mui/icons-material/PushPin';
import ReplyIcon from '@mui/icons-material/Reply';
import ShareIcon from '@mui/icons-material/Share';
import Menu from '@mui/material/Menu';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { pinActions } from "../actions";
import { PinClass } from "../models/Pin";
import { DraggableDialogForPreview } from "./Item";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'inherit',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    boxShadow: 'none'
}));

export const Publisher = ({ index, data }) => {
    const { ifid, title, description, source, category, url, image, timeago, pinned } = data;
    const identifier = "window_" + index;
    const identifierButton = "button_" + index;
    const [layout, setLayout] = useState(1);
    const qs = useSelector(state => state.quoteswitch);
    const [quoteswitch, setQuoteSwitch] = useState(qs);
    const pw = useSelector(state => state.previewswitch);
    const [previewswitch, setPreviewSwitch] = useState(pw);
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);
    const [isReadMoreShown, setReadMoreShown] = useState(false)
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const lin = useSelector((state) => state.authentication.loggedIn);
    const [loggedIn, setLoggedIn] = useState(lin);
    const u = useSelector((state) => state.authentication.user);
    const [user, setUser] = useState(u);
    const authenticated = loggedIn && user;

    const [entry, setEntry] = useState(true)
    const [onPin, setOnPin] = useState(false)
    const [pinStyle, setPinStyle] = useState({ color: '#fff' })
    const [pinning, setPinning] = useState(false)

    const dp = useSelector((state) => state.pin.done);
    const [done, setDone] = useState(dp)
    const ep = useSelector((state) => state.pin.error);
    const [error, setError] = useState(ep);



    const { width, height } = useWindowDimensions();

    const [openInfoPopup, setOpenInfoPopup] = useState(false);
    const handleOpenInfoPopup = (e) => {
        setOpenInfoPopup(true);
    }

    const handleCloseInfoPopup = () => {
        setOpenInfoPopup(false);
    }

    const [openSignInPopup, setOpenSignInPopup] = useState(false);
    const handleOpenSignInPopup = (e) => {
        setOpenSignInPopup(true);
    }
    const handleCloseSignInPopup = () => {
        setOpenSignInPopup(false);
    }

    const handleOpen = (e) => {
        // e.preventDefault();
        setOpen(true);
    }
    const handleCloseForPreview = () => {
        setOpen(false);
    }

    const openLink = () => {
        if (qs) {
            handleOpen()
        } else {
            window.open(url, "_blank");
        }
    }

    const handleLayoutChange = (e) => {
        e.preventDefault();
        if (layout === 1) {
            setLayout(3);
        } else if (layout === 3) {
            setLayout(1);
        }
    }

    useEffect(() => {
        setLoggedIn(lin)
    }, [lin])

    useEffect(() => {
        setUser(u)
    }, [u])


    useEffect(() => {
        // console.log("balance" + balance)
        if (ifid) {
            if (entry) {
                if (pinned) {
                    setOnPin(true)
                    setPinStyle({ color: '#ff0000' })
                } else {
                    setOnPin(false)
                    setPinStyle({ color: '#fff' })
                }
            }
            setEntry(false)
        }
    }, [])

    useEffect(() => {
        if (pinning) {
            if (dp) {
                if (ep !== undefined) {
                    setPinning(false)

                    // Toast.fire({
                    //     icon: "error",
                    //     title: "An error has occurred. Please try again ..."
                    // });
                } else {
                    setPinning(false)

                    // Toast.fire({
                    //     icon: "success",
                    //     title: "Successfully deleted"
                    // });
                }
            }
        }
    }, [dp]);

    const handlePin = () => {
        if (authenticated) {
            if (ifid) {
                if (!onPin) {
                    setPinStyle({ color: '#ff0000' })
                    setOnPin(true)
                } else {
                    setPinStyle({ color: '#fff' })
                    setOnPin(false)
                }

                var pin = new PinClass()
                pin.ifid = ifid;
                pin.pinned = pinned
                pin.uuid = user.uuid;
                pin.firstname = user.firstname;
                pin.name = user.name;
                pin.email = user.email;
                pin.displayname = user.displayname;

                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var pinInfos = JSON.parse(JSON.stringify(pin, replacer));

                setPinning(true)

                dispatch(pinActions.pin(pinInfos))
            }
        } else {
            handleOpenSignInPopup()
        }
    }

    useEffect(() => {
        setLayout(layout);
    }, [layout])

    useEffect(() => {
        localStorage.setItem("quote", qs)
        setQuoteSwitch(qs);
    }, [qs]);

    useEffect(() => {
        setPreviewSwitch(pw);
    }, [pw]);

    useEffect(() => {
        setTheme(t);
    }, [t])

    useEffect(() => {
        setUser(u)
    }, [u])

    const makeScrollable = (e) => {
        if (layout === 1 || layout === 4) {
            setLayout(2);
        } else if (layout === 2) {
            setLayout(1);
        }
    }

    const makeScrollableForText = (e) => {
        setLayout(1);
        const index = e.target.id.split("_")[1];
        const window = "window_" + index;
        // console.log(index)
        // console.log(window)
        // console.log(document.getElementById(window).style.overflowY)
        if (document.getElementById(window).style.overflowY === "hidden") {
            document.getElementById(window).style.overflowY = "auto";
            document.getElementById(e.target.id).innerText = "See Less";
        } else {
            document.getElementById(window).style.overflowY = "hidden";
            document.getElementById(e.target.id).innerText = "See More";
            $('#' + window).animate({ scrollTop: 0 }, 1000);
        }

    }

    /* const displayImage = () => setTimeout(() => {
        setLayout(1);
      }, 10000);
    
      useEffect(() => {
        var identifier = "window_" + index;
        const interval = setInterval(() => {
        var rNummer = getRandomNumber(1, 100);
        if (rNummer >= 95 && rNummer <= 100 && document.getElementById(identifier + "t").style.overflowY !== "auto") {
          setLayout(3);
          displayImage();
        }
      }, 5000);
    
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
      }, []) 
    */


    var styleMobLarge1;
    var styleMobLarge2;
    if (width > 767) {
        styleMobLarge1 = { maxwidth: '80vw', height: 60, backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { maxWidth: '100vw', backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px" }
    } else {
        styleMobLarge1 = { width: '80vw', height: 60, backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderTopLeftRadius: 20, borderTopRightRadius: 20, margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }
        styleMobLarge2 = { width: '80vw', backgroundColor: theme === 'dark' ? "#002147" : "#F7F9F9", borderBottomLeftRadius: "20px 20px", borderBottomRightRadius: "20px 20px" }
    }


    if (layout === 1) {
        // var autoplayTime = window.innerWidth >= 960 ? 15000 : 60000;
        var autoplayTime = 5000;

        return (
            <Container style={{ paddingLeft: 0 }}>
                <div style={{ display: 'block' }}>
                    <h6 style={styleMobLarge1} class="p-3 bg-primary bg-gradient text-white draggable">
                        <span style={{ color: "#fff", textDecoration: "none", cursor: 'auto' }}>{source}</span>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '0.875rem' }}>
                            <InfoIcon className="notDraggable text-white" onClick={e => handleOpenInfoPopup()} style={{ float: 'right', cursor: "pointer" }} />
                        </div>
                    </h6>

                    <DraggableSignInPopup openSignInPopup={openSignInPopup} handleCloseSignInPopup={handleCloseSignInPopup} />

                    <DraggableInfoPopup openInfoPopup={openInfoPopup} handleCloseInfoPopup={handleCloseInfoPopup} info={"aboutpublishers"} publishers={true} />

                    <DraggableDialogForPreview open={open} handleCloseForPreview={handleCloseForPreview} url={url} title={title} />

                    <Card sx={styleMobLarge2}>

                        <Box style={{ height: 'auto', width: '100%' }}>
                            <div style={{ margin: 15 }}>
                                <Typography gutterBottom variant="h6" component="div" style={{ overflowWrap: 'break-word', color: theme === 'dark' ? "#fff" : "#000" }}>
                                    <span class="bg-primary bg-gradient text-white" style={{ padding: '2px 5px 2px 5px', borderRadius: 5, marginRight: 5 }} >{formatTimeAgo(timeago)}</span>
                                    <span style={{ textDecoration: "underline dotted", cursor: 'pointer' }}>{title && title.length > 120 ?
                                        <ReadMoreReadLess limit={120} isReadMoreShown={isReadMoreShown} setReadMoreShown={setReadMoreShown} openLink={openLink}>
                                            {title}
                                        </ReadMoreReadLess> :
                                        <span onClick={openLink}>{title}</span>
                                    }</span>
                                </Typography>
                            </div>
                            <ImagePreview image={image} theme={theme} />
                        </Box>

                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={1}
                            p={1}
                        >
                            <Item>
                                <Button title={"Pin"} size="small" onClick={handlePin} variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 50, minWidth: 0, padding: 5 }}>
                                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20, padding: 5 }}><PushPinIcon style={pinStyle} /></Box>
                                </Button>
                            </Item>
                            <Item><ShareInfoNews title={title} path={url} /></Item>
                            <Item><Button size="large" variant="contained" style={{ borderRadius: 20 }} onClick={() => { openLink() }} >More</Button></Item>
                        </Stack>
                    </Card>
                </div>
            </Container>
        );
    }
    else if (layout === 2) {
        return (
            <>

            </>
        );
    } else if (layout === 3) {
        return (
            <>

            </>
        );
    } else if (layout === 4) {
        return (
            <>

            </>
        );
    }

}

const ImagePreview = ({ image, theme }) => {
    var autoplayTime = 5000;

    return (
        <>
            <Swiper
                style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                    '--swiper-navigation-size': '150px'
                }}
                centeredSlides={true}
                loop={true}
                autoHeight={true}
                effect={"fade"}
                autoplay={{
                    delay: autoplayTime,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                lazy={true}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation, EffectFade]}
                className="imgSwiper"
            >
                <SwiperSlide>
                    <div>
                        <PreviewImage image={image} theme={theme} />
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
}

const PreviewImage = ({ image, theme }) => {
    const [loaded, setLoaded] = useState(false);
    const showImage = () => {
        setLoaded(true)
    }
    const [error, setError] = useState(false);
    const hasError = () => {
        setError(true)
    }
    return (
        <Suspense>
            <SuspenseImg alt="" src={error || image == "no image" ? "/newspaper.jpg" : image} onError={(e) => { e.preventDefault(); hasError(); }} />
            {/* {theme === "dark" && <SuspenseImg alt="" src={error ? "/onelike-dark_1200x620.png" : image} onError={(e) => { e.preventDefault(); hasError(); }} />} */}
        </Suspense>
    );
}

export function ShareInfoNews({ title, path }) {
    const [shareStyle, setShareStyle] = useState({ color: "#fff", transform: 'scaleX(-1)' })

    return (
        <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => {

                const copyLink = () => {
                    navigator.clipboard.writeText(path)
                    popupState.close()
                }

                const otherOptions = () => {
                    if (navigator.share) {
                        navigator.share({
                            title: title,
                            // text: post,
                            url: path,
                        })
                            .then(() => console.log('Successful share'))
                            .catch((error) => console.log('Error sharing', error));
                    }
                    popupState.close()
                }


                return (
                    <React.Fragment>
                        <Button title={"Share"} size="small" variant="contained" {...bindTrigger(popupState)} style={{ backgroundColor: "#166fe5", borderRadius: 50, minWidth: 0, padding: 5 }}>
                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20, padding: 5 }}><ReplyIcon style={shareStyle} /></Box>
                            {/* <TipsAndUpdatesIcon style={{ color: "#0D1F2D", transform: 'rotate(180deg)' }} /> */}
                        </Button>
                        <Menu
                            {...bindMenu(popupState)}
                            style={{ zIndex: 10000000010 }}
                        >
                            <MenuItem onClick={copyLink}><ContentCopyIcon style={{ marginRight: 5 }} />Copy Link</MenuItem>
                            <MenuItem onClick={otherOptions}><ShareIcon style={{ marginRight: 5 }} />Other Options</MenuItem>
                            {/* <MenuItem onClick={popupState.close}>Logout</MenuItem> */}
                        </Menu>
                    </React.Fragment>
                );
            }}
        </PopupState>
    );
}