import { combineReducers } from 'redux';
import { socialmedia, socialmediaDE, socialmediaES, socialmediaFR, socialmediaPT, socialmediaHI, socialmediaINDO, socialmediaRU, socialmediaCH, socialmediaJP, socialmediaAR, socialmediaIT, socialmediaTR, socialmediaNL, socialmediaGR} from './socialmedia.reducer';
import { socialmediapreview } from './socialmediapreview.reducer';
import { previewswitch } from './previewswitch.reducer';
import { account } from './account.reducer';
import { menuswitch } from './switch.reducer';
import { languageswitch } from './languageswitch.reducer';
import { breach } from './breach.reducer';
import { news } from './news.reducer';
import { quote } from './quote.reducer';
import { quoteswitch } from './quoteswitch.reducer';
import { themeswitch } from './themeswitch.reducer';
import { page } from './page.reducer';
import { gdprdata } from './gdprdata.reducer';
import { space } from './space.reducer';
import { discussion, feedfilter } from './discussion.reducer';
import { authentication } from './authentication.reducer';
import { user, atname, moderation } from './user.reducer';
import { appreciation, myappreciation } from './appreciation.reducer';
import { comment } from './comment.reducer';
import { spaceswitch, listonespaceswitch } from './spaceswitch.reducer';
import { profil } from './profil.reducer';
import { message, checkreadmessage } from './message.reducer';
import { chat } from './chat.reducer';
import { blockchat } from './blockchat.reducer';
import { report } from './report.reducer';
import { info, infonewsguides } from './info.reducer';
import { outofscope } from './outofscope.reducer';
import { publisher } from './publisher.reducer';
import { pin } from './pin.reducer';
import { publisherswitch } from './publisherswitch.reducer';

const rootReducer = combineReducers({
  socialmedia,
  socialmediaDE,
  socialmediaES,
  socialmediaFR,
  socialmediaPT,
  socialmediaHI,
  socialmediaINDO,
  socialmediaRU,
  socialmediaCH,
  socialmediaJP,
  socialmediaAR,
  socialmediaIT,
  socialmediaTR,
  socialmediaNL,
  socialmediaGR,
  account,
  menuswitch,
  languageswitch,
  breach,
  news,
  quote,
  quoteswitch,
  themeswitch,
  socialmediapreview,
  previewswitch,
  page,
  gdprdata,
  space,
  discussion,
  authentication,
  user,
  appreciation,
  comment,
  spaceswitch,
  listonespaceswitch,
  profil,
  message,
  chat,
  blockchat,
  report,
  myappreciation,
  atname,
  feedfilter,
  moderation,
  checkreadmessage,
  info,
  outofscope,
  publisher,
  infonewsguides,
  pin,
  publisherswitch
});

export default rootReducer;