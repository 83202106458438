import { ThumbUp } from "@mui/icons-material";
import AddLocationIcon from '@mui/icons-material/AddLocation';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ApiIcon from '@mui/icons-material/Api';
import CancelIcon from '@mui/icons-material/Cancel';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CoffeeIcon from '@mui/icons-material/Coffee';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteIcon from '@mui/icons-material/Delete';
import FacebookIcon from '@mui/icons-material/Facebook';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ImageIcon from '@mui/icons-material/Image';
import InfoIcon from '@mui/icons-material/Info';
import InstagramIcon from '@mui/icons-material/Instagram';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import RedditIcon from '@mui/icons-material/Reddit';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import TelegramIcon from '@mui/icons-material/Telegram';
import XIcon from '@mui/icons-material/X';
import { React, useState } from "react";
import { slide as Burger } from 'react-burger-menu';
import { batch, useDispatch, useSelector } from 'react-redux';

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";

import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, FormHelperText, InputAdornment, Paper, Stack, TextField } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/system';
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import Draggable from 'react-draggable';
import { appreciationActions, discussionActions, infoActions, listonespaceswitchActions, messageActions, profilActions, profileActions, publisherActions, publisherswitchActions, spaceActions, spaceswitchActions, switchActions, userActions } from "../actions";
import { alertConstants, listonespaceswitchConstants, publisherswitchConstants, spaceswitchConstants, switchConstants, userConstants, workerConstants } from "../constants";
import { base64ToBlob, containsWhitespace, getErrorMessageFetch, isOnlyWhitespace, isValidUrl, removeWhitespaces, truncateFullName } from "../helpers";
import { ProfileClass } from "../models/profile";
import { actualYear, truncateNumber } from "../services/util.service";

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudSyncSharpIcon from '@mui/icons-material/CloudSyncSharp';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import StarIcon from '@mui/icons-material/Star';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import YouTubeIcon from '@mui/icons-material/YouTube';

import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { commentActions } from "../actions/comment.actions";
import { TikTokBlueIcon, TikTokWhiteIcon, TwitchBlueIcon, TwitchWhiteIcon } from "./CustomSVG";


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { useToPng } from '@hugocxl/react-to-image';
import dayjs from "dayjs";
import { WebSocketServer } from "ws";
import { AtClass } from "../models/at";
import { ProfilImageClass, ProfilImageUploadClass } from "../models/image";
import { Spinner } from "../spinner/spinner";
import { ProfilCard } from "./ProfilItem";
import { DraggableShowProfilePopup, DraggableWarningProfilPopup, Share } from "./SpaceItem";

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import { GivingClass } from "../models/giving";

import $ from 'jquery';

Object.assign(global, { WebSocketServer });

const COMMUNO = workerConstants.WORKER
const preUrlProfil = workerConstants.PRE_URL_PROFIL


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: 'inherit',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    boxShadow: 'none'
}));

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});

export const Menu = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const { space } = useParams();

    const ms = useSelector(state => state.menuswitch);
    const [menuSwitch, setMenuSwitch] = useState(ms);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [open1, setOpen1] = useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);

    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    const [open3, setOpen3] = useState(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);

    const [open4, setOpen4] = useState(false);
    const handleOpen4 = () => setOpen4(true);
    const handleClose4 = () => setOpen4(false);

    const [open5, setOpen5] = useState(false);
    const handleOpen5 = () => setOpen5(true);
    const handleClose5 = () => setOpen5(false);

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    const lin = useSelector((state) => state.authentication.loggedIn);
    const [loggedIn, setLoggedIn] = useState(lin);
    const u = useSelector((state) => state.authentication.user);
    const [user, setUser] = useState(u);
    const username = user ? user.firstname : "";
    const fullname = user ? user.firstname + " " + user.name : "";
    const profileUrl = user ? user.profileUrl : "/avatar2.png";
    const authenticated = loggedIn && user;

    const [loading, setLoading] = useState(false);
    const WAIT_CHANGE = 2000;

    const interval = () => setTimeout(() => {
        setLoading(false);
    }, WAIT_CHANGE);

    const lop = useSelector(state => state.listonespaceswitch);
    const ss = useSelector(state => state.spaceswitch);
    const ds = useSelector(state => state.discussion.discussionList);
    const [discussionList, setDiscussionList] = useState(ds);

    const [urlp, setUlrp] = useState(localStorage.getItem("urlparam"))

    const mya = useSelector(state => state.myappreciation.myappreciation);
    const [myAppreciation, setMyAppreciation] = useState(mya)

    const publisherSwitch = useSelector(state => state.publisherswitch);

    const share = () => {
        if (navigator.share) {
            navigator.share({
                title: 'web.dev',
                text: 'Check out web.dev.',
                url: 'https://web.dev/',
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        }
    }

    useEffect(() => {
        setUser(u)
    }, [u])

    useEffect(() => {
        setLoggedIn(lin)
    }, [lin])

    useEffect(() => {
        setMenuSwitch(ms)
    }, [ms])

    useEffect(() => {
        if (authenticated) {
            // const sock = new SockJS('https://service.onelike.app/ws/');
            // const client = Stomp.over(sock);

            // client.connect({}, () => {
            //     setStompClient(client);
            //     client.subscribe('/topic/messages', (message) => {
            //         sendMessage()
            //         alert(JSON.parse(message.body).content);
            //     });

            // });

            // return () => {
            //     if (client) {
            //         client.disconnect();
            //     }
            // };
            // const rxStomp = new RxStomp();
            // rxStomp.configure({
            //     brokerURL: 'ws://service.onelike.app/ws/',
            // });

            // rxStomp.activate();

            // const subscription = rxStomp
            //     .watch({ destination: "/topic/messages" })
            //     .subscribe((message) => console.log(message.body));

            // rxStomp.publish({
            //     destination: "/topic/messages",
            //     body: "First message to RxStomp",
            // });

            // setTimeout(async () => {
            //     subscription.unsubscribe();
            //     await rxStomp.deactivate();
            // }, 3000);




        }
    }, [authenticated]);


    // for (var i = 0; i < 1000; i++){
    //     setTimeout(5000)
    //     if (client.connected) {
    //         client.publish("/app/sendMessage", {}, JSON.stringify({ content: message }));
    //     }
    // }

    const [openProfilPopup, setOpenProfilPopup] = useState(false);
    const handleOpenProfilPopup = (e) => {
        if (authenticated) {
            dispatch(userActions.clearAtName());
            setOpenProfilPopup(true);
        }
    }
    const handleCloseProfilPopup = () => {
        setOpenProfilPopup(false);
    }

    const [openStatusPopup, setOpenStatusPopup] = useState(false);
    const handleOpenStatusPopup = (e) => {
        setOpenStatusPopup(true);
    }
    const handleCloseStatusPopup = () => {
        setOpenStatusPopup(false);
    }

    const show = (e) => {
        e.preventDefault();
        handleOpen();
    }

    const show1 = (e) => {
        e.preventDefault();
        handleOpen1();
    }

    const show2 = (e) => {
        e.preventDefault();
        handleOpen2();
    }

    const show3 = (e) => {
        e.preventDefault();
        handleOpen3();
    }

    const show4 = (e) => {
        e.preventDefault();
        handleOpen4();
    }

    const show5 = (e) => {
        e.preventDefault();
        handleOpen5();
    }

    useEffect(() => {
        setTheme(t);
    }, [t])

    var styles = {
        bmBurgerButton: {
            position: 'fixed',
            width: '36px',
            height: '30px',
            left: '36px',
            top: '36px',
            // zIndex: "1000000 !important"
        },
        bmBurgerBars: {
            background: theme === 'dark' ? '#fff' : '#000', //light
        },
        bmBurgerBarsHover: {
            background: '#166fe5'
        },
        bmCrossButton: {
            height: '24px',
            width: '24px'
        },
        bmCross: {
            background: '#bdc3c7'
        },
        bmMenuWrap: {
            position: 'fixed',
            height: '100%',
            // zIndex: "1000000 !important"
        },
        bmMenu: {
            background: theme === 'dark' ? '#0D1F2D' : '#fff', //light
            padding: '2.5em 1.5em 0',
            fontSize: '1.15em',
        },
        bmMorphShape: {
            fill: '#373a47'
        },
        bmItemList: {
            color: theme === 'dark' ? '#F5F0F6' : '#000', //light
            padding: '0.8em'
        },
        bmItem: {
            display: 'inline-block'
        },
        bmOverlay: {
            background: 'rgba(0, 0, 0, 0.3)'
        }
    }

    const styleTextFieldAdditional3 = {
        // width: 320,
        // margin: '15px',
        // marginLeft: "15px",
        // marginTop: "15px",
        marginTop: '15px',
        marginBottom: '15px',
        // maxWidth: '100%',
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
    }

    var style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: window.innerWidth <= 320 ? 295 : 350,
        height: 570,
        bgcolor: theme === 'dark' ? '#0D1F2D' : '#fff', // light
        color: theme === 'dark' ? '#fff' : '#000', // light
        border: theme === 'dark' ? "2px solid #fff" : "2px solid #000", // light
        borderRadius: "5px",
        boxShadow: 24,
        overflowY: "auto",
        overflowX: "hidden",
        zIndex: 1100000,
        p: 4,
        '@media (min-width: 600px)': {
            width: 500
        }
    };

    const style1 = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: window.innerWidth <= 320 ? 295 : 350,
        bgcolor: theme === 'dark' ? '#0D1F2D' : '#fff', // light
        color: theme === 'dark' ? '#fff' : '#000', // light
        border: theme === 'dark' ? "2px solid #fff" : "2px solid #000", // light
        borderRadius: "5px",
        boxShadow: 24,
        overflowY: "auto",
        overflowX: "hidden",
        zIndex: 1100000,
        p: 4,
        '@media (min-width: 600px)': {
            width: 500
        }
    };

    const style2 = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 340,
        bgcolor: theme === 'dark' ? '#0D1F2D' : '#fff', // light
        color: theme === 'dark' ? '#fff' : '#000', // light
        border: theme === 'dark' ? "2px solid #fff" : "2px solid #000", // light
        borderRadius: "5px",
        boxShadow: 24,
        overflowY: "auto",
        overflowX: "hidden",
        zIndex: 1100000,
        p: 4,
    };

    const ItemWithBlock = styled(Paper)(({ theme }) => ({
        backgroundColor: 'inherit',
        padding: theme.spacing(1),
        textAlign: 'center',
        color: '#fff',
        boxShadow: 'none',
        display: 'block'
    }));

    function Logout() {
        handleCloseProfilPopup();

        const user = JSON.parse(sessionStorage.getItem('user'));
        // client.deactivate();
        dispatch(userActions.logout());
        dispatch(appreciationActions.clearAppreciation());
        dispatch(commentActions.clearComment());

        dispatch(getAllInfosForSpace())


        resetPage();

        if (menuSwitch == switchConstants.PUBLISHERS_NR) {
            dispatch(publisherswitchActions.showPublishers(publisherswitchConstants.ALL_PUBLISHERS))
            dispatch(switchActions.changeTab(switchConstants.PUBLISHERS))
            dispatch(publisherActions.getPublisherList())
        }

        const backToSpace = () => {
            navigate("/space");
            dispatch(switchActions.changeTab(switchConstants.SPACE))
            dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
            dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.FEED))
            dispatch(discussionActions.getFeedFilter())
            dispatch(discussionActions.getFeed());
        }

        const backToOneSpace = () => {
            if (ds && ds.urlparam) {
                localStorage.setItem("urlparam", ds.urlparam)
            }
            var urlparam = localStorage.getItem("urlparam")
            navigate("/space/" + urlparam);
            dispatch(switchActions.changeTab(switchConstants.SPACE))
            dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.ALL_SPACES))
            dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.ONE_SPACE))
            dispatch(discussionActions.getDiscussionList(urlparam, "", false));
        }

        if (menuSwitch == switchConstants.SPACE_NR) {
            if (lop === listonespaceswitchConstants.SPACE_LIST_NR) {
                if (ss == spaceswitchConstants.FEED_NR) {
                    dispatch(discussionActions.getFeedFilter())
                    dispatch(discussionActions.getFeed());
                }

                if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                    dispatch(spaceActions.getSpaceList());
                }

                if (ss == spaceswitchConstants.MY_SPACE_NR) {
                    backToSpace()
                    // dispatch(discussionActions.getMyDiscussionList());
                }

                if (ss == spaceswitchConstants.LIGHT_NR) {
                    backToSpace()
                    // dispatch(discussionActions.getLightDiscussionList());
                }

                if (ss == spaceswitchConstants.STAR_NR) {
                    backToSpace()
                }

                if (ss == spaceswitchConstants.MESSAGE_NR) {
                    backToSpace()
                }

                if (ss == spaceswitchConstants.FIND_NR) {
                    dispatch(profilActions.getProfilList());
                }
            }

            if (lop === listonespaceswitchConstants.ONE_SPACE_NR) {
                if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                    if (ds && ds.urlparam) {
                        localStorage.setItem("urlparam", ds.urlparam)
                        var urlparam = localStorage.getItem("urlparam")
                        dispatch(discussionActions.getDiscussionList(urlparam, "", false));
                    } else {
                        dispatch(discussionActions.getDiscussionList("guideandannouncement", "", false));
                    }
                }
                if (ss == spaceswitchConstants.MY_SPACE_NR) {
                    backToOneSpace()
                    // dispatch(discussionActions.getMyDiscussionList());
                }
                if (ss == spaceswitchConstants.LIGHT_NR) {
                    backToOneSpace()
                    // dispatch(discussionActions.getLightDiscussionList());
                }

                if (ss == spaceswitchConstants.STAR_NR) {
                    backToOneSpace()
                }

                if (ss == spaceswitchConstants.MESSAGE_NR) {
                    backToOneSpace()
                }

                if (ss == spaceswitchConstants.FIND_NR) {
                    dispatch(profilActions.getProfilList());
                }
            }
        }

        console.log('You are logged out, ' + user.firstname);
        Toast.fire({
            icon: "success",
            title: "We'll miss you, " + user.firstname + " ! Come back soon"
        });
    }

    const handleLoginSuccess = (response) => {
        // console.log(response)
        // const hasAccess = hasGrantedAnyScopeGoogle(
        //     response,
        //     'https://www.googleapis.com/auth/user.birthday.read',
        //     'https://www.googleapis.com/auth/user.gender.read',
        //     'https://www.googleapis.com/auth/userinfo.profile'
        // );
        // console.log(hasAccess)
        const idToken = response.credential;
        // Send idToken to your backend via HTTPS
        // Backend should verify token and respond with the user's role
        // This is a simplified example; you would typically use Axios or Fetch API to make the HTTP request.
        // console.log(idToken)
        dispatch(request({ user: "" }));
        fetch(COMMUNO + "api/auth/google", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ idToken: idToken }),
        })
            .then(response => response.json())
            .then(data => {
                dispatch(success(data))

                sessionStorage.setItem('user', JSON.stringify(data));

                resetPage();

                if (menuSwitch == switchConstants.PUBLISHERS_NR) {
                    dispatch(publisherActions.getPublisherList())
                }

                if (menuSwitch == switchConstants.SPACE_NR) {
                    if (lop === listonespaceswitchConstants.SPACE_LIST_NR) {
                        if (ss == spaceswitchConstants.FEED_NR) {
                            dispatch(discussionActions.getFeedFilter())
                            dispatch(discussionActions.getFeed());
                        }
                        if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                            dispatch(spaceActions.getSpaceList());
                        }
                        if (ss == spaceswitchConstants.MY_SPACE_NR) {
                            dispatch(discussionActions.getMyDiscussionList());
                        }
                        if (ss == spaceswitchConstants.LIGHT_NR) {
                            dispatch(discussionActions.getLightDiscussionList());
                        }
                        if (ss == spaceswitchConstants.FIND_NR) {
                            dispatch(profilActions.getProfilList());
                        }
                    }

                    if (lop === listonespaceswitchConstants.ONE_SPACE_NR) {
                        if (ss == spaceswitchConstants.ALL_SPACES_NR) {
                            if (ds && ds.urlparam) {
                                localStorage.setItem("urlparam", ds.urlparam)
                                var urlparam = localStorage.getItem("urlparam")
                                dispatch(discussionActions.getDiscussionList(urlparam, "", false));
                            } else {
                                dispatch(discussionActions.getDiscussionList("guideandannouncement", "", false));
                            }
                        }
                        if (ss == spaceswitchConstants.MY_SPACE_NR) {
                            dispatch(discussionActions.getMyDiscussionList());
                        }
                        if (ss == spaceswitchConstants.LIGHT_NR) {
                            dispatch(discussionActions.getLightDiscussionList());
                        }
                        if (ss == spaceswitchConstants.FIND_NR) {
                            dispatch(profilActions.getProfilList());
                        }
                    }
                }

                dispatch(getAllInfosForSpace())

                console.log('You are logged in, ' + data.firstname);
                Toast.fire({
                    icon: "success",
                    title: "Welcome " + data.firstname + " !"
                });

                // setUserRole(data.role); Assuming the backend responds with { role: "admin" } or { role: "user" }
            })
            .catch(error => {
                console.error('Error:', error);
                Toast.fire({
                    icon: "error",
                    title: "An error has occurred. Please try again ..."
                });

            });

        function request(payload) { return { type: userConstants.LOGIN_REQUEST, payload } }
        function success(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }
    };

    const getAllInfosForSpace = () => {
        return (dispatch, getState) => {
            batch(() => {
                dispatch(infoActions.getInfos())
                dispatch(messageActions.checkReadMessage())
                dispatch(appreciationActions.myAppreciation())
            })
        }
    }

    // const login = useGoogleOneTapLogin({
    //     onSuccess: handleLoginSuccess,
    //     use_fedcm_for_prompt : true,
    // });

    // useGoogleOneTapLogin({
    //     onSuccess: handleLoginSuccess,
    //     onError: () => {
    //         console.log('Login Failed');
    //     },
    // });

    const handleLoginFailure = (error) => {
        console.error('Login Failed:', error);
        dispatch(failure(getErrorMessageFetch(error)))
        dispatch(error(getErrorMessageFetch(error)))

        function failure(payload) { return { type: userConstants.LOGIN_FAILURE, payload } }
        function error(payload) { return { type: alertConstants.ERROR, payload } }
    };

    const login = useGoogleLogin({
        onSuccess: codeResponse => handleLoginSuccess(codeResponse),
        onError: error => handleLoginFailure(error),
        flow: 'implicit',
        // scope: 'https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.gender.read https://www.googleapis.com/auth/userinfo.profile'
    });

    useEffect(() => {
        dispatch(appreciationActions.myAppreciation())
    }, []);

    useEffect(() => {
        setMyAppreciation(mya)
    }, [mya]);

    const goToTwitterX = () => {
        window.open("https://www.x.com/OneLikeApp")
    }

    const goToReddit = () => {
        window.open("https://www.reddit.com/r/OneLike")
    }

    const goToTelegram = () => {
        window.open("https://t.me/OnelikeApp")
    }

    const goToRssFeed = () => {
        window.open("https://social.onelike.app/rss/how-to-subscribe")
    }

    const goToSpace = (e) => {
        e.preventDefault();
        setLoading(true);

        setTimeout(() => {
            $('#react-burger-cross-btn').click()
        }, 1000)
        navigate("/space")
        resetPage()

        dispatch(switchActions.changeTab(switchConstants.SPACE))
        dispatch(listonespaceswitchActions.switchSpace(listonespaceswitchConstants.SPACE_LIST))
        dispatch(spaceswitchActions.changeSpace(spaceswitchConstants.FEED))

        interval();
    }

    const goToFeedback = (e) => {
        e.preventDefault();
        setLoading(true);

        setTimeout(() => {
            $('#react-burger-cross-btn').click()
        }, 1000)
        navigate("/space/feedback")
        resetPage()

        interval();
    }

    function resetPage() {
        // dispatch(pageActions.changePage(pageConstants.RESET))
        localStorage.setItem("page", 1)
    }

    return (
        <div id="outer-container">

            <Spinner show={loading} />

            <Burger left width={'310px'} styles={styles}>
                <p style={{ fontSize: '1.5em' }}><ThumbUp style={{ marginBottom: 3 }} />{" "}OneLike</p>
                {/* <p><PaidIcon style={{ marginBottom: 3 }} />{" "}Advertising helps finance this App</p> */}
                <br />
                <br />
                {authenticated ?
                    <><Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={0}
                        style={{ display: 'flex' }}
                    >
                        <Item style={{ padding: 0, cursor: 'pointer' }} onClick={() => handleOpenProfilPopup()}><Avatar alt={(user && user.displayname) ? user.displayname : ((user && user.name) ? user.firstname + " " + user.name : user.firstname)} src={profileUrl} /></Item>
                        <Item style={{ cursor: 'pointer', color: theme == 'dark' ? "#fff" : "#000" }} onClick={() => handleOpenProfilPopup()}>{user && user.displayname ? truncateFullName(user.displayname) : ((user && user.name) ? truncateFullName(user.firstname + " " + user.name) : truncateFullName(user.firstname))}</Item>
                    </Stack>
                        <p>
                            {!isOnlyWhitespace(user.mytwitterx) ? <><a class="hover" target="_blank" href={user.mytwitterx}><XIcon style={{ marginBottom: 3, color: theme == 'dark' ? "#fff" : "#166FE5" }} /></a>{" "}</> : null}
                            {!isOnlyWhitespace(user.myreddit) ? <><a target="_blank" href={user.myreddit}><RedditIcon style={{ marginBottom: 3, color: theme == 'dark' ? "#fff" : "#166FE5" }} /></a>{" "}</> : null}
                            {!isOnlyWhitespace(user.mytelegram) ? <><a target="_blank" href={user.mytelegram}><TelegramIcon style={{ marginBottom: 3, color: theme == 'dark' ? "#fff" : "#166FE5" }} /></a>{" "}</> : null}
                            {!isOnlyWhitespace(user.mylinkedin) ? <><a target="_blank" href={user.mylinkedin}><LinkedInIcon style={{ marginBottom: 3, color: theme == 'dark' ? "#fff" : "#166FE5" }} /></a>{" "}</> : null}
                            {!isOnlyWhitespace(user.mygithub) ? <><a target="_blank" href={user.mygithub}><GitHubIcon style={{ marginBottom: 3, color: theme == 'dark' ? "#fff" : "#166FE5" }} /></a>{" "}</> : null}
                            {!isOnlyWhitespace(user.myyoutube) ? <><a target="_blank" href={user.myyoutube}><YouTubeIcon style={{ marginBottom: 3, color: theme == 'dark' ? "#fff" : "#166FE5" }} /></a>{" "}</> : null}
                            {!isOnlyWhitespace(user.mytwitch) ? <><a target="_blank" href={user.mytwitch}>{theme == 'dark' ? <TwitchWhiteIcon style={{ marginBottom: 3 }} /> : <TwitchBlueIcon style={{ marginBottom: 3 }} />}</a>{" "}</> : null}
                            {!isOnlyWhitespace(user.myinstagram) ? <><a target="_blank" href={user.myinstagram}><InstagramIcon style={{ marginBottom: 3, color: theme == 'dark' ? "#fff" : "#166FE5" }} /></a>{" "}</> : null}
                            {!isOnlyWhitespace(user.myfacebook) ? <><a target="_blank" href={user.myfacebook}><FacebookIcon style={{ marginBottom: 3, color: theme == 'dark' ? "#fff" : "#166FE5" }} /></a>{" "}</> : null}
                            {!isOnlyWhitespace(user.mytiktok) ? <><a target="_blank" href={user.mytiktok}>{theme == 'dark' ? <TikTokWhiteIcon style={{ marginBottom: 3 }} /> : <TikTokBlueIcon style={{ marginBottom: 3 }} />}</a></> : null}
                        </p>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                            p={0}
                            style={{ display: "flex", marginTop: 5 }}
                        >
                            <DraggableStatusPopup openShowStatusPopup={openStatusPopup} handleCloseStatusPopup={handleCloseStatusPopup} myAppreciation={myAppreciation} />
                            <Box style={{ backgroundColor: "#166fe5", display: "flex", justifyContent: "center", alignItems: "center", padding: 5, borderRadius: 20, marginLeft: 0 }}><span style={{ marginRight: 2, fontWeight: 'bold', color: theme == 'dark' ? "#fff" : "#fff" }}>{truncateNumber(myAppreciation?.stars)}</span><StarIcon style={{ color: "#FFFF00" }} /></Box>
                            <Box style={{ backgroundColor: "#166fe5", display: "flex", justifyContent: "center", alignItems: "center", padding: 5, borderRadius: 20 }}><span style={{ marginRight: 2, fontWeight: 'bold', color: theme == 'dark' ? "#fff" : "#fff" }}>{truncateNumber(myAppreciation?.lumens)}</span><TipsAndUpdatesIcon style={{ color: "#FFFF00" }} /></Box>
                            <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", display: "flex", justifyContent: "center", alignItems: "center", padding: 8, borderRadius: 25, minWidth: 0 }} onClick={() => handleOpenStatusPopup()}>
                                <Box>
                                    {myAppreciation?.banned && <ErrorIcon style={{ color: '#FF0000' }} title={"Banned"} />}
                                    {myAppreciation?.suspended && <NewReleasesIcon style={{ color: "#FFFF00" }} title={"Suspended"} />}
                                    {!myAppreciation?.suspended && !myAppreciation?.banned && <CheckCircleIcon style={{ color: '#00ff00' }} title={"Good"} />}
                                </Box>
                            </Button>
                        </Stack>
                    </>
                    :
                    <>
                        {/* <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, padding: 5, marginBottom : 20 }} onClick={() => login()}>
                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }} onClick={() => login()}>
                                <Box style={{ backgroundColor: '#fff', borderRadius: 5, margin: 5 }}><GoogleIcon /></Box>
                                Sign in with Google</Box>
                        </Button> */}

                        <GoogleLogin
                            onSuccess={handleLoginSuccess}
                            onError={handleLoginFailure}
                            text={"signin_with"}
                            theme={"filled_blue"}
                        // scope='https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.gender.read https://www.googleapis.com/auth/userinfo.profile'
                        // useOneTap
                        />
                    </>}
                {authenticated ?
                    <>
                        {/* force to reinitialize when reopening*/}
                        {openProfilPopup ?
                            <DraggableProfilPopup openProfilPopup={openProfilPopup} handleCloseProfilPopup={handleCloseProfilPopup} user={user} setUser={setUser} Logout={Logout} authenticated={authenticated} myAppreciation={myAppreciation} setMyAppreciation={setMyAppreciation} />
                            : null}
                    </>
                    : null}
                {/* <Button onClick={() => Logout()} style={{ backgroundColor: '#1976d2', color : '#fff'}}>Logout</Button> */}

                {/* <p><a href="mailto:info@onelike.app"><AlternateEmailIcon style={{ marginBottom: 3 }} />{" "}info@onelike.app</a></p> */}
                <br />
                {/* <p><InfoIcon style={{ marginBottom: 3 }} />{" "}Still in Development</p> */}
                <p><a href="#" target="_self" onClick={(e) => goToSpace(e)} class="hover"><PeopleIcon style={{ marginBottom: 3 }} />{" "}Community</a></p>
                <br />
                <p><a target="_blank" href="https://rapidapi.com/onelike-onelike-default/api/onelike1/" class="hover"><ApiIcon style={{ marginBottom: 3 }} />{" "}OneLike API</a></p>
                <br />
                <Mobil open5={open5} customStyle={style1} handleClose5={handleClose5} />
                <p><a href="#" class="hover" onClick={e => show5(e)}><InstallMobileIcon style={{ marginBottom: 3 }} />{" "}Mobil</a></p>
                <br />
                <p><a href="#" target="_self" onClick={(e) => goToFeedback(e)} class="hover"><FeedbackIcon style={{ marginBottom: 3 }} />{" "}Feedback</a></p>
                <br />
                <p><a href="#" class="hover" onClick={e => show(e)}><InfoIcon style={{ marginBottom: 3 }} />{" "}Privacy Policy</a></p>
                <PrivacyPolicy open={open} customStyle={style} handleClose={handleClose} />
                <p><a href="#" class="hover" onClick={e => show1(e)}><InfoIcon style={{ marginBottom: 3 }} />{" "}Terms of Use</a></p>
                <TermsOfUse open1={open1} customStyle={style} handleClose1={handleClose1} />
                <p><a href="#" class="hover" onClick={e => show2(e)}><InfoIcon style={{ marginBottom: 3 }} />{" "}About</a></p>
                <br />
                <About open2={open2} customStyle={style1} handleClose2={handleClose2} />
                <p><a href="#" class="hover" onClick={e => show4(e)}><SportsEsportsIcon style={{ marginBottom: 3 }} />{" "}Gaming</a></p>
                <Gaming open4={open4} customStyle={style2} handleClose4={handleClose4} />
                <p><a href="#" class="hover" onClick={e => show3(e)}><CardGiftcardIcon style={{ marginBottom: 3 }} />{" "}Donate</a></p>
                <br />
                <p><a target="_blank" href="https://www.google.com/maps/place/Am+Mertenshof+100,+50859+Cologne,+Germany"><AddLocationIcon style={{ marginBottom: 3 }} />{" "}Cologne, Germany</a></p>
                <Donate open3={open3} customStyle={style1} handleClose3={handleClose3} />
                <p><CopyrightIcon style={{ marginBottom: 3, fontSize: "1rem" }} />{" "}<span style={{ fontSize: "1rem" }}>{actualYear()}</span>{" "}<a style={{ fontSize: "1rem" }} href="https://social.onelike.app">social.onelike.app</a></p>
                <p>
                    <Button size="small" variant="contained" style={{ borderRadius: 20, minWidth: 0, padding: 5, marginRight: 5 }} onClick={goToTwitterX} title="https://x.com/OneLikeApp"><XIcon style={{ marginBottom: 3 }} /></Button>
                    {/* <a class="hover" target="_blank" href="https://x.com/OneLikeApp"><XIcon style={{ marginBottom: 3 }} /></a>{" "} */}
                    <Button size="small" variant="contained" style={{ borderRadius: 20, minWidth: 0, padding: 5, marginRight: 5 }} onClick={goToReddit} title="https://www.reddit.com/r/OneLike"><RedditIcon style={{ marginBottom: 3 }} /></Button>
                    {/* <a target="_blank" href="https://www.reddit.com/r/OneLike/"><RedditIcon style={{ marginBottom: 3 }} /></a>{" "} */}
                    <Button size="small" variant="contained" style={{ borderRadius: 20, minWidth: 0, padding: 5, marginRight: 5 }} onClick={goToTelegram} title="https://t.me/OnelikeApp"><TelegramIcon style={{ marginBottom: 3 }} /></Button>
                    {/* <a target="_blank" href="https://t.me/OnelikeApp"><TelegramIcon style={{ marginBottom: 3 }} /></a> */}
                    <Button size="small" variant="contained" style={{ borderRadius: 20, minWidth: 0, padding: 5 }} onClick={goToRssFeed} title="https://social.onelike.app/rss/how-to-subscribe"><RssFeedIcon style={{ marginBottom: 3 }} /></Button>
                </p><br />
                {/* <p><Button onClick={() => Logout()} style={{ backgroundColor: '#1976d2', color: '#fff' }}>Logout</Button></p>               */}
            </Burger>
        </div>
    );
}


export const PrivacyPolicy1 = ({ customStyle, open, handleClose }) => {

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={customStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <h1>Privacy Policy</h1>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div>
                            <p>Last updated: August 05, 2022</p>

                            <p>OneLike ("us", "we", or "our") operates social.onelike.app (the "Site"). This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Site.</p>

                            <p>We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible at social.onelike.app.</p>

                            <h4>Information Collection And Use</h4>

                            <p>While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your name, email address, postal address and phone number ("Personal Information").</p>

                            <h4>Portfolio Data</h4>

                            <p>OneLike <u>will never</u> share, sell or rent users portfolio data with any third parties.</p>

                            <h4>Log Data</h4>

                            <p>Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>

                            <h4>Cookies</h4>

                            <p>Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive.</p>

                            <p>Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.</p>

                            <h4>Security</h4>

                            <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>

                            <h4>Links To Other Sites</h4>

                            <p>Our Site may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>

                            <p>OneLike has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party sites or services.</p>

                            <h4>Changes To This Privacy Policy</h4>

                            <p>OneLike may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Site. You are advised to review this Privacy Policy periodically for any changes.</p>



                            <h4>Contact Us</h4>
                            <p>If you have any questions about this Privacy Policy, please contact us.</p>
                            <br /><br />
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        overflowY: 'auto',
        overflowX: 'hidden',
        width: 350,

        '@media (min-width: 600px) and (max-width: 800px)': {
            width: 500
        },

        '@media (min-width: 800px)': {
            width: 600
        },
    },
    customizedButton: {
        backgroundColor: '#007bff',
        color: '#fff',
        float: 'right',
    }
}));

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export const PrivacyPolicy = ({ open, handleClose }) => {
    const classes = useStyles();
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    const changeUrl = () => {
        window.open("/gdpr-data-request")
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.paper }}
                style={{ zIndex: 10000000000 }}
            >
                <DialogTitle style={{ cursor: 'move', color: '#0D1F2D' }} id="draggable-dialog-title">
                    <span style={{ color: '#166fe5' }}>Privacy Policy</span> {/* light */}
                    {/*<IconButton autoFocus onClick={handleClose} color="primary" className={classes.customizedButton}>
                        <CloseIcon />
                </IconButton> */}
                </DialogTitle>
                <DialogContent style={{ background: theme === 'dark' ? '#0D1F2D' : '#fff' }}> {/* light */}
                    <DialogContentText style={{ color: theme === 'dark' ? '#fff' : '#000' }}>
                        <div style={{ marginTop: 20 }}>
                            <p>Last updated: August 05, 2022</p>

                            <p>OneLike ("us", "we", or "our") operates social.onelike.app (the "Site"). This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Site.</p>

                            <p>We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible at social.onelike.app.</p>

                            <h4>Information Collection And Use</h4>

                            <p>While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your name, email address, postal address and phone number ("Personal Information").</p>

                            <h4>Portfolio Data</h4>

                            <p>OneLike <u>will never</u> share, sell or rent users portfolio data with any third parties.</p>

                            <h4>Log Data</h4>

                            <p>Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>

                            <h4>Cookies</h4>

                            <p>Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive.</p>

                            <p>Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.</p>

                            <h4>Security</h4>

                            <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>

                            <h4>Links To Other Sites</h4>

                            <p>Our Site may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>

                            <p>OneLike has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party sites or services.</p>

                            <h4>Changes To This Privacy Policy</h4>

                            <p>OneLike may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Site. You are advised to review this Privacy Policy periodically for any changes.</p>

                            <h4>Contact Us</h4>
                            <p>If you have any questions about this Privacy Policy, please contact us.</p>
                            <br /><br />
                        </div>
                    </DialogContentText>{/* light */}
                </DialogContent>
                <DialogActions style={{ background: theme === 'dark' ? '#0D1F2D' : '#fff' }}>
                    <Button onClick={changeUrl} variant="contained">
                        GDPR Data Request
                    </Button>
                    <Button onClick={handleClose} variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export const TermsOfUse = ({ customStyle, open1, handleClose1 }) => {
    return (
        <div>
            <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={customStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <h1>Terms of Use</h1>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div>
                            <p>Last updated: August 05, 2022</p>

                            <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>

                            <p>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the https://social.onelike.app website (the "Service") operated by OneLike ("us", "we", or "our").</p>

                            <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>

                            <h4>Links To Other Web Sites</h4>

                            <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by OneLike.</p>

                            <p>OneLike has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that OneLike shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>

                            <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</p>

                            <h4>Termination</h4>

                            <p>We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. </p>

                            <p>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>

                            <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>

                            <p>Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.</p>

                            <p>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>

                            <h4>Indemnification</h4>

                            <p>You agree to defend, indemnify and hold harmless OneLike and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password, or b) a breach of these Terms.</p>

                            <h4>Limitation Of Liability</h4>

                            <p>In no event shall OneLike, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>

                            <h4>Warranty Disclaimer</h4>

                            <p>Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</p>

                            <p>OneLike its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</p>

                            <h4>Exclusions</h4>

                            <p>Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.</p>

                            <h4>Governing Law</h4>

                            <p>These Terms shall be governed and construed in accordance with the laws of Latvia, without regard to its conflict of law provisions and excluding the United Nations Convention on Contracts for the International Sale of Goods (CISG).</p>

                            <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>

                            <h4>Changes</h4>

                            <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

                            <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</p>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );

}


export const About = ({ customStyle, open2, handleClose2 }) => {
    return (
        <div>
            <Modal
                open={open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={customStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <h1>About</h1>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div>
                            <p>OneLike is a News Aggregator App / Platform for Social Medias</p>
                            <ul>
                                <li>Get news in real-time about Social Medias : Twitter, Facebook, Instagram, etc.</li>

                                <li>Stay informed with reliable news updates from trusted, handpicked sources.</li>

                                <li>Search profiles of your friends on all social medias.</li>

                                {/* <li>Check if you have an email address that has been compromised in a data breach.</li> */}

                                <li>Share News and your Opinions, make Discussions about the Social Space.</li>
                            </ul>
                            <p>Logo Designed by Bui Quang Khai{" "}<a target="_blank" href="https://www.facebook.com/ella.holubova.5"><FacebookIcon style={{ marginBottom: 3 }} /></a></p>
                            <p>Developed by Bayon Mbayo</p>
                            <p><a target="_blank" href="https://x.com/bayon2013"><XIcon style={{ marginBottom: 3 }} /></a>{" "}<a target="_blank" href="https://t.me/keplerb22"><TelegramIcon style={{ marginBottom: 3 }} /></a>{" "}<a target="_blank" href="https://www.reddit.com/user/bayonmbayo/"><RedditIcon style={{ marginBottom: 3 }} /></a>{" "}<a target="_blank" href="https://www.instagram.com/bayonmbayo/"><InstagramIcon style={{ marginBottom: 3 }} /></a></p>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );

}

const Donate = ({ customStyle, open3, handleClose3 }) => {
    return (
        <div>
            <Modal
                open={open3}
                onClose={handleClose3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={customStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <h1>Donate</h1>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p>Would you like to support the Project ?</p>
                        <p>
                            You can donate via <a target="_blank" href="https://paypal.me/OnelikeSocial">PayPal</a> or buy a <a target="_blank" href="https://www.buymeacoffee.com/onelike">Coffee<CoffeeIcon style={{ marginBottom: 3 }} /></a><span> for US, Thanks :)</span>
                        </p>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );

}

export const Gaming = ({ customStyle, open4, handleClose4 }) => {
    return (
        <div>
            <Modal
                open={open4}
                onClose={handleClose4}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={customStyle}>
                    {/*                     <Typography id="modal-modal-title" variant="h6" component="h2">
                        <h1>NoLife</h1>
                    </Typography> */}
                    {/*                     <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <iframe width="560" height="315" allow="fullscreen; autoplay; encrypted-media" src="https://games.construct.net/769/latest" frameborder="0" allowfullscreen="true" msallowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" allowpaymentrequest="false" referrerpolicy="unsafe-url" sandbox="allow-same-origin allow-forms allow-scripts allow-pointer-lock allow-orientation-lock allow-popups" scrolling="no"></iframe>
                    </Typography> */}
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <h1 style={{ textAlign: "center" }}>Gaming</h1>
                    </Typography>
                    <Typography>
                        <div class="row">
                            <div class="col">
                                <a href='https://play.google.com/store/apps/details?id=com.onelikegaming.roosbreaker' title='Download Roos Breaker' target={"blank_"} style={{ textDecoration: "none" }}>
                                    <div class="row">
                                        <div class="col" style={{ padding: 20, textAlign: "center" }}><img style={{ width: 100 }} src='/logo-roos-dark-rounded.png' alt='Download Roos Breaker' /></div>
                                        <div class="col">
                                            <p style={{ padding: 20, fontSize: 30, textAlign: "center" }}><a style={{ textDecoration: "none" }}>Roos Breaker</a></p>
                                            <p style={{ padding: 20, fontSize: 20, textAlign: "center" }}>Complete Version</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="row">
                            <span><a style={{ textDecoration: "none", fontSize: 12 }} href='https://roosbreaker-gaming.onelike.app' target={"blank_"}>FAQ (For Gaming)</a></span>
                        </div>
                        <div class="row">
                            <span><a style={{ textDecoration: "none", fontSize: 12 }} href='/gaming/privacy-policy' target={"blank_"}>Privacy Policy (For Gaming)</a></span>
                        </div>
                        <div class="row">
                            <span><a style={{ textDecoration: "none", fontSize: 12 }} href='/gaming/terms-of-use' target={"blank_"}>Terms & Conditions (For Gaming)</a></span>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );

}

export const Mobil = ({ customStyle, open5, handleClose5 }) => {
    return (
        <div>
            <Modal
                open={open5}
                onClose={handleClose5}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={customStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <h1>OneLike Mobil</h1>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p>You'll find here the Mobil version of OneLike</p>
                        <div class="row">
                            <div class="col" style={{ padding: 5 }}>
                                <a href='https://play.google.com/store/apps/details?id=com.onelike.app' title='Download OneLike' target={"blank_"}>
                                    <img style={{ width: 220, marginLeft: -13 }} src='/google-play-badge.png' alt='Download OneLike' />
                                </a>
                            </div>
                            <div class="col topcol" style={{ padding: 5 }}>
                                <a href='https://galaxy.store/onelike' title='Download OneLike' target={"blank_"}>
                                    <img style={{ width: 220 }} src='/GalaxyStore_English.png' alt='Download OneLike' />
                                </a>
                            </div>
                            <div class="col" style={{ padding: 5 }}>
                                <a href='//onelike.en.uptodown.com/android' title='Download OneLike' target={"blank_"}>
                                    <img src='https://stc.utdstc.com/img/mediakit/download-aao-big.png' alt='Download OneLike' />
                                </a>
                            </div>
                            <div class="col" style={{ padding: 5 }}>
                                <a href='https://url.cloud.huawei.com/k0PWhcIxpK?shareTo=qrcode' title='Download OneLike' target={"blank_"}>
                                    <img style={{ width: 220 }} src='/huawei-badge.png' alt='Download OneLike' />
                                </a>
                            </div>
                            <div class="col" style={{ padding: 5 }}>
                                <a href='https://www.amazon.com/gp/product/B0BJCDL9BG/ref=mas_pm_OneLike' title='Download OneLike' target={"blank_"}>
                                    <img style={{ width: 220 }} src='/amazon-appstore-badge.png' alt='Download OneLike' />
                                </a>
                            </div>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );

}

const styleTextField = {
    // width: 320,
    // margin: '15px',
    marginBottom: '15px',
    // maxWidth: '100%',
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
}

export function BasicDateField() {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateField']}>
                <DateField label="Birthday" />
            </DemoContainer>
        </LocalizationProvider>
    );
}

export function BasicDatePicker() {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <DatePicker
                    label="Birthday"
                    slotProps={{
                        textField: {
                            helperText: 'MM/DD/YYYY',
                        },
                    }}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}

const DraggableProfilPopup = ({ openProfilPopup, handleCloseProfilPopup, Logout, authenticated, myAppreciation, setMyAppreciation }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const u = useSelector((state) => state.authentication.user);
    const [user, setUser] = useState(u);

    const ex = useSelector((state) => state.atname.exist);
    const exd = useSelector((state) => state.atname.done);
    const [existDone, setExistDone] = useState(exd);
    const [exist, setExist] = useState(ex);

    const profileUrl = user ? user.profileUrl : "";

    const l = useSelector((state) => state.authentication.loading);
    const d = useSelector((state) => state.authentication.done);
    const [done, setDone] = useState(d)
    const e = useSelector((state) => state.authentication.error);

    const [error, setError] = useState(e);
    const [sending, setSending] = useState(false);

    const [inputText, setInputText] = useState(user ? user.description : "")
    const [characterLimit] = useState(500)

    const [valid, setValid] = useState(false)
    const inputUpload = "inputUpload";
    const [files, setFiles] = useState([])

    const [shareStyle, setShareStyle] = useState({ color: "#fff", transform: 'scaleX(-1)' })

    var title = user && user.displayname ?
        user.displayname + " - OneLike Social"
        :
        (user.name ?
            user.firstname + " " + user.name + " - OneLike Social"
            :
            user.firstname + " - OneLike Social")

    const [showProfilCard, setShowProfilCard] = useState(false)

    const changeUrl = (url) => {
        window.open(url)
    }

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);
    const [entry, setEntry] = useState(true)

    const [openShowProfilePopup, setOpenShowProfilePopup] = useState(false);
    const handleOpenShowProfilePopup = (e) => {
        setOpenShowProfilePopup(true);
    }

    const handleCloseShowProfilePopup = () => {
        setOpenShowProfilePopup(false);
    }

    const [openWarningProfilPopup, setOpenWarningProfilPopup] = useState(false);
    const handleOpenShowWarningProfil = (e) => {
        setOpenWarningProfilPopup(true);
    }

    const handleCloseWarningProfilPopup = () => {
        setOpenWarningProfilPopup(false);
    }

    const [openShowProfilAccessPopup, setOpenShowProfilAccessPopup] = useState(false);
    const handleOpenProfilAccessPopup = (e) => {
        setOpenShowProfilAccessPopup(true);
    }

    const handleCloseProfilAccessPopup = () => {
        setOpenShowProfilAccessPopup(false);
    }

    const [openShowProfilRolePopup, setOpenShowProfilRolePopup] = useState(false);
    const handleOpenProfilRolePopup = (e) => {
        setOpenShowProfilRolePopup(true);
    }

    const handleCloseProfilRolePopup = () => {
        setOpenShowProfilRolePopup(false);
    }

    const [openShowProfilVisibilityPopup, setOpenShowProfilVisibilityPopup] = useState(false);
    const handleOpenProfilVisibilityPopup = (e) => {
        setOpenShowProfilVisibilityPopup(true);
    }

    const handleCloseProfilVisibilityPopup = () => {
        setOpenShowProfilVisibilityPopup(false);
    }

    const [openShowMessagePopup, setOpenShowMessagePopup] = useState(false);
    const handleOpenMessagePopup = (e) => {
        setOpenShowMessagePopup(true);
    }

    const handleCloseMessagePopup = () => {
        setOpenShowMessagePopup(false);
    }

    const [previewBeforeSending, setPreviewBeforeSending] = useState(
        user && user.previewUrl ?
            user.previewUrl :
            user && user.profileUrl ? user.profileUrl : "/avatar2.png")
    const [imageBlob, setImageBlob] = useState("")
    const [defaultavatar, setDefaultavatar] = useState(null)
    const [onlyProfilcard, setOnlyProfilcard] = useState(null)
    const [onlyPicture, setOnlyPicture] = useState(null)

    const [savingProfileWithAtName, setSavingProfileWithAtName] = useState(false)
    const [changingProfileImage, setChangingProfileImage] = useState(false)

    const [{ isSuccess }, convert] = useToPng({
        selector: '#myprofilcard',
        onSuccess: data => {
            setImageBlob(base64ToBlob(data, 'image/png'));
            // const formData = new FormData();
            // formData.append("image", imageBlob, files[0].uiid + "-profilesharing.png");

            // dispatch(profileActions.saveImageProfile(profil, imageBlob))
        }
        // console.log('Converted #my-element to PNG!', data),
    })

    const [onStar, setOnStar] = useState(false)
    const [starStyle, setStarStyle] = useState({ color: '#fff' })

    useEffect(() => {
        setUser(u);
        // setPreviewBeforeSending(user.previewUrl ? user.previewUrl : user.profileUrl)
    }, [u])

    useEffect(() => {
        setTheme(t);
    }, [t])

    useEffect(() => {
        setTheme(t);
    }, [t])

    useEffect(() => {
        setExist(ex);
    }, [ex])

    useEffect(() => {
        setExistDone(exd);
    }, [exd])

    useEffect(() => {
        if (sending) {
            if (d) {
                if (savingProfileWithAtName) {
                    if (exd && ex) {
                        if (e !== undefined) {
                            setSending(false)
                            setSavingProfileWithAtName(false)
                            Toast.fire({
                                icon: "error",
                                title: "An error has occurred. Please try again ..."
                            });
                        } else {
                            setSending(false)
                            setSavingProfileWithAtName(false)
                            Toast.fire({
                                icon: "success",
                                title: "Profile successfully updated"
                            });
                        }
                    } else {
                        setSending(false)
                        setSavingProfileWithAtName(false)
                        Toast.fire({
                            icon: "warning",
                            title: "The username is already taken. Please choose another one"
                        });
                    }
                } else {
                    if (e !== undefined) {
                        setSending(false)
                        Toast.fire({
                            icon: "error",
                            title: "An error has occurred. Please try again ..."
                        });
                    } else {
                        setSending(false)
                        Toast.fire({
                            icon: "success",
                            title: "Profile successfully updated"
                        });
                    }
                }
            }
        }
    }, [d, exd, ex]);

    const handleChange = e => {
        if (e.target && e.target.id) {
            if (e.target.id === "displayname") {
                setUser({ ...user, [e.target.id]: e.target.value });
            }
            if (e.target.id === "atname") {
                setUser({ ...user, [e.target.id]: e.target.value });
            }
            if (e.target.id === "description") {
                setInputText(e.target.value);
                setUser({ ...user, [e.target.id]: e.target.value });
            }
            if (e.target.id === "mytwitterx") {
                setUser({ ...user, [e.target.id]: e.target.value });
            }
            if (e.target.id === "myreddit") {
                setUser({ ...user, [e.target.id]: e.target.value });
            }
            if (e.target.id === "mytelegram") {
                setUser({ ...user, [e.target.id]: e.target.value });
            }
            if (e.target.id === "mylinkedin") {
                setUser({ ...user, [e.target.id]: e.target.value });
            }
            if (e.target.id === "mygithub") {
                setUser({ ...user, [e.target.id]: e.target.value });
            }
            if (e.target.id === "myyoutube") {
                setUser({ ...user, [e.target.id]: e.target.value });
            }
            if (e.target.id === "mytwitch") {
                setUser({ ...user, [e.target.id]: e.target.value });
            }
            if (e.target.id === "myinstagram") {
                setUser({ ...user, [e.target.id]: e.target.value });
            }
            if (e.target.id === "myfacebook") {
                setUser({ ...user, [e.target.id]: e.target.value });
            }
            if (e.target.id === "mytiktok") {
                setUser({ ...user, [e.target.id]: e.target.value });
            }
        }
    };

    const handleChangeForCheckBox = (e) => {
        let v = false;

        if (e.target.type === "checkbox") {
            if (e.target.value === "false" || e.target.value === false) {
                v = true;
            }
        } else {
            v = e.target.value;
        }

        setUser({ ...user, [e.target.name]: v })
    };

    const handleChangeForDate = (n) => (e) => {
        let value = e;
        if (n === "birthday") {
            let birthday = value.format('YYYY-MM-DD')
            // console.log(birthday)
            setUser({ ...user, [n]: birthday });
        }
    }

    const handleChangeForProfilAccess = (e) => {
        if (e.target.type === "radio") {
            setUser({ ...user, ["access"]: e.target.value });
        }
    }

    const handleChangeForProfilRole = (e) => {
        if (e.target.type === "radio") {
            setUser({ ...user, ["role"]: e.target.value });
        }
    }

    const handleChangeForProfilVisibility = (e) => {
        if (e.target.type === "radio") {
            setUser({ ...user, ["visibility"]: e.target.value });
        }
    }

    const handleChangeForMessage = (e) => {
        if (e.target.type === "radio") {
            setUser({ ...user, ["message"]: e.target.value });
        }
    }

    const handleChangeForGender = (e) => {
        setUser({ ...user, ["gender"]: e.target.value });
    };

    const checkExist = () => {
        if (user && user.atname && !isOnlyWhitespace(user.atname)) {
            if (containsWhitespace(user.atname)) {
                Toast.fire({
                    icon: "warning",
                    title: "Please ensure your username contains no spaces"
                });

                return;
            }

            var at = new AtClass();
            at.atname = user.atname;

            const replacer = (key, value) => {
                if (typeof value === 'undefined')
                    return ""
                else return value;
            }
            setSavingProfileWithAtName(true)
            var atInfos = JSON.parse(JSON.stringify(at, replacer));
            dispatch(userActions.checkAtName(atInfos))
        } else {
            setSavingProfileWithAtName(false)
        }
    }

    const setBalanceOnStar = (e) => {
        if (authenticated) {
            if (!onStar) {
                setStarStyle({ color: '#fff' })
                setStarStyle({ color: '#ffff00' })

                var giving = new GivingClass()
                giving.user = user.uuid;
                giving.star = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));

                setOnStar(true)
                setMyAppreciation({ ...myAppreciation, ["star"]: true })
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            } else {
                setStarStyle({ color: '#ffff00' })
                setStarStyle({ color: '#fff' })

                var giving = new GivingClass()
                giving.user = user.uuid;
                giving.resetStar = true;
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var givingInfos = JSON.parse(JSON.stringify(giving, replacer));

                setOnStar(false)
                setMyAppreciation({ ...myAppreciation, ["star"]: false })
                dispatch(appreciationActions.giveAppreciation(givingInfos))
            }
        } else {
            // handleOpenSignInPopup()
        }
    }

    useEffect(() => {
        // console.log("balance" + balance)
        if (myAppreciation) {
            if (entry) {
                if (myAppreciation.star) {
                    setOnStar(true)
                    setStarStyle({ color: '#ffff00' })
                    // console.log("sssssssssssttttttttttttttaaaaaaaaaaaaaaaarrrrrrrrrrrrrrrrrrrr")
                }
            }
            setEntry(false)
        }
    }, [])

    useEffect(() => {
        if (isSuccess) {
            if (changingProfileImage) {
                var profil;
                var profilUploadInfo;
                if (!onlyProfilcard) {
                    if (defaultavatar) {
                        if (!onlyPicture) {
                            profilUploadInfo = new ProfilImageUploadClass()
                            profilUploadInfo.pictureurl = true
                            profilUploadInfo.profilcard = true
                            const replacer = (key, value) => {
                                if (typeof value === 'undefined')
                                    return ""
                                else return value;
                            }
                            var uploadInfos = JSON.parse(JSON.stringify(profilUploadInfo, replacer));

                            profil = ""
                            dispatch(profileActions.saveImageProfile(profil, imageBlob, true, false, uploadInfos))
                        }
                    } else {
                        if (!onlyPicture) {
                            profilUploadInfo = new ProfilImageUploadClass()
                            profilUploadInfo.pictureurl = true
                            profilUploadInfo.profilcard = true
                            const replacer = (key, value) => {
                                if (typeof value === 'undefined')
                                    return ""
                                else return value;
                            }
                            var uploadInfos = JSON.parse(JSON.stringify(profilUploadInfo, replacer));

                            profil = files[0]
                            dispatch(profileActions.saveImageProfile(profil, imageBlob, false, false, uploadInfos))
                        }
                    }
                } else {
                    profilUploadInfo = new ProfilImageUploadClass()
                    profilUploadInfo.pictureurl = false
                    profilUploadInfo.profilcard = true
                    const replacer = (key, value) => {
                        if (typeof value === 'undefined')
                            return ""
                        else return value;
                    }
                    var uploadInfos = JSON.parse(JSON.stringify(profilUploadInfo, replacer));

                    profil = ""
                    dispatch(profileActions.saveImageProfile(profil, imageBlob, false, true, uploadInfos))
                }

            }
            setChangingProfileImage(false)
        }
    }, [files, isSuccess, defaultavatar, onlyPicture, onlyProfilcard, changingProfileImage])

    const handleFileUpload = (e) => {
        setSending(true)
        setChangingProfileImage(true)
        setSavingProfileWithAtName(false)
        // console.log(e.target.files)

        if (e.target.files.length > 0) {

            var pathImage = window.URL.createObjectURL(e.target.files[0])
            setPreviewBeforeSending(pathImage)
            setDefaultavatar(false)

            setFiles((prev) => {
                var imgsNew = [];

                var img = new ProfilImageClass()
                img.file = e.target.files[0]
                img.uiid = img.file.name
                img.new = true
                imgsNew.push(img)

                return imgsNew;
            });

            if ((user && user.profilcardUrl) || (onlyProfilcard)) {
                setOnlyPicture(false)
                setOnlyProfilcard(false)

                setTimeout(() => {
                    convert();
                }, [1000]);
            } else {
                var profil;
                var profilUploadInfo;

                setOnlyPicture(true)
                setOnlyProfilcard(false)

                profilUploadInfo = new ProfilImageUploadClass()
                profilUploadInfo.pictureurl = true
                profilUploadInfo.profilcard = false
                const replacer = (key, value) => {
                    if (typeof value === 'undefined')
                        return ""
                    else return value;
                }
                var uploadInfos = JSON.parse(JSON.stringify(profilUploadInfo, replacer));

                var img = new ProfilImageClass()
                img.file = e.target.files[0]
                img.uiid = img.file.name
                img.new = true

                profil = img
                dispatch(profileActions.saveImageProfile(profil, imageBlob, false, false, uploadInfos))
            }
        }
    }

    const handleDeleteProfilImage = (e) => {
        var profil = ""
        const imageBlob = ""

        setSending(true)
        setChangingProfileImage(true)
        setSavingProfileWithAtName(false)

        var pathImage = "/avatar2.png"
        setPreviewBeforeSending(pathImage)
        setDefaultavatar(true)

        if ((user && user.profilcardUrl) || (onlyProfilcard)) {
            setOnlyPicture(false)
            setOnlyProfilcard(false)

            setTimeout(() => {
                convert();
            }, [1000]);
        } else {
            var profilUploadInfo;

            setOnlyPicture(true)
            setOnlyProfilcard(false)

            profilUploadInfo = new ProfilImageUploadClass()
            profilUploadInfo.pictureurl = true
            profilUploadInfo.profilcard = false
            const replacer = (key, value) => {
                if (typeof value === 'undefined')
                    return ""
                else return value;
            }
            var uploadInfos = JSON.parse(JSON.stringify(profilUploadInfo, replacer));

            profil = ""
            dispatch(profileActions.saveImageProfile(profil, imageBlob, true, false, uploadInfos))
        }
    }

    const makeProfilCard = (e) => {
        if (!user || !user.atname || isOnlyWhitespace(user.atname)) {
            Toast.fire({
                icon: "warning",
                title: "Please save first your profile with a username"
            });
            return;
        }

        setSending(true)
        setChangingProfileImage(true)
        setSavingProfileWithAtName(false)

        setOnlyPicture(false)
        setOnlyProfilcard(true)

        var pathImage = user.previewUrl ? user.previewUrl : user.profileUrl ? user.profileUrl : "/avatar2.png"

        setPreviewBeforeSending(pathImage)
        setDefaultavatar(false)

        setTimeout(() => {
            convert();
        }, [1000]);
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const handleSubmit = () => {
        if (containsWhitespace(user.atname)) {
            Toast.fire({
                icon: "warning",
                title: "Please ensure your username contains no spaces"
            });

            return;
        }

        if (user && user.description && !isOnlyWhitespace(user.description) && user.description.length > 500) {
            Toast.fire({
                icon: "warning",
                title: "Please write a short description"
            });

            return;
        }

        checkExist();

        if (authenticated) {
            var profile = new ProfileClass();
            profile.displayname = user.displayname;
            profile.atname = user.atname;
            profile.gender = user.gender;
            profile.showgender = user.showgender;
            profile.description = user.description;
            if (user.birthday && dayjs().diff(dayjs(user.birthday), "year") == 0) {
                profile.birthday = "";
            } else {
                profile.birthday = user.birthday;
            }
            profile.showage = user.showage;
            profile.mytwitterx = removeWhitespaces(user.mytwitterx);
            profile.myreddit = removeWhitespaces(user.myreddit);
            profile.mytelegram = removeWhitespaces(user.mytelegram);
            profile.mylinkedin = removeWhitespaces(user.mylinkedin);
            profile.mygithub = removeWhitespaces(user.mygithub);
            profile.myyoutube = removeWhitespaces(user.myyoutube);
            profile.mytwitch = removeWhitespaces(user.mytwitch);
            profile.myinstagram = removeWhitespaces(user.myinstagram);
            profile.myfacebook = removeWhitespaces(user.myfacebook);
            profile.mytiktok = removeWhitespaces(user.mytiktok);
            profile.access = user.access;
            profile.role = user.role;
            profile.visibility = user.visibility;
            profile.message = user.message;

            const replacer = (key, value) => {
                if (typeof value === 'undefined')
                    return ""
                else return value;
            }
            var profileInfos = JSON.parse(JSON.stringify(profile, replacer));

            setSending(true)
            dispatch(profileActions.saveProfile(profileInfos));
        }


        // dispatch(discussionActions.getDiscussionList(spacename))
    }

    return (
        <div>
            <Dialog
                open={openProfilPopup}
                onClose={handleCloseProfilPopup}
                // PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.paper }}
                style={{ zIndex: 10000000000 }}
            >
                <Spinner show={l} />
                <DraggableShowProfilePopup openShowProfilePopup={openShowProfilePopup} handleCloseShowProfilePopup={handleCloseShowProfilePopup} createdby={user} setBalanceOnStar={setBalanceOnStar} starStyle={starStyle} showStar={true} profile={true} />
                <DraggableProfilAccessPopup openShowProfilAccessPopup={openShowProfilAccessPopup} handleCloseProfilAccessPopup={handleCloseProfilAccessPopup} />
                <DraggableProfilRolePopup openShowProfilRolePopup={openShowProfilRolePopup} handleCloseProfilRolePopup={handleCloseProfilRolePopup} />
                <DraggableProfilVisibilityPopup openShowProfilVisibilityPopup={openShowProfilVisibilityPopup} handleCloseProfilVisibilityPopup={handleCloseProfilVisibilityPopup} />
                <DraggableMessagePopup openShowMessagePopup={openShowMessagePopup} handleCloseMessagePopup={handleCloseMessagePopup} />

                <DialogTitle style={{ /*cursor: 'move',*/ color: theme === 'dark' ? '#fff' : '#000', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} id="draggable-dialog-title">
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={0}
                        style={{ display: 'flex', marginTop: 10 }}
                    >
                        <Item style={{ padding: 0, cursor: 'auto' }}>
                            <span style={{ fontSize: '1.3em', color: theme == 'dark' ? '#fff' : '#000' }}>My Profile</span>
                        </Item>
                        <Item style={{ cursor: 'pointer' }}>
                            <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={handleCloseProfilPopup}>
                                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><CancelIcon /></Box>
                            </Button>
                        </Item>
                    </Stack>

                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={0}
                        style={{ display: 'flex', marginTop: 10 }}
                    >
                        <Item style={{ padding: 0, cursor: 'pointer' }} onClick={handleOpenShowProfilePopup}><Avatar alt={(user && user.displayname) ? user.displayname : ((user && user.name) ? user.firstname + " " + user.name : user.firstname)} src={profileUrl} /></Item>
                        <Item style={{ cursor: 'pointer', color: theme === 'dark' ? "#fff" : '#000' }} onClick={handleOpenShowProfilePopup}>{(user && user.displayname) ? user.displayname : ((user && user.name) ? user.firstname + " " + user.name : user.firstname)}</Item>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={0}
                        style={{ display: 'flex', marginTop: 10 }}
                    >
                        <Item style={{ padding: 0 }}>
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                style={{ backgroundColor: "#166fe5", borderRadius: 20, marginTop: 8 }}
                                startIcon={<><ImageIcon /><EditIcon style={{ marginLeft: 8 }} /></>}
                            >
                                <VisuallyHiddenInput id={inputUpload} type="file" accept="image/x-png, image/gif, image/jpeg, .webp" onChange={handleFileUpload} />
                            </Button>
                        </Item>
                        <Item>
                            <Button variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0 }} onClick={handleDeleteProfilImage}>
                                <ImageIcon /><DeleteIcon style={{ marginLeft: 8 }} />
                            </Button>
                        </Item>
                    </Stack>

                    <p style={{ fontSize: '0.8em', fontWeight: 300, marginTop: 8, marginBottom: 0 }}>Enhance your profile by adding your social media links, making it easier for the community to connect with you across platforms</p>
                </DialogTitle>
                <DialogContent style={{ padding: 0 }}>
                    <DialogContentText style={{ backgroundColor: theme === 'dark' ? '#0D1F2D' : '#fff', color: theme === 'dark' ? '#fff' : '#000', marginBottom: 0 }}>
                        <Container style={{ overflow: 'auto' }}>
                            <Box style={{ paddingTop: 24, paddingBottom: 4 }}>
                                <TextField
                                    fullWidth
                                    id="displayname"
                                    placeholder={"Display Name"}
                                    // sx={styleTextFieldAdditional3}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon style={{ color: "#166fe5" }} />
                                            </InputAdornment>
                                        ),
                                        style: { color: theme === 'dark' ? '#fff' : '#000' } // light
                                    }}
                                    onChange={handleChange}
                                    value={user ? user.displayname : ""}
                                />
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={0}
                                    style={{ display: 'flex' }}
                                >
                                    <Item style={{ width: '100%', paddingLeft: 0, paddingBottom: 0 }}>
                                        {/* <div style={{ width: '80%' }}> */}
                                        <div>
                                            <TextField
                                                fullWidth
                                                id="atname"
                                                placeholder={"Username"}
                                                // sx={styleTextFieldAdditional3}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AlternateEmailIcon style={{ color: "#166fe5" }} />
                                                        </InputAdornment>
                                                    ),
                                                    style: { color: theme === 'dark' ? '#fff' : '#000' } // light
                                                }}
                                                onChange={handleChange}
                                                value={user ? user.atname : ""}
                                            />
                                            {exist && exist.exist ?
                                                existDone && (<FormHelperText error={true}>Username already exist</FormHelperText>)
                                                :
                                                existDone && (<FormHelperText sx={{ color: "#00ff00", paddingBottom: '8px' }} error={false}>Username available</FormHelperText>)
                                            }
                                        </div>
                                        {/* </div>                                       */}
                                    </Item>
                                    <Item>
                                        <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={checkExist}>
                                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><CheckBoxIcon /></Box>
                                            {/* <TipsAndUpdatesIcon style={{ color: "#0D1F2D", transform: 'rotate(180deg)' }} /> */}
                                        </Button>
                                    </Item>
                                </Stack>

                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={0}
                                    style={{ display: 'flex' }}
                                >
                                    <Item style={{ paddingLeft: 0, paddingBottom: 0 }}>
                                        {/* <div style={{ width: '80%' }}> */}
                                        <div>
                                            <Gender handleChange={handleChangeForGender} user={user} />
                                        </div>
                                        {/* </div>                                       */}
                                    </Item>
                                    <Item>
                                        <FormGroup style={{ color: theme === 'dark' ? "#fff" : '#000' }}>
                                            <FormControlLabel control={<Checkbox name="showgender" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={user && user.showgender} value={user && user.showgender} onChange={handleChangeForCheckBox} />} label="Show Gender" />
                                        </FormGroup>
                                    </Item>
                                </Stack>

                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={0}
                                    style={{ display: 'flex' }}
                                >
                                    <Item style={{ paddingLeft: 0, paddingBottom: 0 }}>
                                        <div>
                                            <div>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker
                                                            label="Birthday"
                                                            fullWidth
                                                            sx={{
                                                                svg: { color: theme === 'dark' ? "#fff" : '#000' },
                                                                input: { color: theme === 'dark' ? "#fff" : '#000' },
                                                                label: { color: '#A9A9A9' },
                                                                helperText: { color: theme === 'dark' ? "#fff" : '#000' }
                                                            }}
                                                            onChange={handleChangeForDate('birthday')}
                                                            value={user && user.birthday ? dayjs(user.birthday) : ""}
                                                            slotProps={{
                                                                popper: {
                                                                    disablePortal: true
                                                                }
                                                            }}
                                                        // slotProps={{
                                                        //     textField: {
                                                        //         helperText: 'MM/DD/YYYY',
                                                        //     },
                                                        // }}
                                                        // renderInput={(params) => {
                                                        //     return (
                                                        //         <TextField
                                                        //             {...params}
                                                        //             sx={{
                                                        //                 svg: { color: "#166fe5" },
                                                        //                 input: { color : "#166fe5" },
                                                        //                 label: { color : "#166fe5" }
                                                        //             }}
                                                        //         />
                                                        //     );
                                                        // }}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                            <FormHelperText sx={{ color: theme === 'dark' ? "#fff" : '#000', paddingBottom: '8px' }}>MM/DD/YYYY</FormHelperText>
                                        </div>
                                    </Item>
                                    <Item>
                                        <FormGroup style={{ color: theme === 'dark' ? "#fff" : '#000', marginBottom: 15 }}>
                                            <FormControlLabel control={<Checkbox name="showage" sx={{ color: theme == 'dark' ? '#fff' : '#000' }} checked={user && user.showage} value={user && user.showage} onChange={handleChangeForCheckBox} />} label="Show Age" />
                                        </FormGroup>
                                    </Item>
                                </Stack>


                                <div>
                                    <TextField
                                        fullWidth
                                        id="description"
                                        multiline
                                        rows={5}
                                        placeholder={"About me..."}
                                        sx={styleTextField}
                                        InputProps={{
                                            style: { color: theme === 'dark' ? "#fff" : '#000' }, // light
                                        }}
                                        onChange={handleChange}
                                        value={user ? user.description : ""}
                                    /*value={inputText}
                                    onChange={handleChange}
                                    onKeyUp={startTyping}*/
                                    // error={inputText.length > characterLimit}
                                    // helperText={inputText.length > characterLimit ? "" : ""}
                                    />
                                    {(inputText && inputText.length) > characterLimit ? (<FormHelperText error={true}>Long Text</FormHelperText>) : null}
                                    <span style={{ color: theme === 'dark' ? "#fff" : '#000', float: "right" }}>{(inputText && inputText.length) ? inputText.length : 0}/{characterLimit}</span>
                                </div>

                                <TextField
                                    fullWidth
                                    id="mytwitterx"
                                    placeholder={"My TwitterX Profile Url"}
                                    // sx={styleTextFieldAdditional3}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <XIcon style={{ color: "#166fe5" }} />
                                            </InputAdornment>
                                        ),
                                        style: { color: theme === 'dark' ? "#fff" : '#000' } // light
                                    }}
                                    onChange={handleChange}
                                    value={user ? user.mytwitterx : ""}
                                    style={{ marginTop: 15 }}
                                />
                                <div>
                                    {user && user.mytwitterx && !isValidUrl(user.mytwitterx) ? (<FormHelperText error={true}>No Valid Url</FormHelperText>) : null}
                                </div>
                            </Box>
                            <Box style={{ paddingTop: 4, paddingBottom: 4 }}>
                                <TextField
                                    fullWidth
                                    id="myreddit"
                                    placeholder={"My Reddit Profile Url"}
                                    // sx={styleTextFieldAdditional3}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <RedditIcon style={{ color: "#166fe5" }} />
                                            </InputAdornment>
                                        ),
                                        style: { color: theme === 'dark' ? "#fff" : '#000' } // light
                                    }}
                                    onChange={handleChange}
                                    value={user ? user.myreddit : ""}
                                />
                                <div>
                                    {user && user.myreddit && !isValidUrl(user.myreddit) ? (<FormHelperText error={true}>No Valid Url</FormHelperText>) : null}
                                </div>
                            </Box>
                            <Box style={{ paddingTop: 4, paddingBottom: 4 }}>
                                <TextField
                                    fullWidth
                                    id="mytelegram"
                                    placeholder={"My Telegram Profile Url"}
                                    // sx={styleTextFieldAdditional3}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <TelegramIcon style={{ color: "#166fe5" }} />
                                            </InputAdornment>
                                        ),
                                        style: { color: theme === 'dark' ? "#fff" : '#000' } // light
                                    }}
                                    onChange={handleChange}
                                    value={user ? user.mytelegram : ""}
                                />
                                <div>
                                    {user && user.mytelegram && !isValidUrl(user.mytelegram) ? (<FormHelperText error={true}>No Valid Url</FormHelperText>) : null}
                                </div>
                            </Box>
                            <Box style={{ paddingTop: 4, paddingBottom: 4 }}>
                                <TextField
                                    fullWidth
                                    id="mylinkedin"
                                    placeholder={"My LinkedIn Profile Url"}
                                    // sx={styleTextFieldAdditional3}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LinkedInIcon style={{ color: "#166fe5" }} />
                                            </InputAdornment>
                                        ),
                                        style: { color: theme === 'dark' ? "#fff" : '#000' } // light
                                    }}
                                    onChange={handleChange}
                                    value={user ? user.mylinkedin : ""}
                                />
                                <div>
                                    {user && user.mylinkedin && !isValidUrl(user.mylinkedin) ? (<FormHelperText error={true}>No Valid Url</FormHelperText>) : null}
                                </div>
                            </Box>
                            <Box style={{ paddingTop: 4, paddingBottom: 4 }}>
                                <TextField
                                    fullWidth
                                    id="mygithub"
                                    placeholder={"My GitHub Profile Url"}
                                    // sx={styleTextFieldAdditional3}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <GitHubIcon style={{ color: "#166fe5" }} />
                                            </InputAdornment>
                                        ),
                                        style: { color: theme === 'dark' ? "#fff" : '#000' } // light
                                    }}
                                    onChange={handleChange}
                                    value={user ? user.mygithub : ""}
                                />
                                <div>
                                    {user && user.mygithub && !isValidUrl(user.mygithub) ? (<FormHelperText error={true}>No Valid Url</FormHelperText>) : null}
                                </div>
                            </Box>
                            <Box style={{ paddingTop: 4, paddingBottom: 4 }}>
                                <TextField
                                    fullWidth
                                    id="myyoutube"
                                    placeholder={"My Youtube Channel Url"}
                                    // sx={styleTextFieldAdditional3}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <YouTubeIcon style={{ color: "#166fe5" }} />
                                            </InputAdornment>
                                        ),
                                        style: { color: theme === 'dark' ? "#fff" : '#000' } // light
                                    }}
                                    onChange={handleChange}
                                    value={user ? user.myyoutube : ""}
                                />
                                <div>
                                    {user && user.myyoutube && !isValidUrl(user.myyoutube) ? (<FormHelperText error={true}>No Valid Url</FormHelperText>) : null}
                                </div>
                            </Box>
                            <Box style={{ paddingTop: 4, paddingBottom: 4 }}>
                                <TextField
                                    fullWidth
                                    id="mytwitch"
                                    placeholder={"My Twitch Channel Url"}
                                    // sx={styleTextFieldAdditional3}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <TwitchBlueIcon />
                                            </InputAdornment>
                                        ),
                                        style: { color: theme === 'dark' ? "#fff" : '#000' } // light
                                    }}
                                    onChange={handleChange}
                                    value={user ? user.mytwitch : ""}
                                />
                                <div>
                                    {user && user.mytwitch && !isValidUrl(user.mytwitch) ? (<FormHelperText error={true}>No Valid Url</FormHelperText>) : null}
                                </div>
                            </Box>
                            <Box style={{ paddingTop: 4, paddingBottom: 4 }}>
                                <TextField
                                    fullWidth
                                    id="myinstagram"
                                    placeholder={"My Instagram Profile Url"}
                                    // sx={styleTextFieldAdditional3}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <InstagramIcon style={{ color: "#166fe5" }} />
                                            </InputAdornment>
                                        ),
                                        style: { color: theme === 'dark' ? "#fff" : '#000' } // light
                                    }}
                                    onChange={handleChange}
                                    value={user ? user.myinstagram : ""}
                                />
                                <div>
                                    {user && user.myinstagram && !isValidUrl(user.myinstagram) ? (<FormHelperText error={true}>No Valid Url</FormHelperText>) : null}
                                </div>
                            </Box>
                            <Box style={{ paddingTop: 4, paddingBottom: 4 }}>
                                <TextField
                                    fullWidth
                                    id="myfacebook"
                                    placeholder={"My Facebook Profile Url"}
                                    // sx={styleTextFieldAdditional3}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FacebookIcon style={{ color: "#166fe5" }} />
                                            </InputAdornment>
                                        ),
                                        style: { color: theme === 'dark' ? "#fff" : '#000' } // light
                                    }}
                                    onChange={handleChange}
                                    value={user ? user.myfacebook : ""}
                                />
                                <div>
                                    {user && user.myfacebook && !isValidUrl(user.myfacebook) ? (<FormHelperText error={true}>No Valid Url</FormHelperText>) : null}
                                </div>
                            </Box>
                            <Box style={{ paddingTop: 4, paddingBottom: 10 }}>
                                <TextField
                                    fullWidth
                                    id="mytiktok"
                                    placeholder={"My TikTok Profile Url"}
                                    // sx={styleTextFieldAdditional3}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <TikTokBlueIcon />
                                            </InputAdornment>
                                        ),
                                        style: { color: theme === 'dark' ? "#fff" : '#000' } // light
                                    }}
                                    onChange={handleChange}
                                    value={user ? user.mytiktok : ""}
                                />
                                <div>
                                    {user && user.mytiktok && !isValidUrl(user.mytiktok) ? (<FormHelperText error={true}>No Valid Url</FormHelperText>) : null}
                                </div>
                            </Box>

                            {(user && user.role && user.role.includes("ADMIN")) ?
                                <>
                                    <ProfilRole handleOpenProfilRolePopup={handleOpenProfilRolePopup} handleChange={handleChangeForProfilRole} user={user} />
                                    <br /><br />
                                </>
                                : null}

                            <ProfilVisibility handleOpenProfilVisibilityPopup={handleOpenProfilVisibilityPopup} handleChange={handleChangeForProfilVisibility} user={user} />
                            <br /><br />

                            {(user && user.visibility && user.visibility.includes("VISIBLE")) ?
                                <>
                                    <ProfilAccess handleOpenProfilAccessPopup={handleOpenProfilAccessPopup} handleChange={handleChangeForProfilAccess} user={user} />
                                    <br /><br />
                                </>
                                : null}

                            <ProfilAllowMessage handleOpenMessagePopup={handleOpenMessagePopup} handleChange={handleChangeForMessage} user={user} />
                            <br /><br />


                            {(user && user.profilcardUrl) || (onlyProfilcard) ?
                                <Box style={{ paddingTop: 4, paddingBottom: 4 }}>
                                    <Stack
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={0}
                                        style={{ display: 'flex', marginTop: 10 }}
                                    >
                                        <Item style={{ padding: 0 }}>
                                            <p>
                                                <span style={{ marginRight: 10, color: theme === 'dark' ? '#fff' : '#000' }}>My Social Card</span>
                                                {user && user.visibility && user.visibility.includes("VISIBLE") && user.access && user.access.includes("PUBLIC") && user.atname ?
                                                    <>
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="flex-start"
                                                            alignItems="flex-start"
                                                            spacing={0}
                                                            style={{ display: 'inline-flex' }}
                                                        >
                                                            <Item>
                                                                <Button title={"Update your Social Card"} size="small" variant="contained" onClick={makeProfilCard} style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }}>
                                                                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><CloudSyncSharpIcon /></Box>
                                                                </Button>
                                                            </Item>
                                                            <Item>
                                                                <Share shareStyle={shareStyle} title={title} post={user ? user.description : ""} path={user ? user.atname : ""} preUrl={preUrlProfil} profilsharing={true} handleOpenShowWarningProfil={handleOpenShowWarningProfil} />
                                                            </Item>
                                                        </Stack>
                                                    </>

                                                    :
                                                    null}

                                                <DraggableWarningProfilPopup openWarningProfilPopup={openWarningProfilPopup} handleCloseWarningProfilPopup={handleCloseWarningProfilPopup} />
                                            </p>
                                        </Item>
                                        <Item style={{ padding: 8 }}>
                                            <ProfilCard
                                                name={user ? user.name : ""}
                                                firstname={user ? user.firstname : ""}
                                                atname={user ? user.atname : ""}
                                                pictureUrl={previewBeforeSending}
                                                displayname={user ? user.displayname : ""}
                                                mytwitterx={user ? user.mytwitterx : ""}
                                                myreddit={user ? user.myreddit : ""}
                                                mytelegram={user ? user.mytelegram : ""}
                                                mylinkedin={user ? user.mylinkedin : ""}
                                                mygithub={user ? user.mygithub : ""}
                                                myyoutube={user ? user.myyoutube : ""}
                                                mytwitch={user ? user.mytwitch : ""}
                                                myinstagram={user ? user.myinstagram : ""}
                                                myfacebook={user ? user.myfacebook : ""}
                                                mytiktok={user ? user.mytiktok : ""}
                                                profilViewid={"myprofilcard"}
                                            />
                                        </Item>
                                    </Stack>
                                </Box>
                                :
                                <Button size="large" variant="contained" style={{ borderRadius: 20, marginBottom: 10 }} onClick={makeProfilCard}>
                                    Make your Social Card
                                </Button>
                            }
                        </Container>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ color: '#fff', background: theme === 'dark' ? '#0D1F2D' : '#fff', padding: 24 }} > {/* light */}
                    {/* <Button onClick={handleCloseProfilPopup} variant="contained" color="error">
                        Delete
                    </Button> */}
                    <Button onClick={() => { Logout(); handleCloseProfilPopup() }} variant="contained">
                        Logout
                    </Button>
                    <Button onClick={() => handleSubmit()} variant="contained">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useProfileStyles = makeStyles(theme => ({
    paper: {
        overflow: 'hidden !important',
        width: 300,
        '@media (min-width: 600px) and (max-width: 800px)': {
            width: 300
        },

        '@media (min-width: 800px)': {
            width: 300
        }
    },
    customizedButton: {
        backgroundColor: '#007bff',
        color: '#fff',
        float: 'right',
    },
}));

export const DraggableStatusPopup = ({ openShowStatusPopup, handleCloseStatusPopup, myAppreciation, banned, suspended, profil }) => {
    const classes = useProfileStyles();

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    return (
        <div>
            <Dialog
                open={openShowStatusPopup}
                onClose={handleCloseStatusPopup}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.paper }}
                style={{ zIndex: 10000000000 }}
            >
                <DialogTitle style={{ cursor: 'move', color: theme === 'dark' ? '#fff' : '#000', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} id="draggable-dialog-title">
                    {profil ?
                        <>
                            {!suspended && !banned && <span>Account Status: Good</span>}
                            {suspended && <span>Account Suspended</span>}
                            {banned && <span>Account Banned</span>}
                        </>
                        :
                        <>
                            {!myAppreciation?.suspended && !myAppreciation?.banned && <span>Account Status: Good</span>}
                            {myAppreciation?.suspended && <span>Account Suspended</span>}
                            {myAppreciation?.banned && <span>Account Banned</span>}
                        </>}
                </DialogTitle>
                <DialogContent style={{ padding: 0, overflowY: 'auto', }}>
                    <DialogContentText style={{ backgroundColor: theme === 'dark' ? '#0D1F2D' : '#fff', color: theme === 'dark' ? '#fff' : '#000', padding: 10, textAlign: 'center' }}>
                        {profil ?
                            <>
                                {!suspended && !banned && <span>Your account is in good standing. Enjoy sharing news and connecting with others!</span>}
                                {suspended && <span>You're suspended for 48 hours due to guideline violations. Sharing, commenting and messaging are restricted. Please review our guidelines.</span>}
                                {banned && <span>Your account is banned. Consider appealing for reinstatement.</span>}
                            </>
                            :
                            <>
                                {!myAppreciation?.suspended && !myAppreciation?.banned && <span>Your account is in good standing. Enjoy sharing news and connecting with others!</span>}
                                {myAppreciation?.suspended && <span>You're suspended for 48 hours due to guideline violations. Sharing, commenting and messaging are restricted. Please review our guidelines.</span>}
                                {myAppreciation?.banned && <span>Your account is banned. Consider appealing for reinstatement.</span>}
                            </>}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ color: '#fff', background: theme === 'dark' ? '#0D1F2D' : '#fff' }}>
                    <Button onClick={handleCloseStatusPopup} variant="contained">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export const DraggableProfilAccessPopup = ({ openShowProfilAccessPopup, handleCloseProfilAccessPopup }) => {
    const classes = useProfileStyles();

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    return (
        <div>
            <Dialog
                open={openShowProfilAccessPopup}
                onClose={handleCloseProfilAccessPopup}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.paper }}
                style={{ zIndex: 10000000000 }}
            >
                <DialogTitle style={{ cursor: 'move', color: theme === 'dark' ? '#fff' : '#000', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} id="draggable-dialog-title">
                    Profil Access
                </DialogTitle>
                <DialogContent style={{ padding: 0, overflowY: 'auto', }}>
                    <DialogContentText style={{ backgroundColor: theme === 'dark' ? '#0D1F2D' : '#fff', color: theme === 'dark' ? '#fff' : '#000', padding: 10, textAlign: 'left' }}>
                        <ul>
                            <li><p><b>Public :</b> Your profile can be viewed by anyone on the Internet</p></li>
                            <li><p><b>Private :</b> Your profile is visible only within the OneLike Space</p></li>
                        </ul>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ color: '#fff', background: theme === 'dark' ? '#0D1F2D' : '#fff' }}>
                    <Button onClick={handleCloseProfilAccessPopup} variant="contained">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export const DraggableProfilVisibilityPopup = ({ openShowProfilVisibilityPopup, handleCloseProfilVisibilityPopup }) => {
    const classes = useProfileStyles();

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    return (
        <div>
            <Dialog
                open={openShowProfilVisibilityPopup}
                onClose={handleCloseProfilVisibilityPopup}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.paper }}
                style={{ zIndex: 10000000000 }}
            >
                <DialogTitle style={{ cursor: 'move', color: theme === 'dark' ? '#fff' : '#000', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} id="draggable-dialog-title">
                    Profil Visibility
                </DialogTitle>
                <DialogContent style={{ padding: 0, overflowY: 'auto', }}>
                    <DialogContentText style={{ backgroundColor: theme === 'dark' ? '#0D1F2D' : '#fff', color: theme === 'dark' ? '#fff' : '#000', padding: 10, textAlign: 'left' }}>
                        <ul>
                            <li><p><b>Visible :</b> Your profile can be set to public or private access</p></li>
                            <li><p><b>Hidden :</b> Your profile will not appear in the profile list</p></li>
                        </ul>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ color: '#fff', background: theme === 'dark' ? '#0D1F2D' : '#fff' }}>
                    <Button onClick={handleCloseProfilVisibilityPopup} variant="contained">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export function ProfilAccess({ handleOpenProfilAccessPopup, handleChange, user }) {
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    return (
        <FormControl>
            <FormLabel id="profil-access" style={{ color: theme == 'dark' ? "#fff" : "#000" }}>
                <span style={{ marginRight: 10 }}>Access</span>
                <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={handleOpenProfilAccessPopup}>
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><InfoIcon /></Box>
                </Button>
            </FormLabel>
            <RadioGroup
                row
                aria-labelledby="profil-access"
                name="access"
                onChange={handleChange}
                defaultValue={user && user.access ? user.access : "PUBLIC"}
            >
                <FormControlLabel value={"PUBLIC"} control={<Radio sx={{ color: theme == 'dark' ? '#fff' : '#000' }} />} label="Public" />
                <FormControlLabel value={"PRIVATE"} control={<Radio sx={{ color: theme == 'dark' ? '#fff' : '#000' }} />} label="Private" />
            </RadioGroup>
        </FormControl>
    );
}

export const DraggableProfilRolePopup = ({ openShowProfilRolePopup, handleCloseProfilRolePopup }) => {
    const classes = useProfileStyles();

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    return (
        <div>
            <Dialog
                open={openShowProfilRolePopup}
                onClose={handleCloseProfilRolePopup}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.paper }}
                style={{ zIndex: 10000000000 }}
            >
                <DialogTitle style={{ cursor: 'move', color: theme === 'dark' ? '#fff' : '#000', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} id="draggable-dialog-title">
                    Role
                </DialogTitle>
                <DialogContent style={{ padding: 0, overflowY: 'auto', }}>
                    <DialogContentText style={{ backgroundColor: theme === 'dark' ? '#0D1F2D' : '#fff', color: theme === 'dark' ? '#fff' : '#000', padding: 10, textAlign: 'left' }}>
                        <ul>
                            <li><p><b>User :</b> Share, Comment, make Discussions in the OneLike Space</p></li>
                            <li><p><b>Moderator :</b> Moderate the OneLike Space for Security and Safety</p></li>
                        </ul>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ color: '#fff', background: theme === 'dark' ? '#0D1F2D' : '#fff' }}>
                    <Button onClick={handleCloseProfilRolePopup} variant="contained">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export const DraggableMessagePopup = ({ openShowMessagePopup, handleCloseMessagePopup }) => {
    const classes = useProfileStyles();

    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    useEffect(() => {
        setTheme(t);
    }, [t])

    return (
        <div>
            <Dialog
                open={openShowMessagePopup}
                onClose={handleCloseMessagePopup}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.paper }}
                style={{ zIndex: 10000000000 }}
            >
                <DialogTitle style={{ cursor: 'move', color: theme === 'dark' ? '#fff' : '#000', background: theme === 'dark' ? '#0D1F2D' : '#fff' }} id="draggable-dialog-title">
                    Message
                </DialogTitle>
                <DialogContent style={{ padding: 0, overflowY: 'auto', }}>
                    <DialogContentText style={{ backgroundColor: theme === 'dark' ? '#0D1F2D' : '#fff', color: theme === 'dark' ? '#fff' : '#000', padding: 10, textAlign: 'left' }}>
                        <ul>
                            <li><p><b>Enable :</b> Enable the Private Message or Chat Service</p></li>
                            <li><p><b>Disable :</b> Disable the Private Message or Chat Service</p></li>
                        </ul>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ color: '#fff', background: theme === 'dark' ? '#0D1F2D' : '#fff' }}>
                    <Button onClick={handleCloseMessagePopup} variant="contained">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export function ProfilRole({ handleOpenProfilRolePopup, handleChange, user }) {
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    return (
        <FormControl>
            <FormLabel id="profil-role" style={{ color: theme == 'dark' ? "#fff" : "#000" }}>
                <span style={{ marginRight: 10 }}>Role</span>
                <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={handleOpenProfilRolePopup}>
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><InfoIcon /></Box>
                </Button>
            </FormLabel>
            <RadioGroup
                row
                aria-labelledby="profil-role"
                name="role"
                onChange={handleChange}
                defaultValue={user && user.role ? user.role : "USER"}
            >
                <FormControlLabel value={"USER"} control={<Radio sx={{ color: theme == 'dark' ? '#fff' : '#000' }} />} label="User" />
                <FormControlLabel value={"MODERATOR"} control={<Radio sx={{ color: theme == 'dark' ? '#fff' : '#000' }} />} label="Moderator" />
            </RadioGroup>
        </FormControl>
    );
}

export function ProfilVisibility({ handleOpenProfilVisibilityPopup, handleChange, user }) {
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    return (
        <FormControl>
            <FormLabel id="profil-visibility" style={{ color: theme == 'dark' ? "#fff" : "#000" }}>
                <span style={{ marginRight: 10 }}>Visibility</span>
                <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={handleOpenProfilVisibilityPopup}>
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><InfoIcon /></Box>
                </Button>
            </FormLabel>
            <RadioGroup
                row
                aria-labelledby="profil-visibility"
                name="visibility"
                onChange={handleChange}
                defaultValue={user && user.visibility ? user.visibility : "VISIBLE"}
            >
                <FormControlLabel value={"VISIBLE"} control={<Radio sx={{ color: theme == 'dark' ? '#fff' : '#000' }} />} label="Visible" />
                <FormControlLabel value={"HIDDEN"} control={<Radio sx={{ color: theme == 'dark' ? '#fff' : '#000' }} />} label="Hidden" />
            </RadioGroup>
        </FormControl>
    );
}

export function ProfilAllowMessage({ handleOpenMessagePopup, handleChange, user }) {
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    return (
        <FormControl>
            <FormLabel id="profil-allow-message" style={{ color: theme == 'dark' ? "#fff" : "#000" }}>
                <span style={{ marginRight: 10 }}>Message</span>
                <Button size="small" variant="contained" style={{ backgroundColor: "#166fe5", borderRadius: 20, minWidth: 0, padding: 5 }} onClick={handleOpenMessagePopup}>
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 20 }}><InfoIcon /></Box>
                </Button>
            </FormLabel>
            <RadioGroup
                row
                aria-labelledby="profil-allow-message"
                name="message"
                onChange={handleChange}
                defaultValue={user && user.message ? user.message : "YES"}
            >
                <FormControlLabel value={"YES"} control={<Radio sx={{ color: theme == 'dark' ? '#fff' : '#000' }} />} label="Enable" />
                <FormControlLabel value={"NO"} control={<Radio sx={{ color: theme == 'dark' ? '#fff' : '#000' }} />} label="Disable" />
            </RadioGroup>
        </FormControl>
    );
}

export function Gender({ handleChange, user }) {
    const t = useSelector(state => state.themeswitch);
    const [theme, setTheme] = useState(t);

    return (
        <FormControl>
            {/* <InputLabel id="profil-gender">Gender</InputLabel> */}
            <Select
                labelId="profil-gender"
                id="gender"
                value={user && user.gender ? user.gender : "NONE"}
                onChange={handleChange}
                sx={{
                    color: theme == 'dark' ? '#fff' : '#000',
                    '.MuiSvgIcon-root ': {
                        fill: theme == 'dark' ? "white !important" : "#166FE5 !important",
                    },
                }}
                MenuProps={{
                    style: { zIndex: 10000000010 }
                }}

            >
                <MenuItem value={"NONE"}>
                    <em>Gender</em>
                </MenuItem>
                <MenuItem value={"FEMALE"}>Female</MenuItem>
                <MenuItem value={"MALE"}>Male</MenuItem>
            </Select>
        </FormControl>
    )
}

