export const spaceswitchConstants = {
    FEED: 'FEED',
    ALL_SPACES: 'ALL_SPACES',
    MY_SPACE: 'MY_SPACE',
    LIGHT: 'LIGHT',
    STAR: 'STAR',
    MESSAGE: 'MESSAGE',
    FIND: 'FIND',

    FEED_NR: 0,
    ALL_SPACES_NR: 1,
    MY_SPACE_NR: 2,
    LIGHT_NR: 3,
    STAR_NR: 4,
    MESSAGE_NR: 5,
    FIND_NR: 6
};